import PropTypes from 'prop-types';
import Immutable from 'immutable';
import FontAwesome from 'react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Cell } from 'fixed-data-table';

import {
  recordTranslations,
  riskTranslations
} from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';
import { injectIntl } from 'react-intl';

const style = (theme) => ({
  tooltip: {
    backgroundColor: '#D87878',
    color: 'white',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold'
  }
});

// DataArray cell
export const DataRiskArrayCell = withStyles(style)(
  injectIntl(
    ({
      items,
      rowIndex,
      columnKey,
      defaultContent,
      tooltipId,
      intl,
      showRiskCountOnly,
      ...props
    }) => {
      const data = items.get(rowIndex)[columnKey] || [];
      const riskMessages = [];
      let riskLength = 0;
      Object.values(data).forEach((value) => {
        value.forEach((risk) => {
          if (!risk.isIgnored) {
            riskMessages.push(riskTranslations(risk.riskMessage));
            riskLength += 1;
          }
        });
      });

      const content = [];

      // riskMessages has the formattedMessages for each risks and
      // placeholders for each of them in values
      riskMessages.forEach((message) => {
        let dataItems = {};
        const placeholders = message.props.values;
        if (placeholders.values && placeholders.values.length > 0) {
          const holders = [];
          //  The placeholders that requires translations are done here and copied to dataItems
          // These are especially for global risks.
          placeholders.values.forEach((value) => {
            const translation = recordTranslations[value];
            holders.push(intl.formatMessage(translation.props));
            return holders;
          });
          dataItems = { values: holders.join(', ') };
        } else {
          // The placeholders that does not require translations are directly copied to dataItems
          dataItems = placeholders;
        }
        content.push(intl.formatMessage(message.props, dataItems));
        return content;
      });

      const { classes } = props;
      const values = tooltipId && content.join(', ');
      const tooltip =
        tooltipId &&
        content.map((item) => (
          <span>
            {item}
            <br />
          </span>
        ));

      const hasNoRisk = Object.values(data).every((item) => item.length === 0);

      return (
        <Cell {...props}>
          {hasNoRisk ? (
            <div
              style={{
                color: 'green',
                width: '250px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {showRiskCountOnly ? (
                <div>{recordTranslations.none}</div>
              ) : (
                <div>
                  <FontAwesome
                    name="check"
                    size="lg"
                    style={{ lineHeight: '1em' }}
                  />
                  <span style={{ fontWeight: 'bold', paddingLeft: '15px' }}>
                    {riskLength}
                  </span>
                  <span style={{ paddingLeft: '15px' }}>
                    {' '}
                    {defaultContent}{' '}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={tooltip}
              arrow={true}
            >
              <div style={{ width: '380px' }}>
                {showRiskCountOnly ? (
                  <div>
                    <span>{riskLength}</span>
                    <span style={{ paddingLeft: '10px' }}>
                      {recordTranslations.Risks}
                    </span>
                  </div>
                ) : (
                  <div style={styles.textWithEllipsis}>
                    <FontAwesome
                      name="warning"
                      size="lg"
                      style={{ lineHeight: '1em' }}
                    />
                    <span style={{ fontWeight: 'bold', paddingLeft: '15px' }}>
                      {riskLength}
                    </span>
                    <span style={{ paddingLeft: '15px' }}> {values} </span>
                  </div>
                )}
              </div>
            </Tooltip>
          )}
        </Cell>
      );
    }
  )
);

DataRiskArrayCell.propTypes = {
  items: PropTypes.instanceOf(Immutable.List),
  rowIndex: PropTypes.number,
  columnKey: PropTypes.string,
  defaultContent: PropTypes.node,
  tooltipId: PropTypes.string
};

DataRiskArrayCell.defaultProps = {
  items: Immutable.List(),
  rowIndex: -1,
  columnKey: '',
  tooltipId: '',
  defaultContent: null
};
