import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Column } from 'fixed-data-table';
import { injectIntl } from 'react-intl';

import Note from '@packages/components/note';
import {
  ResponsiveTableWrapper,
  SortHeaderCell,
  DataCell,
  DateCell,
  InformationCell,
  ActionCell
} from '@packages/components/responsive-table';
import {
  statusValueMapper,
  commonTranslations,
  recordTranslations,
  deleteRecord,
  customTranslation
} from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';
import {
  RECORDS_FETCH_LIMIT,
  getEmbedContainerHeight
} from '@packages/utils/common-utils';
import { getQueryStrings } from '@packages/utils/query-parameters';
import CustomDialog from '@packages/components/custom-dialog';
import {
  filterItem,
  getModifiedActions,
  statusColorCodes
} from '@packages/utils/record-utils';

import {
  shouldHideMenuItem,
  getRecordStatusFilterValues,
  CheckBoxCell,
  recordRiskFilterValues
} from '../../record-utils';
import NotificationDialogBox from '../../../privacy-record-detail/components/notificationDialog';
import { DataRiskArrayCell } from './components/data-risk-array-cell/dataRiskArrayCell';

const actionItems = [
  {
    action: 'graphicalView',
    primaryText: recordTranslations.graphicalView
  },
  {
    action: 'addNote',
    primaryText: commonTranslations.addNote
  },
  {
    action: 'editNote',
    primaryText: commonTranslations.editNote
  },
  {
    action: 'viewNote',
    primaryText: commonTranslations.viewNote
  },
  {
    action: 'removeNote',
    primaryText: commonTranslations.removeNote
  },
  {
    action: 'changelog',
    primaryText: recordTranslations.viewChangeLog
  },
  {
    action: 'copyRecord',
    primaryText: recordTranslations.copyRecord
  }
];

export const AssessmentRecordList = (props, context) => {
  const {
    isFetching,
    items,
    pageSearchText,
    filteredOn,
    isEditable,
    pricingPlan,
    isVendor,
    sortKey,
    sortOrder,
    filterColumn,
    locale,
    allowPDFDownload,
    isPublic,
    registryDetails,
    isPreview,
    embed,
    getNextData,
    onChooseFilter,
    onRecordSelection,
    selectedRecords,
    updateNote,
    deletePrivacyRecord,
    isUpdating,
    toggleLoader,
    copyAssessment,
    intl,
    position,
    recordLayoutId,
    onMount,
    isViewable,
    filterMenuData,
    canApprove,
    isDeletable,
    isGlobal
  } = props;

  const [open, setOpen] = useState(false);
  const [deleteNoteOpen, setDeleteNoteOpen] = useState(false);
  const [editNoteOpen, setEditNoteOpen] = useState(false);
  const [viewNoteOpen, setViewNoteOpen] = useState(false);
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [content, setContent] = useState(undefined);
  const [recordName, setRecordName] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentSelectedRecords, setCurrentSelectedRecords] = useState(
    Immutable.List()
  );
  const [recordId, setRecordId] = useState(-1);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [isInSync, setIsInSync] = useState(false);

  useEffect(() => {
    const isPublic = window.location.pathname.includes('public');
    if (isPublic && registryDetails.size > 0 && onMount) {
      /* when a language switch happens via the language menu an unnecessary render triggers
       * which ends in creating duplicate records. Hence checking if the registryDetails are
       * available and ready to be used.
       */
      onMount();
    } else if (!isPublic && onMount) {
      onMount(recordLayoutId);
    }
  }, []);

  useEffect(() => {
    if (isDelete) {
      getNextData(position, 1, recordLayoutId); // rowCount=1
      setIsDelete(false);
    }
    toggleLoader(isUpdating || isFetching);
    if (selectedRecords.length) {
      setCurrentSelectedRecords(selectedRecords);
    }
  }, [items.size, isUpdating, isFetching]);

  const onCheckHandler = (event, item) => {
    const selectedIndex = currentSelectedRecords.findIndex(
      (el) => el.id === item.id
    );
    const selectedRecords = [...currentSelectedRecords];
    if (event.target.checked) {
      selectedRecords.push({ id: item.id });
    } else {
      selectedRecords.splice(selectedIndex, 1);
    }
    setCurrentSelectedRecords(selectedRecords);
    onRecordSelection(selectedRecords);
  };

  const onBulkExportReset = (event) => {
    if (!event.target.checked) {
      setCurrentSelectedRecords([]);
      onRecordSelection([]);
    }
  };

  const getContainerHeight = () => {
    // headerHeight: 80 + tabHeight: 50 + tableHeader: 50 + whiteSpace: 20
    if (isVendor) return window.innerHeight - 200;
    // headerHeight 80 + margin 10
    else if (embed) return window.innerHeight - 90;
    // navbar height: 100 + header: 65 + whitespace: 25
    else if (isPublic) return window.innerHeight - 190;
    // navbar: 100 + messageBanner: 50 + header: 65 + whiteSpace: 15
    return window.innerHeight - 230;
  };

  const handleDeleteRecord = () => {
    setIsDelete(true);
    deletePrivacyRecord(recordId);
    handleClose();
  };

  const handleRowClick = (e, index) => {
    const { id } = items.get(index);
    const url = `/assessment/${id}/view`;
    if (isVendor) {
      const location =
        window.location.origin ||
        `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }`;
      window.open(`${location}/#${url}`, '_blank');
    } else if (isViewable) {
      const hashURL = window.location.hash;
      const urlParams = getQueryStrings(hashURL);
      const location =
        urlParams.from === 'overview' ? 'overview' : 'privacyrecords';
      context.router.history.push(`${url}?from=${location}`);
    } else if (isPublic) {
      const registryId = registryDetails.toJS().id;
      context.router.history.push(
        `/${registryId}${url}?from=publicList&embed=${embed}&isPreview=${isPreview}`
      );
    }
  };

  const handleEditRecordAction = (index) => {
    const hashURL = window.location.hash;
    const urlParams = getQueryStrings(hashURL);
    const { id, status, syncInfo } = items.get(index);

    if (
      status === 'record_status_requested' ||
      syncInfo?.isLibrarySyncEnabled
    ) {
      setOpenNotification(true);
      setIsInSync(syncInfo?.isLibrarySyncEnabled);
    } else if (isVendor) {
      const location =
        window.location.origin ||
        `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }`;
      window.open(`${location}/#/assessment/${id}/edit`, '_blank');
    } else if (urlParams.from === 'overview') {
      context.router.history.push(`/assessment/${id}/edit?from=overview`);
    } else {
      context.router.history.push(`/assessment/${id}/edit`);
    }
  };

  const handleDeleteRecordAction = (index) => {
    const { id, name, status, syncInfo } = items.get(index);
    if (
      (!canApprove && status === 'record_status_requested') ||
      syncInfo?.isLibrarySyncEnabled
    ) {
      setOpenNotification(true);
      setIsInSync(syncInfo?.isLibrarySyncEnabled);
    } else {
      handleOpen(id, name);
    }
  };

  const handleScrollEnd = (scrollX, scrollY) => {
    if (
      items.size >= RECORDS_FETCH_LIMIT &&
      40 * (items.size - 1) -
        (embed ? getEmbedContainerHeight() : getContainerHeight()) <=
        scrollY
    ) {
      getNextData(position, 50, recordLayoutId);
    }
    setIsDelete(false);
  };

  const handleRecordActions = (actionButton, index, action) => {
    const { id: recordId, name, note } = items.get(index);
    if (action === 'edit') {
      handleEditRecordAction(index);
    } else if (action === 'delete') {
      handleDeleteRecordAction(index);
    } else if (action === 'graphicalView') {
      context.router.history.push(`/assessment/${recordId}/graph`);
    } else if (action === 'changelog') {
      context.router.history.push(`/assessment/${recordId}/revisions`);
    } else if (action === 'addNote') {
      handleAddNoteOpen(recordId, name);
    } else if (action === 'editNote') {
      handleEditNoteOpen(recordId, name, note);
    } else if (action === 'viewNote') {
      handleViewNoteOpen(name, note);
    } else if (action === 'removeNote') {
      handleDeleteNoteOpen(recordId, name);
    } else if (action === 'copyRecord') {
      handleCopyRecord(name, recordId);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  const handleCopyRecord = (name, recordId) => {
    const copyOfName = {
      name: intl.formatMessage(customTranslation('copyOfName').props, {
        value: name
      })
    };
    copyAssessment(copyOfName, recordId, isGlobal);
  };

  const handleAddNoteOpen = (recordId, recordName) => {
    setAddNoteOpen(true);
    setRecordId(recordId);
    setRecordName(recordName);
  };

  const handleEditNoteOpen = (recordId, recordName, content) => {
    setEditNoteOpen(true);
    setRecordId(recordId);
    setRecordName(recordName);
    setContent(content);
  };

  const handleViewNoteOpen = (recordName, content) => {
    setViewNoteOpen(true);
    setRecordName(recordName);
    setContent(content);
  };

  const handleViewNote = (evt, rowIndex) => {
    evt.stopPropagation();
    const { name, note } = items.get(rowIndex);
    handleViewNoteOpen(name, note);
  };

  const handleDeleteNoteOpen = (recordId, recordName) => {
    setDeleteNoteOpen(true);
    setRecordId(recordId);
    setRecordName(recordName);
    setContent('');
  };

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  const handleEditNote = (event, note) => {
    const noteContent = note || content;
    if (
      recordId !== -1 &&
      (((addNoteOpen || editNoteOpen) && noteContent) || deleteNoteOpen)
    ) {
      updateNote(
        recordId,
        'note',
        noteContent.trim(),
        recordName,
        recordLayoutId
      );
    }
    requestNoteClose();
  };

  const handleMouseHover = (event, index) => {
    setCurrentIndex(index);
  };

  const requestNoteClose = () => {
    setRecordId(-1);
    setEditNoteOpen(false);
    setViewNoteOpen(false);
    setAddNoteOpen(false);
    setDeleteNoteOpen(false);
    setContent(undefined);
  };

  const filterData = (filterKey, filterParams) => {
    onChooseFilter({ ...filterParams, filterKey }, recordLayoutId);
  };

  const handleOpen = (recordId, recordName) => {
    setOpen(true);
    setRecordId(recordId);
    setRecordName(recordName);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordId(-1);
  };

  const handleFilterIcon = (show) => {
    setShowFilterIcon(show);
  };

  const handleMenuHidden = (menu, rowIndex, action) =>
    shouldHideMenuItem(
      action,
      rowIndex,
      items,
      isEditable,
      isViewable,
      isVendor
    );

  const handleMenuDisabled = (menu, rowIndex, action) =>
    action === 'copyRecord' && filterMenuData?.status === 'Inactive';

  const modifiedAction = getModifiedActions(pricingPlan, actionItems);

  const vendorNoContentStyle = {
    ...styles.tableNoContentStyle,
    top: 300,
    left: '45%'
  };

  const recordStatusFilterValues = getRecordStatusFilterValues(registryDetails);

  return (
    <div>
      {!isFetching && items.size === 0 && (
        <div
          style={isVendor ? vendorNoContentStyle : styles.tableNoContentStyle}
        >
          {commonTranslations.NoData}
        </div>
      )}
      <ResponsiveTableWrapper
        id="assessment_responsive_table"
        showActionSelector={!isPublic}
        actionChooserStyle={styles.actionChooserStyle}
        rowHeight={40}
        headerHeight={45}
        rowsCount={items.size}
        onRowClick={handleRowClick}
        actionItems={modifiedAction}
        onActionHandler={handleRecordActions}
        isHidden={handleMenuHidden}
        onScrollEnd={handleScrollEnd}
        actionChooserClass="actionChooserClass"
        onRowMouseEnter={handleMouseHover}
        height={getContainerHeight()}
        isDisabled={handleMenuDisabled}
        {...props}
      >
        <Column
          columnKey="bulkExport"
          visible={allowPDFDownload && !isVendor && !isPublic}
          header={
            <SortHeaderCell
              showFilterIcon={false}
              showBulkCheck={true}
              checkedItemsSize={currentSelectedRecords.length}
              onBulkReset={onBulkExportReset}
              enableSorting={false}
              tooltip={false}
            />
          }
          cell={
            <CheckBoxCell
              items={items}
              selectedRecordSize={currentSelectedRecords.length}
              selectedRecords={currentSelectedRecords}
              onCheck={onCheckHandler}
            />
          }
          width={50}
        />
        <Column
          id="privacyRecordNumber"
          columnKey="privacyRecordNumber"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              searchText={pageSearchText.get('privacyRecordNumber')}
              onChooseFilter={filterData}
              pageSearchStyle={{ width: '100px' }}
            >
              {recordTranslations.recordId}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          width={160}
        />
        <Column
          id="name"
          columnKey="name"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              searchText={pageSearchText.get('name')}
              onChooseFilter={filterData}
              tooltip={true}
            >
              {commonTranslations.name}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          flexGrow={2}
          width={100}
        />
        <Column
          id="status"
          visible={!isPublic}
          columnKey="status"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              enableFilter={true}
              filterValues={recordStatusFilterValues}
              filteredOn={filteredOn}
              sortKey={sortKey}
              sortOrder={sortOrder}
              filterColumn={filterColumn && filterColumn.status}
              onChooseFilter={filterData}
              filterItem={filterItem}
            >
              {recordTranslations.status}
            </SortHeaderCell>
          }
          cell={
            <DataCell
              items={items}
              mapper={statusValueMapper}
              styleMapper={statusColorCodes}
            />
          }
          width={190}
        />
        <Column
          columnKey="dpiaRisks"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableFilter={true}
              filterValues={recordRiskFilterValues}
              filteredOn={filteredOn}
              filterColumn={filterColumn && filterColumn.risks}
              onChooseFilter={filterData}
              showSelectAll={false}
            >
              {recordTranslations.outstandingRisks}
            </SortHeaderCell>
          }
          cell={<DataRiskArrayCell items={items} />}
          flexGrow={1}
          width={160}
          visible={isVendor}
        />
        <Column
          id="lastChanged"
          columnKey="lastChanged"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              onChooseFilter={filterData}
            >
              {' '}
              {recordTranslations.lastChanged}
            </SortHeaderCell>
          }
          cell={
            <DateCell dateFormat="DD MMM YYYY" items={items} locale={locale} />
          }
          width={180}
          visible={!isPublic}
        />
        <Column
          id="action-cell"
          columnKey="action-cell"
          align="center"
          visible={isEditable || isDeletable}
          cell={
            <ActionCell
              currentIndex={currentIndex}
              cellStyle={{ float: 'right' }}
              handleEdit={handleEditRecordAction}
              handleDelete={handleDeleteRecordAction}
              isEditHidden={!isEditable}
              isDeleteHidden={!isDeletable || isVendor}
            />
          }
          width={80}
        />
        <Column
          id="note"
          columnKey="note"
          align="center"
          cell={
            <InformationCell
              items={items}
              handleOnClick={handleViewNote}
              className="informationClass"
            />
          }
          width={30}
        />
      </ResponsiveTableWrapper>
      <CustomDialog
        title={commonTranslations.deletePrivacyRecord}
        id="delete_record_dialog"
        show={open}
        proceed={handleDeleteRecord}
        cancel={handleClose}
        content={deleteRecord(recordName)}
      />
      {(editNoteOpen || addNoteOpen) && (
        <Note
          id="edit-note"
          content={addNoteOpen ? null : content}
          onChange={handleChange}
          isEdit={true}
          headerLabel={
            addNoteOpen
              ? commonTranslations.addNoteHeader
              : commonTranslations.editNoteHeader
          }
          entityName={recordName}
          open={editNoteOpen || addNoteOpen}
          handleEditNote={handleEditNote}
          close={requestNoteClose}
        />
      )}
      {viewNoteOpen && (
        <Note
          id="view-note"
          content={content}
          isEdit={false}
          headerLabel={commonTranslations.viewNoteHeader}
          entityName={recordName}
          open={viewNoteOpen}
          close={requestNoteClose}
        />
      )}
      <CustomDialog
        id="delete_note_dialog"
        show={deleteNoteOpen}
        proceed={(e) => handleEditNote(e, '')}
        cancel={requestNoteClose}
        content={recordTranslations.deleteNote}
      />
      <NotificationDialogBox
        open={openNotification}
        cancel={handleCloseNotification}
        message={isInSync ? recordTranslations.itemIsInSync : ''}
      />
    </div>
  );
};

AssessmentRecordList.propTypes = {
  locale: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isViewable: PropTypes.bool,
  position: PropTypes.number,
  items: PropTypes.instanceOf(Immutable.List),
  isFetching: PropTypes.bool,
  onMount: PropTypes.func,
  updateNote: PropTypes.func,
  deletePrivacyRecord: PropTypes.func,
  getNextData: PropTypes.func,
  onChooseFilter: PropTypes.func,
  pageSearchText: PropTypes.instanceOf(Immutable.Map),
  filteredOn: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  filterColumn: PropTypes.objectOf(PropTypes.string),
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  copyAssessment: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  toggleLoader: PropTypes.func,
  onRecordSelection: PropTypes.func,
  selectedRecords: PropTypes.arrayOf(PropTypes.string),
  pricingPlan: PropTypes.instanceOf(Immutable.Map),
  allowPDFDownload: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  isVendor: PropTypes.bool,
  isPublic: PropTypes.bool,
  embed: PropTypes.bool,
  registryDetails: PropTypes.instanceOf(Immutable.Map),
  isPreview: PropTypes.bool,
  recordLayoutId: PropTypes.string,
  filterMenuData: PropTypes.shape(PropTypes.string),
  canApprove: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isGlobal: PropTypes.bool
};

AssessmentRecordList.defaultProps = {
  isFetching: false,
  isEditable: false,
  isViewable: false,
  position: 0,
  items: Immutable.List(),
  onMount: (e) => e,
  updateNote: (e) => e,
  deletePrivacyRecord: (e) => e,
  getNextData: (e) => e,
  onChooseFilter: (e) => e,
  pageSearchText: Immutable.Map(),
  filteredOn: {},
  filterColumn: {},
  sortKey: '',
  sortOrder: '',
  isUpdating: false,
  toggleLoader: (e) => e,
  onRecordSelection: (e) => e,
  selectedRecords: [],
  pricingPlan: Immutable.Map(),
  allowPDFDownload: true,
  isVendor: false,
  isPublic: false,
  embed: false,
  registryDetails: Immutable.Map(),
  isPreview: false,
  recordLayoutId: '',
  filterMenuData: {},
  canApprove: false,
  isDeletable: false,
  isGlobal: false
};

AssessmentRecordList.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

export default injectIntl(AssessmentRecordList);
