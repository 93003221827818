import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';


import styles from '@packages/ui/styles';

class DropDownSelector extends React.Component {
  render () {
    const { fields, items, selectedItems } = this.props;
    return (
      <div>
        <Select
          classes={{
            outlined: 'outlined-select'
          }}
          id="selected_field"
          value={selectedItems && selectedItems[0]}
          style={Object.assign({}, styles.textField, styles.textBox, { paddingLeft: 0 })}
          input={<OutlinedInput />}
          onChange={(event) => {
            fields.removeAll();
            if (event.target.value !== '') {
              fields.push(event.target.value);
            }
          }}
          underlineStyle={{ display: 'none' }}
        >{items.map(item => <MenuItem value={item.value}>{item.label}</MenuItem>)}
        </Select>
      </div>
    );
  }
}

DropDownSelector.propTypes = {
  fields: PropTypes.shape({
    removeAll: PropTypes.func,
    push: PropTypes.func
  }),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

DropDownSelector.defaultProps = {
  fields: {
    removeAll: e => e,
    push: e => e
  }
};

export default DropDownSelector;
