import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import { commonTranslations } from '@packages/utils/commontranslations';
import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import DataSubjectCategoryDialog from '@packages/features/environment/components/data-subject-categories/components/dataSubjectCategoryDialog';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import { getItemNames } from '../../common-utils';

class DataSubjectCategorySelector extends React.Component {
  static filterDataSubjectCategories(
    selectedDataSubjectCategories,
    searchedItems
  ) {
    const filteredData =
      searchedItems &&
      searchedItems.filter((dataSubjectCategory) => {
        const index = selectedDataSubjectCategories.findIndex(
          (selectedDataSubjectCategory) =>
            (selectedDataSubjectCategory.value
              ? selectedDataSubjectCategory.value.name
              : selectedDataSubjectCategory.name) === dataSubjectCategory.name
        );
        return index === -1;
      });
    return filteredData;
  }

  static handleGetMultipleItems(newFields, items) {
    const selectedDataSubjectCategories = newFields.getAll();
    const multipleDataSubjectCategoryList =
      selectedDataSubjectCategories && selectedDataSubjectCategories.length > 0
        ? DataSubjectCategorySelector.filterDataSubjectCategories(
          selectedDataSubjectCategories,
          items
        )
        : items;
    return multipleDataSubjectCategoryList;
  }

  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      open: false,
      updated: false, // eslint-disable-line react/no-unused-state
      multiple: false,
      selectedIndex: -1,
      searchText: '',
      openEdit: false,
      dataSubjectCategoryList: [],
      multipleDataSubjectCategoryList: [],
      tagSearchResult: {},
      isFetch: true
    };

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.performRemove = this.performRemove.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.performEdit = this.performEdit.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.handleListItems = this.handleListItems.bind(this);
    this.handleUsageClick = this.handleUsageClick.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.initDataSubjectCategories) {
      this.props.initDataSubjectCategories(this.props.isGlobal);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.fields.getAll() !== this.props.fields.getAll() ||
      this.props.dataSubjectCategories !== nextProps.dataSubjectCategories ||
      nextProps.searchResults !== this.props.searchResults
    ) {
      const itemList = this.handleListItems(
        nextProps.searchResults,
        nextProps.dataSubjectCategories,
        nextProps.fields,
        nextProps.position
      );
      const { dataSubjectCategoryList, multipleDataSubjectCategoryList } =
        itemList;
      this.setState({
        dataSubjectCategoryList,
        multipleDataSubjectCategoryList
      });
    }
    if (this.props.tagSearchResult !== nextProps.tagSearchResult) {
      this.setState({ tagSearchResult: nextProps.tagSearchResult });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
      !(this.state === nextState) ||
      !(this.props.dataSubjectCategories === nextProps.dataSubjectCategories) ||
      !(this.props.prepareLinkGroup === nextProps.prepareLinkGroup) ||
      !(this.props.isEditable !== nextProps.isEditable) ||
      !(this.props.showSelector !== nextProps.showSelector)
    );
  }

  handleListItems(searchResults, dataSubjectCategories, fields, position) {
    const { searchText } = this.state;
    const searchedItems =
      searchText !== '' || searchResults.length > 0
        ? searchResults
        : dataSubjectCategories;
    const dataSubjectCategoryList =
      DataSubjectCategorySelector.handleGetMultipleItems(fields, searchedItems);
    const multipleDataSubjectCategoryList =
      DataSubjectCategorySelector.handleGetMultipleItems(
        fields,
        dataSubjectCategories
      );
    if (dataSubjectCategories) {
      if (
        (multipleDataSubjectCategoryList.size < 15 ||
          dataSubjectCategoryList.size < 15) &&
        dataSubjectCategories.size > 0 &&
        this.state.searchText === '' &&
        this.props.position !== position &&
        this.state.isFetch
      ) {
        this.props.getNextData(position);
      }
    }
    const itemList = {
      dataSubjectCategoryList,
      multipleDataSubjectCategoryList
    };
    return itemList;
  }

  handleAddClick(searchText) {
    this.setState({
      isEdit: false,
      open: true,
      multiple: false,
      selectedIndex: -1,
      inputValue: {
        name: searchText
      }
    });
  }

  handleEditClick(index) {
    const isLinkAssociated =
      this.props.isItemInLinkGroup &&
      this.props.isItemInLinkGroup('dataSubjectCategories', index);
    if (isLinkAssociated) {
      this.setState({
        openEdit: true,
        selectedIndex: index
      });
    } else {
      this.setState({
        isEdit: true,
        open: true,
        multiple: false,
        openEdit: false,
        selectedIndex: index,
        inputValue: this.props.fields.get(index).value
      });
    }
  }

  handleMultipleSelect() {
    this.setState({
      open: false,
      multiple: true
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
      multiple: false,
      selectedIndex: -1,
      searchText: ''
    });
  }

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const isLinkAssociated =
      this.props.isItemInLinkGroup &&
      this.props.isItemInLinkGroup('dataSubjectCategories', selectedIndex);
    if (isLinkAssociated) {
      this.setState({
        openDelete: true,
        selectedIndex
      });
    } else {
      this.performRemove(selectedIndex);
    }
  }

  handleClose() {
    this.setState({
      openDelete: false,
      openEdit: false,
      selectedIndex: -1
    });
  }

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    const items = this.props.fields.getAll() || [];
    const selectedDataSubjectCategories = [...items, ...selectedItems];
    this.updateDataSubjectCategories(selectedDataSubjectCategories);
  }

  // Handler to add/edit items
  handleSelectedItem(selectedItem, closeDialog) {
    const modifiedItem = { ...selectedItem };
    const { selectedIndex } = this.state;
    if (selectedIndex !== -1) {
      modifiedItem.note = this.props.fields.get(selectedIndex).note;
    }
    this.handleEditItem(modifiedItem, selectedIndex, closeDialog);
  }

  // Update the changed items.
  handleEditItem(selectedItem, selectedIndex, closeDialog) {
    const selectedDataSubjectCategories = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex === -1) {
      selectedDataSubjectCategories.push(selectedItem);
    } else {
      selectedDataSubjectCategories.splice(selectedIndex, 1, selectedItem);
    }
    this.updateDataSubjectCategories(selectedDataSubjectCategories);

    if (closeDialog) {
      this.handleRequestClose();
    }
  }

  handleFilter(selectedItem, enableGetData) {
    const value = selectedItem ? [selectedItem.id] : [];
    this.props.onChooseFilter(
      Object.assign({}, { filterKey: 'tags', filteredOn: value })
    );
    this.setState({ isFetch: enableGetData });
  }

  handleSearch(searchText) {
    this.setState({
      searchText
    });
    const searchParams = { ...searchText, searchKey: 'name' };
    this.props.onSearch(searchParams);
  }

  handleScrollEnd() {
    this.props.getNextData(this.props.position);
  }

  handleUsageClick(index) {
    const { id } = this.props.fields.get(index).value;
    this.props.handleUsageClick(id, this.props.dataItemType);
  }

  performEdit(index) {
    this.setState({
      isEdit: true,
      open: true,
      multiple: false,
      openEdit: false,
      selectedIndex: index,
      inputValue: this.props.fields.get(index).value
    });
  }

  performRemove(selectedIndex) {
    const selectedDataSubjectCategories = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex !== -1) {
      selectedDataSubjectCategories.splice(selectedIndex, 1);
    }
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedDataSubjectCategories);
    } else {
      this.props.updateDataSubjectCategories(selectedDataSubjectCategories);
    }
    this.handleClose();
  }

  updateDataSubjectCategories(selectedDataSubjectCategories) {
    // There are cases where re-render is not invoked.
    // TODO: check and confirm the below state change.
    this.setState({ updated: true, searchText: '' }); // eslint-disable-line react/no-unused-state
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedDataSubjectCategories);
    } else {
      this.props.updateDataSubjectCategories(selectedDataSubjectCategories);
    }
  }

  render() {
    const {
      userPermissions,
      isPartOf,
      showSelector,
      showEditIcon,
      tags,
      showTextField
    } = this.props;
    const selectedDataSubjectCategories = this.props.fields.getAll();

    const { createEditDataSubjectCategories } = userPermissions.toJS();

    const actions = [
      <Button key="btn_remove_no" onClick={this.handleClose}>
        {commonTranslations.Ok}
      </Button>
    ];

    return (
      <div>
        {!showTextField ? (
          getItemNames(selectedDataSubjectCategories)
        ) : (
          <div>
            {selectedDataSubjectCategories &&
              selectedDataSubjectCategories.length > 0 && (
                <ItemList
                  id="item_list"
                  {...this.props}
                  isEditable={createEditDataSubjectCategories && showEditIcon}
                  type={this.props.label}
                  handleRemoveItem={this.handleRemoveItem}
                  handleEditClick={this.handleEditClick}
                  handleEditItem={this.handleEditItem}
                  handleItemClick={(index) =>
                    this.props.toggleLinkGroupItem(
                      'dataSubjectCategories',
                      index
                    )
                  }
                  selectedItems={selectedDataSubjectCategories}
                  isPartOf={isPartOf}
                  handleUsageClick={this.handleUsageClick}
                />
            )}
            {showSelector && (
              <ItemSelector
                id="dataSubjectCategories_selector"
                multiValue={this.props.multiValue}
                createNewMenuItem={
                  createEditDataSubjectCategories &&
                  this.props.createNewMenuItem
                }
                selectFromListMenuItem={
                  this.props.selectFromListMenuItem || false
                }
                selectedItem={this.props.selectedItems}
                dataSource={this.state.dataSubjectCategoryList}
                dataSourceConfig={{ text: 'key', value: 'key' }}
                hintTextLabel={this.props.hintTextLabel}
                handleSelectedItem={this.handleSelectedItem}
                handleAddClick={this.handleAddClick}
                handleMultipleSelect={this.handleMultipleSelect}
                onSearch={this.handleSearch}
              />
            )}
            {this.state.open && (
              <DataSubjectCategoryDialog
                isEdit={this.state.isEdit}
                open={this.state.open}
                dataItemId={this.state.inputValue.id}
                inputValue={this.state.inputValue}
                selectedItems={selectedDataSubjectCategories}
                onRequestClose={this.handleRequestClose}
                handleSelectedItem={this.handleSelectedItem}
                source="records"
                hideTags={this.props.isGlobal}
              />
            )}
            {this.state.multiple && (
              <MultipleSelectorDialog
                id="multiple_selector"
                title={
                  <FormattedMessage
                    id="MultipleDataSubCategoryDialog.header"
                    description="Select Data Subcategories Dialog Header"
                    defaultMessage="Select data subject categories"
                  />
                }
                open={this.state.multiple}
                onScrollStop={this.handleScrollEnd}
                filteredData={this.state.multipleDataSubjectCategoryList}
                onRequestClose={this.handleRequestClose}
                handleMultipleItems={this.handleMultipleItems}
                filterTagValue={tags}
                onChooseFilter={this.handleFilter}
                showFilterByTag={!this.props.isGlobal}
                tagSearch={this.props.tagsSearch}
                tagSearchResult={this.state.tagSearchResult}
                getNextTags={this.props.getNextTags}
                tagsPosition={this.props.tagsPosition}
              />
            )}
            {this.state.openDelete && (
              <CommonDialog
                id="delete-confirm-dialog"
                show={this.state.openDelete}
                onCancel={this.handleClose}
                buttonActions={actions}
                showCloseIcon={false}
                buttonPosition="right"
                maxWidth="sm"
                fullWidth={true}
              >
                {commonTranslations.linkItemDeletefromGroup}
              </CommonDialog>
            )}
            {this.state.openEdit && (
              <CommonDialog
                id="edit-confirm-dialog"
                show={this.state.openEdit}
                onCancel={this.handleClose}
                buttonActions={actions}
                showCloseIcon={false}
                buttonPosition="right"
                maxWidth="sm"
                fullWidth={true}
              >
                {commonTranslations.linkItemUpdatefromGroup}
              </CommonDialog>
            )}
          </div>
        )}
      </div>
    );
  }
}

DataSubjectCategorySelector.propTypes = {
  multiValue: PropTypes.bool,
  showSelector: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  initDataSubjectCategories: PropTypes.func,
  updateDataSubjectCategories: PropTypes.func,
  dataSubjectCategories: PropTypes.instanceOf(Immutable.List).isRequired,
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  position: PropTypes.number,
  getNextData: PropTypes.func,
  isItemInLinkGroup: PropTypes.func,
  prepareLinkGroup: PropTypes.bool,
  toggleLinkGroupItem: PropTypes.func,
  label: PropTypes.node,
  hintTextLabel: PropTypes.node,
  isPartOf: PropTypes.string,
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  handleUsageClick: PropTypes.func,
  dataItemType: PropTypes.string,
  showEditIcon: PropTypes.bool,
  onChooseFilter: PropTypes.func,
  tags: PropTypes.instanceOf(Immutable.List),
  masterDataType: PropTypes.string,
  entityType: PropTypes.string,
  tagSearchResult: PropTypes.instanceOf(Immutable.List),
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  tagsSearch: PropTypes.func,
  showTextField: PropTypes.bool,
  isRegistry: PropTypes.bool,
  updateRegistryFilters: PropTypes.func,
  isGlobal: PropTypes.bool,
  userPermissions: PropTypes.instanceOf(Immutable.Map)
};

DataSubjectCategorySelector.defaultProps = {
  multiValue: true,
  selectedItems: null,
  showSelector: true,
  selectFromListMenuItem: false,
  createNewMenuItem: false,
  prepareLinkGroup: false,
  initDataSubjectCategories: (e) => e,
  updateDataSubjectCategories: (e) => e,
  label: null,
  getNextData: (e) => e,
  isItemInLinkGroup: () => {},
  toggleLinkGroupItem: () => {},
  position: 0,
  hintTextLabel: null,
  isPartOf: '',
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  handleUsageClick: (e) => e,
  dataItemType: '',
  showEditIcon: true,
  tags: Immutable.List(),
  onChooseFilter: (e) => e,
  entityType: '',
  masterDataType: '',
  tagSearchResult: Immutable.List(),
  getNextTags: (e) => e,
  tagsPosition: 0,
  tagsSearch: (e) => e,
  showTextField: true,
  isRegistry: false,
  updateRegistryFilters: (e) => e,
  isGlobal: false,
  userPermissions: Immutable.Map()
};

export default DataSubjectCategorySelector;
