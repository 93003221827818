import { handleServiceDown } from '@packages/utils/common-utils';
import registry from 'app-registry';
import { put } from 'redux-saga/effects';

import { serviceDownError } from '@packages/utils/commontranslations';
import dashboardTranslations from './dashboardTranslations';

const getTenantActionType = (isGlobal) =>
  isGlobal ? 'HOLDING_DASHBOARD' : 'TENANT_DASHBOARD';

export const fetchData = async ({ endpoint, service }, tenantId = 'all') => {
  try {
    const modifiedEndPoint =
      tenantId !== 'all'
        ? `${endpoint}${
          endpoint.includes('?') ? '&' : '?'
        }filter=subTenantIds=${tenantId}`
        : endpoint;
    const response = await registry.get('request').get(modifiedEndPoint, null);
    switch (response.status) {
      case 200: {
        return { data: response.body, error: undefined };
      }
      case 403: {
        return { error: dashboardTranslations.noAccess, showWarning: true };
      }
      default: {
        return { error: response.body.msg };
      }
    }
  } catch (error) {
    return { error: serviceDownError(service) };
  }
};

export function* fetchAllDashboards(action) {
  yield put({ type: 'LOADER:SHOW' });
  const { locale, tenentLocale, isGlobal } = action;
  const dashboardType = getTenantActionType(isGlobal);

  try {
    const response = yield registry
      .get('request')
      .get(`/v1/custom-dashboard-layout`);
    switch (response.status) {
      case 200: {
        yield put({ type: `${dashboardType}:LAYOUTS:FETCH_ALL:INIT:SUCCESS` });
        yield put({
          type: `${dashboardType}:FILTER:INIT`,
          data: response.body,
          locale,
          tenentLocale
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
    yield put({ type: 'LOADER:HIDE' });
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* saveDashboard(action) {
  yield put({ type: 'LOADER:SHOW' });

  const { data, isEdit, locale, tenantLocale, isGlobal } = action;
  const dashboardType = getTenantActionType(isGlobal);

  try {
    const response = isEdit
      ? yield registry
        .get('request')
        .put(`/v1/custom-dashboard-layout/${action.data.id}`, action.data)
      : yield registry.get('request').post(`/v1/custom-dashboard-layout`, data);

    yield put({ type: 'LOADER:HIDE' });

    switch (response.status) {
      case 200: {
        yield put({
          type: `${dashboardType}:SAVE:SUCCESS`,
          data: response.body,
          isEdit,
          locale,
          tenantLocale
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: dashboardTranslations.saveDashboardMessage,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* removeDashboardLayout({ id, isDelete, changeFilter, isGlobal }) {
  const dashboardType = getTenantActionType(isGlobal);

  yield put({ type: 'LOADER:SHOW' });

  try {
    const response = isDelete
      ? yield registry
        .get('request')
        .delete(`/v1/custom-dashboard-layout/${id}`)
      : yield registry
        .get('request')
        .put(`/v1/custom-dashboard-layout/${id}/reject`);

    yield put({ type: 'LOADER:HIDE' });

    switch (response.status) {
      case 204: {
        if (changeFilter) {
          yield put({ type: `${dashboardType}:FILTERS:CHANGE` });
        }
        yield put({
          type: `${dashboardType}:LAYOUTS:FETCH_ALL:INIT`,
          isGlobal
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: isDelete
              ? dashboardTranslations.deleteDashboardMessage
              : dashboardTranslations.rejectDashboardSuccess,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* fetchAllWidgets() {
  yield put({ type: 'LOADER:SHOW' });

  try {
    const response = yield registry.get('request').get(`/v1/custom/widget`);

    yield put({ type: 'LOADER:HIDE' });

    switch (response.status) {
      case 200: {
        yield put({
          type: 'DASHBOARD:FETCH:WIDGETS:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* saveDashboardLayout(action) {
  yield put({ type: 'LOADER:SHOW' });

  const { data, locale, tenantLocale, isGlobal } = action;
  const dashboardType = getTenantActionType(isGlobal);

  try {
    const response = yield registry
      .get('request')
      .put(`/v1/custom-dashboard-layout/${data.id}`, data);
    yield put({ type: 'LOADER:HIDE' });
    switch (response.status) {
      case 200: {
        yield put({
          type: 'DASHBOARD:LAYOUT:UNSAVED_DATA:SET',
          value: false
        });
        yield put({
          type: `${dashboardType}:LAYOUT:SAVE:SUCCESS`,
          data: response.body,
          locale,
          tenantLocale
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: dashboardTranslations.saveDashboardMessage,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* shareDashboardLayout(action) {
  yield put({ type: 'LOADER:SHOW' });

  const { data, callback, locale, tenantLocale, isGlobal } = action;
  const dashboardType = getTenantActionType(isGlobal);

  try {
    const response = yield registry
      .get('request')
      .put(`/v1/custom-dashboard-layout/${data.id}/share`, data);
    yield put({ type: 'LOADER:HIDE' });
    switch (response.status) {
      case 200: {
        yield put({
          type: `${dashboardType}:LAYOUT:SHARE:SUCCESS`,
          data: response.body,
          locale,
          tenantLocale
        });
        if (callback) callback();
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: dashboardTranslations.shareDashboardMessage,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* setFavouriteDashboard({ dashboardId, locale, tenantLocale, isGlobal }) {
  yield put({ type: 'LOADER:SHOW' });

  try {
    const response = yield registry
      .get('request')
      .put(`/v1/users/favourites`, { dashboardId });
    yield put({ type: 'LOADER:HIDE' });
    switch (response.status) {
      case 200: {
        yield put({
          type: 'USER:PROFILE:REQUEST'
        });
        yield put({
          type: `${isGlobal ? 'HOLDING_DASHBOARD' : 'TENANT_DASHBOARD' }:LAYOUTS:FETCH_ALL:INIT`,
          locale,
          tenantLocale,
          isGlobal
        });
        if (dashboardId) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: dashboardTranslations.markFavouriteSuccess,
              type: 'success'
            }
          });
        } else {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: dashboardTranslations.unmarkFavouriteSuccess,
              type: 'success'
            }
          });
        }
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
  }
}
