import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Column } from 'fixed-data-table';
import { FormattedMessage, injectIntl } from 'react-intl';

import Note from '@packages/components/note';
import {
  ResponsiveTableWrapper,
  SortHeaderCell,
  DataCell,
  DateCell,
  InformationCell,
  ActionCell
} from '@packages/components/responsive-table';
import {
  statusValueMapper,
  jurisdictionValueMapper,
  commonTranslations,
  recordTranslations,
  deleteRecord,
  customTranslation
} from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';
import './style.css';
import {
  supportedJurisdictions,
  RECORDS_FETCH_LIMIT,
  getEmbedContainerHeight
} from '@packages/utils/common-utils';
import { getQueryStrings } from '@packages/utils/query-parameters';
import CustomDialog from '@packages/components/custom-dialog';
import {
  filterItem,
  getModifiedActions,
  statusColorCodes
} from '@packages/utils/record-utils';
import { singularTerms as upperCaseSingular } from '@packages/utils/uppercaseTranslations';
import {
  shouldHideMenuItem,
  getRecordStatusFilterValues,
  recordRiskFilterValues,
  CheckBoxCell
} from '../../record-utils';
import NotificationDialogBox from '../../../privacy-record-detail/components/notificationDialog';
import { DataRiskArrayCell } from './components/data-risk-array-cell/dataRiskArrayCell';
import { DataArrayCell } from './components/data-array-cell/dataArrayCell';

const actionItems = [
  {
    action: 'graphicalView',
    primaryText: recordTranslations.graphicalView
  },
  {
    action: 'addNote',
    primaryText: commonTranslations.addNote
  },
  {
    action: 'editNote',
    primaryText: commonTranslations.editNote
  },
  {
    action: 'viewNote',
    primaryText: commonTranslations.viewNote
  },
  {
    action: 'removeNote',
    primaryText: commonTranslations.removeNote
  },
  {
    action: 'changelog',
    primaryText: recordTranslations.viewChangeLog
  },
  {
    action: 'copyRecord',
    primaryText: recordTranslations.copyRecord
  }
];

export const ProcessingRecordList = (props, context) => {
  const {
    visibleFields,
    isFetching,
    items,
    columns,
    stakeholders,
    onRecordSelection,
    onChooseFilter,
    stakeholderSearch,
    getNextData,
    getNextStakeholders,
    onMount,
    position,
    pageSearchText,
    filteredOn,
    filterColumn,
    sortKey,
    sortOrder,
    userPermissions,
    locale,
    isEditable,
    isViewable,
    isVendor,
    registryDetails,
    pricingPlan,
    embed,
    isPublic,
    isPreview,
    allowPDFDownload,
    toggleColumn,
    stakeholdersInit,
    copyProcessing,
    intl,
    updateNote,
    deletePrivacyRecord,
    isUpdating,
    selectedRecords,
    filterMenuData,
    toggleLoader,
    isDeletable,
    canApprove,
    isDefaultProcessing,
    currentFilterData,
    isGlobal
  } = props;

  const { createEditProcessing } = userPermissions.toJS();
  const { recordLayoutId, useDefaultLayoutLogic } = currentFilterData;

  const [recordId, setRecordId] = useState(-1);
  const [open, setOpen] = useState(false);
  const [deleteNoteOpen, setDeleteNoteOpen] = useState(false);
  const [editNoteOpen, setEditNoteOpen] = useState(false);
  const [viewNoteOpen, setViewNoteOpen] = useState(false);
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [content, setContent] = useState(undefined);
  const [recordName, setRecordName] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [stakeholderItems, setStakeholderItems] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [currentSelectedRecords, setCurrentSelectedRecords] = useState(
    Immutable.List()
  );
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [stakeholderType, setStakeholderType] = useState('');
  const [isInSync, setIsInSync] = useState(false);

  const stakeholdersFilterValues = (stakeholders, type) => {
    const modifiedList = [{ label: type, value: '' }];
    const stakeholderList = stakeholders.map((item) => ({
      label: item.name,
      value: item.id
    }));
    if (stakeholders.size > 0) {
      return modifiedList.concat(stakeholderList.toJS());
    }
    return stakeholderList;
  };

  if (visibleFields.length > 0) {
    if (!visibleFields.includes('Controllers'))
      toggleColumn('controllers', false);
    if (!visibleFields.includes('Processors'))
      toggleColumn('processorList', false);
    if (!visibleFields.includes('ExecutingEntities'))
      toggleColumn('executingEntities', false);
  }

  useEffect(() => {
    toggleColumn('jurisdictions', showJurisdictions());
    toggleColumn(
      'bulkExport',
      !isPublic &&
        (allowPDFDownload || createEditProcessing) &&
        isDefaultProcessing
    );
    toggleColumn('lastChanged', !isPublic);
    toggleColumn('status', !isPublic);
    const isPublicPath = window.location.pathname.includes('public');
    if (isPublicPath && registryDetails.size > 0 && onMount) {
      /* when a language switch happens via the language menu an unnecessary render triggers
       * which ends in creating duplicate records. Hence checking if the registryDetails are
       * available and ready to be used.
       */
      onMount();
    } else if (!isPublicPath && onMount) {
      onMount(recordLayoutId);
    }
  }, [recordLayoutId]);

  useEffect(() => {
    if (isDelete) {
      getNextData(position, 1, recordLayoutId); // rowCount=1
      setIsDelete(false);
    }
    if (stakeholders.items?.size > 0) {
      const stakeholderMessageKey =
        {
          ppControllers: 'defaultController',
          ppProcessors: 'defaultProcessor',
          ppExecutingEntities: 'defaultEntity'
        }[stakeholderType] || 'defaultController';

      const defaultMessage = intl.formatMessage(
        recordTranslations[stakeholderMessageKey]?.props
      );

      const searchTextFilter =
        stakeholders.searchText.size > 0
          ? stakeholdersFilterValues(stakeholders.searchItems)
          : stakeholdersFilterValues(
            stakeholders.items,
            `-- ${defaultMessage} --`
          );

      setStakeholderItems(searchTextFilter);
    }
    setCurrentSelectedRecords(selectedRecords);
  }, [items.size, stakeholders, isDelete]);

  useEffect(() => {
    toggleLoader(isUpdating || isFetching);
  }, [isFetching, isUpdating]);

  const onCheckHandler = (event, item) => {
    const selectedIndex = currentSelectedRecords.findIndex(
      (el) => el.id === item.id
    );
    const selectedRecords = [...currentSelectedRecords];
    if (event.target.checked) {
      selectedRecords.push({ ...item });
    } else {
      selectedRecords.splice(selectedIndex, 1);
    }
    setCurrentSelectedRecords(selectedRecords);
    onRecordSelection(selectedRecords);
  };

  const onBulkExportReset = (event) => {
    if (!event.target.checked) {
      setCurrentSelectedRecords([]);
      onRecordSelection([]);
    }
  };

  const getContainerHeight = () => {
    // headerHeight: 80 + tabHeight: 50 + tableHeader: 50 + whiteSpace: 20
    if (isVendor) return window.innerHeight - 200;
    // headerHeight 80 + margin 10
    else if (embed) return window.innerHeight - 90;
    // navbar height: 100 + header: 65 + whitespace: 25
    else if (isPublic) return window.innerHeight - 190;
    // navbar: 100 + messageBanner: 50 + header: 65 + whiteSpace: 15
    return window.innerHeight - 230;
  };

  const showJurisdictions = () => {
    if (!isDefaultProcessing && !useDefaultLayoutLogic) return false;
    const newJurisdictions = supportedJurisdictions.filter(
      (item) => item.data !== 'GDPR'
    );
    let jurisdictionCount = 0;
    newJurisdictions.forEach((item) => {
      if (userPermissions.get(`view${item.data}Processing`)) {
        jurisdictionCount += 1;
      }
    });
    return jurisdictionCount > 0;
  };

  const handleEditRecordAction = (index) => {
    const hashURL = window.location.hash;
    const urlParams = getQueryStrings(hashURL);
    const { id, status, syncInfo } = items.get(index);

    if (
      status === 'record_status_requested' ||
      syncInfo?.isLibrarySyncEnabled
    ) {
      setOpenNotification(true);
      setIsInSync(syncInfo?.isLibrarySyncEnabled);
    } else if (isVendor) {
      const location =
        window.location.origin ||
        `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }`;
      window.open(`${location}/#/processing/${id}/edit`, '_blank');
    } else if (urlParams.from === 'overview') {
      context.router.history.push(`/processing/${id}/edit?from=overview`);
    } else {
      context.router.history.push(`/processing/${id}/edit`);
    }
  };

  const handleDeleteRecordAction = (index) => {
    const { id, name, status, syncInfo } = items.get(index);
    if (
      (!canApprove && status === 'record_status_requested') ||
      syncInfo?.isLibrarySyncEnabled
    ) {
      setOpenNotification(true);
      setIsInSync(syncInfo?.isLibrarySyncEnabled);
    } else {
      handleOpen(id, name);
    }
  };

  const handleDeleteRecord = () => {
    setIsDelete(true);
    deletePrivacyRecord(recordId);
    handleClose();
  };

  const handleRowClick = (e, index) => {
    const { id } = items.get(index);
    if (isVendor) {
      const location =
        window.location.origin ||
        `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }`;
      window.open(`${location}/#/processing/${id}/view`, '_blank');
    } else if (isViewable) {
      const hashURL = window.location.hash;
      const urlParams = getQueryStrings(hashURL);
      const location =
        urlParams.from === 'overview' ? 'overview' : 'privacyrecords';
      context.router.history.push(`/processing/${id}/view?from=${location}`);
    } else if (isPublic) {
      const registryId = registryDetails.toJS().id;
      context.router.history.push(
        `/${registryId}/processing/${id}/view?from=publicList&embed=${embed}&isPreview=${isPreview}`
      );
    }
  };

  const handleScrollEnd = (scrollX, scrollY) => {
    if (
      items.size >= RECORDS_FETCH_LIMIT &&
      40 * items.size -
        (embed ? getEmbedContainerHeight() : getContainerHeight()) <=
        scrollY
    ) {
      getNextData(position, 50, recordLayoutId);
    }
    setIsDelete(false);
  };

  const handleRecordActions = (actionButton, index, action) => {
    const { id: recordId, name, note } = items.get(index);
    if (action === 'edit') {
      handleEditRecordAction(index);
    } else if (action === 'delete') {
      handleDeleteRecordAction(index);
    } else if (action === 'graphicalView') {
      context.router.history.push(`/processing/${recordId}/graph`);
    } else if (action === 'changelog') {
      context.router.history.push(`/processing/${recordId}/revisions`);
    } else if (action === 'addNote') {
      handleAddNoteOpen(recordId, name);
    } else if (action === 'editNote') {
      handleEditNoteOpen(recordId, name, note);
    } else if (action === 'viewNote') {
      handleViewNoteOpen(name, note);
    } else if (action === 'removeNote') {
      handleDeleteNoteOpen(recordId, name);
    } else if (action === 'copyRecord') {
      handleCopyRecord(name, recordId);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  const handleCopyRecord = (name, recordId) => {
    const copyOfName = {
      name: intl.formatMessage(customTranslation('copyOfName').props, {
        value: name
      })
    };
    copyProcessing(copyOfName, recordId, isGlobal);
  };

  const handleAddNoteOpen = (recordId, recordName) => {
    setAddNoteOpen(true);
    setRecordId(recordId);
    setRecordName(recordName);
  };

  const handleEditNoteOpen = (recordId, recordName, content) => {
    setEditNoteOpen(true);
    setRecordId(recordId);
    setRecordName(recordName);
    setContent(content);
  };

  const handleViewNote = (evt, rowIndex) => {
    evt.stopPropagation();

    const { name, note } = items.get(rowIndex);
    handleViewNoteOpen(name, note);
  };

  const handleViewNoteOpen = (name, note) => {
    setViewNoteOpen(true);
    setRecordName(name);
    setContent(note);
  };

  const handleDeleteNoteOpen = (id, recordName) => {
    setDeleteNoteOpen(true);
    setRecordId(id);
    setRecordName(recordName);
    setContent('');
  };

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  // Handle note value changes
  const handleEditNote = (event, note) => {
    const noteContent = note || content;
    if (
      recordId !== -1 &&
      (((addNoteOpen || editNoteOpen) && noteContent) || deleteNoteOpen)
    ) {
      updateNote(
        recordId,
        'note',
        noteContent.trim(),
        recordName,
        recordLayoutId
      );
    }
    requestNoteClose();
  };

  const handleMouseHover = (event, index) => {
    setCurrentIndex(index);
  };

  const requestNoteClose = () => {
    setEditNoteOpen(false);
    setViewNoteOpen(false);
    setAddNoteOpen(false);
    setDeleteNoteOpen(false);
    setRecordId(-1);
    setContent(undefined);
  };

  const filterData = (filterKey, filterParams) => {
    onChooseFilter({ ...filterParams, filterKey }, recordLayoutId);
  };

  const searchStakeholderData = (sortOn, searchParams, type) => {
    const modifiedSearchParams = { ...searchParams, searchKey: 'name' };
    setStakeholderType(type);
    if (!modifiedSearchParams.searchText) {
      setStakeholderItems([]);
      stakeholdersInit(type);
    } else stakeholderSearch({ ...modifiedSearchParams }, type);
  };

  const handleClose = () => {
    setRecordId(-1);
    setOpen(false);
  };

  const handleOpen = (recordId, recordName) => {
    setRecordId(recordId);
    setRecordName(recordName);
    setOpen(true);
  };

  const handleFilterIcon = (show) => {
    setShowFilterIcon(show);
  };

  const handleMenuHidden = (menu, rowIndex, action) => {
    if (!isDefaultProcessing && ['changelog', 'graphicalView'].includes(action))
      return true;
    return shouldHideMenuItem(
      action,
      rowIndex,
      items,
      isEditable,
      isViewable,
      isVendor
    );
  };

  const handleMenuDisabled = (menu, rowIndex, action) =>
    action === 'copyRecord' && filterMenuData?.status === 'Inactive';

  const handleStakeholdersScrollEnd = (type) => {
    getNextStakeholders(stakeholders.position, type);
  };

  const modifiedAction = getModifiedActions(pricingPlan, actionItems);

  const jurisdictionsList = supportedJurisdictions.map((item) => ({
    label: intl.formatMessage(upperCaseSingular(item.data.toLowerCase()).props),
    value: item.data
  }));

  const recordStatusFilterValues = getRecordStatusFilterValues(registryDetails);

  const vendorNoContentStyle = {
    ...styles.tableNoContentStyle,
    top: 300,
    left: '45%'
  };

  return (
    <div>
      {!isFetching && items.size === 0 && (
        <div
          style={isVendor ? vendorNoContentStyle : styles.tableNoContentStyle}
        >
          {commonTranslations.NoData}
        </div>
      )}
      <ResponsiveTableWrapper
        id="processing_responsive_table"
        showActionSelector={!isPublic}
        actionChooserStyle={styles.actionChooserStyle}
        rowHeight={40}
        headerHeight={45}
        columns={columns}
        rowsCount={items.size}
        onRowClick={handleRowClick}
        actionItems={modifiedAction}
        onActionHandler={handleRecordActions}
        isHidden={handleMenuHidden}
        onScrollEnd={handleScrollEnd}
        actionChooserClass="actionChooserClass"
        onRowMouseEnter={handleMouseHover}
        height={getContainerHeight()}
        isDisabled={handleMenuDisabled}
        {...props}
      >
        <Column
          columnKey="bulkExport"
          visible={allowPDFDownload && !isVendor}
          header={
            <SortHeaderCell
              showFilterIcon={false}
              showBulkCheck={true}
              checkedItemsSize={currentSelectedRecords.length}
              onBulkReset={onBulkExportReset}
              enableSorting={false}
              tooltip={false}
            />
          }
          cell={
            <CheckBoxCell
              items={items}
              selectedRecordSize={currentSelectedRecords.length}
              selectedRecords={currentSelectedRecords}
              onCheck={onCheckHandler}
            />
          }
          width={50}
        />
        <Column
          id="privacyRecordNumber"
          columnKey="privacyRecordNumber"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              searchText={pageSearchText.get('privacyRecordNumber')}
              onChooseFilter={filterData}
              pageSearchStyle={{ width: '80px' }}
            >
              {recordTranslations.recordId}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          width={80}
        />
        <Column
          id="name"
          columnKey="name"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              searchText={pageSearchText.get('name')}
              sortKey={sortKey}
              sortOrder={sortOrder}
              onChooseFilter={filterData}
              tooltip={true}
            >
              {commonTranslations.name}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          flexGrow={2}
          width={150}
        />
        <Column
          id="jurisdictions"
          columnKey="jurisdictions"
          header={
            <SortHeaderCell
              id="jurisdictions"
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={false}
              enableSearch={false}
              enableFilter={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              showSelectAll={true}
              filterColumn={filterColumn && filterColumn.jurisdictions}
              filteredOn={filteredOn}
              filterValues={jurisdictionsList}
              columnStyle={{ minWidth: '220px' }}
              onChooseFilter={filterData}
            >
              {recordTranslations.jurisdictions}
            </SortHeaderCell>
          }
          cell={
            <DataArrayCell items={items} mapper={jurisdictionValueMapper} />
          }
          flexGrow={1}
          width={150}
          visible={!isVendor}
        />
        <Column
          id="controllers"
          columnKey="controllers"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={false}
              enableSearch={
                filteredOn.controllers ? filteredOn.controllers[0] !== '' : true
              }
              searchText={stakeholders.searchText}
              enableFilter={true}
              filteredOn={filteredOn}
              sortKey={sortKey}
              searchKey="name"
              sortOrder={sortOrder}
              showSelectAll={false}
              columnStyle={{ minWidth: '220px' }}
              onScrollStop={() => handleStakeholdersScrollEnd('ppControllers')}
              filterColumn={filterColumn && filterColumn.controllers}
              filterValues={
                stakeholderItems.length === 0 ? [] : stakeholderItems
              }
              onSearch={(sortOn, searchParams) =>
                searchStakeholderData(sortOn, searchParams, 'ppControllers')
              }
              onChooseFilter={filterData}
            >
              {recordTranslations.controllers}
            </SortHeaderCell>
          }
          cell={
            <DataArrayCell
              items={items}
              defaultContent={recordTranslations.defaultController}
            />
          }
          flexGrow={1}
          width={170}
        />
        <Column
          id="processors"
          columnKey="processorList"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={false}
              enableSearch={
                filteredOn.processorList
                  ? filteredOn.processorList[0] !== ''
                  : true
              }
              searchText={stakeholders.searchText}
              enableFilter={true}
              filteredOn={filteredOn}
              sortKey={sortKey}
              searchKey="name"
              sortOrder={sortOrder}
              showSelectAll={false}
              columnStyle={{ width: '220px' }}
              onScrollStop={() => handleStakeholdersScrollEnd('ppProcessors')}
              filterColumn={filterColumn && filterColumn.processorList}
              filterValues={
                stakeholderItems.length === 0 ? [] : stakeholderItems
              }
              onSearch={(sortOn, searchParams) =>
                searchStakeholderData(sortOn, searchParams, 'ppProcessors')
              }
              onChooseFilter={filterData}
            >
              {recordTranslations.processors}
            </SortHeaderCell>
          }
          cell={
            <DataArrayCell
              items={items}
              defaultContent={recordTranslations.defaultProcessor}
            />
          }
          flexGrow={1}
          width={170}
          visible={!isVendor}
        />
        <Column
          id="executingEntities"
          columnKey="executingEntities"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={false}
              enableSearch={
                filteredOn.executingEntities
                  ? filteredOn.executingEntities[0] !== ''
                  : true
              }
              searchText={stakeholders.searchText}
              enableFilter={true}
              filteredOn={filteredOn}
              sortKey={sortKey}
              searchKey="name"
              sortOrder={sortOrder}
              showSelectAll={false}
              columnStyle={{ minWidth: '220px' }}
              onScrollStop={() =>
                handleStakeholdersScrollEnd('ppExecutingEntities')
              }
              filterColumn={filterColumn && filterColumn.executingEntities}
              filterValues={
                stakeholderItems.length === 0 ? [] : stakeholderItems
              }
              onSearch={(sortOn, searchParams) =>
                searchStakeholderData(
                  sortOn,
                  searchParams,
                  'ppExecutingEntities'
                )
              }
              onChooseFilter={filterData}
            >
              {recordTranslations.executingEntities}
            </SortHeaderCell>
          }
          cell={
            <DataArrayCell
              items={items}
              defaultContent={recordTranslations.defaultEntity}
            />
          }
          flexGrow={1}
          width={170}
          visible={!isVendor}
        />
        <Column
          id="status"
          columnKey="status"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              enableFilter={true}
              filterValues={recordStatusFilterValues}
              filteredOn={filteredOn}
              sortKey={sortKey}
              sortOrder={sortOrder}
              filterColumn={filterColumn && filterColumn.status}
              onChooseFilter={filterData}
              filterItem={filterItem}
            >
              {recordTranslations.status}
            </SortHeaderCell>
          }
          cell={
            <DataCell
              items={items}
              mapper={statusValueMapper}
              styleMapper={statusColorCodes}
            />
          }
          width={90}
        />
        <Column
          columnKey="risks"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={isVendor && showFilterIcon}
              enableFilter={true}
              filterValues={recordRiskFilterValues}
              filteredOn={filteredOn}
              filterColumn={filterColumn && filterColumn.risks}
              onChooseFilter={filterData}
              showSelectAll={false}
            >
              {isVendor ? (
                recordTranslations.outstandingRisks
              ) : (
                <FormattedMessage
                  id="PrivacyRecordList.risks"
                  description="Privacy Record List Column Risks"
                  defaultMessage="Risks"
                />
              )}
            </SortHeaderCell>
          }
          cell={
            <DataRiskArrayCell
              style={{ color: 'red' }}
              items={items}
              defaultContent={commonTranslations.noRisks}
              tooltipId={isVendor ? '' : 'processingRiskTitle'}
              showRiskCountOnly={isVendor}
            />
          }
          width={isVendor ? 100 : 450}
          flexGrow={isVendor ? 1 : 0}
        />
        <Column
          id="lastChanged"
          columnKey="lastChanged"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              onChooseFilter={filterData}
            >
              {' '}
              {recordTranslations.lastChanged}
            </SortHeaderCell>
          }
          cell={
            <DateCell dateFormat="DD MMM YYYY" items={items} locale={locale} />
          }
          width={100}
          flexGrow={1}
          visible={!isPublic}
        />
        <Column
          id="action-cell"
          columnKey="action-cell"
          align="center"
          visible={isEditable || isDeletable}
          cell={
            <ActionCell
              currentIndex={currentIndex}
              cellStyle={{ float: 'right' }}
              handleEdit={handleEditRecordAction}
              isEditHidden={!isEditable}
              isDeleteHidden={!isDeletable || isVendor}
              handleDelete={handleDeleteRecordAction}
            />
          }
          flexGrow={1}
          width={80}
        />
        <Column
          id="note"
          columnKey="note"
          align="center"
          cell={
            <InformationCell
              items={items}
              tooltipId="processingNoteTitle"
              handleOnClick={handleViewNote}
              className="informationClass"
            />
          }
          width={30}
        />
      </ResponsiveTableWrapper>
      {open && (
        <CustomDialog
          title={commonTranslations.deletePrivacyRecord}
          id="delete_record_dialog"
          show={open}
          proceed={handleDeleteRecord}
          cancel={handleClose}
          content={deleteRecord(recordName)}
        />
      )}
      {(editNoteOpen || addNoteOpen) && (
        <Note
          id="edit-note"
          content={addNoteOpen ? null : content}
          onChange={handleChange}
          isEdit={true}
          headerLabel={
            addNoteOpen
              ? commonTranslations.addNoteHeader
              : commonTranslations.editNoteHeader
          }
          entityName={recordName}
          open={editNoteOpen || addNoteOpen}
          handleEditNote={handleEditNote}
          close={requestNoteClose}
        />
      )}
      {viewNoteOpen && (
        <Note
          id="view-note"
          content={content}
          isEdit={false}
          headerLabel={commonTranslations.viewNoteHeader}
          entityName={recordName}
          open={viewNoteOpen}
          close={requestNoteClose}
        />
      )}
      <CustomDialog
        id="delete_note_dialog"
        show={deleteNoteOpen}
        proceed={(e) => handleEditNote(e, '')}
        cancel={requestNoteClose}
        content={recordTranslations.deleteNote}
      />
      <NotificationDialogBox
        open={openNotification}
        cancel={handleCloseNotification}
        message={isInSync ? recordTranslations.itemIsInSync : ''}
      />
    </div>
  );
};

ProcessingRecordList.propTypes = {
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  locale: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isViewable: PropTypes.bool,
  deletePrivacyRecord: PropTypes.func,
  getNextData: PropTypes.func,
  updateNote: PropTypes.func,
  items: PropTypes.instanceOf(Immutable.List),
  columns: PropTypes.instanceOf(Immutable.List),
  stakeholders: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isFetching: PropTypes.bool,
  onChooseFilter: PropTypes.func,
  onMount: PropTypes.func,
  stakeholderSearch: PropTypes.func,
  position: PropTypes.number,
  pageSearchText: PropTypes.instanceOf(Immutable.Map),
  filteredOn: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  filterColumn: PropTypes.objectOf(PropTypes.string),
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  copyProcessing: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  isUpdating: PropTypes.bool,
  getNextStakeholders: PropTypes.func,
  stakeholdersInit: PropTypes.func,
  selectedRecords: PropTypes.arrayOf(PropTypes.string),
  onRecordSelection: PropTypes.func,
  isDownloading: PropTypes.bool,
  toggleLoader: PropTypes.func,
  visibleFields: PropTypes.arrayOf(PropTypes.shape()),
  toggleColumn: PropTypes.func,
  pricingPlan: PropTypes.instanceOf(Immutable.Map),
  allowPDFDownload: PropTypes.bool,
  isVendor: PropTypes.bool,
  isPublic: PropTypes.bool,
  registryDetails: PropTypes.instanceOf(Immutable.Map),
  embed: PropTypes.bool,
  isPreview: PropTypes.bool,
  filterItem: PropTypes.shape(),
  filterMenuData: PropTypes.shape(),
  isDeletable: PropTypes.bool,
  canApprove: PropTypes.bool,
  isDefaultProcessing: PropTypes.bool,
  currentFilterData: PropTypes.shape({
    recordLayoutId: PropTypes.string,
    useDefaultLayoutLogic: PropTypes.bool
  }),
  isGlobal: PropTypes.bool
};

ProcessingRecordList.defaultProps = {
  userPermissions: Immutable.Map(),
  isFetching: false,
  isEditable: false,
  isViewable: false,
  items: Immutable.List(),
  deletePrivacyRecord: (e) => e,
  stakeholderSearch: (e) => e,
  getNextData: (e) => e,
  updateNote: (e) => e,
  onChooseFilter: (e) => e,
  onMount: (e) => e,
  position: 0,
  pageSearchText: Immutable.Map(),
  filteredOn: {},
  filterColumn: {},
  sortKey: '',
  sortOrder: '',
  columns: Immutable.List(),
  stakeholders: {},
  isUpdating: false,
  getNextStakeholders: (e) => e,
  stakeholdersInit: (e) => e,
  selectedRecords: [],
  onRecordSelection: (e) => e,
  isDownloading: false,
  toggleLoader: (e) => e,
  visibleFields: {},
  toggleColumn: (e) => e,
  pricingPlan: Immutable.Map({}),
  allowPDFDownload: false,
  isVendor: false,
  isPublic: false,
  registryDetails: Immutable.Map(),
  embed: false,
  isPreview: false,
  filterItem: {},
  filterMenuData: {},
  isDeletable: false,
  canApprove: false,
  isDefaultProcessing: true,
  currentFilterData: {},
  isGlobal: false
};

ProcessingRecordList.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

export default injectIntl(ProcessingRecordList);
