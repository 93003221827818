/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import CustomDialog from '@packages/components/custom-dialog';
import styles from '@packages/ui/styles';

import OrganisationEntityDialog from '../../../organisation-entities/organisationEntityDialog';
import MultipleSelectorDialog from '../../../entity-selector/components/multi-selector';
import { EEACountries, getIntlChecked } from '../../common-utils';

class LegalEntitySelector extends React.Component {
  static filterLegalEntities(selectedEntities, searchedItems) {
    const filteredData =
      searchedItems &&
      searchedItems.filter((legalEntity) => {
        const index = selectedEntities.findIndex(
          (selectedItem) => legalEntity.id === selectedItem.value.id
        );
        return index === -1;
      });
    return filteredData;
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isEdit: false,
      updated: false, // eslint-disable-line react/no-unused-state
      multiple: false,
      selectedIndex: -1,
      entityDetails: [],
      searchText: '',
      openDeleteNotification: false,
      searchResults:
        props.searchResults[this.props.fields.name] &&
        props.searchResults[this.props.fields.name].searchResults
    };

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleUsageClick = this.handleUsageClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { searchResults: currentSearchResults } =
      this.props.searchResults[
        !this.props.hasFieldName ? this.props.id : this.props.fields.name
      ] || this.props.searchResults.custom;
    const { searchResults: nextSearchResults } =
      nextProps.searchResults[
        !this.props.hasFieldName ? this.props.id : this.props.fields.name
      ] || this.props.searchResults.custom;
    if (currentSearchResults !== nextSearchResults) {
      this.setState({ searchResults: nextSearchResults });
    }

    if (this.props.entityDetails !== nextProps.entityDetails) {
      this.setState({
        entityDetails: nextProps.entityDetails
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
      !(this.state === nextState) ||
      !(this.props.legalEntities === nextProps.legalEntities) ||
      !(this.props.selectedDpoOrgId === nextProps.selectedDpoOrgId)
    );
  }

  handleAddClick(searchText) {
    this.setState({
      open: true,
      isEdit: false,
      multiple: false,
      selectedIndex: -1,
      inputValue: {
        name: searchText,
        isInternal: undefined
      }
    });
  }

  handleEditClick(index) {
    this.setState({
      open: true,
      isEdit: true,
      multiple: false,
      selectedIndex: index,
      inputValue: this.props.fields.get(index).value
    });
  }

  handleMultipleSelect() {
    this.setState({
      open: false,
      multiple: true
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
      multiple: false,
      selectedIndex: -1,
      searchText: '',
      searchResults: [],
      openDeleteNotification: false
    });
  }

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const selectedLegalEntities = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    let selectedOrgId = '';
    if (selectedIndex !== -1) {
      const selectedOrg = selectedLegalEntities.splice(selectedIndex, 1);
      selectedOrgId = selectedOrg[0].value && selectedOrg[0].value.id;
    }
    this.setState({ openDeleteNotification: false, selectedIndex: -1 });
    if (selectedOrgId === this.props.selectedDpoOrgId) {
      this.props.handleRemoveDpo(
        selectedOrgId,
        selectedLegalEntities,
        'controllers'
      );
    } else if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedLegalEntities);
    } else {
      this.props.updateData(selectedLegalEntities);
    }
  }

  handleDelete = (selectedIndex) => {
    if (this.props.showDeleteNotification) {
      this.setState({ selectedIndex, openDeleteNotification: true });
    } else this.handleRemoveItem(selectedIndex);
  };

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    let intlChecked = false;
    const items = this.props.fields.getAll() || [];
    const selectedLegalEntities = [...items, ...selectedItems];
    if (selectedItems.size && selectedItems.size > 0) {
      intlChecked = getIntlChecked(selectedItems);
    }
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedLegalEntities);
    } else {
      this.props.updateData(selectedLegalEntities, intlChecked);
    }
  }

  // Handler to add/edit items
  handleSelectedItem(selectedItem, closeDialog) {
    const { selectedIndex } = this.state;
    const modifiedItem = {
      ...selectedItem,
      note:
        selectedIndex !== -1
          ? this.props.fields.get(selectedIndex).note
          : selectedItem.value.note
    };
    this.handleEditItem(modifiedItem, selectedIndex, closeDialog);
  }

  // Update the changed items.
  handleEditItem(selectedItem, selectedIndex, closeDialog = true) {
    let intlChecked = false;
    const selectedLegalEntities = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex === -1) {
      selectedLegalEntities.push(selectedItem);
    } else {
      selectedLegalEntities.splice(selectedIndex, 1, selectedItem);
    }
    if (
      selectedItem.value.country &&
      !EEACountries.includes(selectedItem.value.country)
    ) {
      intlChecked = true;
    }
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedLegalEntities);
    } else {
      this.props.updateData(selectedLegalEntities, intlChecked);
    }

    // Close the dialog box
    if (closeDialog) {
      this.handleRequestClose();
    }
  }

  handleSearch(searchText) {
    this.setState({
      searchText
    });
    const searchParams = {
      ...searchText,
      searchKey: 'name',
      legalEntityType: this.props.hasFieldName
        ? this.props.fields.name
        : this.props.id
    };
    this.props.onSearch(searchParams);
  }

  handleUsageClick(index) {
    const { id } = this.props.fields.get(index).value;
    this.props.handleUsageClick(
      id,
      this.props.dataItemType,
      this.props.customStakeholderEntityId && {
        filter: 'customStakeholderEntityIds',
        value: this.props.customStakeholderEntityId
      }
    );
  }

  render() {
    const {
      showItemList,
      dataItemType,
      showDPO,
      handleDpoClick,
      maxCount,
      hideItemSelector,
      userPermissions
    } = this.props;
    const selectedEntities = this.props.fields.getAll();
    const excludedEntities =
      selectedEntities?.length > 0
        ? selectedEntities.concat(this.props.excludeEntities)
        : this.props.excludeEntities;
    const { searchText, searchResults } = this.state;
    const searchedItems =
      searchText !== '' || (searchResults && searchResults.size) > 0
        ? searchResults
        : this.props.legalEntities;
    const legalEntityList =
      excludedEntities?.length > 0
        ? LegalEntitySelector.filterLegalEntities(
          excludedEntities,
          searchedItems
        )
        : searchedItems;
    const itemSelectorStyle =
      selectedEntities?.length >= maxCount ? styles.disabled : {};

    const { createEditOrganisation } = userPermissions.toJS();

    return (
      <div>
        {showItemList && selectedEntities?.length > 0 && (
          <ItemList
            {...this.props}
            isEditable={createEditOrganisation}
            type={this.props.label}
            showDPO={showDPO}
            handleRemoveItem={this.handleDelete}
            handleEditClick={this.handleEditClick}
            handleEditItem={this.handleEditItem}
            selectedItems={selectedEntities}
            handleDpoClick={handleDpoClick}
            handleUsageClick={this.handleUsageClick}
          />
        )}
        {!hideItemSelector && (
          <div style={itemSelectorStyle}>
            <ItemSelector
              key={dataItemType}
              multiValue={this.props.multiValue}
              createNewMenuItem={
                createEditOrganisation && this.props.createNewMenuItem
              }
              selectFromListMenuItem={
                this.props.selectFromListMenuItem || false
              }
              dataSource={legalEntityList}
              dataSourceConfig={{ text: 'key', value: 'key' }}
              hintTextLabel={this.props.hintTextLabel}
              handleSelectedItem={this.handleSelectedItem}
              handleAddClick={this.handleAddClick}
              handleMultipleSelect={this.handleMultipleSelect}
              onSearch={this.handleSearch}
              disabled={selectedEntities?.length >= maxCount}
            />
          </div>
        )}
        {this.state.open && (
          <OrganisationEntityDialog
            open={this.state.open}
            isEdit={this.state.isEdit}
            label={this.props.label}
            entityType={this.props.entityType}
            entityId={this.state.inputValue.id}
            inputValue={this.state.inputValue}
            dataItems={this.props.legalEntities}
            onRequestClose={this.handleRequestClose}
            handleSelectedItem={this.handleSelectedItem}
            entityDetails={this.state.entityDetails}
            source="records"
            showSaveAndCreate={this.props.showSaveAndCreate}
            showParentEntity={true}
            hideTags={this.props.isGlobal}
          />
        )}
        {this.state.multiple && (
          <MultipleSelectorDialog
            title={this.props.dialogHeaderLabel}
            excludeEntities={this.props.excludeEntities}
            open={this.state.multiple}
            selectedItems={selectedEntities}
            onRequestClose={this.handleRequestClose}
            handleMultipleItems={this.handleMultipleItems}
            searchText={this.state.searchText.searchText}
            selectedEntitiesSize={selectedEntities?.length || 0}
            maxCount={maxCount}
          />
        )}
        {this.state.openDeleteNotification && (
          <CustomDialog
            id="legalEntity-delete"
            show={this.state.openDeleteNotification}
            proceed={() => this.handleRemoveItem(this.state.selectedIndex)}
            cancel={this.handleRequestClose}
            content={
              <FormattedMessage
                id="Records.legalEntityDelete"
                defaultMessage="Removing the controller {controller} would also remove the DPO consultation details,
            if any, associated with it. Do you want to continue?"
                values={{
                  controller: this.props.fields.get(this.state.selectedIndex)
                    .value.key
                }}
              />
            }
          />
        )}
      </div>
    );
  }
}

LegalEntitySelector.propTypes = {
  multiValue: PropTypes.bool,
  showItemList: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  legalEntities: PropTypes.instanceOf(Immutable.List),
  updateData: PropTypes.func,
  dialogHeaderLabel: PropTypes.node,
  fields: PropTypes.shape({
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    name: PropTypes.string,
    length: PropTypes.number
  }).isRequired,
  label: PropTypes.node,
  hintTextLabel: PropTypes.node,
  entityType: PropTypes.node,
  fetchDetails: PropTypes.func,
  entityDetails: PropTypes.shape({}),
  onSearch: PropTypes.func,
  searchResults: PropTypes.shape({}),
  handleUsageClick: PropTypes.func,
  dataItemType: PropTypes.string,
  showDPO: PropTypes.bool,
  handleDpoClick: PropTypes.func,
  selectedDpoOrgId: PropTypes.string,
  showDeleteNotification: PropTypes.bool,
  handleRemoveDpo: PropTypes.func,
  isRegistry: PropTypes.bool,
  updateRegistryFilters: PropTypes.func,
  id: PropTypes.string,
  hasFieldName: PropTypes.bool,
  maxCount: PropTypes.number,
  showSaveAndCreate: PropTypes.bool,
  isGlobal: PropTypes.bool,
  hideItemSelector: PropTypes.bool,
  excludeEntities: PropTypes.arrayOf(PropTypes.object),
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  customStakeholderEntityId: PropTypes.string
};

LegalEntitySelector.defaultProps = {
  multiValue: true,
  showItemList: false,
  selectFromListMenuItem: false,
  createNewMenuItem: false,
  legalEntities: Immutable.List(),
  updateData: (e) => e,
  dialogHeaderLabel: null,
  label: null,
  hintTextLabel: null,
  entityType: null,
  fetchDetails: (e) => e,
  entityDetails: {},
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  handleUsageClick: (e) => e,
  dataItemType: '',
  showDPO: false,
  handleDpoClick: (e) => e,
  selectedDpoOrgId: '',
  showDeleteNotification: false,
  handleRemoveDpo: (e) => e,
  isRegistry: false,
  updateRegistryFilters: (e) => e,
  id: '',
  hasFieldName: true,
  maxCount: undefined,
  showSaveAndCreate: true,
  isGlobal: false,
  hideItemSelector: false,
  excludeEntities: [],
  userPermissions: Immutable.Map(),
  customStakeholderEntityId: ''
};

export default LegalEntitySelector;
