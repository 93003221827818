import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import keycode from 'keycode';
import FontAwesome from 'react-fontawesome';
import Linkify from 'react-linkify';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import { withTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionHelp from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ActionInfo from '@material-ui/icons/Info';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import ArrowTooltip from '@packages/components/tooltip';
import CustomDialog from '@packages/components/custom-dialog';
import { removeTranslations, editTranslations } from '@packages/utils/actionTranslations';
import { commonTranslations, recordTranslations, editTypeTranslations,
  removeTypeTranslations } from '@packages/utils/commontranslations';
import Note from '../note';
import styles from '../styles';

const rowHeight = 40;

const deleteIconStyle = {
  color: 'red',
  marginLeft: '11px',
  cursor: 'pointer',
  width: '14px',
  marginRight: '9px',
  fontSize: '22px',
  marginBottom: '2px'
};

class ItemRow extends PureComponent {
  static renderLink({ input }) {
    const inputValue = input.value.value ? input.value.value : input.value;
    const value = inputValue.key ? inputValue.key : inputValue;
    return (
      <div style={styles.link}>
        <span title={value}>{value}</span>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      editNoteOpen: false,
      viewNoteOpen: false,
      deleteNoteOpen: false,
      content: undefined, // eslint-disable-line react/no-unused-state
      index: -1
    };

    this.handleTouchTap = this.handleTouchTap.bind(this);
    this.handleEditNote = this.handleEditNote.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
    this.handleDeleteNote = this.handleDeleteNote.bind(this);
    this.handleAddNoteOpen = this.handleAddNoteOpen.bind(this);
    this.handleEditNoteOpen = this.handleEditNoteOpen.bind(this);
    this.handleViewNoteOpen = this.handleViewNoteOpen.bind(this);
    this.requestDeleteNoteClose = this.requestDeleteNoteClose.bind(this);
    this.requestEditNoteClose = this.requestEditNoteClose.bind(this);
    this.requestViewNoteClose = this.requestViewNoteClose.bind(this);
    this.requestMenuClose = this.requestMenuClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.getListItemStyle = this.getListItemStyle.bind(this);
    this.getItemActionButtonStyle = this.getItemActionButtonStyle.bind(this);
  }

  handleTouchTap(event) {
    event.stopPropagation();

    this.setState({
      openMenu: true,
      anchorEl: event.currentTarget
    });
  }

  handleRowClick() {
    if (this.props.handleItemClick) {
      this.props.handleItemClick(this.props.index);
    }
  }

  handleKeyDown(event) {
    if (keycode(event) === 'enter') {
      event.preventDefault();
      // current context needs to be set while using setTimeout
      const { index, content, addNoteOpen, editNoteOpen } = this.state;
      const note = event.target.value;
      setTimeout(() => {
        this.handleEditNote(event, note, { index, content, addNoteOpen, editNoteOpen });
      }, 0);
      this.requestEditNoteClose();
    }
  }

  handleAddNoteOpen(selectedIndex) {
    this.setState({
      addNoteOpen: true,
      index: selectedIndex
    });
  }

  handleViewNoteOpen(selectedIndex) {
    this.setState({
      viewNoteOpen: true,
      index: selectedIndex
    });
  }

  handleEditNoteOpen(selectedIndex) {
    this.setState({
      editNoteOpen: true,
      index: selectedIndex
    });
  }

  handleDeleteNoteOpen(selectedIndex) {
    this.setState({
      deleteNoteOpen: true,
      index: selectedIndex
    });
  }

  // Handle note value changes
  handleEditNote(event, note, currentState) {
    const stateObject = currentState || this.state;
    const { content, index: selectedIndex, addNoteOpen, editNoteOpen } = stateObject;
    const noteContent = note || content;
    if ((addNoteOpen && noteContent !== '') || editNoteOpen) {
      const selectedItem = Object.assign({}, this.props.data, { note: noteContent && noteContent.trim() });
      this.handleNoteChange(selectedIndex, selectedItem);
    }
    this.requestEditNoteClose();
  }

  handleDeleteNote() {
    const selectedIndex = this.state.index;
    const selectedItem = Object.assign({}, this.props.data);
    selectedItem.note = '';
    this.setState({ content: '' });
    this.handleNoteChange(selectedIndex, selectedItem);
    this.requestDeleteNoteClose();
  }

  handleNoteChange(selectedIndex, searchText) {
    this.props.handleEditItem(searchText, selectedIndex);
  }

  handleChange(event) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ content: event.target.value });
  }

  getListItemStyle() {
    const itemIndex = this.props.index;
    return (itemIndex % 2) === 0 ? styles.evenRow : {};
  }

  getItemActionButtonStyle() {
    return (rowHeight * this.props.index) + 20;
  }

  getMenuItems(enableNote, note) {
    const { index, handleUsageClick, isEditable, isNoteEditable, showUsage } = this.props;
    const menuItems = [];
    if ((isEditable || isNoteEditable) && enableNote && !note) {
      menuItems.push(
        <MenuItem onClick={() => { this.requestMenuClose(); this.handleAddNoteOpen(index); }}>
          {commonTranslations.addNote}
        </MenuItem>
      );
    }
    if ((isEditable || isNoteEditable) && enableNote && note) {
      menuItems.push(
        <MenuItem onClick={() => { this.requestMenuClose(); this.handleEditNoteOpen(index); }}>
          {commonTranslations.editNote}
        </MenuItem>
      );
    }
    if (note) {
      menuItems.push(
        <MenuItem onClick={() => { this.requestMenuClose(); this.handleViewNoteOpen(index); }}>
          {commonTranslations.viewNote}
        </MenuItem>
      );
    }
    if ((isEditable || isNoteEditable) && enableNote && note) {
      menuItems.push(
        <MenuItem onClick={() => { this.requestMenuClose(); this.handleDeleteNoteOpen(index); }}>
          {commonTranslations.removeNote}
        </MenuItem>
      );
    }
    if (isEditable && showUsage) {
      menuItems.push(
        <MenuItem onClick={() => { this.requestMenuClose(); handleUsageClick(index); }}>
          {commonTranslations.usageInRecords}
        </MenuItem>
      );
    }
    return menuItems;
  }

  requestMenuClose() {
    this.setState({
      openMenu: false
    });
  }

  requestEditNoteClose() {
    this.setState({
      editNoteOpen: false,
      addNoteOpen: false,
      index: -1
    });
  }

  requestDeleteNoteClose() {
    this.setState({
      deleteNoteOpen: false,
      index: -1
    });
  }

  requestViewNoteClose() {
    this.setState({
      viewNoteOpen: false,
      index: -1
    });
  }

  renderLabel({ input, data }) {
    const { multiLine } = this.props;
    const themeColor = this.props.theme.palette.primary.main;
    const inputValue = input.value.value ? input.value.value : input.value;
    const value = inputValue.key || inputValue.name || inputValue || data.key || data.name || data;
    const addtlValue = inputValue.addlkey || '';
    const labelIcon = data.icon || '';
    const addtlInfo = data.type || '';
    const className = multiLine ? 'line-clamp' : 'single-line';
    const addtlInfoStyle = { width: '60%' };
    const readOnly = typeof (inputValue.readOnly) === 'undefined' ? false : inputValue.readOnly;

    const rowTextStyle = this.props.customStyle ? Object.assign({}, styles.textLength, this.props.customStyle) :
      styles.textLength;

    let currentStyle = { ...styles.label, color: readOnly ? '#6a6565' : '' };

    if (multiLine) {
      currentStyle = Object.assign({}, styles.label, styles.multiLine);
    } else if (inputValue.selected) {
      currentStyle = Object.assign({}, styles.label, { color: '#00ad55' });
    }
    return (
      <Linkify properties={{ target: '_blank', style: { color: themeColor } }}>
        <div
          className={className}
          style={Object.assign({}, currentStyle, inputValue.content && { marginRight: '30px' })}
        >
          <ArrowTooltip title={value} style={{ width: '75%' }}>
            <div style={addtlInfo ? addtlInfoStyle : rowTextStyle} >
              {labelIcon && <span>{labelIcon}</span>}
              {value}
              {addtlValue && <span> ({addtlValue})</span>}
            </div>
          </ArrowTooltip>
          {addtlInfo &&
          <div style={{ fontWeight: 'bold', color: themeColor }}>
            &nbsp;{addtlInfo}
          </div>}
        </div>
      </Linkify>
    );
  }

  render () {
    const { item, data, index, type, isNote, isLink, multiLine, dataItemType, allowedActions,
      showDPO, handleDpoClick, selectedDpoOrgId, customRowStyle } = this.props;
    const enableNote = typeof (isNote) === 'undefined' || isNote;
    const themeColor = this.props.theme.palette.primary.main;
    let title = '';
    let content = '';
    let note = '';
    let readOnly = false;
    let deleteDisabled = false;
    const selectedItemValue = data.value ? data.value : data;
    const itemWarning = data.value?.itemWarning;
    if (data) {
      title = selectedItemValue.key ? selectedItemValue.key : '';

      content = selectedItemValue.content ? selectedItemValue.content : null;
      note = data.note ? data.note : null;

      // if this is set, none of the menu options are displayed. This over-rides the isEditable and enableNote.
      readOnly = typeof (selectedItemValue.readOnly) === 'undefined' ? false : selectedItemValue.readOnly;
      deleteDisabled = data.deleteDisabled || false;
    }
    const viewAction = [
      <Button
        onClick={this.requestViewNoteClose}
      >
        {commonTranslations.close}
      </Button>
    ];
    const menuItems = this.getMenuItems(enableNote, note);
    const rowStyle = multiLine ? styles.multiLineRow : styles.row;
    const isDPOSelected = selectedDpoOrgId === selectedItemValue.id;
    const listItemStyle = { ...this.props.style,
      ...this.getListItemStyle(),
      ...rowStyle,
      display: 'flex',
      ...customRowStyle };
    const { handleEditClick, handleRemoveItem, isEditable } = this.props;
    const itemRowEditStyle = { color: this.props.theme.palette.primary.main, fontSize: '20px', cursor: 'pointer' };

    return (
      <div className="item-row-icons">
        <ListItem
          button={true}
          key={`${index}_${dataItemType}`}
          onClick={this.handleRowClick}
          style={listItemStyle}
          ContainerComponent="li"
          ContainerProps={{
            style: { display: 'flex' }
          }}
        >
          <ListItemText
            disableTypography={true}
            primary={
              <Field
                name={item}
                key={`${index}_${dataItemType}`}
                component={isLink ? ItemRow.renderLink : this.renderLabel}
                data={selectedItemValue}
              />}
          />
          <ListItemSecondaryAction style={{ top: this.getItemActionButtonStyle() }}>
            <div>
              <div className="item-action-icons">
                {!readOnly &&
                <div className="delete-edit-icon" >
                  {isEditable && allowedActions.includes('edit') &&
                    <ArrowTooltip title={editTypeTranslations(dataItemType) || editTranslations('edit')}>
                      <EditIcon
                        style={itemRowEditStyle}
                        key="edit-icon"
                        onClick={() => { this.requestMenuClose(); handleEditClick(index); }}
                      />
                    </ArrowTooltip>
                   }
                  {allowedActions.includes('delete') &&
                  <ArrowTooltip title={!deleteDisabled ? removeTypeTranslations(dataItemType) || 
                    removeTranslations('remove') : commonTranslations.deleteWarning}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{...deleteIconStyle, opacity: deleteDisabled && 0.5}}
                      key="delete-icon"
                      onClick={!deleteDisabled ? () => {
                        this.requestMenuClose();
                        handleRemoveItem(index);
                      } : null}
                    />
                  </ArrowTooltip>
                }
                </div>}
                <div style={{ display: 'flex' }}>
                  {content &&
                  <ArrowTooltip title={content}>
                    <IconButton
                      style={styles.rightIcon}
                    >
                      <ActionHelp color="primary" style={{ marginRight: '6px', fontSize: '22px' }} />
                    </IconButton>
                  </ArrowTooltip>}
                  {showDPO &&
                  <div className={isDPOSelected ? '' : 'dpo-icon'}>
                    <ArrowTooltip
                      title={isDPOSelected ? recordTranslations.removeDpo : recordTranslations.selectDpo}
                    >
                      <FontAwesomeIcon
                        style={{ paddingRight: '9px',
                          cursor: 'pointer',
                          paddingTop: '4px',
                          color: isDPOSelected ? themeColor : 'grey' }}
                        icon={faUsers}
                        onClick={() => handleDpoClick(selectedItemValue.id)}
                      />
                    </ArrowTooltip>
                  </div>
                  }
                  <div style={{ display: 'flex' }}>
                    {!readOnly && (menuItems.length > 0) && note &&
                    <ArrowTooltip title={note}>
                      <IconButton
                        style={styles.rightIcon}
                      >
                        <ActionInfo style={{ fontSize: '22px' }} />
                      </IconButton>
                    </ArrowTooltip>}
                    {!readOnly && (menuItems.length > 0) && allowedActions.includes('actionChooser') &&
                    <IconButton
                      edge="end"
                      style={styles.rightIcon}
                      onClick={this.handleTouchTap}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                  </div>
                </div>
                {itemWarning &&
                <div style={{ color: 'red', marginLeft: '5px' }}>
                  <ArrowTooltip title={<span style={{ color: 'red' }}>{itemWarning}</span>}>
                    <FontAwesome
                      name="warning"
                      size="sm"
                      style={{ lineHeight: '1em' }}
                    />
                  </ArrowTooltip>
                </div>}
              </div>
            </div>
          </ListItemSecondaryAction>
        </ListItem>
        {(this.state.editNoteOpen || this.state.addNoteOpen) &&
          <Note
            id="edit-note"
            content={this.state.addNoteOpen ? null : note}
            onChange={this.handleChange}
            isEdit={true}
            headerLabel={this.state.addNoteOpen ? commonTranslations.addNoteHeader : commonTranslations.editNoteHeader}
            entityName={type}
            title={title}
            open={this.state.editNoteOpen || this.state.addNoteOpen}
            handleEditNote={this.handleEditNote}
            close={this.requestEditNoteClose}
          />
        }
        {this.state.viewNoteOpen &&
          <Note
            id="view-note"
            content={note}
            isEdit={false}
            headerLabel={commonTranslations.viewNoteHeader}
            entityName={type}
            title={title}
            open={this.state.viewNoteOpen}
            saveActions={viewAction}
            close={this.requestViewNoteClose}
          />
        }
        {this.state.deleteNoteOpen && <CustomDialog
          show={this.state.deleteNoteOpen}
          proceed={this.handleDeleteNote}
          cancel={this.requestDeleteNoteClose}
          content={recordTranslations.deleteNote}
        />}
        {this.state.openMenu &&
          <Menu
            open={this.state.openMenu}
            anchorEl={this.state.anchorEl}
            onClose={this.requestMenuClose}
          >
            {menuItems}
          </Menu>}
      </div>
    );
  }
}

ItemRow.propTypes = {
  type: PropTypes.node,
  style: PropTypes.shape({}).isRequired,
  isNote: PropTypes.bool,
  isLink: PropTypes.bool,
  index: PropTypes.number.isRequired,
  handleEditClick: PropTypes.func,
  handleItemClick: PropTypes.func,
  handleEditItem: PropTypes.func,
  handleRemoveItem: PropTypes.func,
  isEditable: PropTypes.bool,
  isNoteEditable: PropTypes.bool,
  customRowStyle: PropTypes.shape({}),
  item: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string
  ]),
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  multiLine: PropTypes.bool,
  dataItemType: PropTypes.string,
  handleUsageClick: PropTypes.func,
  showUsage: PropTypes.bool,
  showDPO: PropTypes.bool,
  handleDpoClick: PropTypes.func,
  selectedDpoOrgId: PropTypes.string,
  customStyle: PropTypes.shape({}),
  allowedActions: PropTypes.arrayOf(PropTypes.string)
};

ItemRow.defaultProps = {
  type: null,
  isNote: true,
  isLink: false,
  isEditable: true,
  isNoteEditable: false,
  handleEditClick: e => e,
  handleItemClick: e => e,
  handleEditItem: e => e,
  handleRemoveItem: e => e,
  data: {},
  theme: {},
  // isPartOf: '',
  multiLine: false,
  dataItemType: '',
  handleUsageClick: e => e,
  showUsage: true,
  showDPO: false,
  handleDpoClick: e => e,
  selectedDpoOrgId: '',
  customStyle: {},
  allowedActions: [],
  customRowStyle: {}
};

export default withTheme(ItemRow);
