/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';

import ItemRow from './itemRow';
import DivWrapper from '../divWrapper';

class ItemList extends React.Component {
  listRef = React.createRef();

  shouldComponentUpdate(nextProps) {
    return (
      !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
      !(this.props.selectedDpoOrgId === nextProps.selectedDpoOrgId) ||
      !(this.props.allowedActions === nextProps.allowedActions)
    );
  }

  handleScroll = ({ target }) => {
    const { scrollTop } = target;
    this.listRef.current.scrollTo(scrollTop);
  };

  render() {
    const {
      fields,
      isPartOf,
      dataItemType,
      type,
      isNote,
      isLink,
      multiLine,
      handleEditItem,
      handleItemClick,
      isEditable,
      handleDpoClick,
      selectedDpoOrgId,
      isNoteEditable,
      handleEditClick,
      handleRemoveItem,
      theme,
      handleUsageClick,
      showUsage,
      allowedActions,
      customRowStyle,
      showDPO,
      customStyle,
      customItemLengthSelector
    } = this.props;

    const itemsArray = fields.getAll();

    // eslint-disable-next-line react/no-unstable-nested-components
    const Row = (props) => {
      const { index, style } = props;
      return (
        <ItemRow
          style={style}
          data={
            fields.get(index)
              ? fields.get(index).emailId || fields.get(index)
              : {}
          }
          key={`${index}_${dataItemType}`}
          item={`${dataItemType}[${index}]`}
          index={index}
          isPartOf={isPartOf}
          dataItemType={dataItemType}
          type={type}
          showDPO={showDPO}
          selectedDpoOrgId={selectedDpoOrgId}
          isNote={isNote}
          isLink={isLink}
          multiLine={multiLine}
          handleItemClick={handleItemClick}
          handleEditItem={handleEditItem}
          isNoteEditable={isNoteEditable}
          handleEditClick={handleEditClick}
          handleDpoClick={handleDpoClick}
          handleRemoveItem={handleRemoveItem}
          isEditable={isEditable}
          theme={theme}
          handleUsageClick={handleUsageClick}
          showUsage={showUsage}
          customStyle={customStyle}
          allowedActions={allowedActions}
          customRowStyle={customRowStyle}
        />
      );
    };

    // Set the scroll height
    const calculatedHeight =
      itemsArray && itemsArray.length <= (customItemLengthSelector || 5)
        ? itemsArray.length * 40
        : customItemLengthSelector * 40 || 200;

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {itemsArray && itemsArray.length > 0 && (
          <DivWrapper
            autoHeight={multiLine}
            autoHeightMax={calculatedHeight}
            onScroll={this.handleScroll}
            style={{
              height: multiLine ? 'auto' : calculatedHeight,
              maxHeight: calculatedHeight
            }}
          >
            <List
              className="List"
              height={calculatedHeight}
              itemCount={itemsArray.length}
              itemSize={40}
              ref={this.listRef}
              style={{
                pointerEvents: this.props.disabled ? 'none' : '',
                overflow: 'none'
              }}
            >
              {Row}
            </List>
          </DivWrapper>
        )}
      </>
    );
  }
}

ItemList.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    getAll: PropTypes.func,
    get: PropTypes.func,
    length: PropTypes.number
  }).isRequired,
  isPartOf: PropTypes.string,
  multiLine: PropTypes.bool,
  dataItemType: PropTypes.string,
  type: PropTypes.node,
  isNote: PropTypes.bool,
  isLink: PropTypes.bool,
  handleEditItem: PropTypes.func,
  handleItemClick: PropTypes.func,
  isEditable: PropTypes.bool,
  isNoteEditable: PropTypes.bool,
  handleEditClick: PropTypes.func,
  handleRemoveItem: PropTypes.func,
  theme: PropTypes.shape({}).isRequired,
  handleUsageClick: PropTypes.func,
  showUsage: PropTypes.bool,
  handleDpoClick: PropTypes.func,
  showDPO: PropTypes.bool,
  selectedDpoOrgId: PropTypes.string,
  disabled: PropTypes.bool,
  index: PropTypes.number,
  style: PropTypes.shape({}),
  customStyle: PropTypes.shape({}),
  customRowStyle: PropTypes.shape({}),
  customItemLengthSelector: PropTypes.number,
  allowedActions: PropTypes.arrayOf(PropTypes.string)
};

ItemList.defaultProps = {
  isPartOf: '',
  multiLine: false,
  dataItemType: '',
  type: {},
  customRowStyle: {},
  isNote: true,
  isLink: false,
  showUsage: true,
  handleEditItem: (e) => e,
  handleItemClick: (e) => e,
  isEditable: PropTypes.bool,
  isNoteEditable: true,
  handleEditClick: (e) => e,
  handleRemoveItem: (e) => e,
  handleUsageClick: (e) => e,
  handleDpoClick: (e) => e,
  showDPO: false,
  selectedDpoOrgId: '',
  disabled: false,
  index: -1,
  style: {},
  customStyle: {},
  customItemLengthSelector: 0,
  allowedActions: ['edit', 'delete', 'actionChooser']
};

export default React.memo(ItemList);
