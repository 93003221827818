import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ItemSelector from '@packages/components/item-selector';
import { recordTranslations } from '@packages/utils/commontranslations';
import MultipleSelectorDialog from '../multi-selector';

const defaultStyle = {
  autoComplete: {
    height: '40px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #9b9b9b'
  }
};

class EntitySelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      multiple: false,
      searchText: '',
      searchResults: [],
      typeChange: false,
      selectedKey: props.selectedKey || ''
    };

    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getSearchText = this.getSearchText.bind(this);
    this.filterOrganisationEntities = this.filterOrganisationEntities.bind(this);
    this.filterGlobalEntities = this.filterGlobalEntities.bind(this);
  }

  componentDidMount() {
    if (this.props.init && !this.props.isEdit && !this.props.isImportParent) { // for add
      this.props.init();
    } else if (this.props.isInternal !== undefined) {
      const entityFilter = this.props.isVendor && 'orgType=legalentity';
      this.props.init(this.props.isInternal, entityFilter);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchResults !== this.props.searchResults) this.setState({ searchResults: nextProps.searchResults });
    if (nextProps.selectedKey !== this.props.selectedKey) {
      this.setState({ selectedKey: nextProps.selectedKey });
    }
    if (this.props.isImportParent && (this.props.isLegalEntity !== nextProps.isLegalEntity)) {
      this.props.init(nextProps.isInternal);
      this.setState({ selectedKey: '' });
    }
    if (this.props.isEdit) {
      if (this.props.isInternal === undefined && nextProps.isInternal !== undefined) {
        this.props.init(nextProps.isInternal);
      } else if (this.props.isInternal !== nextProps.isInternal) {
        this.handleTypeChange(nextProps.isInternal);
      }
      if (this.props.input.value !== nextProps.input.value) this.setState({ selectedItemId: nextProps.input.value });
    } else if (this.props.isInternal !== nextProps.isInternal) {
      this.handleTypeChange(nextProps.isInternal);
    }
  }

  handleTypeChange(isInternal) {
    if (!this.props.isImportParent || this.props.isLegalEntity === 'true') {
      this.props.init(isInternal);
      this.setState({
        typeChange: true,
        searchText: '',
        searchResults: []
      });
    }
  }

  handleMultipleSelect() {
    this.setState({
      multiple: true
    });
  }

  handleRequestClose() {
    this.setState({
      multiple: false,
      searchText: '',
      searchResults: []
    });
  }

  handleMultipleItems(selectedItems) {
    this.props.input.onChange(selectedItems.get(0) ? selectedItems.get(0).value.id : '');
    this.setState({
      selectedKey: selectedItems.get(0).value.key,
      typeChange: false
    });
    this.handleRequestClose();
  }

  handleSelectedItem(searchText) {
    this.setState({
      selectedKey: searchText.value.key,
      selectedItemId: searchText.value.id,
      typeChange: false
    });
    this.props.input.onChange(searchText.value.id);
    this.props.handleSelectOrganisation(searchText.value);
  }

  handleSearch(searchText) {
    this.setState({
      searchText
    });
    const searchParams = { ...searchText,
      searchKey: 'name',
      isInternal: this.props.isInternal };
    this.props.onSearch(searchParams, this.props.isVendor);
    if (searchText.searchText === '') this.props.input.onChange('');
  }

  getSearchText (organisationEntityList) {
    const selectedEntity = organisationEntityList.find(item => item.id === this.props.input.value);
    let searchText = (selectedEntity === undefined ? this.state.selectedKey : selectedEntity.key);
    if (this.state.typeChange && !(this.props.isImportParent && this.props.isLegalEntity === 'false')) {
      searchText = '';
      this.props.input.onChange('');
    }
    return searchText;
  }

  filterOrganisationEntities(searchresults) {
    const filteredData = searchresults.filter(organisationEntity =>
      (organisationEntity.id !== this.props.selectedOrgEntityId));
    return filteredData;
  }

  filterGlobalEntities(searchresults) {
    const filteredData = searchresults.filter(organisationEntity =>
      ((organisationEntity.id !== this.props.selectedOrgEntityId) && organisationEntity.orgType === 'legalentity'));
    return filteredData;
  }

  render () {
    const { hintTextLabel, textFieldStyle, input, showFilters, isVendor, isGlobal, isSubTenantDepartment } = this.props;
    const { searchText, searchResults } = this.state;
    const searchedItems = searchText !== '' || searchResults.size > 0 ? searchResults : this.props.organisationEntities;
    const globalSearchedItems = this.filterGlobalEntities(searchedItems);
    const organisationEntityList = this.props.selectedOrgEntityId ?
      this.filterOrganisationEntities(searchedItems) : searchedItems;
    const globalEntityList = this.props.selectedOrgEntityId ?
      this.filterGlobalEntities(searchedItems) : globalSearchedItems;
    const searchtext = this.getSearchText(organisationEntityList);
    return (
      <div>
        <ItemSelector
          id="item_selector"
          multiValue={false}
          selectFromListMenuItem={true}
          selectedItem={searchtext}
          dataSource={!isGlobal && !isSubTenantDepartment ? organisationEntityList : globalEntityList}
          handleSelectedItem={this.handleSelectedItem}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          hintTextLabel={hintTextLabel}
          textFieldStyle={Object.assign({}, defaultStyle.autoComplete, textFieldStyle)}
          handleMultipleSelect={this.handleMultipleSelect}
          onAfterReset={this.props.onAfterReset}
          onSearch={this.handleSearch}
          input={input}
          disabled={this.props.disabled}
          createNewMenuItem={this.props.createNewMenuItem}
          handleAddClick={this.props.handleAddClick}
        />
        {this.state.multiple && <MultipleSelectorDialog
          id="multi_selector_dialog"
          title={recordTranslations.orgDialogHeader}
          open={this.state.multiple}
          multiValue={false}
          isGlobal={isGlobal}
          isSubTenantDepartment={isSubTenantDepartment}
          isInternal={this.props.isInternal}
          showOrganisationFilter={this.props.isInternal || isGlobal ? false : this.props.isInternal}
          selectedOrgEntityId={this.props.selectedOrgEntityId}
          selectedItems={this.state.selectedItemId}
          onRequestClose={this.handleRequestClose}
          handleMultipleItems={this.handleMultipleItems}
          showFilters={showFilters}
          isVendor={isVendor}
          isLegalEntity={this.props.isLegalEntity}
        />}
      </div>
    );
  }
}

EntitySelector.propTypes = {
  init: PropTypes.func,
  selectedOrgEntityId: PropTypes.string,
  selectedKey: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string
  }),
  handleSelectOrganisation: PropTypes.func,
  onAfterReset: PropTypes.func,
  textFieldStyle: PropTypes.node,
  hintTextLabel: PropTypes.node,
  isInternal: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  organisationEntities: PropTypes.instanceOf(Immutable.List),
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  disabled: PropTypes.bool,
  isVendor: PropTypes.bool,
  isGlobal: PropTypes.bool,
  showFilters: PropTypes.bool,
  isImportParent: PropTypes.bool,
  createNewMenuItem: PropTypes.func,
  handleAddClick: PropTypes.func,
  isLegalEntity: PropTypes.string,
  isSubTenantDepartment: PropTypes.bool
};

EntitySelector.defaultProps = {
  init: e => e,
  handleSelectOrganisation: e => e,
  input: {},
  onAfterReset: e => e,
  textFieldStyle: null,
  hintTextLabel: null,
  organisationEntities: Immutable.List(),
  selectedOrgEntityId: null,
  selectedKey: null,
  onSearch: e => e,
  searchResults: Immutable.List(),
  disabled: false,
  isVendor: false,
  isGlobal: false,
  showFilters: true,
  isImportParent: false,
  createNewMenuItem: false,
  handleAddClick: e => e,
  isLegalEntity: 'false',
  isSubTenantDepartment: false
};


export default EntitySelector;
