/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';

import OrganisationEntityDialog from '../organisation-entities/organisationEntityDialog';
import MultipleSelectorDialog from './components/multi-selector';

export const LegalEntitySelector = (props) => {
  const {
    multiValue,
    userPermissions,
    showItemList,
    showSaveAndCreate,
    input,
    selectedItems,
    resetSearchFilter,
    name,
    showResetButton,
    fields,
    onSearch,
    legalEntities,
    label,
    createNewMenuItem,
    dialogHeaderLabel,
    showParentEntity,
    hideTags,
    source,
    entityType,
    onAfterReset,
    selectFromListMenuItem,
    hintTextLabel,
    isInternal
  } = props;
  const { createEditOrganisation } = userPermissions.toJS();

  const [open, setOpen] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [searchText, setSearchText] = useState('');
  const [multiSearchTextValue, setMultiSearchTextValue] = useState({});
  const [searchResults, setSearchResults] = useState(undefined);
  const [inputValue, setInputValue] = useState(undefined);

  useEffect(() => {
    setSearchResults(props.searchResults);
  }, [props.searchResults]);

  useEffect(() => {
    if (!multiValue) {
      const searchText = input.value.key || input.value;
      setSearchText(searchText);
    }
  }, [multiValue, input]);

  const handleAddClick = (searchText) => {
    setOpen(true);
    setIsEdit(false);
    setMultiple(false);
    setSelectedIndex(-1);
    setInputValue({
      name: searchText,
      isInternal: undefined
    });
  };

  const handleEditClick = (index) => {
    setOpen(true);
    setIsEdit(true);
    setMultiple(false);
    setSelectedIndex(index);
    setInputValue(selectedItems[index].value);
  };

  const handleMultipleSelect = () => {
    setOpen(false);
    setMultiple(true);
  };

  const handleRequestClose = () => {
    resetSearchFilter(name.toUpperCase());
    setOpen(false);
    setMultiple(false);
    setSelectedIndex(-1);
    setMultiSearchTextValue('');
  };

  const handleMultipleItems = (selectedItems) => {
    if (props.handleMultipleItems) {
      props.handleMultipleItems(selectedItems);
    }
    if (!multiValue && selectedItems.get(0)) {
      input.onChange(selectedItems.get(0).value);
      setSearchText(selectedItems.get(0).value.key);
    }
    handleRequestClose();
  };

  const handleSelectedItem = (searchText, closeDialog) => {
    if (props.handleSelectedItem) {
      props.handleSelectedItem(searchText);
      if (showResetButton) {
        input.onChange(searchText.value);
        setSearchText(searchText.value.key || searchText.value);
      }
    } else if (multiValue) {
      if (selectedIndex === -1) {
        fields.push(searchText);
      } else {
        fields.remove(selectedIndex);
        fields.insert(selectedIndex, searchText);
      }
    } else {
      input.onChange(searchText.value);
      setSearchText(searchText.value.key || searchText.value);
    }
    if (closeDialog) {
      handleRequestClose();
      setSearchText('');
    }
    setSearchResults([]);
  };

  const filterLegalEntities = (searchedItems) => {
    const filteredData = searchedItems.filter((legalEntity) => {
      const index = selectedItems.findIndex(
        (selectedItem) =>
          legalEntity.id ===
          (selectedItem.value ? selectedItem.value.id : selectedItem.id)
      );
      return index === -1;
    });
    return filteredData;
  };

  const handleSearch = (searchText) => {
    setMultiSearchTextValue(searchText); // setting searchText was giving unexpected result.
    const searchParams = {
      ...searchText,
      searchKey: 'name',
      legalEntityType: name.toUpperCase()
    };
    onSearch(searchParams);
    if (searchParams.searchText === '') input.onChange('');
  };

  const searchedItems =
    searchText !== '' || (searchResults && searchResults.size) > 0
      ? searchResults
      : legalEntities;
  const legalEntityList =
    selectedItems && selectedItems.length > 0
      ? filterLegalEntities(searchedItems)
      : searchedItems;

  return (
    <div>
      {showItemList && (
        <ItemList
          isEditable={createEditOrganisation}
          {...props}
          type={label}
          handleEditClick={handleEditClick}
        />
      )}
      <ItemSelector
        multiValue={multiValue}
        createNewMenuItem={createEditOrganisation && createNewMenuItem}
        selectFromListMenuItem={selectFromListMenuItem || false}
        selectedItem={searchText}
        dataSource={legalEntityList}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        hintTextLabel={hintTextLabel}
        handleSelectedItem={handleSelectedItem}
        handleAddClick={handleAddClick}
        handleMultipleSelect={handleMultipleSelect}
        showResetButton={showResetButton}
        onAfterReset={onAfterReset}
        input={input}
        onSearch={handleSearch}
      />
      {open && (
        <OrganisationEntityDialog
          open={open}
          isEdit={isEdit}
          label={label}
          entityType={entityType}
          inputValue={inputValue}
          dataItems={legalEntities}
          onRequestClose={handleRequestClose}
          handleSelectedItem={handleSelectedItem}
          showSaveAndCreate={showSaveAndCreate}
          source={source}
          hideTags={hideTags}
          showParentEntity={showParentEntity}
        />
      )}
      {multiple && (
        <MultipleSelectorDialog
          title={dialogHeaderLabel}
          open={multiple}
          multiValue={multiValue}
          selectedItems={selectedItems}
          onRequestClose={handleRequestClose}
          handleMultipleItems={handleMultipleItems}
          searchText={multiSearchTextValue.searchText}
          isInternal={isInternal}
        />
      )}
    </div>
  );
};

LegalEntitySelector.propTypes = {
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  handleSelectedItem: PropTypes.func,
  showResetButton: PropTypes.bool,
  onAfterReset: PropTypes.func,
  handleMultipleItems: PropTypes.func,
  fields: PropTypes.shape({
    push: PropTypes.func,
    insert: PropTypes.func,
    remove: PropTypes.func
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        key: PropTypes.string
      })
    ])
  }),
  label: PropTypes.node,
  hintTextLabel: PropTypes.node,
  dialogHeaderLabel: PropTypes.node,
  entityType: PropTypes.node,
  multiValue: PropTypes.bool,
  showItemList: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  legalEntities: PropTypes.instanceOf(Immutable.List),
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  onSearch: PropTypes.func,
  searchResults: PropTypes.shape({}),
  name: PropTypes.string,
  showSaveAndCreate: PropTypes.bool,
  resetSearchFilter: PropTypes.func.isRequired,
  source: PropTypes.string,
  hideTags: PropTypes.bool,
  showParentEntity: PropTypes.bool,
  isInternal: PropTypes.bool
};

LegalEntitySelector.defaultProps = {
  showResetButton: false,
  userPermissions: Immutable.Map(),
  multiValue: true,
  showItemList: false,
  handleSelectedItem: null,
  input: {},
  onAfterReset: (e) => e,
  handleMultipleItems: (e) => e,
  fields: {
    push: (e) => e,
    insert: (e) => e,
    remove: (e) => e
  },
  label: null,
  hintTextLabel: null,
  dialogHeaderLabel: null,
  entityType: null,
  selectedItems: [],
  legalEntities: Immutable.List(),
  selectFromListMenuItem: false,
  createNewMenuItem: false,
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  name: '',
  showSaveAndCreate: true,
  source: '',
  hideTags: false,
  showParentEntity: false,
  isInternal: undefined
};
export default LegalEntitySelector;
