/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { push, replace as replaceRouter } from 'connected-react-router';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';

import notificationtranslations from '@packages/utils/notificationtranslations';
import {
  getErrorMessage,
  handleServiceDown
} from '@packages/utils/common-utils';

import { getUserOrg, transformCopyRecordData } from '../common-utils';
import { isRecordEditable, getRecordData } from '../saga-utils';
import {
  transformRequestData,
  transformResponseData,
  getTiaVendorData
} from './utils/tiaUtils';

function* getDefaultTiaData() {
  const user = yield select((state) => state.user);
  const aclOrgs = getUserOrg(user);
  return {
    startDateWithPrefix: null,
    endDateWithPrefix: null,
    purposes: [],
    aclUsers: [],
    aclOrgs: aclOrgs.length > 1 ? [] : aclOrgs,
    dataSubjectCategories: [],
    personalDataItems: [],
    personalDataCategories: [],
    dataSources: [],
    transferGrounds: [],
    isExecuteAccess: true,
    status: 'record_status_draft',
    purposesOfTransfer: [],
    companyAccess: false,
    dataExporters: [],
    text: {},
    htmlText: {},
    pickList: {},
    stakeholder: {},
    checkbox: {},
    picklist: {},
    scoredPicklist: {}
  };
}

const getTiaDetailFetchUrl = (
  recordId,
  isTemplateMode,
  isPreviewMode,
  libraryTenantId
) => {
  let url = '/v1/records';
  if (isTemplateMode) url += '/templates';
  url += `/tias/${recordId}`;
  if (isPreviewMode) url += `/preview/${libraryTenantId}`;
  return url;
};

export function* initializeTiaDetail(action) {
  const {
    locale,
    tenantLocale,
    isTemplateMode,
    isPreviewMode,
    libraryTenantId
  } = action;
  const { recordId } = getParameterValuesFromHash(
    '/:routeType/:recordId/:mode'
  );

  yield put({ type: 'TIA:DETAIL:FETCH', recordId });

  try {
    const response = yield registry
      .get('request')
      .get(
        getTiaDetailFetchUrl(
          recordId,
          isTemplateMode,
          isPreviewMode,
          libraryTenantId
        ),
        null
      );

    switch (response.status) {
      case 200:
        {
          const recordDetail = response.body;
          const userState = yield select((state) => state.user);
          const currentUser = userState ? userState.get('profile') : null;
          const isEditable = yield isRecordEditable(recordDetail, currentUser);
          const store = registry.get('store');
          const responseData = Object.assign(
            {},
            yield getDefaultTiaData(),
            recordDetail
          );
          const data = yield transformResponseData(responseData, currentUser);
          if (recordDetail.status === 'record_status_requested') {
            store.dispatch(replaceRouter(`/tia/${recordDetail.id}/view`));
            yield put({
              type: 'TIA:EDIT:RESTRICTED',
              isEditRestricted: true
            });
          }
          yield put({
            type: 'TIA:DETAIL:FETCH:SUCCESS',
            isEditable,
            recordName: data.name,
            data
          });
          const { layoutId } = response.body;
          yield put({
            type: 'RECORD_LAYOUT:FETCH_INIT',
            recordType: 'TIA',
            layoutId,
            locale,
            tenantLocale,
            libraryTenantId,
            isPreviewMode
          });
        }
        break;

      case 404:
      case 403: {
        const error = response.body.msg;
        yield put({
          type: 'TIA:DETAIL:FETCH:FAIL',
          error
        });

        const currentState = yield select((state) => state.tia);
        const store = registry.get('store');

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        if (!isPreviewMode)
          store.dispatch(replaceRouter(currentState.get('prevLocation')));

        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    const error = getErrorMessage(err);
    yield put({ type: 'TIA:DETAIL:FETCH:FAIL', error });
  }
}

export function* upsertTiaDetail(action) {
  const request = registry.get('request');
  const {
    templateId,
    copyOfRecordId,
    data,
    createFromEdit,
    isVendor,
    isTemplateMode,
    templateFromRecord,
    refetchRecords,
    isGlobal
  } = action;

  yield put({ type: 'LOADER:TOGGLE', toggle: true });

  try {
    // If assessment is a copy of an existing assessment, then get that assessment data.
    const defaultReqData =
      copyOfRecordId || templateId
        ? yield getRecordData({ copyOfRecordId, templateId }, 'tias')
        : yield getDefaultTiaData();
    const modifiedData =
      isVendor && templateId && templateId !== -1
        ? getTiaVendorData(defaultReqData, data)
        : Object.assign({}, defaultReqData, data);
    const userState = yield select((state) => state.user);
    const aclOrgs = getUserOrg(userState);
    const currentUser = userState ? userState.get('profile') : null;
    const newData =
      copyOfRecordId && !isTemplateMode
        ? transformCopyRecordData(modifiedData, currentUser, aclOrgs)
        : modifiedData;
    // TODO: To be refactored later
    const requestData =
      copyOfRecordId || templateId
        ? { ...newData, ...(isGlobal ? { companyAccess: true } : {}) }
        : transformRequestData(newData, isGlobal);

    const requestUrl = `/v1/records${isTemplateMode ? '/templates' : ''}/tias`;
    const response = yield request.post(requestUrl, requestData);

    yield put({ type: 'LOADER:TOGGLE', toggle: true });

    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        yield put({ type: 'TIA:DETAIL:UPSERT:SUCCESS' });
        if (isVendor) {
          refetchRecords();
          const location =
            window.location.origin ||
            `${window.location.protocol}//${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ''
            }`;
          yield window.open(
            `${location}/#/tia/${response.body.id}/edit`,
            '_blank'
          );
        } else if (templateFromRecord) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: notificationtranslations.templateCreated,
              type: 'success'
            }
          });
          yield put(replaceRouter('/privacyrecords'));
        } else if (isTemplateMode) {
          yield put(push(`/tia-template/${response.body.id}/edit`));
        } else if (createFromEdit) {
          yield put(push(`/tia/${response.body.id}/view`));
        } else {
          yield put(push(`/tia/${response.body.id}/edit`));
        }
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({
          type: 'TIA:DETAIL:UPSERT:FAIL',
          error
        });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      case 409:
        yield put({
          type: 'TIA:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        if (copyOfRecordId) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
        break;
      case 423:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'warning'
          }
        });
        yield put({
          type: 'TIA:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        yield put({ type: 'TENANT:PRICING:PLAN:INIT' });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'TIA:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    const error = getErrorMessage(err);
    yield handleServiceDown(err, 'records');

    yield put({ type: 'TIA:DETAIL:UPSERT:FAIL', error });
  }
}

export function* upsertTiaRecordDetail(action) {
  const request = registry.get('request');
  const { copyOfRecordId, data, isTemplateMode } = action;
  try {
    const defaultReqData = copyOfRecordId
      ? yield getRecordData({ copyOfRecordId }, 'tias')
      : yield getDefaultTiaData();
    const modifiedData = Object.assign({}, defaultReqData, data);

    const requestData = transformRequestData(modifiedData);
    const recordId = modifiedData.id || '';
    const url = `/v1/records${
      isTemplateMode ? '/templates' : ''
    }/tias/${recordId}`;
    const response = yield request.put(url, requestData);
    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        const userState = yield select((state) => state.user);
        const currentUser = userState ? userState.get('profile') : null;
        const responseData = yield transformResponseData(
          response.body,
          currentUser
        );
        yield put({
          type: 'TIA:DETAIL:UPSERT:SUCCESS',
          data: responseData
        });
        if (action.promoteInitiated) {
          yield put({ type: 'PRIVACY_RECORDS:ACTION_DIALOG:STATE_CHANGE' });
        }
        if (action.prevLocation !== '')
          yield put(replaceRouter(action.prevLocation));
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({
          type: 'TIA:DETAIL:UPSERT:FAIL',
          error
        });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      case 409:
        if (
          response.headers.get('reason') &&
          response.headers.get('reason') === 'version-mismatch'
        ) {
          yield put({
            type: 'TIA:DETAIL:UPSERT:LOCKED'
          });
        } else {
          yield put({
            type: 'TIA:DETAIL:UPSERT:FAIL',
            error: response.body.msg
          });
        }
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'TIA:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    const error = getErrorMessage(err);
    yield handleServiceDown(err, 'records');
    yield put({ type: 'TIA:DETAIL:UPSERT:FAIL', error });
  }
}

export function* fetchUsersForTia() {
  const { recordId } = getParameterValuesFromHash('/tia/:recordId/edit?:is');
  yield put({ type: 'TIA:USERS:LIST:REQUEST:FETCH' });
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/records/tias/${recordId}/users`, {});
    switch (response.status) {
      case 200: {
        yield put({
          type: 'TIA:USERS:LIST:REQUEST:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* fetchCountryAnalysis() {
  yield put({ type: 'TIA:FETCH:COUNTRY:ANALYSIS:INIT' });

  try {
    const response = yield registry
      .get('request')
      .get(`/v1/records/tias/country-analysis`, null);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'TIA:COUNTRY:ANALYSIS:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default:
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* saveTiaComment(action) {
  const currentState = yield select((state) => state.tia);
  const currentComments = currentState
    ? currentState.get('comments').toJS()
    : [];
  let modifiedComments = [];
  const { comment, isEdit, id } = action;

  try {
    const request = registry.get('request');
    let response = {};
    if (!isEdit) {
      response = yield request.post(`/v1/records/tias/${id}/comments`, comment);
    } else {
      response = yield request.put(
        `/v1/records/tias/${id}/comments/${comment.id}`,
        comment
      );
    }
    switch (response.status) {
      case 200: {
        if (!isEdit) {
          modifiedComments = [...currentComments, response.body];
        } else {
          const currentIndex = currentComments.findIndex(
            (item) => item.id === response.body.id
          );
          modifiedComments = [...currentComments];
          modifiedComments[currentIndex] = response.body;
        }
        yield put({
          type: 'TIA:COMMENT:SAVE:SUCCESS',
          comments: modifiedComments
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.commentedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* deleteTiaComment(action) {
  const { id, comment } = action;
  const currentState = yield select((state) => state.tia);
  const currentComments = currentState
    ? currentState.get('comments').toJS()
    : [];
  try {
    const request = registry.get('request');
    const response = yield request.delete(
      `/v1/records/tias/${id}/comments/${comment.id}`
    );
    switch (response.status) {
      case 204: {
        const currentIndex = currentComments.findIndex(
          (item) => item.id === comment.id
        );
        currentComments.splice(currentIndex, 1);
        yield put({
          type: 'TIA:COMMENT:DELETE:SUCCESS',
          comments: currentComments
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.commentDeletedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* saveTiaAsTemplate() {
  const store = registry.get('store');
  const request = registry.get('request');
  try {
    const tiaDetailState = yield select((state) => state.tia);
    const tiaData = tiaDetailState ? tiaDetailState.get('data').toJS() : {};

    const requestData = transformRequestData(tiaData);
    const response = yield request.post(
      `/v1/records/tia-templates`,
      requestData
    );
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.templateCreated,
            type: 'success'
          }
        });
        store.dispatch(replaceRouter('/privacyrecords'));
        break;
      }
      case 409:
        yield put({
          type: 'TIA:DETAIL:TEMPLATE:SAVE:FAIL',
          error: response.body.msg
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      case 423:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'warning'
          }
        });
        yield put({
          type: 'TIA:DETAIL:TEMPLATE:SAVE:FAIL',
          error: response.body.msg
        });
        yield put({ type: 'TENANT:PRICING:PLAN:INIT' });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    const error = getErrorMessage(err);
    yield put({
      type: 'TIA:DETAIL:TEMPLATE:SAVE:FAIL',
      error
    });
  }
}

export function* updateTiaStatus(action) {
  try {
    const request = registry.get('request');
    const { name, status, nextReviewDate } = action.data;
    const modifiedRequest = { name, status, nextReviewDate };
    const response = yield request.put(
      `/v1/records/tias/${action.recordId}`,
      modifiedRequest
    );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.recordStatusUpdateSuccess,
            type: 'success'
          }
        });

        // Update the record status
        const transferImpactAssessmentState = yield select(
          (state) => state.tia
        );
        const data = transferImpactAssessmentState
          .get('data')
          .set('status', action.data.status)
          .set('lastApprovalDate', response.body.lastApprovalDate)
          .set('nextReviewDate', response.body.nextReviewDate);
        yield put({
          type: 'TIA:DETAIL:UPSERT:SUCCESS',
          data
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}
