import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, FieldArray } from 'redux-form';
import Immutable from 'immutable';
import { withTheme } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Row, Col } from 'react-grid-system';
import Button from '@material-ui/core/Button';

import Card from '@packages/components/card';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import {
  recordTranslations,
  environmentHeaderTranslation,
  commonTranslations
} from '@packages/utils/commontranslations';
import {
  pluralTerms as uppercasePlurals,
  singularTerms as uppercaseSingulars
} from '@packages/utils/uppercaseTranslations';
import { removeTranslations } from '@packages/utils/actionTranslations';
import { renderSelectField } from '@packages/components/form-components';
import { statusItems } from '@packages/utils/record-utils';
import {
  sectionHeaderTranslations,
  addMasterTypeTranslations
} from '@packages/features/environment/masterDataTranslations';
import DivWrapper from '@packages/components/divWrapper';
import {
  getDialogContentHeight,
  supportedJurisdictions
} from '@packages/utils/common-utils';
import CustomDialog from '@packages/components/custom-dialog';
import styles from '@packages/ui/styles';

import {
  renderItemSelector,
  renderLegalGroundSelector,
  renderLinkGroup
} from '../../../privacy-record-detail/renderers/renderers';
import AddGroupDialog from '../../../privacy-record-detail/components/add-group-dialog/addGroupDialog';

const DIALOG_MAX_HEIGHT = 400;
const WINDOW_INNER_HEIGHT = window.innerHeight;

export const BulkUpdateDialog = (props) => {
  const {
    checkedFields,
    updateType,
    updateItems,
    initialize,
    init,
    onRequestClose,
    resetBulkItems,
    updateProperty,
    change,
    handleSummaryDialog,
    selectedRecords,
    linkGroupItems,
    visibility,
    open,
    userPermissions
  } = props;

  const [currentData, setCurrentData] = useState({});
  const [currentCheckedFields, setCurrentCheckedFields] =
    useState(checkedFields);
  const [bulkUpdateType, setBulkUpdateType] = useState(undefined);
  const [currentType, setCurrentType] = useState(undefined);
  const [isUpdateTypeEmpty, setIsUpdateTypeEmpty] = useState(false);
  const [isFieldsEmpty, setIsFieldsEmpty] = useState(false);
  const [openLinkGroups, setOpenLinkGroups] = useState(false);
  const [confirmCompanyAccess, setConfirmCompanyAccess] = useState(false);
  const [isCompanyAccess, setIsCompanyAccess] = useState(false);
  const [openBulkUpdateExplanation, setOpenBulkUpdateExplanation] =
    useState(false);
  const [openTypeChangeConfirmation, setOpenTypeChangeConfirmation] =
    useState(false);

  useEffect(() => {
    if (updateItems) {
      setCurrentData(updateItems);
      setBulkUpdateType(updateType);
      const selectedItems = Object.keys(updateItems);
      let selectedFields = {};
      selectedItems.forEach((item) => {
        selectedFields = { ...selectedFields, [item]: true };
      });
      const modifiedItems = {
        ...updateItems,
        updateType
      };
      initialize(modifiedItems);
    }
    init();
  }, []);

  const updateTypes = [
    {
      name: uppercaseSingulars('replace'),
      value: 'Bulk_Replace'
    },
    {
      name: removeTranslations('remove'),
      value: 'Bulk_Remove'
    },
    {
      name: uppercaseSingulars('add'),
      value: 'Bulk_Update'
    },
    {
      name: uppercaseSingulars('emptyFields'),
      value: 'Bulk_Clear_Fields'
    }
  ];

  const companyAccessValues = [
    {
      label: commonTranslations.Yes,
      value: 'yes'
    },
    {
      label: commonTranslations.No,
      value: 'no'
    }
  ];

  const requestClose = () => {
    onRequestClose();
    resetBulkItems();
    updateProperty('checkedFields', {});
  };

  const handleRequestClose = () => {
    setOpenLinkGroups(false);
  };

  const handleAddGroup = () => {
    setOpenLinkGroups(true);
  };

  const handleLinksSave = (links) => {
    const selectedLinks = currentData.links ? [...currentData.links] : [];
    selectedLinks.push(...links);
    handleChangeProperty('links', selectedLinks);
    handleRequestClose();
  };

  const onCheck = (event, field) => {
    setIsFieldsEmpty(false);
    setCurrentCheckedFields({
      ...currentCheckedFields,
      [field]: event.target.checked
    });
    if (!event.target.checked) {
      handleChangeProperty(
        field,
        [],
        field === 'internationalTransfer' ||
          !currentCheckedFields.internationalTransfer
          ? undefined
          : true
      );
    }
  };

  const renderCheckBox = (label, field) => (
    <FormControlLabel
      control={
        <Checkbox
          style={{ height: '1px' }}
          checked={currentCheckedFields[field] || false}
          onChange={(event) => onCheck(event, field)}
          color="primary"
        />
      }
      label={label}
    />
  );

  const handleChangeProperty = (property, data, intlChecked) => {
    if (data.length > 0) {
      let modifiedData = { ...currentData, [property]: data };
      if (intlChecked && bulkUpdateType !== 'Bulk_Remove') {
        modifiedData = {
          ...modifiedData,
          internationalTransfer: intlChecked ? 'yes' : 'no'
        };
      }
      setCurrentData(modifiedData);
    } else delete currentData[property];
    let modifiedCheckedFields = {
      ...currentCheckedFields,
      [property]: data.length > 0
    };
    if (intlChecked && bulkUpdateType !== 'Bulk_Remove') {
      modifiedCheckedFields = {
        ...modifiedCheckedFields,
        internationalTransfer: intlChecked
      };
    }
    setCurrentCheckedFields(modifiedCheckedFields);
    setIsFieldsEmpty(false);
    change(property, data);
    if (intlChecked && bulkUpdateType !== 'Bulk_Remove') {
      change('internationalTransfer', intlChecked ? 'yes' : 'no');
    }
  };

  const handleChangeTransferProperty = (property, data) => {
    let intlChecked;
    // make internationalTransfer true if transfer purpose or transfer ground is added.
    if (!currentData[property] || currentData[property].length < data.length)
      intlChecked = true;
    handleChangeProperty(property, data, intlChecked);
  };

  const handleTypeChange = (value) => {
    const isEmptyData = Object.keys(currentData).length === 0;
    if (bulkUpdateType !== '' && !isEmptyData) {
      setOpenTypeChangeConfirmation(true);
      setCurrentType(value);
      change('updateType', bulkUpdateType);
    } else {
      setBulkUpdateType(value);
      setIsUpdateTypeEmpty(false);
      if (value === 'Bulk_Remove' || value === 'Bulk_Clear_Fields') {
        setCurrentCheckedFields({
          ...currentCheckedFields,
          internationalTransfer: false,
          companyAccess: false,
          status: false
        });
        if (value === 'Bulk_Clear_Fields') setCurrentData({});
        else {
          delete currentData.status;
          delete currentData.companyAccess;
          delete currentData.internationalTransfer;
        }
      }
    }
  };

  const handleUpdateTypeChange = () => {
    setBulkUpdateType(currentType);
    change('updateType', currentType);
    setCurrentCheckedFields({});
    Object.keys(currentData).forEach((item) => change(item, []));
    setCurrentData({});
    setOpenTypeChangeConfirmation(false);
  };

  const handleUpdate = () => {
    const isEmptyData =
      (Object.keys(currentData).length === 0 &&
        bulkUpdateType !== 'Bulk_Clear_Fields') ||
      (Object.values(currentCheckedFields).every((item) => item === false) &&
        bulkUpdateType === 'Bulk_Clear_Fields');
    if (bulkUpdateType && !isEmptyData) handleSummary();
    else if (!bulkUpdateType) setIsUpdateTypeEmpty(true);
    else setIsFieldsEmpty(true);
    updateProperty('checkedFields', currentCheckedFields);
  };

  const handleSummary = () => {
    onRequestClose();
    handleSummaryDialog(bulkUpdateType, currentData, currentCheckedFields);
  };

  const getDialogTitle = () => (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 200 }}>{recordTranslations.bulkUpdate}</div>
      <div style={{ display: 'flex', fontSize: 17 }}>
        <div style={{ marginRight: 5, marginTop: 5 }}>
          {recordTranslations.updateType}:
        </div>
        <div style={{ width: '200px' }}>
          <Field
            name="updateType"
            showHint={true}
            onSelect={handleTypeChange}
            component={renderSelectField}
          >
            <MenuItem
              value=""
              disabled={true}
              classes={{ selected: 'menuitem-selected' }}
            >
              {recordTranslations.selectUpdateTypeHintText}
            </MenuItem>
            {updateTypes.map(({ name, value }) => (
              <MenuItem value={value} key={`${value}`}>
                {name}
              </MenuItem>
            ))}
          </Field>
        </div>
      </div>
    </div>
  );

  const filterOutAssociatedLinks = () => {
    let filteredData = [];
    const selectedGroups = currentData.links ? [...currentData.links] : [];
    const selectedIds =
      selectedGroups && selectedGroups.map((selectedItem) => selectedItem.id);
    filteredData = linkGroupItems.filter(
      (item) => item.id && !selectedIds.includes(item.id)
    );
    return filteredData;
  };

  const handleCompanyAccess = (value) => {
    setConfirmCompanyAccess(value === 'yes');
    setIsCompanyAccess(value === 'yes');
    handleChangeProperty('companyAccess', value);
  };

  const handleCloseCompanyConfirm = () => {
    setConfirmCompanyAccess(false);
    setIsCompanyAccess(false);
    handleChangeProperty('companyAccess', '');
  };

  const handleCompanyAccessConfirmation = () => {
    handleChangeProperty('userRights', []);
    handleChangeProperty('organisationRights', []);
    setCurrentCheckedFields({
      ...currentCheckedFields,
      userRights: false,
      organisationRights: false
    });
    setIsCompanyAccess(true);
    setConfirmCompanyAccess(false);
  };

  const handleBulkUpdateDetails = () => {
    setOpenBulkUpdateExplanation(true);
  };

  const getAllowedJurisdictions = () => {
    const allowedJurisdictions = [
      { data: 'GDPR', key: recordTranslations.gdpr }
    ];
    supportedJurisdictions.forEach((item) => {
      if (userPermissions.get(`createEdit${item.data}Processing`)) {
        allowedJurisdictions.push({ ...item });
      }
    });
    return allowedJurisdictions;
  };

  const getStatusList = () => {
    const autoUpdateStatuses = [
      'record_status_review_soon',
      'record_status_review_overdue'
    ];
    const restrictApprove = selectedRecords.some(({ status }) =>
      autoUpdateStatuses.includes(status)
    );
    if (restrictApprove) autoUpdateStatuses.push('record_status_approved');
    const statusList = statusItems.filter(
      ({ value }) => !autoUpdateStatuses.includes(value)
    );
    return statusList;
  };

  const allowedJurisdictions = getAllowedJurisdictions();

  const recordHasMultipleJurisdiction = selectedRecords.some(
    (each) => each.jurisdictions.length > 1
  );

  const isGdprJurisdiction = selectedRecords.every(({ jurisdictions }) =>
    jurisdictions.includes('GDPR')
  );

  return (
    <CommonDialog
      id="bulk-update-dialog"
      show={open}
      onCancel={requestClose}
      title={getDialogTitle()}
      fullWidth={true}
      subTitle={
        <div style={{ fontSize: 14, marginTop: -12 }}>
          <Button
            id="bulkUpdate-explanation"
            variant="text"
            onClick={handleBulkUpdateDetails}
            style={{ paddingLeft: '0px', marginLeft: '0px', marginTop: -15 }}
          >
            <div style={{ textDecoration: 'underline', fontSize: 12 }}>
              {commonTranslations.whatsThis}
            </div>
          </Button>
          <div>
            {recordTranslations.recordsSelected}: {selectedRecords.length}
          </div>
        </div>
      }
    >
      <form>
        <div>
          <DivWrapper
            autoHeight={true}
            autoHeightMax={getDialogContentHeight(
              WINDOW_INNER_HEIGHT,
              DIALOG_MAX_HEIGHT
            )}
          >
            <div>
              <Card
                title={uppercaseSingulars('general')}
                expanded={true}
                headerStyle={{ marginTop: -45 }}
              >
                <Row>
                  <Col md={6} lg={4}>
                    <FieldArray
                      id="processingCategories"
                      name="processingCategories"
                      type="processingCategories"
                      headerLabel={renderCheckBox(
                        recordTranslations.processingCategories,
                        'processingCategories'
                      )}
                      itemLabel={recordTranslations.processingCategoryHeader}
                      updateProcessingCategories={(item) =>
                        handleChangeProperty('processingCategories', item)
                      }
                      style={{ width: '90%' }}
                      hintText={recordTranslations.processorCategoryAddHintText}
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  {bulkUpdateType !== 'Bulk_Remove' &&
                    bulkUpdateType !== 'Bulk_Clear_Fields' && (
                      <Col>
                        <Field
                          id="status"
                          name="status"
                          showHint={true}
                          style={{ width: '90%' }}
                          label={renderCheckBox(
                            recordTranslations.processingStatus,
                            'status'
                          )}
                          component={renderSelectField}
                          onSelect={(value) =>
                            handleChangeProperty('status', value)
                          }
                        >
                          <MenuItem
                            value=""
                            disabled={true}
                            classes={{ selected: 'menuitem-selected' }}
                          >
                            {recordTranslations.selectStatusHintText}
                          </MenuItem>
                          {getStatusList().map((item) => (
                            <MenuItem
                              key={`${item.value}_status`}
                              value={item.value}
                            >
                              {item.label}
                            </MenuItem>
                          ))}
                        </Field>
                      </Col>
                  )}
                  <Col md={6} lg={4}>
                    <FieldArray
                      id="references"
                      name="references"
                      type="references"
                      headerLabel={renderCheckBox(
                        recordTranslations.references,
                        'references'
                      )}
                      itemLabel={recordTranslations.reference}
                      component={renderItemSelector}
                      hintText={recordTranslations.addReferences}
                      updateEntities={(item) =>
                        handleChangeProperty('references', item)
                      }
                      style={{ width: '90%' }}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <FieldArray
                      name="purposes"
                      type="purposes"
                      headerLabel={renderCheckBox(
                        recordTranslations.processingPurpose,
                        'purposes'
                      )}
                      itemLabel={recordTranslations.processingPurpose}
                      hintText={recordTranslations.addAssessmentPurpose}
                      updateEntities={(item) =>
                        handleChangeProperty('purposes', item)
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  {allowedJurisdictions.length > 1 &&
                    bulkUpdateType !== 'Bulk_Clear_Fields' &&
                    (bulkUpdateType !== 'Bulk_Remove' ||
                      recordHasMultipleJurisdiction) && (
                      <Col md={6} lg={4}>
                        <FieldArray
                          id="jurisdictions"
                          name="jurisdictions"
                          type="simpleItems"
                          headerLabel={renderCheckBox(
                            recordTranslations.jurisdictions,
                            'jurisdictions'
                          )}
                          hintText={recordTranslations.addJurisdiction}
                          component={renderItemSelector}
                          items={allowedJurisdictions}
                          updateData={(value) =>
                            handleChangeProperty('jurisdictions', value)
                          }
                        />
                      </Col>
                  )}
                </Row>
              </Card>
              <hr style={{ marginTop: 15 }} />
              <Card
                title={uppercasePlurals('Stakeholders')}
                expanded={true}
                headerStyle={{ marginTop: -35 }}
              >
                <Row>
                  <Col>
                    <FieldArray
                      id="controllers"
                      name="controllers"
                      type="legalEntities"
                      stakeHolderType="controllers"
                      hintText={recordTranslations.controllerHintText}
                      headerLabel={renderCheckBox(
                        recordTranslations.controllers,
                        'controllers'
                      )}
                      dialogHeaderLabel={recordTranslations.selectControllers}
                      itemLabel={recordTranslations.controller}
                      entityType={recordTranslations.Controller}
                      updateData={(item, intlChecked) =>
                        handleChangeProperty('controllers', item, intlChecked)
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col>
                    <FieldArray
                      id="processors"
                      name="processors"
                      type="legalEntities"
                      stakeHolderType="processors"
                      helpNotes={recordTranslations.removeProcessorConfirmation}
                      hintText={recordTranslations.processorHintText}
                      headerLabel={renderCheckBox(
                        uppercasePlurals('processors'),
                        'processors'
                      )}
                      dialogHeaderLabel={recordTranslations.selectControllers}
                      itemLabel={recordTranslations.controller}
                      entityType={recordTranslations.Controller}
                      updateData={(item, intlChecked) =>
                        handleChangeProperty('processors', item, intlChecked)
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col>
                    <FieldArray
                      id="executingEntities"
                      name="executingEntities"
                      type="legalEntities"
                      stakeHolderType="executingEntities"
                      hintText={recordTranslations.executingEntityHintText}
                      headerLabel={renderCheckBox(
                        recordTranslations.executingEntities,
                        'executingEntities'
                      )}
                      dialogHeaderLabel={
                        recordTranslations.executingEntitiesDialogHeader
                      }
                      itemLabel={recordTranslations.executingEntity}
                      entityType={recordTranslations.executingEntityType}
                      updateData={(item, intlChecked) =>
                        handleChangeProperty(
                          'executingEntities',
                          item,
                          intlChecked
                        )
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={4}>
                    <FieldArray
                      id="dataRecipients"
                      name="dataRecipients"
                      type="legalEntities"
                      stakeHolderType="dataRecipients"
                      hintText={recordTranslations.dataRecipientsHintText}
                      headerLabel={renderCheckBox(
                        recordTranslations.dataRecipients,
                        'dataRecipients'
                      )}
                      dialogHeaderLabel={
                        recordTranslations.dataRecipientsDialogHeader
                      }
                      itemLabel={recordTranslations.dataRecipient}
                      entityType={recordTranslations.Datarecipient}
                      updateData={(item, intlChecked) =>
                        handleChangeProperty(
                          'dataRecipients',
                          item,
                          intlChecked
                        )
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <FieldArray
                      id="dataRecipientCategories"
                      name="dataRecipientCategories"
                      type="dataRecipientCategories"
                      headerLabel={renderCheckBox(
                        recordTranslations.dataRecipientCategories,
                        'dataRecipientCategories'
                      )}
                      itemLabel={recordTranslations.dataRecipientCategory}
                      entityType={recordTranslations.dataRecipientCategory}
                      updateDataRecipientCategories={(item, intlChecked) =>
                        handleChangeProperty(
                          'dataRecipientCategories',
                          item,
                          intlChecked
                        )
                      }
                      component={renderItemSelector}
                      hintText={addMasterTypeTranslations(
                        'dataRecipientCategories'
                      )}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                </Row>
              </Card>
              <hr style={{ marginTop: 15, marginBottom: -30 }} />
              <Card
                title={recordTranslations.groups}
                expanded={true}
                helpNotes={recordTranslations.addRemoveGroupConfirmation}
                headerStyle={{ display: 'flex' }}
              >
                <Row>
                  <Col>
                    <FieldArray
                      id="links"
                      name="links"
                      type="links"
                      recordType="processings"
                      headerLabel={renderCheckBox(
                        recordTranslations.groups,
                        'links'
                      )}
                      selectedItems={currentData.links}
                      isEditable={false}
                      isOnlyDelete={true}
                      isOnlyEdit={false}
                      showCardTitle={false}
                      updateLinkGroups={(item) =>
                        handleChangeProperty('links', item)
                      }
                      tableWidth={window.innerWidth - 210}
                      isRecordEditMode={false}
                      component={renderLinkGroup}
                      containerHeight={400}
                      visibleFields={visibility}
                      source="processing"
                      showCheckbox={bulkUpdateType === 'Bulk_Clear_Fields'}
                    />
                    {bulkUpdateType !== 'Bulk_Clear_Fields' && (
                      <Button
                        id="select_groups"
                        classes={{
                          root: 'button_black',
                          label: 'buttonLabel_legalgrounds'
                        }}
                        style={{ marginRight: '15px', marginTop: '5px' }}
                        onClick={handleAddGroup}
                      >
                        {recordTranslations.selectGroups}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card>
              <hr style={{ marginTop: 15 }} />
              <Card
                title={uppercaseSingulars('processedData')}
                expanded={true}
                headerStyle={{ marginTop: -35 }}
              >
                <Row>
                  <Col>
                    <FieldArray
                      name="dataSubjectCategories"
                      type="dataSubjectCategories"
                      headerLabel={renderCheckBox(
                        recordTranslations.dataSubjectCategories,
                        'dataSubjectCategories'
                      )}
                      itemLabel={recordTranslations.dataSubjectCategory}
                      updateDataSubjectCategories={(item) =>
                        handleChangeProperty('dataSubjectCategories', item)
                      }
                      component={renderItemSelector}
                      hintText={
                        recordTranslations.addDataSubjectCategoryHintText
                      }
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col>
                    <FieldArray
                      name="personalDataCategories"
                      type="personalDataCategories"
                      headerLabel={renderCheckBox(
                        recordTranslations.personalDataCategories,
                        'personalDataCategories'
                      )}
                      itemLabel={environmentHeaderTranslation(
                        'personalDataCategories'
                      )}
                      hintText={
                        recordTranslations.personalDataCategoriesHintText
                      }
                      updatePersonalDataCategories={(item) =>
                        handleChangeProperty('personalDataCategories', item)
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col>
                    <FieldArray
                      name="personalDataItems"
                      type="personalDataItems"
                      headerLabel={renderCheckBox(
                        recordTranslations.personalDataItems,
                        'personalDataItems'
                      )}
                      itemLabel={recordTranslations.personaldata}
                      hintText={recordTranslations.personalDataItemsHintText}
                      updatePersonalDataItems={(item) =>
                        handleChangeProperty('personalDataItems', item)
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FieldArray
                      name="dataSources"
                      type="dataSources"
                      headerLabel={renderCheckBox(
                        recordTranslations.dataSources,
                        'dataSources'
                      )}
                      itemLabel={recordTranslations.datasource}
                      updateDataSources={(item, intlChecked) =>
                        handleChangeProperty('dataSources', item, intlChecked)
                      }
                      hintText={recordTranslations.dataSourcesHintText}
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col>
                    <FieldArray
                      name="dataSourceCategories"
                      type="dataSourceCategories"
                      headerLabel={renderCheckBox(
                        recordTranslations.dataSourceCategories,
                        'dataSourceCategories'
                      )}
                      itemLabel={recordTranslations.dataSourceCategories}
                      hintText={recordTranslations.dataSourceCategoriesHintText}
                      updateEntities={(item) =>
                        handleChangeProperty('dataSourceCategories', item)
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col>
                    <FieldArray
                      name="retentionTerms"
                      type="retentionTerms"
                      headerLabel={renderCheckBox(
                        recordTranslations.retentionTerms,
                        'retentionTerms'
                      )}
                      itemLabel={recordTranslations.retentionTerms}
                      updateRetentionTerm={(item) =>
                        handleChangeProperty('retentionTerms', item)
                      }
                      component={renderItemSelector}
                      hintText={recordTranslations.retentionTermHintText}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={4}>
                    <FieldArray
                      name="technicalSecurityMeasures"
                      type="technicalSecurityMeasures"
                      entityType="technicalSecurityMeasures"
                      masterDataType="securityMeasures"
                      headerLabel={renderCheckBox(
                        sectionHeaderTranslations.technicalSecurityMeasuresHeader,
                        'technicalSecurityMeasures'
                      )}
                      itemLabel={sectionHeaderTranslations.measures}
                      hintText={
                        recordTranslations.addSecurityMeasuresDescriptionHintText
                      }
                      updateEntities={(item) =>
                        handleChangeProperty('technicalSecurityMeasures', item)
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <FieldArray
                      name="organisationalSecurityMeasures"
                      type="organisationalSecurityMeasures"
                      entityType="organisationalSecurityMeasures"
                      hintText={
                        recordTranslations.addSecurityMeasuresDescriptionHintText
                      }
                      masterDataType="securityMeasures"
                      headerLabel={renderCheckBox(
                        sectionHeaderTranslations.organisationalMeasuresHeader,
                        'organisationalSecurityMeasures'
                      )}
                      itemLabel={sectionHeaderTranslations.measures}
                      updateEntities={(item) =>
                        handleChangeProperty(
                          'organisationalSecurityMeasures',
                          item
                        )
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <FieldArray
                      name="contractualSecurityMeasures"
                      type="contractualSecurityMeasures"
                      entityType="contractualSecurityMeasures"
                      hintText={
                        recordTranslations.addSecurityMeasuresDescriptionHintText
                      }
                      masterDataType="securityMeasures"
                      headerLabel={renderCheckBox(
                        sectionHeaderTranslations.contractualSecurityMeasures,
                        'contractualSecurityMeasures'
                      )}
                      itemLabel={sectionHeaderTranslations.measures}
                      updateEntities={(item) =>
                        handleChangeProperty(
                          'contractualSecurityMeasures',
                          item
                        )
                      }
                      component={renderItemSelector}
                      createNewMenuItem={false}
                      allowedActions={['delete']}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                </Row>
              </Card>
              {isGdprJurisdiction && <hr style={{ marginTop: 15 }} />}
              {isGdprJurisdiction && (
                <Card
                  title={uppercasePlurals('legalQualifications')}
                  expanded={true}
                  headerStyle={{ marginTop: -35 }}
                >
                  <Row>
                    <Col md={6} lg={4}>
                      <FieldArray
                        name="processingGrounds"
                        type="processingGrounds"
                        hintText={recordTranslations.processingGroundHintText}
                        headerLabel={renderCheckBox(
                          recordTranslations.processingGrounds,
                          'processingGrounds'
                        )}
                        itemLabel={recordTranslations.processingGround}
                        customStyle={{ paddingTop: 0 }}
                        updateProcessingGrounds={(item) =>
                          handleChangeProperty('processingGrounds', item)
                        }
                        component={renderLegalGroundSelector}
                        allowedActions={['delete']}
                        showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {bulkUpdateType !== 'Bulk_Remove' &&
                      bulkUpdateType !== 'Bulk_Clear_Fields' && (
                        <Col md={6} lg={4}>
                          <Field
                            id="internationalTransfer"
                            name="internationalTransfer"
                            showHint={true}
                            style={{ width: '90%' }}
                            label={renderCheckBox(
                              recordTranslations.internationalTransfer,
                              'internationalTransfer'
                            )}
                            component={renderSelectField}
                            helpNotes={recordTranslations.intlHintText}
                            onSelect={(value) =>
                              handleChangeProperty(
                                'internationalTransfer',
                                value
                              )
                            }
                          >
                            <MenuItem
                              value=""
                              disabled={true}
                              classes={{ selected: 'menuitem-selected' }}
                            >
                              {recordTranslations.chooseYesOrNo}
                            </MenuItem>
                            {companyAccessValues.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Col>
                    )}
                    <Col md={6} lg={4}>
                      <FieldArray
                        name="purposesOfTransfer"
                        type="purposesOfTransfer"
                        masterDataType="purposes"
                        entityType="purposesOfTransfer"
                        hintText={recordTranslations.addTransferPurpose}
                        headerLabel={renderCheckBox(
                          recordTranslations.transferPurpose,
                          'purposesOfTransfer'
                        )}
                        itemLabel={recordTranslations.transferPurpose}
                        targetOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                        updateEntities={(item) =>
                          handleChangeTransferProperty(
                            'purposesOfTransfer',
                            item
                          )
                        }
                        component={renderItemSelector}
                        createNewMenuItem={false}
                        allowedActions={['delete']}
                        showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                      />
                    </Col>
                    <Col md={6} lg={4}>
                      <FieldArray
                        name="transferGrounds"
                        type="transferGrounds"
                        headerLabel={renderCheckBox(
                          recordTranslations.transferGrounds,
                          'transferGrounds'
                        )}
                        itemLabel={recordTranslations.TransferGrounds}
                        hintText={recordTranslations.transferGroundHintText}
                        updateTransferGrounds={(item) =>
                          handleChangeTransferProperty('transferGrounds', item)
                        }
                        customStyle={{ paddingTop: 0 }}
                        component={renderLegalGroundSelector}
                        createNewMenuItem={false}
                        allowedActions={['delete']}
                        showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                      />
                    </Col>
                  </Row>
                </Card>
              )}
              <hr style={{ marginTop: 15 }} />
              <Card
                title={recordTranslations.permissions}
                expanded={true}
                headerStyle={{ marginTop: -35 }}
              >
                <Row>
                  <Col
                    md={6}
                    lg={4}
                    style={isCompanyAccess ? styles.disabled : {}}
                  >
                    <FieldArray
                      id="aclUsers"
                      name="userRights"
                      type="acl"
                      hintText={recordTranslations.addPermissionsHintText}
                      headerLabel={renderCheckBox(
                        commonTranslations.users,
                        'userRights'
                      )}
                      itemLabel={recordTranslations.User}
                      dialogHeaderLabel={
                        recordTranslations.permissionsDialogHeader
                      }
                      updateAcls={(item) =>
                        handleChangeProperty('userRights', item)
                      }
                      component={renderItemSelector}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  <Col
                    md={6}
                    lg={4}
                    style={isCompanyAccess ? styles.disabled : {}}
                  >
                    <FieldArray
                      id="aclOrgs"
                      name="organisationRights"
                      type="organisation"
                      dataItemType="aclOrgs"
                      hintText={recordTranslations.addPermissionsOrgHintText}
                      headerLabel={renderCheckBox(
                        recordTranslations.organisationRights,
                        'organisationRights'
                      )}
                      itemLabel={recordTranslations.aclOrgs}
                      dialogHeaderLabel={recordTranslations.orgDialogHeader}
                      updateData={(item) =>
                        handleChangeProperty('organisationRights', item)
                      }
                      component={renderItemSelector}
                      showTextbox={bulkUpdateType !== 'Bulk_Clear_Fields'}
                    />
                  </Col>
                  {bulkUpdateType !== 'Bulk_Remove' &&
                    bulkUpdateType !== 'Bulk_Clear_Fields' && (
                      <Col md={6} lg={4}>
                        <Field
                          id="companyAccess"
                          name="companyAccess"
                          showHint={true}
                          style={{ width: '90%' }}
                          label={renderCheckBox(
                            recordTranslations.companyAccess,
                            'companyAccess'
                          )}
                          component={renderSelectField}
                          onSelect={(value) => handleCompanyAccess(value)}
                        >
                          <MenuItem
                            value=""
                            disabled={true}
                            classes={{ selected: 'menuitem-selected' }}
                          >
                            {recordTranslations.chooseYesOrNo}
                          </MenuItem>
                          {companyAccessValues.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Field>
                      </Col>
                  )}
                </Row>
              </Card>
            </div>
          </DivWrapper>
          {(isUpdateTypeEmpty || isFieldsEmpty) && (
            <div style={{ color: 'red', marginTop: 15 }}>
              {isUpdateTypeEmpty
                ? recordTranslations.updateTypeEmpty
                : recordTranslations.fieldsEmptyError}
            </div>
          )}
          <div style={{ marginTop: 12 }}>
            <Button
              id="next_button"
              onClick={handleUpdate}
              style={{ minWidth: '85px' }}
            >
              {uppercaseSingulars('next')}
            </Button>
            <Button id="cancel_button" variant="text" onClick={requestClose}>
              {uppercaseSingulars('Cancel')}
            </Button>
          </div>
          {openLinkGroups && (
            <AddGroupDialog
              isEdit={false}
              open={openLinkGroups}
              onRequestClose={handleRequestClose}
              onSave={handleLinksSave}
              items={filterOutAssociatedLinks()}
              visibleFields={visibility}
            />
          )}
          {openBulkUpdateExplanation && (
            <CustomDialog
              show={openBulkUpdateExplanation}
              proceed={() => {
                setOpenBulkUpdateExplanation(false);
              }}
              title={recordTranslations.bulkUpdate}
              hideConfirmation={true}
              content={recordTranslations.bulkUpdateExplanation}
              isDelete={false}
            />
          )}
          {confirmCompanyAccess && (
            <CustomDialog
              id="confirm_company_access_dialog"
              show={confirmCompanyAccess}
              cancel={handleCloseCompanyConfirm}
              proceed={handleCompanyAccessConfirmation}
              content={recordTranslations.companyAccessConfirmMessage}
              hideConfirmation={true}
            />
          )}
          {openTypeChangeConfirmation && (
            <CustomDialog
              id="confirm_type_change"
              show={openTypeChangeConfirmation}
              cancel={() => setOpenTypeChangeConfirmation(false)}
              proceed={handleUpdateTypeChange}
              content={recordTranslations.changeUpdateTypeConfirmation}
              hideConfirmation={true}
              title={recordTranslations.changeUpdateType}
            />
          )}
        </div>
      </form>
    </CommonDialog>
  );
};

BulkUpdateDialog.propTypes = {
  updateProperty: PropTypes.func,
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  selectedRecords: PropTypes.arrayOf(PropTypes.shape({})),
  change: PropTypes.func,
  init: PropTypes.func,
  resetBulkItems: PropTypes.func,
  linkGroupItems: PropTypes.arrayOf(PropTypes.shape({})),
  visibility: PropTypes.arrayOf(PropTypes.shape({})),
  updateType: PropTypes.string,
  updateItems: PropTypes.shape({}),
  checkedFields: PropTypes.shape({}),
  theme: PropTypes.shape({}),
  handleSummaryDialog: PropTypes.func,
  initialize: PropTypes.func,
  intl: PropTypes.shape({}).isRequired,
  userPermissions: PropTypes.instanceOf(Immutable.Map)
};

BulkUpdateDialog.defaultProps = {
  updateProperty: (e) => e,
  userPermissions: Immutable.Map(),
  open: false,
  onRequestClose: (e) => e,
  selectedRecords: [],
  change: (e) => e,
  init: (e) => e,
  resetBulkItems: (e) => e,
  linkGroupItems: [],
  visibility: [],
  updateType: '',
  updateItems: {},
  checkedFields: {},
  theme: {},
  handleSummaryDialog: (e) => e,
  initialize: (e) => e
};

const BulkUpdateFormWrapper = reduxForm({
  form: 'BulkUpdateDialog'
})(BulkUpdateDialog);

export default injectIntl(withTheme(BulkUpdateFormWrapper));
