import {
  GLOBAL_CUSTOM_PREFIX,
  supportedJurisdictions
} from '@packages/utils/common-utils';
import { recordTranslations } from '@packages/utils/commontranslations';
import errortranslations, {
  customTranslations
} from '@packages/utils/errortranslations';

import { tenantConfigurationTranslations } from './tenantConfigurationTranslations';

export const camelize = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/([^a-zA-Z0-9]|\s+)/g, '');

export const validateFields = (
  values,
  requiredFields,
  uniqueIdLimit = 180,
  nameLimit = null
) => {
  const errors = {};
  requiredFields.forEach((field) => {
    if (field === 'names') {
      let languageErrors = {};
      if (values?.[field]) {
        Object.keys(values[field]).forEach((additionalLanguage) => {
          if (!values[field][additionalLanguage]) {
            languageErrors = {
              ...languageErrors,
              [additionalLanguage]: errortranslations.required
            };
          } else if (
            nameLimit &&
            values[field][additionalLanguage].length > nameLimit
          ) {
            languageErrors = {
              ...languageErrors,
              [additionalLanguage]: customTranslations('maxLimit', nameLimit)
            };
          }
        });
      }
      if (Object.keys(languageErrors).length > 0)
        errors[field] = { ...languageErrors };
    }
    if (field === 'defaultOutputText') {
      let languageErrors = {};
      if (values?.[field]) {
        Object.keys(values[field]).forEach((additionalLanguage) => {
          if (!values[field][additionalLanguage]) {
            languageErrors = {
              ...languageErrors,
              [additionalLanguage]: errortranslations.required
            };
          }
        });
      }
      if (Object.keys(languageErrors).length > 0)
        errors[field] = { ...languageErrors };
    }
    if (!values?.[field]) {
      errors[field] = errortranslations.required;
    }
    if (['layoutFieldUniqueId', 'uniqueId'].includes(field)) {
      if (/[^A-Za-z0-9]/g.test(values?.[field]))
        errors[field] = tenantConfigurationTranslations.noSpecialCharacters;
      const modifiedLimit = values.isGlobal
        ? uniqueIdLimit - GLOBAL_CUSTOM_PREFIX.length
        : uniqueIdLimit;
      if (values[field]?.length > modifiedLimit)
        errors[field] = customTranslations('maxLimit', uniqueIdLimit);
    }
    if (['recordNumberPrefix'].includes(field)) {
      if (/[^A-Za-z]/g.test(values?.[field]))
        errors[field] = tenantConfigurationTranslations.recordPrefixValidation;
    }
    if (field === 'itemList' && !values?.[field]?.length)
      errors[field] = { _error: errortranslations.required };

    if (values?.inputFields?.length > 0 && field === 'inputFields') {
      if (
        values[field]?.find(
          (item) =>
            item.layoutFieldUniqueId ===
            tenantConfigurationTranslations.selectInputField
        )
      ) {
        errors[field] = { _error: errortranslations.required };
      }
    }
  });

  if (values?.fieldType === 'simpleMasterData' && !values?.[values.fieldType]) {
    errors[values.fieldType] = { _error: errortranslations.required };
  }
  return errors;
};

export const getTranslatedLabel = (labels, locale, tenantLocale) =>
  labels &&
  (labels[locale] || labels[tenantLocale] || Object.values(labels)[0]);

export const getUpdatedFieldPosition = (chapterData) => {
  let x = -1;
  let y = -1;
  const modifiedPositionsData = [];
  chapterData.forEach((item, index) => {
    if (modifiedPositionsData[index - 1]?.width === 2 || item?.width === 2) {
      x += 1;
      y = 0;
      modifiedPositionsData[index] = { ...item, position: { x, y } };
    } else {
      x += Math.abs(y) % 2;
      y = (y + 1) % 2;
      modifiedPositionsData[index] = { ...item, position: { x, y } };
    }
    if (item.field.fieldType === 'section')
      modifiedPositionsData[index].subFields = getUpdatedFieldPosition(
        item.subFields
      );
  });

  return modifiedPositionsData;
};

export const availableLanguageOptions = [
  'en',
  'fr',
  'de',
  'es',
  'ja',
  'nl',
  'zh-TW',
  'pt',
  'zh-CN'
];

const getEmptyQuestion = (width, chapter, index, sectionId) => ({
  field: { fieldType: 'emptyField' },
  chapter,
  fieldLabel: {
    en: ''
  },
  layoutFieldUniqueId: `empty${sectionId ? `_${sectionId}` : ''}_${index}`,
  width,
  ...(sectionId && { sectionId })
});

export const getTrandformedLayout = (
  chapterFields,
  currentFilter,
  sectionId
) => {
  const modifiedLayout = [];

  if (!chapterFields.length) {
    modifiedLayout.push(getEmptyQuestion(2, currentFilter, 0, sectionId)); // Insert empty field for empty layout
  }
  chapterFields.forEach((question, index) => {
    modifiedLayout.push(question);
    if (question.field.fieldType === 'section') {
      // Insert empty fields inside section
      modifiedLayout[modifiedLayout.length - 1].subFields =
        getTrandformedLayout(
          question.subFields,
          currentFilter,
          question.layoutFieldUniqueId
        );
    }
    if (index + 1 < chapterFields.length) {
      // Insert empty field at middle. Only possible width is 1.
      if (
        question.position.y === 0 &&
        question.width === 1 &&
        chapterFields[index + 1].width === 2
      ) {
        modifiedLayout.push(
          getEmptyQuestion(1, currentFilter, index, question.sectionId)
        );
      }
    } else if (question.position.y === 0 && question.width === 1) {
      // Insert empty question on end.
      modifiedLayout.push(getEmptyQuestion(1, currentFilter, index, sectionId));
    } else {
      // Question with width 1 or 2 is possible based on the previous question width.
      modifiedLayout.push(getEmptyQuestion(2, currentFilter, index, sectionId));
    }
  });
  return modifiedLayout;
};

export const getLayoutWithSectionFields = (chapterInfo) => {
  const modifiedLayout = [...chapterInfo.fields];
  modifiedLayout.forEach((question, index) => {
    if (question.field.fieldType === 'section') {
      modifiedLayout[index].subFields = chapterInfo.sections.find(
        (field) => field.sectionId === question.layoutFieldUniqueId
      ).fields;
    }
  });
  return { ...chapterInfo, fields: modifiedLayout };
};

export const getSectionsAndFields = (chapterFields) => {
  const fields = [];
  const sections = [];
  chapterFields.forEach((item) => {
    fields.push(item);
    if (item.field.fieldType === 'section') {
      sections.push({
        sectionId: item.layoutFieldUniqueId,
        fields: item.subFields
      });
    }
  });
  return { fields, sections };
};

export const removeEmptyQuestions = (layoutQuestions) => {
  const updatedQuestions = layoutQuestions
    .map((question) => {
      if (question.field.fieldType === 'section') {
        const updatedSection = { ...question };
        updatedSection.subFields = updatedSection.subFields.filter(
          (subQuestion) => subQuestion.field.fieldType !== 'emptyField'
        );
        return updatedSection;
      }
      return question;
    })
    .filter((question) => question.field.fieldType !== 'emptyField');

  return updatedQuestions;
};

export const getModifiedFieldType = (fieldType) =>
  fieldType !== 'htmlText' ? fieldType.toLowerCase() : fieldType;

export const getFieldTypeFromFilter = (filter) =>
  ({
    simpleMasterData: 'simpleMasterData',
    textFields: 'text',
    richTextFields: 'htmlText',
    dropDown: 'picklist',
    scoredDropDown: 'scoredPicklist',
    stakeholderFields: 'stakeholder'
  }[filter]);

export const getUpdatedObject = (item, parent) => ({
  ...item,
  id: item.id || item.layoutUniqueId,
  isGroup: item.isGroup || item.type === 'group',
  enable: true,
  type: item.isGroup || item.type === 'group' ? 'group' : 'chapter',
  key: parent
    ? `${item.id || item.layoutUniqueId}_${parent}`
    : item.id || item.layoutUniqueId
});

export const getChildChapter = (menu) => {
  if (menu.subMenu?.length > 0) return getChildChapter(menu.subMenu[0], menu);
  return menu.key;
};

export const existingChapterKeys = (recordType) =>
  ({
    breaches: [
      'general',
      'breachScope',
      'breachCharacteristics',
      'attachments',
      'legalQualifications',
      'measures',
      'stakeholders',
      'notifications',
      'permissions'
    ],
    assessments: [
      'PreAssessment',
      'groups',
      'processingDescription',
      'LegalQualifications',
      ...supportedJurisdictions.map((item) => item.data.toLowerCase()),
      'NecessityAndProportionality',
      'DataSubjectRights',
      'SecurityControls',
      'ImpactAssessment',
      'Accountability',
      'Summary',
      'attachments',
      'permissions'
    ],
    processings: [
      'general',
      'stakeholders',
      'groups',
      'processedData',
      'SecurityControls',
      'LegalQualifications',
      ...supportedJurisdictions.map((item) => item.data.toLowerCase()),
      ...supportedJurisdictions.map((item) => `${item.data.toLowerCase()}Risk`),
      'attachments',
      'complianceAndRisk',
      'permissions'
    ],
    'document-records': ['documentDetails', 'usage', 'permissions']
  }[recordType] || ['general', 'permissions']);

export const filterExistingFieldItems = (data, existingFields) =>
  data.filter((item) => !existingFields.includes(item.layoutFieldUniqueId));

export const scoreCalculatorData = {
  field: {
    id: '9e343c0f-94d3-47d5-9c9e-55c737bd5d01',
    uniqueId: 'scoreCalculator',
    names: {
      en: 'Score calculator'
    },
    fieldType: 'scoreCalculator',
    source: 'Tenant'
  },
  position: {
    x: 0,
    y: 0
  },
  width: 2
};

export const getScoreCalculatorInitValue = (tenantLocale) => ({
  inputFields: [
    {
      variableName: 'a',
      layoutFieldUniqueId: tenantConfigurationTranslations.selectInputField
    }
  ],
  output: [
    {
      scoreRange: {
        min: 0,
        max: 0
      },
      outputText: { [tenantLocale]: '' }
    }
  ],
  defaultOutputText: {
    [tenantLocale]: ''
  }
});

export const getItemListLabel = (fieldType) =>
  ({
    picklist: tenantConfigurationTranslations.dropDownList,
    scoredPicklist: tenantConfigurationTranslations.scoredDropDownList,
    simpleMasterData: tenantConfigurationTranslations.simpleMasterDataItem
  }[fieldType]);

export const itemListFieldTypes = [
  'simpleMasterData',
  'picklist',
  'scoredPicklist'
];

export const layoutFieldTypes = ['section', 'scoreCalculator'];

export const getJurisdictions = (userPermissions) => {
  const jurisdictions = [];
  const modulePermissions = {
    createEditLGPDProcessing: recordTranslations.lgpd,
    createEditCCPAProcessing: recordTranslations.ccpa,
    createEditPIPLProcessing: recordTranslations.pipl,
    createEditPDPProcessing: recordTranslations.pdp,
    createEditAPPIProcessing: recordTranslations.appi,
    createEditFDPLProcessing: recordTranslations.fdpl,
    createEditSGPDPAProcessing: recordTranslations.sgpdpa,
    createEditPDPAProcessing: recordTranslations.pdpa,
    createEditUKGDPRProcessing: recordTranslations.ukgdpr,
    createEditIDPRProcessing: recordTranslations.idpr
  };
  Object.keys(modulePermissions).forEach((permission) => {
    if (userPermissions?.get(permission))
      jurisdictions.push(modulePermissions[permission]);
  });
  return jurisdictions;
};

export const migratedDefaultFields = (recordType) =>
  ({
    assessments: [
      'ppControllers',
      'ppProcessors',
      'ppProcessingCategories',
      'ppPurpose',
      'ppReferences',
      'ppExecutingEntities',
      'ppDataRecipients',
      'ppDataSubjectCategories',
      'ppPersonalDataCategories',
      'ppDataSourceCategories',
      'ppTechnicalSecurityMeasures',
      'ppOrganisationalSecurityMeasures',
      'ppContractualSecurityMeasures'
    ],
    processings: [
      'ppProcessingCategories',
      'ppPurpose',
      'ppReferences',
      'ppControllers',
      'ppProcessors',
      'ppExecutingEntities',
      'ppDataRecipients',
      'ppDataSubjectCategories',
      'ppPersonalDataCategories',
      'ppDataSourceCategories',
      'ppTechnicalSecurityMeasures',
      'ppOrganisationalSecurityMeasures',
      'ppContractualSecurityMeasures'
    ],
    breaches: [
      'ppReferences',
      'ppControllers',
      'ppProcessors',
      'ppExecutingEntities',
      'ppDataRecipients',
      'ppDataSubjectCategories',
      'ppPersonalDataCategories',
      'ppDataSourceCategories',
      'ppTechnicalSecurityMeasures',
      'ppOrganisationalSecurityMeasures',
      'ppContractualSecurityMeasures'
    ]
  }[recordType] || []);

export const getLayoutFirstChapter = (recordType) =>
  ({
    assessments: 'PreAssessment',
    'document-records': 'documentDetails'
  }[recordType] || 'general');

export const getAvailableLanguage = (languageCode) => {
  const availableLanguages = {
    en: 'English',
    fr: 'French',
    de: 'German',
    es: 'Spanish',
    ja: 'Japanese',
    nl: 'Netherlands',
    'zh-TW': 'Taiwanese',
    pt: 'Portugese',
    'zh-CN': 'Chinese'
  };
  return availableLanguages[languageCode];
};

export const itemLists = [
  'dropDownLists',
  'scoredDropDownLists',
  'simpleMasterDataItems'
];

export const customFields = [
  'textFields',
  'richTextFields',
  'simpleMasterDataFields',
  'dropDownFields',
  'scoredDropDownFields',
  'stakeholderFields'
];
