import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import { getModifiedRecordsList } from '@packages/features/privacy-record-detail/common-utils';

const RecordsSelector = (props) => {
  const {
    label,
    hintTextLabel,
    multiValue,
    fetchRecords,
    updateLinkedRecords,
    records,
    fields,
    layoutId,
    recordId
  } = props;
  const [open, setOpen] = useState(false);

  const handleRecordFetch = () => {
    if (layoutId) {
      fetchRecords(layoutId);
    };
  };

  const handleMultipleSelect = () => {
    setOpen(true);
    handleRecordFetch();
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleSelectedItem = (selectedItem) => {
    const selectedRecords = fields.length ? [...fields.getAll()] : [];
    selectedRecords.push(selectedItem);
    updateComponent(selectedRecords);
  };

  const handleMultipleItems = (selectedItems) => {
    const selectedRecords = fields.length ? [...fields.getAll()] : [];
    selectedItems.forEach((selectedItem) => selectedRecords.push(selectedItem));
    updateComponent(selectedRecords);
  };

  const handleRemoveItem = (selectedIndex) => {
    const selectedRecords = fields.length ? [...fields.getAll()] : [];
    if (selectedIndex !== -1) {
      selectedRecords.splice(selectedIndex, 1);
    };
    updateComponent(selectedRecords);
  };

  const updateComponent = (selectedRecords) => {
    updateLinkedRecords(selectedRecords);
  };

  const handleSearch = (searchText) => {
    const searchParams = { ...searchText, searchKey: 'name' };
    fetchRecords(layoutId, searchParams);
  };

  const selectedItems = fields.getAll();

  return (
    <div>
      {selectedItems && selectedItems.length > 0 && (
        <ItemList
          isEditable={false}
          isNote={false}
          {...props}
          type={label}
          handleRemoveItem={handleRemoveItem}
        />
      )}
      <ItemSelector
        multiValue={multiValue}
        createNewMenuItem={false}
        selectFromListMenuItem={true}
        dataSource={getModifiedRecordsList(fields, recordId, records)}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        hintTextLabel={hintTextLabel}
        handleSelectedItem={handleSelectedItem}
        handleMultipleSelect={handleMultipleSelect}
        onSearch={handleSearch}
        onFocus={handleRecordFetch}
      />
      {open && (
        <MultipleSelectorDialog
          title={
            <FormattedMessage
              id="Records.dialogheader"
              defaultMessage="Select records"
            />
          }
          open={open}
          filteredData={getModifiedRecordsList(fields, recordId, records)}
          onRequestClose={handleRequestClose}
          handleMultipleItems={handleMultipleItems}
          onSearch={handleSearch}
          isSearchEnabled={true}
        />
      )}
    </div>
  );
};

RecordsSelector.propTypes = {
  label: PropTypes.node,
  hintTextLabel: PropTypes.node,
  recordId: PropTypes.string,
  multiValue: PropTypes.bool,
  fetchRecords: PropTypes.func,
  updateLinkedRecords: PropTypes.func,
  records: PropTypes.instanceOf(Immutable.List),
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  searchResults: PropTypes.instanceOf(Immutable.List),
  layoutId: PropTypes.string
};

RecordsSelector.defaultProps = {
  multiValue: true,
  label: null,
  recordId: null,
  hintTextLabel: null,
  fetchRecords: e => e,
  updateLinkedRecords: e => e,
  records: Immutable.List(),
  searchResults: Immutable.List(),
  layoutId: ''
};

export default RecordsSelector;
