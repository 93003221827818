import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import FontAwesome from 'react-fontawesome';
import { FixedSizeList as List } from 'react-window';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DivWrapper from '@packages/components/divWrapper';
import ArrowTooltip from '@packages/components/tooltip';
import CountrySelector from '@packages/components/country-selector';
import styles from '@packages/components/styles';
import { commonTranslations, orgTypesTranslations } from '@packages/utils/commontranslations';
import { getDialogContentHeight } from '@packages/utils/common-utils';

const subHeaderStyle = {
  color: 'black',
  lineHeight: '20px',
  paddingLeft: 0,
  fontSize: '16px'
};

const legalEntityStyle = {
  height: 10, marginTop: -4
};

const labelWithEllipsis = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '500px'
};

class MultipleSelectorForm extends React.Component {
  listRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      filterValues: this.props.filteredData,
      checkLegalEntities: true,
      checkDepartments: true,
      checkInternal: (props.isInternal !== undefined ? props.isInternal : true),
      checkExternal: (props.isInternal !== undefined ? !props.isInternal : true),
      selectedItems: Immutable.List(),
      country: ''
    };

    this.handleCheckLegalEntities = this.handleCheckLegalEntities.bind(this);
    this.handleCheckDepartments = this.handleCheckDepartments.bind(this);
    this.handleCheckInternal = this.handleCheckInternal.bind(this);
    this.handleCheckExternal = this.handleCheckExternal.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filteredData !== nextProps.filteredData) {
      this.setState({
        filterValues: nextProps.filteredData
      });
    }
    if (this.props.isInternal !== nextProps.isInternal) {
      // isInternal is passed as a component-prop when multiselector is opened
      // from organisation-is-part-of
      this.setState({
        checkInternal: nextProps.isInternal
      });
    }
  }

  handleSubmit() {
    this.props.handleSubmit(this.state.selectedItems);
  }

  handleScroll = ({ target }) => {
    const { scrollTop } = target;
    this.listRef.current.scrollTo(scrollTop);
  };


  handleChange(event, item) {
    let { selectedItems } = this.state;
    selectedItems = Immutable.List();
    selectedItems = selectedItems.push(item);
    this.setState({
      selectedItems
    });
  }

  handleCountryChange(selectedItem) {
    this.prepareFilterParameters({
      country: selectedItem || ''
    });
  }

  handleCheckLegalEntities(checked) {
    this.prepareFilterParameters({
      checkLegalEntities: checked
    });
  }

  handleCheckDepartments(checked) {
    this.prepareFilterParameters({
      checkDepartments: checked
    });
  }

  handleCheckInternal(checked) {
    this.prepareFilterParameters({
      checkInternal: checked
    });
  }

  handleCheckExternal(checked) {
    this.prepareFilterParameters({
      checkExternal: checked
    });
  }

  getValueToDisplay = (item) => {
    let value = '';
    value = item.key ? item.key : item;
    value = item.addlkey ? `${value} (${item.addlkey})` : value;
    return value;
  };

  prepareFilterParameters(filterParams) {
    this.setState(filterParams);
    const { checkLegalEntities, checkDepartments, checkInternal, checkExternal, country } = this.state;
    this.checkFilters(Object.assign({},
      { checkLegalEntities, checkDepartments, checkInternal, checkExternal, country },
      filterParams));
  }

  checkFilters(filters) {
    const { checkLegalEntities, checkDepartments, checkInternal, checkExternal, country } = filters;
    let orgType = 'legalentity';
    if (checkLegalEntities === checkDepartments) { // when both are unchecked/checked all records to be fetched
      orgType = 'all';
    } else if (!checkLegalEntities) {
      orgType = 'department';
    }

    let isInternal = 'true';
    if (checkInternal === checkExternal) { // when both are unchecked/checked all records to be fetched
      isInternal = 'all';
    } else if (!checkInternal) {
      isInternal = 'false';
    }

    this.filterLegalEntities(orgType, isInternal, country);
  }

  filterLegalEntities(orgType, isInternal, country) {
    if (orgType !== 'all' && isInternal !== 'all') {
      if (country !== '') {
        this.props.filterItems([{ orgType }, { isInternal }, { country }]);
      } else {
        this.props.filterItems([{ orgType }, { isInternal }]);
      }
    } else if (orgType === 'all' && isInternal === 'all') {
      if (country !== '') {
        this.props.filterItems([{ country }]);
      } else {
        this.props.filterItems([]);
      }
    } else if (orgType === 'all') {
      if (country !== '') {
        this.props.filterItems([{ isInternal }, { country }]);
      } else {
        this.props.filterItems([{ isInternal }]);
      }
    } else if (isInternal === 'all') {
      if (country !== '') {
        this.props.filterItems([{ orgType }, { country }]);
      } else {
        this.props.filterItems([{ orgType }]);
      }
    }
  }

  render () {
    const { onCancel, showOrganisationFilter, showFilters, showDepartment } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components
    const Row = (props) => {
      const { index } = props;
      const item = this.state.filterValues.toJS()[index];
      const valueToDisplay = this.getValueToDisplay(item);
      return (
        <RadioGroup
          name="radio-group"
          value={this.state.selectedItems.toJS()[0] ? this.state.selectedItems.toJS()[0].key : ''}
          onChange={event => this.handleChange(event, item)}
          labelStyle={{ fontSize: '16px' }}
          style={props.style}
        >
          <FormControlLabel
            value={item.key}
            key={item.key}
            control={<Radio
              style={styles.checkboxStyle}
            />}
            label={
              <ArrowTooltip title={valueToDisplay}><div style={labelWithEllipsis}>{valueToDisplay}</div></ArrowTooltip>
                }
          />
        </RadioGroup>
      );
    };

    const TABLE_MAXHEIGHT = 300;
    const WINDOW_INNERHEIGHT = 794;
    return (
      <div>
        <div style={{ width: '80%', paddingBottom: '20px' }}>
          <ListSubheader style={subHeaderStyle}>
            <FormattedMessage
              id="LegalEntities.filterByCountry"
              description="select country to filter"
              defaultMessage="Show organisation entities from the following country"
            />
          </ListSubheader>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '75%' }} >
              <CountrySelector
                id="country-selector"
                defaultValue={this.state.country}
                hintTextLabel={commonTranslations.selectCountry}
                onChange={this.handleCountryChange}
              />
            </div>
            <FontAwesome
              id="font-awesome"
              name="times-circle"
              style={{ lineHeight: '1em',
                fontSize: '20px',
                marginTop: 10,
                marginLeft: 15,
                color: 'black',
                cursor: 'pointer' }}
              onClick={() => this.handleCountryChange()}
            />
          </div>
        </div>
        {showFilters &&
        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 15, flexWrap: 'wrap' }} >
          <FormControlLabel
            id="legal"
            control={
              <Checkbox
                id="legal_entities"
                key="legal_entities"
                checked={this.state.checkLegalEntities}
                style={legalEntityStyle}
                onChange={(evt, isChecked) => this.handleCheckLegalEntities(isChecked)}
                color="primary"
              />}
            label={<FormattedMessage
              id="LegalEntities.legalEntitiesLabel"
              description="Legal entities label"
              defaultMessage="Legal entities"
            />}
            style={{ width: '49%', paddingBottom: 10 }}
          />
          {showDepartment &&
          <FormControlLabel
            id="departments_form"
            control={
              <Checkbox
                id="departments"
                key="departments"
                checked={this.state.checkDepartments}
                style={legalEntityStyle}
                onChange={(evt, isChecked) => this.handleCheckDepartments(isChecked)}
                color="primary"
              />}
            style={{ width: '49%', paddingBottom: 10 }}
            label={<FormattedMessage
              id="LegalEntities.departmentsLabel"
              description="Departments label"
              defaultMessage="Departments"
            />}
          />
            }
          {showOrganisationFilter &&
          <FormControlLabel
            control={
              <Checkbox
                id="internal_entities"
                key="internal_entities"
                checked={this.state.checkInternal}
                style={{ ...styles.checkboxStyle, marginTop: -4 }}
                onChange={(evt, isChecked) => this.handleCheckInternal(isChecked)}
                color="primary"
              />}
            style={{ width: '49%' }}
            label={orgTypesTranslations.internalOrg}
          />
            }
          {showOrganisationFilter &&
          <FormControlLabel
            control={
              <Checkbox
                id="external_entities"
                key="external_entities"
                checked={this.state.checkExternal}
                style={{ ...styles.checkboxStyle, marginTop: -4 }}
                onChange={(evt, isChecked) => this.handleCheckExternal(isChecked)}
                color="primary"
              />}
            style={{ width: '49%' }}
            label={orgTypesTranslations.externalOrg}
          />
            }
        </div>}

        <DivWrapper
          id="div-wrapper"
          autoHeight={true}
          onScrollEnd={this.props.onScrollStop}
          onScroll={this.handleScroll}
          autoHeightMax={getDialogContentHeight(WINDOW_INNERHEIGHT, TABLE_MAXHEIGHT)}
          style={{ paddingTop: 15 }}
        >
          {this.state.filterValues &&
            <List
              style={{ overflow: 'none' }}
              height={200}
              itemCount={this.state.filterValues.size}
              itemSize={40}
              ref={this.listRef}
            >
              {Row}
            </List>
          }
        </DivWrapper>
        <div style={styles.multiselectAddButton}>
          <Button
            id="submit"
            onClick={this.handleSubmit}
          >
            {commonTranslations.saveAndClose}
          </Button>
          <Button
            id="cancel"
            variant="text"
            onClick={onCancel}
          >
            {commonTranslations.Cancel}
          </Button>
        </div>
      </div>
    );
  }
}

MultipleSelectorForm.propTypes = {
  showOrganisationFilter: PropTypes.bool,
  isInternal: PropTypes.bool,
  showDepartment: PropTypes.bool,
  onScrollStop: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  filterItems: PropTypes.func,
  showFilters: PropTypes.bool,
  filteredData: PropTypes.instanceOf(Immutable.List)
};

MultipleSelectorForm.defaultProps = {
  isInternal: undefined,
  onScrollStop: e => e,
  showOrganisationFilter: true,
  showDepartment: true,
  onCancel: e => e,
  filterItems: e => e,
  handleSubmit: e => e,
  showFilters: true,
  filteredData: Immutable.List()
};
export default MultipleSelectorForm;
