/* eslint-disable import/no-cycle */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'fixed-data-table';
import { FormattedMessage, injectIntl } from 'react-intl';
import ContentLink from '@material-ui/icons/Link';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Immutable from 'immutable';

import Card from '@packages/components/card';
import Note from '@packages/components/note';
import {
  linkGroupItems,
  getContainerHeight,
  getRowCount
} from '@packages/utils/common-utils';
import ArrowTooltip from '@packages/components/tooltip';
import {
  commonTranslations,
  recordTranslations
} from '@packages/utils/commontranslations';
import { pluralTerms as uppercasePlural } from '@packages/utils/uppercaseTranslations';
import {
  ResponsiveTableWrapper,
  DataArrayCellForGroups,
  InformationCell,
  ActionCell,
  SortHeaderCell,
  DataCell
} from '@packages/components/responsive-table';
import styles from '@packages/ui/styles';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import CustomDialog from '@packages/components/custom-dialog';
import { sectionHeaderTranslations } from '@packages/features/environment/masterDataTranslations';
import {
  removeTranslations,
  editTranslations
} from '@packages/utils/actionTranslations';
import { checkForMergeItems } from '../../../../environment/utils';
import ImpactAnalysisPage from '../../../../environment/impact-analysis';
import { CheckBoxCell } from '../../../../environment/renderers';
import EditGroup from '../../../../environment/components/links/components';

const actionItems = [
  {
    action: 'delete',
    primaryText: (
      <FormattedMessage
        id="Link_Group.ungroup"
        description="Ungroup"
        defaultMessage="Ungroup"
      />
    )
  },
  {
    action: 'addNote',
    primaryText: commonTranslations.addNote
  },
  {
    action: 'editNote',
    primaryText: commonTranslations.editNote
  },
  {
    action: 'viewNote',
    primaryText: commonTranslations.viewNote
  },
  {
    action: 'removeNote',
    primaryText: commonTranslations.removeNote
  }
];

const cellItemHeight = 20;

const titleStyle = {
  fontSize: '14px',
  maxWidth: 100,
  overflow: ' hidden',
  textOverflow: ' ellipsis',
  whiteSpace: 'nowrap'
};

export class GroupList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actionChooserIcon: <MoreVertIcon />,
      addNoteOpen: false,
      editNoteOpen: false,
      viewNoteOpen: false,
      deleteNoteOpen: false,
      selectedLinkIndex: -1,
      content: undefined,
      currentIndex: -1,
      deleteGroupOpen: false,
      openRemoveGroupDialog: false,
      removeIndex: -1,
      items: Immutable.List(),
      editGroupOpen: false,
      editLink: {},
      isTagEdit: false,
      isUsed: false,
      isDeleteWithJob: true
    };

    this.rowHeightGetterhandler = this.rowHeightGetterhandler.bind(this);
    this.handleMenuHidden = this.handleMenuHidden.bind(this);
    this.handleGroupMenuAction = this.handleGroupMenuAction.bind(this);
    this.handleAddNoteOpen = this.handleAddNoteOpen.bind(this);
    this.handleEditNote = this.handleEditNote.bind(this);
    this.requestNoteDialogClose = this.requestNoteDialogClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleViewNote = this.handleViewNote.bind(this);
    this.handleDeleteNote = this.handleDeleteNote.bind(this);
    this.handleremoveGroupOpen = this.handleremoveGroupOpen.bind(this);
    this.handleGroupRemove = this.handleGroupRemove.bind(this);
    this.handleOnConfirm = this.handleOnConfirm.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.selectedItems ||
      nextProps.bulkItems !== this.props.bulkItems
    ) {
      const modifiedItems =
        nextProps.bulkItems.size > 0
          ? checkForMergeItems(this.props.selectedItems, nextProps.bulkItems)
          : Immutable.List(this.props.selectedItems);
      this.setState({
        items: modifiedItems
      });
    }
    if (
      nextProps.usage !== undefined &&
      Object.keys(nextProps.usage).length > 0
    ) {
      this.setState({ isUsed: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !(this.props.selectedItems === nextProps.selectedItems) ||
      !(this.state === nextState) ||
      !(this.props.isLinkEditMode === nextProps.isLinkEditMode)
    );
  }

  handleCheckReset = (event) => {
    if (!event.target.checked) this.props.onCheckReset();
  };

  handleViewNote(evt, rowIndex) {
    evt.stopPropagation();

    const { note } = this.props.selectedItems[rowIndex];
    this.handleViewNoteOpen(rowIndex, note);
  }

  handleViewNoteOpen(index, note) {
    this.setState({
      viewNoteOpen: true,
      selectedLinkIndex: index,
      content: note
    });
  }

  handleDeleteNoteOpen(index) {
    this.setState({
      selectedLinkIndex: index,
      deleteNoteOpen: true
    });
  }

  handleMenuHidden(menu, rowIndex, action) {
    const items = this.props.selectedItems;
    if (action === 'addNote') {
      const item = items[rowIndex];
      return !(item.note === undefined || item.note === '');
    }
    if (
      action === 'editNote' ||
      action === 'removeNote' ||
      action === 'viewNote'
    ) {
      const item = items[rowIndex];
      return item.note === undefined || item.note === '';
    }
    return false;
  }

  handleGroupMenuAction(actionButton, index, action) {
    const { note = '' } = this.props.selectedItems[index];
    if (action === 'edit') {
      this.setState({
        actionChooserIcon: <ContentLink />
      });
      this.props.handleEditGroup(index);
    } else if (action === 'delete') {
      this.handleremoveGroupOpen(index);
    } else if (action === 'addNote') {
      this.handleAddNoteOpen(index);
    } else if (action === 'viewNote') {
      this.handleViewNoteOpen(index, note);
    } else if (action === 'editNote') {
      this.handleEditNoteOpen(index, note);
    } else if (action === 'removeNote') {
      this.handleDeleteNoteOpen(index);
    }
  }

  handleremoveGroupOpen(index) {
    this.setState({
      openRemoveGroupDialog: true,
      removeIndex: index
    });
  }

  handleGroupRemove() {
    const updatedData = this.props.selectedItems[this.state.currentIndex];
    if (!this.props.hasNoLimit && this.state.isUsed) {
      this.requestDialogClose();
    } else if (this.state.isUsed) {
      this.props.removeGroupData(
        updatedData,
        this.state.isDeleteWithJob,
        updatedData.linkNumber
      );
    } else {
      this.props.deleteGroup(updatedData.id);
    }
    this.requestDialogClose();
  }

  handleOnConfirm() {
    this.props.handleRemoveMasterdataItems(this.state.removeIndex);
    this.setState({
      openRemoveGroupDialog: false
    });
  }

  handleRemoveGroup = () => {
    this.props.handleRemoveGroup(this.state.removeIndex);
    this.setState({
      openRemoveGroupDialog: false
    });
  };

  handleAddNoteOpen(index) {
    this.setState({
      addNoteOpen: true,
      selectedLinkIndex: index
    });
  }

  handleEditNoteOpen(index, note) {
    this.setState({
      editNoteOpen: true,
      selectedLinkIndex: index,
      content: note
    });
  }

  handleEditNote(event, note, currentState) {
    const stateObject = currentState || this.state;
    const { content, selectedLinkIndex, addNoteOpen, editNoteOpen } =
      stateObject;
    const noteContent = note || content;

    if ((addNoteOpen && noteContent !== '') || editNoteOpen) {
      const currentLinkGroup = this.props.selectedItems[selectedLinkIndex];
      const { _rev } = currentLinkGroup.value || currentLinkGroup;
      const modifiedLinkItem = Object.assign({}, currentLinkGroup, {
        note: noteContent && noteContent.trim(),
        _rev
      });
      this.props.handleNoteChange(selectedLinkIndex, modifiedLinkItem);
    }
    this.requestNoteDialogClose();
  }

  handleChange(event) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ content: event.target.value });
  }

  handleMouseHover = (event, indexValue) => {
    this.setState({ currentIndex: indexValue });
  };

  handleDeleteClick = () => {
    if (this.props.isOnlyDelete) {
      const currentLinkGroups = this.props.selectedItems;
      currentLinkGroups.splice(this.state.currentIndex, 1);
      this.props.updateLinkGroups(currentLinkGroups);
    } else {
      this.onCheckUsage();
      this.setState({ deleteGroupOpen: true });
    }
  };

  handleEditClick = () => {
    const currentLinkGroup = this.props.selectedItems[this.state.currentIndex];
    this.setState({
      editGroupOpen: true,
      editLink: currentLinkGroup,
      removeIndex: this.state.currentIndex
    });
  };

  handleTagEdit = () => {
    const currentLinkGroup = this.props.selectedItems[this.state.currentIndex];
    this.setState({
      editGroupOpen: true,
      editLink: currentLinkGroup,
      isTagEdit: true
    });
  };

  handleEditWithJob = (isChecked) => {
    this.setState({ isDeleteWithJob: isChecked });
  };

  handleDeleteNote() {
    const { selectedLinkIndex } = this.state;
    const currentLinkGroup = this.props.selectedItems[selectedLinkIndex];
    const { _rev } = currentLinkGroup.value || currentLinkGroup;
    const modifiedLinkItem = Object.assign({}, currentLinkGroup, {
      note: '',
      _rev
    });
    this.props.handleNoteChange(selectedLinkIndex, modifiedLinkItem);
    this.requestNoteDialogClose();
  }

  handleScrollEnd = (scrollX, scrollY) => {
    const rowCount = getRowCount();
    if (
      this.props.selectedItems.length >= rowCount &&
      40 * this.props.selectedItems.length - getContainerHeight() <= scrollY
    ) {
      this.props.getNextData(this.props.position, this.props.source);
    }
  };

  onCheckHandler = (event, item) => {
    this.props.onItemCheck(item);
  };

  onCheckUsage = () => {
    if (this.props.source === 'environment') {
      const currentLinkGroup =
        this.props.selectedItems[this.state.currentIndex].id;
      this.props.checkUsage(currentLinkGroup);
    }
  };

  onSaveClick = () => {
    this.props.handleSave(this.props.bulkItems.toJS());
    this.props.onCheckReset();
  };

  showHeader = (title) => (
    <ArrowTooltip title={title}>
      <div style={titleStyle}>{title}</div>
    </ArrowTooltip>
  );

  requestDialogClose = () => {
    this.props.resetLinkGroup();
    this.setState({
      isUsed: false,
      deleteGroupOpen: false,
      editGroupOpen: false,
      isTagEdit: false,
      openRemoveGroupDialog: false
    });
  };
  
  requestNoteDialogClose() {
    this.setState({
      addNoteOpen: false,
      editNoteOpen: false,
      viewNoteOpen: false,
      deleteNoteOpen: false
    });
  }

  rowHeightGetterhandler(rowIndex) {
    const itemCount = [];
    const link = this.props.selectedItems && this.props.selectedItems[rowIndex];
    const modifiedLinkItems = [...linkGroupItems, 'tags'];

    if (link) {
      modifiedLinkItems.forEach((key) => {
        const linkLength = link.value
          ? link.value[key].length
          : link[key].length;
        itemCount.push(linkLength);
      });
    }

    return Math.max(...itemCount) * cellItemHeight + 20;
  }

  render() {
    const viewAction = [
      <Button onClick={this.requestNoteDialogClose}>
        {commonTranslations.close}
      </Button>
    ];

    const buttonActions = [
      <Button
        classes={{
          root: 'button_confirm_dialog',
          label: 'buttonLabel_confirm_dialog'
        }}
        style={{ marginRight: '17px' }}
        onClick={this.handleOnConfirm}
      >
        {commonTranslations.Yes}
      </Button>,
      <Button
        classes={{
          root: 'button_confirm_dialog',
          label: 'buttonLabel_confirm_dialog'
        }}
        onClick={this.handleRemoveGroup}
      >
        {commonTranslations.No}
      </Button>,
      <Button
        variant="text"
        id="cancel-button"
        key="cancel-removeGroup"
        onClick={this.requestDialogClose}
      >
        {commonTranslations.Cancel}
      </Button>
    ];

    const {
      isLinkEditMode,
      isRecordEditMode,
      showCardTitle,
      fontStyle,
      visibleFields,
      source,
      selectedItems,
      bulkItems,
      isMultipleGroupSelect,
      onClose,
      showSaveButton
    } = this.props;
    const links =
      (selectedItems && selectedItems.length && [...selectedItems]) || [];
    const bulkItemsSize = bulkItems.size;
    const modifiedLinks = links.map((item) =>
      item.value ? { ...item.value } : item
    );
    // const modifiedItems = getItemsWithModifiedTags(links);

    const title = <span>{recordTranslations.dataItemGroups}</span>;
    return (
      <div>
        {links && links.length > 0 && (
          <Card
            showCardTitle={showCardTitle}
            title={title}
            headerStyle={{ fontSize: '20px', paddingTop: '5px' }}
            rootStyle={{ marginTop: '0px' }}
          >
            <div>
              <ResponsiveTableWrapper
                id="link_group_responsive_table"
                data={links}
                showActionSelector={isRecordEditMode}
                actionChooserStyle={styles.actionChooserStyle}
                actionChooserIcon={
                  isLinkEditMode ? (
                    this.state.actionChooserIcon
                  ) : (
                    <MoreVertIcon />
                  )
                }
                currentActiveRow={this.props.selectedLinkIndex}
                toggleActionChooserIcon={isLinkEditMode}
                rowHeight={40}
                headerHeight={45}
                rowsCount={links.length}
                containerWidth={this.props.tableWidth}
                rowHeightGetter={this.rowHeightGetterhandler}
                actionItems={actionItems}
                isHidden={this.handleMenuHidden}
                onRowMouseEnter={this.handleMouseHover}
                onRowClick={this.props.handleRowClick}
                onActionHandler={this.handleGroupMenuAction}
                actionChooserClass="actionChooserClass"
                componentHeight={this.props.containerHeight}
                onScrollEnd={this.handleScrollEnd}
                {...this.props}
              >
                <Column
                  columnKey="checkMerge"
                  header={
                    <SortHeaderCell
                      showFilterIcon={false}
                      enableSorting={false}
                      showMergeCheck={true}
                      bulkItemsSize={bulkItemsSize}
                      onMergeReset={this.handleCheckReset}
                      enablePageSearch={false}
                      tooltip={false}
                    />
                  }
                  cell={
                    <CheckBoxCell
                      items={this.state.items}
                      onCheck={this.onCheckHandler}
                      unused={this.props.isMultipleGroupSelect}
                      bulkItemsSize={bulkItemsSize}
                    />
                  }
                  flexGrow={0.3}
                  width={40}
                  visible={isMultipleGroupSelect}
                />
                <Column
                  id="linkNumber"
                  columnKey="linkNumber"
                  header={this.showHeader(recordTranslations.recordId)}
                  cell={<DataCell items={modifiedLinks} />}
                  flexGrow={1}
                  width={80}
                  visible={true}
                />
                <Column
                  id="dataSubjectCategories"
                  columnKey="dataSubjectCategories"
                  header={this.showHeader(
                    uppercasePlural('dataSubjectCategories')
                  )}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={2}
                  width={100}
                  visible={
                    source === 'environment'
                      ? true
                      : visibleFields.includes('DataSubjectCategories')
                  }
                />
                <Column
                  id="personalDataCategories"
                  columnKey="personalDataCategories"
                  header={this.showHeader(
                    uppercasePlural('personalDataCategories')
                  )}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={2}
                  width={100}
                  visible={
                    source === 'environment'
                      ? true
                      : visibleFields.includes('PersonalDataCategories')
                  }
                />
                <Column
                  id="personalDataItems"
                  columnKey="personalDataItems"
                  header={this.showHeader(uppercasePlural('personalDataItems'))}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={2}
                  width={100}
                  visible={
                    source === 'environment'
                      ? true
                      : visibleFields.includes('PersonalDataItems')
                  }
                />
                <Column
                  id="dataSources"
                  columnKey="dataSources"
                  header={this.showHeader(uppercasePlural('dataSources'))}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={2}
                  width={100}
                  visible={
                    source === 'environment'
                      ? true
                      : visibleFields.includes('DataSources')
                  }
                />
                <Column
                  id="dataSourceCategories"
                  columnKey="dataSourceCategories"
                  header={this.showHeader(
                    uppercasePlural('dataSourceCategories')
                  )}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={2}
                  width={100}
                  visible={
                    source === 'environment'
                      ? true
                      : visibleFields.includes('DataSourceCategories')
                  }
                />
                <Column
                  id="retentionTerms"
                  columnKey="retentionTerms"
                  header={this.showHeader(uppercasePlural('retentionTerms'))}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={2}
                  width={100}
                  visible={
                    source === 'environment'
                      ? true
                      : visibleFields.includes('RetentionTerms')
                  }
                />
                <Column
                  id="technicalSecurityMeasures"
                  columnKey="technicalSecurityMeasures"
                  header={this.showHeader(
                    sectionHeaderTranslations.technicalSecurityMeasuresHeader
                  )}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={2}
                  width={100}
                  visible={
                    source === 'environment'
                      ? true
                      : visibleFields.includes('TechnicalSecurityMeasures')
                  }
                />
                <Column
                  id="organisationalSecurityMeasures"
                  columnKey="organisationalSecurityMeasures"
                  header={this.showHeader(
                    sectionHeaderTranslations.organisationalMeasuresHeader
                  )}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={2}
                  width={100}
                  visible={
                    source === 'environment'
                      ? true
                      : visibleFields.includes('OrganisationalSecurityMeasures')
                  }
                />
                <Column
                  id="purposes"
                  columnKey="purposes"
                  header={this.showHeader(recordTranslations.assessmentPurpose)}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={2}
                  width={100}
                  visible={
                    source === 'environment'
                      ? true
                      : visibleFields.includes('Purposes')
                  }
                />
                <Column
                  id="purposesOfTransfer"
                  columnKey="purposesOfTransfer"
                  header={this.showHeader(recordTranslations.transferPurpose)}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={2}
                  width={100}
                  visible={
                    source === 'environment'
                      ? true
                      : visibleFields.includes('InternationalTransfers')
                  }
                />
                <Column
                  id="tag-column"
                  columnKey="tags"
                  header={this.showHeader(commonTranslations.tags)}
                  cell={
                    <DataArrayCellForGroups
                      items={links}
                      fontStyle={fontStyle}
                    />
                  }
                  flexGrow={1}
                  width={100}
                />
                <Column
                  id="note"
                  columnKey="note"
                  cell={
                    <InformationCell
                      items={links}
                      align="center"
                      tooltipId="dataGroupNoteTitle"
                      handleOnClick={this.handleViewNote}
                      className="informationClass datagroup"
                      style={{ paddingTop: '12px' }}
                    />
                  }
                  width={30}
                />
                <Column
                  id="action-cell"
                  columnKey="action-cell"
                  align="center"
                  cell={
                    <ActionCell
                      currentIndex={this.state.currentIndex}
                      handleEdit={(rowIndex) => this.handleEditClick(rowIndex)}
                      handleDelete={(rowIndex) =>
                        this.handleDeleteClick(rowIndex)
                      }
                      handleEditTag={(rowIndex) => this.handleTagEdit(rowIndex)}
                      isTagEditHidden={!this.props.isEditable}
                      isEditHidden={
                        !this.props.isEditable && !this.props.isOnlyEdit
                      }
                      isDeleteHidden={
                        !this.props.isEditable && !this.props.isOnlyDelete
                      }
                    />
                  }
                  width={100}
                  visible={
                    this.props.isEditable ||
                    this.props.isOnlyEdit ||
                    this.props.isOnlyDelete
                  }
                />
              </ResponsiveTableWrapper>
              {showSaveButton && (
                <div style={{ marginTop: '7px' }}>
                  <Button
                    classes={{
                      root: 'button_confirm_dialog',
                      label: 'buttonLabel_confirm_dialog'
                    }}
                    style={{ marginRight: '20px' }}
                    onClick={this.onSaveClick}
                  >
                    {commonTranslations.saveAndClose}
                  </Button>
                  <Button
                    classes={{
                      root: 'button_confirm_dialog',
                      label: 'buttonLabel_confirm_dialog'
                    }}
                    onClick={onClose}
                  >
                    {commonTranslations.close}
                  </Button>
                </div>
              )}
              {(this.state.editNoteOpen || this.state.addNoteOpen) && (
                <Note
                  id="edit-note"
                  content={this.state.addNoteOpen ? null : this.state.content}
                  onChange={this.handleChange}
                  isEdit={true}
                  headerLabel={
                    this.state.addNoteOpen
                      ? commonTranslations.addNote
                      : commonTranslations.editNote
                  }
                  open={this.state.editNoteOpen || this.state.addNoteOpen}
                  handleEditNote={this.handleEditNote}
                  close={this.requestNoteDialogClose}
                />
              )}
              {this.state.viewNoteOpen && (
                <Note
                  id="view-note"
                  content={this.state.content}
                  isEdit={false}
                  headerLabel={commonTranslations.viewNote}
                  open={this.state.viewNoteOpen}
                  saveActions={viewAction}
                  close={this.requestNoteDialogClose}
                />
              )}
              {this.state.deleteNoteOpen && (
                <CustomDialog
                  id="delete_note_dialog"
                  show={this.state.deleteNoteOpen}
                  proceed={this.handleDeleteNote}
                  cancel={this.requestNoteDialogClose}
                  content={recordTranslations.deleteNote}
                />
              )}
              {this.state.deleteGroupOpen && (
                <CustomDialog
                  id="delete_note_dialog"
                  show={this.state.deleteGroupOpen}
                  isDelete={!(!this.props.hasNoLimit && this.state.isUsed)}
                  proceed={this.handleGroupRemove}
                  cancel={this.requestDialogClose}
                  title={
                    <>
                      {removeTranslations('linkDelete')} -{' '}
                      {
                        this.props.selectedItems[this.state.currentIndex]
                          .linkNumber
                      }
                    </>
                  }
                  content={
                    this.state.isUsed ? (
                      <ImpactAnalysisPage
                        hasNoLimit={this.props.hasNoLimit}
                        usage={this.props.usage}
                        handleEditWithJob={this.handleEditWithJob}
                        type="dataItemGroup"
                      />
                    ) : (
                      <FormattedMessage
                        id="records.removeGroupData"
                        defaultMessage="Do you want to delete the group?"
                      />
                    )
                  }
                />
              )}
              {this.state.openRemoveGroupDialog && (
                <CommonDialog
                  id="record-remove-group-dialog"
                  show={this.state.openRemoveGroupDialog}
                  onCancel={this.requestDialogClose}
                  fullWidth={true}
                  maxWidth="sm"
                  title={
                    <FormattedMessage
                      id="Link_Group.ungroup"
                      description="Ungroup"
                      defaultMessage="Ungroup"
                    />
                  }
                  buttonActions={buttonActions}
                  showCloseIcon={false}
                >
                  {removeTranslations('removeMasterDataItems')}
                </CommonDialog>
              )}
              {this.state.editGroupOpen && (
                <CommonDialog
                  id="edit_group-dialog"
                  fullWidth={true}
                  maxWidth="md"
                  show={this.state.editGroupOpen}
                  onCancel={this.requestDialogClose}
                  title={
                    this.props.selectedItems[this.state.currentIndex] && (
                      <>
                        {editTranslations('editGroup')} -{' '}
                        {this.props.selectedItems[this.state.currentIndex].value
                          ? this.props.selectedItems[this.state.currentIndex]
                            .value.linkNumber
                          : this.props.selectedItems[this.state.currentIndex]
                            .linkNumber}
                      </>
                    )
                  }
                >
                  <EditGroup
                    checkUsage={this.onCheckUsage}
                    isUsed={this.state.isUsed}
                    usage={this.props.usage}
                    isEdit={this.state.editGroupOpen}
                    isTagEdit={this.state.isTagEdit}
                    considerFieldVisibility={this.props.recordType !== ''}
                    onClose={this.requestDialogClose}
                    link={this.state.editLink.value || this.state.editLink}
                    hasNoLimit={this.props.hasNoLimit}
                    recordType={this.props.recordType}
                    visibilitySettings={visibleFields}
                    removeCurrentGroup={this.handleOnConfirm}
                  />
                </CommonDialog>
              )}
            </div>
          </Card>
        )}
      </div>
    );
  }
}

GroupList.propTypes = {
  links: PropTypes.shape({}),
  bulkItems: PropTypes.instanceOf(Immutable.List),
  fields: PropTypes.shape({
    get: PropTypes.func,
    getAll: PropTypes.func,
    length: PropTypes.number
  }),
  handleEditGroup: PropTypes.func,
  handleRemoveGroup: PropTypes.func,
  handleNoteChange: PropTypes.func,
  isLinkEditMode: PropTypes.bool,
  handleVideoEditorDialog: PropTypes.func,
  isRecordEditMode: PropTypes.bool,
  showCardTitle: PropTypes.bool,
  selectedLinkIndex: PropTypes.number,
  handleRowClick: PropTypes.func,
  containerHeight: PropTypes.number,
  fontStyle: PropTypes.shape({}),
  isMultipleGroupSelect: PropTypes.bool,
  visibleFields: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.string,
  recordType: PropTypes.string,
  onClose: PropTypes.func,
  handleSave: PropTypes.func,
  deleteGroup: PropTypes.func,
  handleRemoveMasterdataItems: PropTypes.func,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
      value: PropTypes.shape({
        _rev: PropTypes.string
      })
    })
  ),
  onItemCheck: PropTypes.func,
  onCheckReset: PropTypes.func,
  showSaveButton: PropTypes.bool,
  isEditable: PropTypes.bool,
  isOnlyEdit: PropTypes.bool,
  isOnlyDelete: PropTypes.bool,
  updateLinkGroups: PropTypes.func,
  removeGroupData: PropTypes.func,
  usage: PropTypes.shape({
    assessment: PropTypes.number,
    processing: PropTypes.number
  }),
  checkUsage: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  hasNoLimit: PropTypes.bool,
  position: PropTypes.number,
  getNextData: PropTypes.func,
  resetLinkGroup: PropTypes.func
};

GroupList.defaultProps = {
  links: {},
  bulkItems: Immutable.List(),
  fields: {
    get: (e) => e,
    getAll: (e) => e,
    length: 0
  },
  handleEditGroup: (e) => e,
  handleRemoveGroup: (e) => e,
  handleVideoEditorDialog: (e) => e,
  handleNoteChange: (e) => e,
  isLinkEditMode: false,
  isRecordEditMode: false,
  showCardTitle: true,
  selectedLinkIndex: -1,
  handleRowClick: (e) => e,
  containerHeight: undefined,
  fontStyle: {},
  visibleFields: [],
  source: undefined,
  recordType: '',
  selectedItems: [],
  isMultipleGroupSelect: false,
  onClose: (e) => e,
  handleSave: (e) => e,
  handleRemoveMasterdataItems: (e) => e,
  showSaveButton: false,
  deleteGroup: (e) => e,
  onItemCheck: (e) => e,
  onCheckReset: (e) => e,
  isEditable: true,
  isOnlyEdit: true,
  isOnlyDelete: false,
  updateLinkGroups: (e) => e,
  removeGroupData: (e) => e,
  usage: undefined,
  checkUsage: (e) => e,
  hasNoLimit: false,
  position: 0,
  getNextData: (e) => e,
  resetLinkGroup: (e) => e
};

export default injectIntl(GroupList);
