/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import 'react-ui-tree/dist/react-ui-tree.css';
import keycode from 'keycode';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ActionInfo from '@material-ui/icons/Info';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import ArrowTooltip from '@packages/components/tooltip';
import {
  commonTranslations,
  recordTranslations,
  typeTranslations,
  environmentHeaderTranslation,
  removeTypeTranslations,
  editTypeTranslations
} from '@packages/utils/commontranslations';
import UITree from '@packages/components/react-ui-tree';
import Note from '@packages/components/note';
import DivWrapper from '@packages/components/divWrapper';
import errortranslations from '@packages/utils/errortranslations';
import CustomDialog from '@packages/components/custom-dialog';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import styles from '@packages/ui/styles';

import LegalEntitySelector from '../../../entity-selector';
import OrganisationEntityDialog from '../../../organisation-entities/organisationEntityDialog';
import './react-ui-tree-custom.css';
import {
  getContentStyle,
  getContent,
  getLegalEntityKey,
  getNoteValue,
  EEACountries,
  getIntlChecked
} from '../../common-utils';

const defaultStyle = {
  rowStyle: {
    overflow: 'auto',
    padding: '15px'
  },
  rootStyle: {
    overflow: 'auto',
    padding: '15px',
    marginLeft: '-24px'
  }
};

const hierarchyActionIconsStyle = {
  display: 'flex',
  marginRight: '2px',
  minWidth: 120,
  justifyContent: 'flex-end',
  alignItems: 'start',
  marginTop: '-2px'
};

const rowStyle = {
  float: 'left',
  position: 'absolute',
  width: '70%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const deleteStyle = {
  color: 'red',
  height: '24px',
  marginLeft: '12px',
  width: '14px'
};

const moreVertStyle = {
  marginTop: '0px',
  marginLeft: '10px'
};

let nodeFound = false;
let nodeIndex;

const transformSelectedItems = (selectedItems) => {
  let transformedItems = [];
  selectedItems.forEach((selectedItem) => {
    // first render will have subProcessors and remaining will hold it as children.
    const value =
      selectedItem.children ||
      (selectedItem.value && selectedItem.value.subProcessors);
    if (value) {
      transformedItems = transformedItems.concat(transformSelectedItems(value));
    }
    transformedItems.push(selectedItem);
  });
  return transformedItems;
};

const getSelectedItems = (selectedItems) => {
  let transformedItems = [];
  const items = selectedItems && (selectedItems.children || selectedItems);
  if (items && items.length) {
    transformedItems = transformSelectedItems(items);
  }
  return transformedItems;
};

class ProcessorHeirarchy extends React.Component {
  processorList = [];

  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      data: props.processors,
      editNoteContent: '',
      viewNoteContent: '',
      open: false,
      editNoteOpen: false,
      viewNoteOpen: false,
      deleteNoteOpen: false,
      entityDetails: [],
      openDialog: false,
      showSidebar: false
    };

    this.renderNode = this.renderNode.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.requestDeleteNoteClose = this.requestDeleteNoteClose.bind(this);
    this.requestEditNoteClose = this.requestEditNoteClose.bind(this);
    this.requestViewNoteClose = this.requestViewNoteClose.bind(this);
    this.requestMenuClose = this.requestMenuClose.bind(this);
    this.handleNoteFieldChange = this.handleNoteFieldChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleEditNote = this.handleEditNote.bind(this);
    this.handleDeleteNoteOpen = this.handleDeleteNoteOpen.bind(this);
    this.handleEditNoteOpen = this.handleEditNoteOpen.bind(this);
    this.handleViewNoteOpen = this.handleViewNoteOpen.bind(this);
    this.handleAddNoteOpen = this.handleAddNoteOpen.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleOpenActionMenu = this.handleOpenActionMenu.bind(this);
    this.handleDeleteNote = this.handleDeleteNote.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedItems !== nextProps.selectedItems) {
      const data = nextProps.processors;
      this.setState({
        data
      });
    }

    if (this.props.entityDetails !== nextProps.entityDetails) {
      this.setState({
        entityDetails: nextProps.entityDetails
      });
    }
    if (this.props.showSidebar !== nextProps.showSidebar) {
      this.setState({
        showSidebar: nextProps.showSidebar
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !(this.props.selectedItems === nextProps.selectedItems) ||
      !(this.props.searchResults === nextProps.searchResults) ||
      !(this.state === nextState) ||
      !(this.props.selectedDpoOrgId === nextProps.selectedDpoOrgId)
    );
  }

  componentWillUpdate(nextProps, nextState) {
    const { data } = nextState;
    if (data.collapsed) {
      data.collapsed = false;
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({
        data
      });
    }
  }

  handleOpenActionMenu(event, node, note) {
    this.setState({
      openActionMenu: true,
      anchorEl: event.currentTarget,
      node,
      note
    });
  }

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    let intlChecked = false;
    const data = Object.assign({}, this.state.data);
    if (selectedItems.size && selectedItems.size > 0) {
      intlChecked = getIntlChecked(selectedItems);
    }
    const tempSelectedItems = selectedItems.map((item) => item.value);
    data.children = [...data.children, ...tempSelectedItems];
    this.handleChange(data, intlChecked);
  }

  handleSelectedItem(selectedItem) {
    let intlChecked = false;
    if (
      selectedItem.value.country &&
      !EEACountries.includes(selectedItem.value.country)
    ) {
      intlChecked = true;
    }
    const data = Object.assign({}, this.state.data);
    data.children.push(selectedItem.value);
    this.handleChange(data, intlChecked);
  }

  handleEditItem(node) {
    let intlChecked = false;
    if (node.value.country && !EEACountries.includes(node.value.country)) {
      intlChecked = true;
    }
    const data = JSON.parse(JSON.stringify(this.state.data));
    const selectedItem = node.value;
    const foundNode = this.resetAndFindNode(
      this.state.inputValue.id,
      data.children
    );
    foundNode.childNode.splice(foundNode.index, 1, selectedItem);
    this.handleChange(data, intlChecked);
    this.handleRequestClose();
  }

  handleChange(processorData, intlChecked) {
    const dataString = JSON.stringify(processorData);
    const data = JSON.parse(
      dataString.split('"subProcessors":').join('"children":')
    );
    if (!this.props.isView) {
      const processors = JSON.parse(JSON.stringify(data));
      this.props.updateProcessors(processors, intlChecked);
    }
  }

  handleDpoRemoval = (selectedOrgId, processorData) => {
    const dataString = JSON.stringify(processorData);
    const data = JSON.parse(
      dataString.split('"subProcessors":').join('"children":')
    );
    const processors = JSON.parse(JSON.stringify(data));
    this.props.handleRemoveDpo(selectedOrgId, processors, 'processors');
  };

  handleRemove(node) {
    this.setState({
      openActionMenu: false
    });
    const data = JSON.parse(JSON.stringify(this.state.data));
    const foundNode = this.resetAndFindNode(node.id, data.children);
    foundNode.childNode.splice(foundNode.index, 1);
    this.processorList.push(node.id);
    const processorIds = this.getProcessorIds(node.children || [node]);
    const isDpoOrg = processorIds.includes(this.props.selectedDpoOrgId);
    if (isDpoOrg) {
      this.handleDpoRemoval(this.props.selectedDpoOrgId, data);
    } else this.handleChange(data);
  }

  handleUsageClick(node) {
    this.props.handleUsageClick(node.id, 'processors');
  }

  handleEditClick(node) {
    this.setState({
      openActionMenu: false,
      open: true,
      isEdit: true,
      inputValue: node
    });
  }

  handleAddNoteOpen(node) {
    this.setState({
      openActionMenu: false,
      addNoteOpen: true,
      currentNode: node,
      noteTitle: node.name
    });
  }

  handleEditNoteOpen(node) {
    this.setState({
      openActionMenu: false,
      editNoteOpen: true,
      currentNode: node,
      noteTitle: node.name,
      editNoteContent: node.note
    });
  }

  handleViewNoteOpen(node) {
    this.setState({
      openActionMenu: false,
      viewNoteOpen: true,
      viewNoteDialogTitle: node.name,
      viewNoteContent: node.note
    });
  }

  handleDeleteNoteOpen(node) {
    this.setState({
      openActionMenu: false,
      deleteNoteOpen: true,
      currentNode: node
    });
  }

  // Handle note value changes
  handleEditNote(event, currentState) {
    const stateObject = currentState || this.state;
    const { currentNode, content, addNoteOpen, editNoteOpen } = stateObject;
    if ((addNoteOpen && content !== '') || editNoteOpen) {
      const data = JSON.parse(JSON.stringify(this.state.data));
      const foundNode = this.resetAndFindNode(currentNode.id, data.children);
      foundNode.childNode[foundNode.index].note = content && content.trim();
      this.handleChange(data);
    }
    this.requestEditNoteClose();
  }

  handleDeleteNote() {
    const { currentNode } = this.state;
    const data = JSON.parse(JSON.stringify(this.state.data));
    const foundNode = this.resetAndFindNode(currentNode.id, data.children);
    delete foundNode.childNode[foundNode.index].note;
    this.setState({ content: '' });
    this.handleChange(data);
    this.requestDeleteNoteClose();
  }

  handleRequestClose() {
    this.setState({
      open: false,
      isEdit: false,
      openDialog: false
    });
  }

  handleNoteFieldChange(event) {
    this.setState({ content: event.target.value });
  }

  handleKeyDown(event) {
    if (this.props.onKeyDown) this.props.onKeyDown(event);

    if (keycode(event) === 'enter') {
      event.preventDefault();
      const { currentNode, content, addNoteOpen, editNoteOpen } = this.state;
      setTimeout(
        () =>
          this.handleEditNote(event, {
            currentNode,
            content,
            addNoteOpen,
            editNoteOpen
          }),
        0
      );
      this.requestEditNoteClose();
    }
  }

  getProcessorIds = (items) => {
    items.forEach((item) => {
      if (item.children && item.children.length > 0) {
        this.processorList.push(item.id);
        this.getProcessorIds(item.children);
      } else this.processorList.push(item.id);
    });
    return this.processorList;
  };

  getProcessorRole = (role) => {
    switch (role) {
      case 'ServiceProvider':
        return recordTranslations.serviceProvider;
      case 'Contractor':
        return recordTranslations.contractor;
      case 'ThirdPartyVendor':
        return recordTranslations.thirdPartyVendor;
      case 'None':
        return recordTranslations.none;
      default:
        return role;
    }
  };

  // Rest the variables and find the corresponding node
  resetAndFindNode(idToRemove, childNode) {
    nodeFound = false;
    nodeIndex = -1;
    return this.findNode(idToRemove, childNode);
  }

  // recursive to find and remove the node.
  findNode(idToRemove, childNode) {
    if (childNode && childNode.length > 0) {
      const index = childNode.findIndex((x) => x.id === idToRemove);
      if (index > -1) {
        nodeFound = true;
        nodeIndex = { childNode, index };
      } else {
        // get into each node under children and search.
        for (let i = 0; i < childNode.length; i += 1) {
          nodeIndex = this.resetAndFindNode(idToRemove, childNode[i].children);
          if (nodeFound) break;
        }
      }
    }
    return nodeIndex;
  }

  requestEditNoteClose() {
    this.setState({
      editNoteOpen: false,
      addNoteOpen: false
    });
  }

  requestViewNoteClose() {
    this.setState({
      viewNoteOpen: false
    });
  }

  requestDeleteNoteClose() {
    this.setState({
      deleteNoteOpen: false
    });
  }

  requestMenuClose() {
    this.setState({
      openActionMenu: false
    });
  }

  renderNode(node) {
    const { id, name, children, key, processorRole } = node.value || node;
    const note = getNoteValue(node);
    const noteId = `processor_note_${id}`;
    let nodeStyle = getContentStyle(node, defaultStyle.rowStyle);
    if (id === 'root_id' && children.length === 0) {
      nodeStyle = defaultStyle.rootStyle;
    }
    const noteStyle = this.props.isView
      ? { ...styles.rightIcon, marginBottom: '18px', marginLeft: -65 }
      : Object.assign({}, styles.rightIcon, {
        marginBottom: '18px',
        marginRight: '-10px',
        marginLeft: '10px'
      });
    const themeColor = this.props.theme.palette.primary.main;
    const editStyle = {
      fontSize: '20px',
      color: this.props.theme.palette.primary.main
    };
    const { userPermissions } = this.props;
    const { createEditOrganisation } = userPermissions.toJS();

    return (
      <div>
        <div
          className="item-row-icons"
          style={{
            ...nodeStyle,
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '0px'
          }}
          role="presentation"
          onMouseDown={
            this.props.isView
              ? (e) => {
                e.stopPropagation();
              }
              : undefined
          }
        >
          <ArrowTooltip title={name} style={{ width: '75%' }}>
            <div
              style={{
                ...rowStyle,
                width: `${this.state.showSidebar ? '55%' : '65%'}`
              }}
            >
              {id !== 'root_id' ? (
                <div style={{ display: 'flex', width: '100%' }}>
                  <div
                    style={{
                      width: processorRole
                        ? `${this.state.showSidebar ? '40%' : '60%'}`
                        : '100%',
                      ...styles.textWithEllipsis
                    }}
                  >
                    {key || getLegalEntityKey(node.value || node)}
                  </div>
                  {processorRole && this.props.showAssignRole && (
                    <div
                      style={{
                        color: themeColor,
                        ...styles.textWithEllipsis,
                        width: `${this.state.showSidebar ? '55%' : '40%'}`
                      }}
                    >
                      {this.getProcessorRole(processorRole)}
                    </div>
                  )}
                </div>
              ) : (
                <div>{getContent(name).data}</div>
              )}
            </div>
          </ArrowTooltip>
          {id !== 'root_id' && (
            <div
              role="presentation"
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              style={hierarchyActionIconsStyle}
            >
              {!this.props.isView && (
                <div className="delete-edit-icon">
                  {createEditOrganisation && (
                    <ArrowTooltip title={editTypeTranslations('processors')}>
                      <EditIcon
                        style={editStyle}
                        key="edit-icon"
                        onClick={() => {
                          this.handleEditClick(node);
                        }}
                      />
                    </ArrowTooltip>
                  )}
                    <ArrowTooltip title={removeTypeTranslations('processors')}>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={deleteStyle}
                        key="delete-icon"
                        onClick={() => {
                          this.handleRemove(node);
                        }}
                      />
                    </ArrowTooltip>
                </div>
              )}
              <div style={{ display: 'flex' }}>
                {this.props.showDPO && (
                  <div
                    className={
                      this.props.selectedDpoOrgId === id ? '' : 'dpo-icon'
                    }
                  >
                    <ArrowTooltip title={recordTranslations.selectDpo}>
                      <FontAwesomeIcon
                        style={{
                          marginBottom: '6px',
                          cursor: 'pointer',
                          marginLeft: '10px',
                          paddingTop: 3,
                          color:
                            this.props.selectedDpoOrgId === id
                              ? themeColor
                              : 'grey'
                        }}
                        icon={faUsers}
                        onClick={() => this.props.handleDpoClick(id)}
                      />
                    </ArrowTooltip>
                  </div>
                )}
                {note && (
                  <ArrowTooltip id={noteId} title={note}>
                    <IconButton
                      style={noteStyle}
                      onClick={() => this.handleViewNoteOpen(node)}
                    >
                      <ActionInfo style={{ fontSize: '22px' }} />
                    </IconButton>
                  </ArrowTooltip>
                )}
                {!this.props.isView && (
                  <MoreVertIcon
                    style={moreVertStyle}
                    onClick={(event) =>
                      this.handleOpenActionMenu(event, node, note)
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { userPermissions, hintText, showUsage } = this.props;
    const { createEditOrganisation } = userPermissions.toJS();
    const data = JSON.parse(JSON.stringify(this.state.data));
    const selectedItems = getSelectedItems(this.props.selectedItems);
    const { node, note, openActionMenu, anchorEl } = this.state;
    const viewAction = [
      <Button onClick={this.requestViewNoteClose}>
        {commonTranslations.close}
      </Button>
    ];

    const actions = [
      <Button
        classes={{
          root: 'button_confirm_dialog',
          label: 'buttonLabel_confirm_dialog'
        }}
        onClick={this.handleRequestClose}
      >
        {commonTranslations.Ok}
      </Button>
    ];
    return (
      <div>
        <DivWrapper autoHeight={true} autoHeightMax={400}>
          <UITree
            paddingLeft={20}
            tree={data}
            showRootNode={false}
            onChange={this.handleChange}
            isNodeCollapsed={this.isNodeCollapsed}
            renderNode={this.renderNode}
          />
        </DivWrapper>
        {!this.props.isView && (
          <LegalEntitySelector
            name="processors"
            multiValue={true}
            searchResults={this.props.searchResults}
            createNewMenuItem={true}
            selectFromListMenuItem={true}
            selectedItems={selectedItems}
            handleSelectedItem={this.handleSelectedItem}
            handleMultipleItems={this.handleMultipleItems}
            headerLabel={typeTranslations.processors}
            hintTextLabel={hintText}
            dialogHeaderLabel={
              <FormattedMessage
                id="ProcessingDetails.processorsDialogHeader"
                description="Processors Dialog list header"
                defaultMessage="Select processors"
              />
            }
            label={environmentHeaderTranslation('processors')}
            source="records"
            hideTags={this.props.isGlobal}
          />
        )}
        {this.state.open && (
          <OrganisationEntityDialog
            open={this.state.open}
            label={environmentHeaderTranslation('processors')}
            isEdit={this.state.isEdit}
            entityId={this.state.inputValue.id}
            inputValue={this.state.inputValue}
            onRequestClose={this.handleRequestClose}
            handleSelectedItem={this.handleEditItem}
            entityDetails={this.state.entityDetails}
            source="records"
            hideTags={this.props.isGlobal}
          />
        )}
        {!this.props.isView && openActionMenu && (
          <Menu
            open={openActionMenu}
            anchorEl={anchorEl}
            onClose={this.requestMenuClose}
          >
            {!note && (
              <MenuItem
                onClick={() => {
                  this.handleAddNoteOpen(node);
                }}
              >
                {commonTranslations.addNote}
              </MenuItem>
            )}
            {note && (
              <MenuItem
                onClick={() => {
                  this.handleEditNoteOpen(node);
                }}
              >
                {commonTranslations.editNote}
              </MenuItem>
            )}
            {note && (
              <MenuItem onClick={() => this.handleViewNoteOpen(node)}>
                {commonTranslations.viewNote}
              </MenuItem>
            )}
            {note && (
              <MenuItem
                onClick={() => {
                  this.handleDeleteNoteOpen(node);
                }}
              >
                {commonTranslations.removeNote}
              </MenuItem>
            )}
            {createEditOrganisation && showUsage && (
              <MenuItem
                onClick={() => {
                  this.handleUsageClick(node);
                }}
              >
                {commonTranslations.usageInRecords}
              </MenuItem>
            )}
          </Menu>
        )}
        {(this.state.editNoteOpen || this.state.addNoteOpen) && (
          <Note
            id="edit-note"
            content={this.state.addNoteOpen ? null : this.state.editNoteContent}
            onChange={this.handleNoteFieldChange}
            isEdit={true}
            headerLabel={
              this.state.addNoteOpen
                ? commonTranslations.addNoteHeader
                : commonTranslations.editNoteHeader
            }
            entityName={environmentHeaderTranslation('processors')}
            open={this.state.editNoteOpen || this.state.addNoteOpen}
            title={this.state.noteTitle}
            handleEditNote={this.handleEditNote}
            close={this.requestEditNoteClose}
          />
        )}
        {this.state.viewNoteOpen && (
          <Note
            id="view-note"
            content={this.state.viewNoteContent}
            isEdit={false}
            headerLabel={commonTranslations.viewNoteHeader}
            entityName={environmentHeaderTranslation('processors')}
            title={this.state.viewNoteDialogTitle}
            open={this.state.viewNoteOpen}
            saveActions={viewAction}
            close={this.requestViewNoteClose}
          />
        )}
        {this.state.deleteNoteOpen && (
          <CustomDialog
            id="delete_note_dialog"
            show={this.state.deleteNoteOpen}
            proceed={this.handleDeleteNote}
            cancel={this.requestDeleteNoteClose}
            content={recordTranslations.deleteNote}
          />
        )}
        {this.state.openDialog && (
          <CommonDialog
            id="delete-confirm-dialog"
            show={this.state.openDialog}
            onCancel={this.handleRequestClose}
            maxWidth="sm"
            fullWidth={true}
            buttonActions={actions}
            showCloseIcon={false}
            buttonPosition="right"
          >
            {errortranslations.masterDataItemInUse}
          </CommonDialog>
        )}
      </div>
    );
  }
}

ProcessorHeirarchy.propTypes = {
  showSidebar: PropTypes.bool,
  showAssignRole: PropTypes.bool,
  isView: PropTypes.bool,
  hintText: PropTypes.node,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  processors: PropTypes.shape({}).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  onKeyDown: PropTypes.func,
  updateProcessors: PropTypes.func,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  entityDetails: PropTypes.shape({}),
  searchResults: PropTypes.instanceOf(Immutable.List),
  data: PropTypes.shape({
    processors: PropTypes.shape({})
  }),
  handleUsageClick: PropTypes.func,
  handleDpoClick: PropTypes.func,
  showDPO: PropTypes.bool,
  selectedDpoOrgId: PropTypes.string,
  handleRemoveDpo: PropTypes.func,
  showUsage: PropTypes.bool,
  isGlobal: PropTypes.bool
};

ProcessorHeirarchy.defaultProps = {
  showSidebar: false,
  showAssignRole: false,
  isView: false,
  userPermissions: Immutable.Map(),
  hintText: null,
  selectedItems: null,
  onKeyDown: (e) => e,
  updateProcessors: (e) => e,
  showDPO: false,
  theme: {},
  entityDetails: {},
  searchResults: Immutable.List(),
  data: {},
  handleUsageClick: (e) => e,
  handleDpoClick: (e) => e,
  selectedDpoOrgId: '',
  handleRemoveDpo: (e) => e,
  showUsage: true,
  isGlobal: false
};

export default ProcessorHeirarchy;
