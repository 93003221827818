import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import { commonTranslations } from '@packages/utils/commontranslations';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import { addTranslations } from '@packages/utils/actionTranslations';

import TransferGroundList from '.';
import { legalGroundTranslations } from '../../../recordTranslations';

const TransferGroundListDialog = (props) => {
  const {
    handleSelectedItems,
    removeFilters,
    selectedItems,
    customStyle,
    isEdit,
    jurisdiction
  } = props;

  const [open, setOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const getFilterAppplied = (isFilterApplied) => {
    setIsFilterApplied(isFilterApplied);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const requestClose = () => {
    setOpen(false);
  };

  const handleOnSubmit = (selectedItems) => {
    if (handleSelectedItems) {
      handleSelectedItems(selectedItems);
    }
    requestClose();
  };

  const handleClick = () => {
    removeFilters();
  };

  return (
    <div style={{ paddingTop: '10px', ...customStyle }}>
      <Button
        id="button"
        classes={{
          root: 'button_black',
          label: 'buttonLabel_legalgrounds'
        }}
        onClick={handleOpen}
      >
        <FormattedMessage
          id="TransferGround.addBtn"
          description="Add transfer ground button label"
          defaultMessage="Add transfer grounds"
        />
      </Button>
      {open && (
        <CommonDialog
          id="Transfer-ground-dialog"
          show={open}
          onCancel={requestClose}
          maxWidth="md"
          fullWidth={true}
          title={addTranslations('addTransferGrounds')}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '50%' }}>
              {legalGroundTranslations.helpNote}
            </div>
            <div className="legal-ground-header">
              {isFilterApplied && (
                <Button
                  id="text-button"
                  variant="text"
                  onClick={() => handleClick()}
                >
                  {commonTranslations.removeFilters}
                </Button>
              )}
            </div>
          </div>
          <TransferGroundList
            id="transfer-ground"
            isEdit={isEdit}
            selectedItems={selectedItems}
            onSave={handleOnSubmit}
            onCancel={requestClose}
            getFilterAppplied={getFilterAppplied}
            jurisdiction={jurisdiction}
          />
        </CommonDialog>
      )}
    </div>
  );
};

TransferGroundListDialog.propTypes = {
  isEdit: PropTypes.bool,
  selectedItems: PropTypes.shape({}),
  handleSelectedItems: PropTypes.func,
  removeFilters: PropTypes.func,
  customStyle: PropTypes.shape({}),
  jurisdiction: PropTypes.string
};

TransferGroundListDialog.defaultProps = {
  isEdit: false,
  selectedItems: [],
  handleSelectedItems: (e) => e,
  removeFilters: (e) => e,
  customStyle: {},
  jurisdiction: 'EU'
};

export default TransferGroundListDialog;
