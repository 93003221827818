import documentTranslations from '@packages/features/privacy-record-detail/documents/utils/documentTranslations';
import React from 'react';

import { FormattedMessage } from 'react-intl';

export default (dataItemType) => {
  switch (dataItemType) {
    case 'createBreaches':
      return (
        <FormattedMessage
          id="CreateBreaches.header"
          description="New breach"
          defaultMessage="New breach"
        />
      );
    case 'createBreacheTemplate':
      return (
        <FormattedMessage
          id="createBreacheTemplate.header"
          defaultMessage="New breach template"
        />
      );
    case 'createAssessments':
      return (
        <FormattedMessage
          id="CreateAssessments.header"
          description="New assessment"
          defaultMessage="New assessment"
        />
      );
    case 'createAssessmentTemplate':
      return (
        <FormattedMessage
          id="createAssessmentTemplate.header"
          defaultMessage="New assessment template"
        />
      );
    case 'createProcessing':
      return (
        <FormattedMessage
          id="CreateProcessings.header"
          description="New processing"
          defaultMessage="New processing"
        />
      );
    case 'createProcessingTemplate':
      return (
        <FormattedMessage
          id="createProcessingTemplate.header"
          defaultMessage="New processing template"
        />
      );
    case 'merge':
      return (
        <FormattedMessage
          id="MasterData.merge"
          description="Merge"
          defaultMessage="Merge"
        />
      );
    case 'dataRecipientCategories':
      return (
        <FormattedMessage
          id="Records.mergedataRecipientCategory"
          description="Merge data recipient category"
          defaultMessage="Merge data recipient category"
        />
      );
    case 'dataSubjectCategories':
      return (
        <FormattedMessage
          id="Records.mergeDataSubjectCategory"
          description="Merge data subject category"
          defaultMessage="Merge data subject category"
        />
      );
    case 'processingCategories':
      return (
        <FormattedMessage
          id="Records.mergeProcessingCategory"
          description="Processing Category Merge label"
          defaultMessage="Merge processing category"
        />
      );
    case 'personalDataCategories':
      return (
        <FormattedMessage
          id="Records.mergePersonalDataCategory"
          description="Personal Data Categories Merge label"
          defaultMessage="Merge personal data category"
        />
      );
    case 'dataSourceCategories':
      return (
        <FormattedMessage
          id="Records.mergeDataSourceCategories"
          description="Data Source Categories Merge label"
          defaultMessage="Merge Data Source Categories"
        />
      );
    case 'personalDataItems':
      return (
        <FormattedMessage
          id="Records.mergePersonalDataItem"
          description="Personal Data Item Merge label"
          defaultMessage="Merge personal data item"
        />
      );
    case 'dataSources':
      return (
        <FormattedMessage
          id="Records.mergeDataSource"
          description="Merge data source"
          defaultMessage="Merge data source"
        />
      );
    case 'securityMeasuresDescription':
      return (
        <FormattedMessage
          id="Records.mergeSecurityMeasure"
          description="Merge security measure"
          defaultMessage="Merge security measure description"
        />
      );
    case 'retentionTermOffset':
      return (
        <FormattedMessage
          id="Records.mergeRetentionTerm"
          description="Merge retention term offset"
          defaultMessage="Merge retention term offset"
        />
      );
    case 'references':
      return (
        <FormattedMessage
          id="Records.mergeReferences"
          description="Merge references"
          defaultMessage="Merge references"
        />
      );
    case 'policies':
      return (
        <FormattedMessage
          id="Records.policies"
          description="Merge policies"
          defaultMessage="Merge policies"
        />
      );
    case 'accountabilitysecuritymeasures':
      return (
        <FormattedMessage
          id="Records.accountabilitysecuritymeasures"
          description="Merge accountability measure"
          defaultMessage="Merge accountability measure"
        />
      );
    case 'securityMeasures':
      return (
        <FormattedMessage
          id="Records.mergeSecurityMeasure"
          description="Merge security measure"
          defaultMessage="Merge security measure"
        />
      );
    case 'purposes':
      return (
        <FormattedMessage
          id="Records.mergePurposes"
          description="Merge Purposes"
          defaultMessage="Merge Purposes"
        />
      );
    case 'qualityControls':
      return (
        <FormattedMessage
          id="Records.mergeQualityControls"
          description="Merge Quality Controls"
          defaultMessage="Merge Quality Controls"
        />
      );
    case 'mitigatingMeasures':
      return (
        <FormattedMessage
          id="Records.mergeMitigatingMeasures"
          description="Merge Mitigating Measures"
          defaultMessage="Merge Mitigating Measures"
        />
      );
    case 'riskDetails':
      return (
        <FormattedMessage
          id="Records.mergeRiskDetails"
          description="Merge Risk Details"
          defaultMessage="Merge Risk Details"
        />
      );
    case 'dataSubjectRights':
      return (
        <FormattedMessage
          id="Records.mergeDataSubjectRights"
          description="Merge Data SubjectRights"
          defaultMessage="Merge Data Subject Rights"
        />
      );
    case 'threats':
      return (
        <FormattedMessage
          id="Records.mergeThreats"
          description="Merge Threats"
          defaultMessage="Merge Threats"
        />
      );
    case 'accountabilities':
      return (
        <FormattedMessage
          id="Records.mergeAccountabilities"
          description="Merge Accountabilities"
          defaultMessage="Merge Accountabilities"
        />
      );
    case 'impacts':
      return (
        <FormattedMessage
          id="Records.mergeImpacts"
          description="Merge Impacts"
          defaultMessage="Merge Impacts"
        />
      );
    case 'fairnessOfDecisions':
      return (
        <FormattedMessage
          id="Records.mergeFairnessOfDecisions"
          defaultMessage="Merge fairness of decisions"
        />
      );
    case 'documentRecordTypes':
      return (
        <FormattedMessage
          id="Records.mergeDocumentTypes"
          defaultMessage="Merge Document types"
        />
      );
    case 'specialCharacteristics':
      return (
        <FormattedMessage
          id="Records.mergeSpecialCharacteristics"
          defaultMessage="Merge special characteristics"
        />
      );
    case 'createDocument':
      return documentTranslations.NewDocumentRecord;
    case 'createDocumentTemplate':
      return (
        <FormattedMessage
          id="createDocumentTemplate.header"
          defaultMessage="New document template"
        />
      );
    default:
      return dataItemType;
  }
};

export const editTranslations = (key) => {
  switch (key) {
    case 'create-edit-document-record':
      return (
        <FormattedMessage
          id="Action.createEditDocumentRecord"
          defaultMessage="Create edit document records"
        />
      );
    case 'create-edit-assessment':
      return (
        <FormattedMessage
          id="Action.createEditAssessment"
          defaultMessage="Create edit assessment"
        />
      );
    case 'create-edit-vendor-forms':
      return (
        <FormattedMessage
          id="Action.createEditForms"
          defaultMessage="Create/edit vendor forms"
        />
      );
    case 'editProcessing':
      return (
        <FormattedMessage
          id="ProcessingDetails.editheader"
          defaultMessage="Edit Processing"
        />
      );
    case 'create-edit-processing':
      return (
        <FormattedMessage
          id="Action.createEditProcessing"
          defaultMessage="Create edit processing"
        />
      );
    case 'create-edit-breach':
      return (
        <FormattedMessage
          id="Action.createEditBreach"
          defaultMessage="Create edit breach"
        />
      );
    case 'edit':
      return <FormattedMessage id="Action.Edit" defaultMessage="Edit" />;
    case 'editAssessment':
      return (
        <FormattedMessage
          id="AssessmentDetails.editheader"
          description="Assessment Details Edit header"
          defaultMessage="Edit assessment"
        />
      );
    case 'editTenant':
      return (
        <FormattedMessage
          id="TenantList.editTenantContextMenu"
          defaultMessage="Edit tenant"
        />
      );
    case 'dataSubjectCategories':
      return (
        <FormattedMessage
          id="DataSubjectCategoryDialog.editheader"
          description="Edit data subject category Context Menu"
          defaultMessage="Edit data subject category"
        />
      );
    case 'controllers':
      return (
        <FormattedMessage
          id="Records.editController"
          description="Edit controller context menu"
          defaultMessage="Edit controller"
        />
      );
    case 'processors':
      return (
        <FormattedMessage
          id="Records.editProcessor"
          description="Edit processor context menu"
          defaultMessage="Edit processor"
        />
      );
    case 'executingEntities':
      return (
        <FormattedMessage
          id="Records.editExecutingEntity"
          description="Edit executing entity context menu"
          defaultMessage="Edit executing entity"
        />
      );
    case 'dataRecipients':
      return (
        <FormattedMessage
          id="Records.editDataRecipient"
          description="Edit data recipient context menu"
          defaultMessage="Edit data recipient"
        />
      );
    case 'processingCategories':
      return (
        <FormattedMessage
          id="ProcessingCategoryDialog.editProcessingCategory"
          description="Edit Processing Category"
          defaultMessage="Edit processing category"
        />
      );
    case 'references':
      return (
        <FormattedMessage
          id="ReferenceDialog.editreference"
          description="Edit reference"
          defaultMessage="Edit reference"
        />
      );
    case 'personalDataCategories':
      return (
        <FormattedMessage
          id="PersonalItemCategoryDialog.editheader"
          description="Edit personal data category context menu"
          defaultMessage="Edit personal data category"
        />
      );
    case 'personalDataItems':
      return (
        <FormattedMessage
          id="PersonalDataItemDialog.editheader"
          description="Personal Data Item Dialog Header"
          defaultMessage="Edit personal data item"
        />
      );
    case 'retentionTerms':
      return (
        <FormattedMessage
          id="Records.editRetentionTerm"
          description="Retention terms edit label"
          defaultMessage="Edit retention term"
        />
      );
    case 'dataSources':
      return (
        <FormattedMessage
          id="AddDataSourceDialog.editheader"
          description="Edit data source"
          defaultMessage="Edit data source"
        />
      );
    case 'securityMeasuresDescription':
      return (
        <FormattedMessage
          id="SecurityMeasureDialog.editsecurityMeasure"
          description="Edit security measure"
          defaultMessage="Edit security measure description"
        />
      );
    case 'qualityControls':
      return (
        <FormattedMessage
          id="QualityControlDialog.editQualityControl"
          description="Edit quality control"
          defaultMessage="Edit quality control"
        />
      );
    case 'purposesOfTransfer':
      return (
        <FormattedMessage
          id="PurposesOfTransfer.editHeader"
          description="Edit purpose of transfer"
          defaultMessage="Edit purpose of transfer"
        />
      );
    case 'purposes':
      return (
        <FormattedMessage
          id="Purposes.editHeader"
          description="Edit purposes"
          defaultMessage="Edit purpose"
        />
      );
    case 'mitigatingMeasures':
      return (
        <FormattedMessage
          id="MitigationMeasures.editmitigationMeasure"
          description="Edit mitigating measure"
          defaultMessage="Edit mitigating measure"
        />
      );
    case 'riskDetails':
      return (
        <FormattedMessage
          id="RiskDetail.editHeader"
          description="Edit risk detail"
          defaultMessage="Edit risk detail"
        />
      );
    case 'informationRights':
    case 'consentRights':
    case 'accessRights':
    case 'objectionRights':
    case 'deletionRights':
    case 'dataPortabilityRights':
      return (
        <FormattedMessage
          id="RightsDetail.editHeader"
          description="Edit right"
          defaultMessage="Edit right"
        />
      );
    case 'technicalSecurityMeasures':
    case 'organisationalSecurityMeasures':
    case 'securityMeasures':
      return (
        <FormattedMessage
          id="SecurityMeasuresDetail.editHeader"
          description="Edit Security Measure"
          defaultMessage="Edit security measure"
        />
      );
    case 'accountabilityMeasures':
      return (
        <FormattedMessage
          id="AccountabilityMeasuresDetail.editHeader"
          description="Edit accountability measure"
          defaultMessage="Edit accountability measure"
        />
      );
    case 'dataProtectionMeasures':
      return (
        <FormattedMessage
          id="DataProtectionDetail.editHeader"
          description="Edit data protection by design and by default measure"
          defaultMessage="Edit data protection by design and by default measure"
        />
      );
    case 'confidentialityThreats':
    case 'integrityThreats':
    case 'availabilityThreats':
      return (
        <FormattedMessage
          id="Threats.editHeader"
          description="Edit threat"
          defaultMessage="Edit threat"
        />
      );

    case 'confidentialityImpacts':
    case 'integrityImpacts':
    case 'availabilityImpacts':
      return (
        <FormattedMessage
          id="Impacts.editHeader"
          description="Edit impact"
          defaultMessage="Edit impact"
        />
      );
    case 'policies':
      return (
        <FormattedMessage
          id="Policies.editHeader"
          description="Edit policy"
          defaultMessage="Edit policy"
        />
      );
    case 'decisions':
      return (
        <FormattedMessage
          id="Decisions.editHeader"
          description="Edit decision"
          defaultMessage="Edit decision"
        />
      );
    case 'findings':
      return (
        <FormattedMessage
          id="Findings.editHeader"
          description="Edit finding"
          defaultMessage="Edit finding"
        />
      );
    case 'editNote':
      return (
        <FormattedMessage
          id="Common.editNote"
          description="Edit Note Context Menu"
          defaultMessage="Edit Note"
        />
      );
    case 'editNoteHeader':
      return (
        <FormattedMessage
          id="Common.editNoteHeader"
          description="Edit note for"
          defaultMessage="Edit note for"
        />
      );
    case 'tenant-administrator':
      return (
        <FormattedMessage
          id="Role.tenantAdministrator"
          description="Create/Edit tenants"
          defaultMessage="Create/Edit Tenants"
        />
      );
    case 'reporting':
      return (
        <FormattedMessage
          id="Role.reporting"
          description="Create/Edit reports"
          defaultMessage="Create/Edit reports"
        />
      );
    case 'upload-document':
      return (
        <FormattedMessage
          id="Role.manageDocument"
          description="Manage documents"
          defaultMessage="Manage Documents"
        />
      );
    case 'create-edit-privacy-record':
      return (
        <FormattedMessage
          id="Role.createEditPrivacyRecord"
          description="Create/Edit Privacy records"
          defaultMessage="Create/Edit Privacy records"
        />
      );
    case 'create-edit-user':
      return (
        <FormattedMessage
          id="Role.createEditUser"
          description="Create/Edit Users"
          defaultMessage="Create/Edit Users"
        />
      );
    case 'create-edit-organisation':
      return (
        <FormattedMessage
          id="Role.createEditOrganisation"
          description="Create/Edit Organisation"
          defaultMessage="Create/Edit Organisation"
        />
      );
    case 'create-edit-permissions':
      return (
        <FormattedMessage
          id="Role.createEditPermission"
          description="Create/Edit Permissions"
          defaultMessage="Create/Edit Permissions"
        />
      );
    case 'change-legal-entities':
      return (
        <FormattedMessage
          id="Role.editLegalEntities"
          description="Create/Edit Legal entities"
          defaultMessage="Create/Edit Controllers, Processors, Executing entities, Data recipients"
        />
      );
    case 'create-edit-data-sources':
      return (
        <FormattedMessage
          id="Role.editDatasources"
          description="Create/Edit Data sources"
          defaultMessage="Create/Edit Data sources"
        />
      );
    case 'create-edit-personal-data-item':
      return (
        <FormattedMessage
          id="Role.editPersonalDataItems"
          description="Create/Edit Personal data items"
          defaultMessage="Create/Edit ersonal data items"
        />
      );
    case 'create-edit-data-subject-categories':
      return (
        <FormattedMessage
          id="Role.editDataSubjectCategories"
          description="Create/Edit Data subject categories"
          defaultMessage="Create/Edit Data subject categories"
        />
      );
    case 'create-edit-processing-categories':
      return (
        <FormattedMessage
          id="Role.editProcessingCategories"
          description="Create/Edit Processing categories"
          defaultMessage="Create/Edit Processing categories"
        />
      );
    case 'create-edit-security-measures-description':
      return (
        <FormattedMessage
          id="Role.editSecurityMeasures"
          description="Create/Edit Security Measures description"
          defaultMessage="Create/Edit security measures description"
        />
      );
    case 'create-edit-personal-data-categories':
      return (
        <FormattedMessage
          id="Role.editPersonalDataCategories"
          description="Create/Edit Personal Data Categories"
          defaultMessage="Create/Edit personal data categories"
        />
      );
    case 'create-edit-reference':
      return (
        <FormattedMessage
          id="Role.reference"
          description="Create/Edit references"
          defaultMessage="Create/edit references"
        />
      );
    case 'create-edit-dpia':
      return (
        <FormattedMessage
          id="Role.dpiaMasterDataItems"
          description="Create/Edit  Dpia"
          defaultMessage="Create/edit dpia master data items"
        />
      );
    case 'create-edit-data-recipient-category':
      return (
        <FormattedMessage
          id="Role.dataRecipientCategory"
          description="Create/Edit  data recipient categories"
          defaultMessage="Create/Edit  data recipient categories"
        />
      );
    case 'editCustomHelpTexts':
      return (
        <FormattedMessage
          id="Common.editCustomHelpTexts"
          description="Edit custom help texts - "
          defaultMessage="Edit custom help texts - "
        />
      );
    case 'editRetentionTerm':
      return (
        <FormattedMessage
          id="Records.editRetentionTerm"
          defaultMessage="Edit retention term"
        />
      );
    case 'editRetentionOffset':
      return (
        <FormattedMessage
          id="RetentionOffset.editOffset"
          defaultMessage="Edit Retention Offset"
        />
      );
    case 'editProcessingCategory':
      return (
        <FormattedMessage
          id="ProcessingCategoryDialog.editProcessingCategory"
          defaultMessage="Edit processing category"
        />
      );
    case 'editPersonalDataItem':
      return (
        <FormattedMessage
          id="PersonalDataItemDialog.editheader"
          defaultMessage="Edit personal data item"
        />
      );
    case 'editPersonalItemCategory':
      return (
        <FormattedMessage
          id="PersonalItemCategoryDialog.editheader"
          defaultMessage="Edit personal data category"
        />
      );
    case 'editDataSubjectCategory':
      return (
        <FormattedMessage
          id="DataSubjectCategoryDialog.editheader"
          defaultMessage="Edit data subject category"
        />
      );
    case 'editDataSource':
      return (
        <FormattedMessage
          id="AddDataSourceDialog.editheader"
          defaultMessage="Edit data source"
        />
      );
    case 'editDataSourceCategory':
      return (
        <FormattedMessage
          id="AddDataSourceCategoryDialog.editheader"
          defaultMessage="Edit data source category"
        />
      );
    case 'editGroup':
      return (
        <FormattedMessage id="Link_Group.edit" defaultMessage="Edit group" />
      );
    case 'updateGroup':
      return (
        <FormattedMessage
          id="Link_Group.update"
          defaultMessage="Update group"
        />
      );
    case 'groupDataUpdate':
      return (
        <FormattedMessage
          id="Link_Group.groupDataUpdate"
          defaultMessage="Do you want to update the group?"
        />
      );
    case 'create-edit-data-source-category':
      return (
        <FormattedMessage
          id="Role.dateSourceCategories"
          defaultMessage="Create/edit data source categories"
        />
      );
    case 'editUser':
      return (
        <FormattedMessage
          id="OrganisationUserDetailDialog.editUser"
          defaultMessage="Edit user"
        />
      );
    case 'editDocumentRecord':
      return (
        <FormattedMessage
          id="DocumentRecord.editDocumentRecord"
          defaultMessage="Edit document"
        />
      );
    default:
      return key;
  }
};

export const addTranslations = (key) => {
  switch (key) {
    case 'addNote':
      return (
        <FormattedMessage
          id="Common.addNote"
          description="Add Note Context Menu"
          defaultMessage="Add Note"
        />
      );
    case 'addNoteHeader':
      return (
        <FormattedMessage
          id="Common.addNoteHeader"
          description="Add Note for"
          defaultMessage="Add Note for"
        />
      );
    case 'addnow':
      return (
        <FormattedMessage
          id="Common.addNow"
          description="Add now"
          defaultMessage="Add now"
        />
      );
    case 'adminEmail':
      return (
        <FormattedMessage
          id="TenantDetail.lastNameHint"
          description="Hint text for tenant last name"
          defaultMessage="Add admin e-mail address.."
        />
      );
    case 'addProcessingPurpose':
      return (
        <FormattedMessage
          id="ProcessingDetails.addlegitimatePurpose"
          description="Add purpose for the processing"
          defaultMessage="Add purpose for the processing"
        />
      );
    case 'addAssessmentPurpose':
      return (
        <FormattedMessage
          id="AssessmentDetails.addlegitimatePurpose"
          description="Add purpose for the assessment"
          defaultMessage="Add purpose for the assessment"
        />
      );
    case 'addTransferPurpose':
      return (
        <FormattedMessage
          id="AssessmentDetails.addlegitimateTransferPurpose"
          description="Add transfer purpose"
          defaultMessage="Add transfer purpose"
        />
      );
    case 'processinggrounds':
      return (
        <FormattedMessage
          id="Record.processinggrounds"
          description="Add processing grounds"
          defaultMessage="Add processing grounds"
        />
      );
    case 'addPermissionsOrgHintText':
      return (
        <FormattedMessage
          id="RecordDetails.addPermissionsOrgHintText"
          description="Hint text for adding permissions"
          defaultMessage="Add organisation"
        />
      );
    case 'processingGroundHintText':
      return (
        <FormattedMessage
          id="RecordDetails.processingGroundHintText"
          description="Hint text for processing grounds Selector"
          defaultMessage="Add a processing ground..."
        />
      );
    case 'personalDataItemsHintText':
      return (
        <FormattedMessage
          id="RecordDetails.personalDataItemsHintText"
          description="Hint text for personal data items Selector"
          defaultMessage="Add a personal data item..."
        />
      );
    case 'personalDataCategoriesHintText':
      return (
        <FormattedMessage
          id="RecordDetails.personalDataCategoriesHintText"
          description="Hint text for personal data category Selector"
          defaultMessage="Add a personal data category..."
        />
      );
    case 'dataSourcesHintText':
      return (
        <FormattedMessage
          id="RecordDetails.dataSourcesHintText"
          description="Hint text for data source Selector"
          defaultMessage="Add a data source..."
        />
      );
    case 'retentionTermHintText':
      return (
        <FormattedMessage
          id="RecordDetails.retentionTermsHintText"
          description="Hint text for retention term selector"
          defaultMessage="Add a retention term..."
        />
      );
    case 'dataSourceCategoriesHintText':
      return (
        <FormattedMessage
          id="RecordDetails.dataSourceCategoriesHintText"
          defaultMessage="Add a data source category"
        />
      );
    case 'dataRecipientsHintText':
      return (
        <FormattedMessage
          id="RecordDetails.dataRecipientsHintText"
          description="Hint text for data recipient Selector"
          defaultMessage="Add a data recipient..."
        />
      );
    case 'addAttachmentsHintText':
      return (
        <FormattedMessage
          id="RecordDetails.addAttachmentsHintText"
          description="Hint text for adding attachments"
          defaultMessage="Add an attachment"
        />
      );
    case 'executingEntityHintText':
      return (
        <FormattedMessage
          id="RecordDetails.executingEntityHintText"
          description="Hint text for executing entity Selector"
          defaultMessage="Add an executing entity..."
        />
      );
    case 'dpoHintText':
      return (
        <FormattedMessage
          id="RecordDetails.dpoHintText"
          description="Hint text for dpo Selector"
          defaultMessage="Add data protection officer"
        />
      );
    case 'transferGroundHintText':
      return (
        <FormattedMessage
          id="RecordDetails.transferGroundHintText"
          description="Hint text for Transfer Ground Selector"
          defaultMessage="Add a transfer ground..."
        />
      );
    case 'controllerHintText':
      return (
        <FormattedMessage
          id="RecordDetails.controllerHintText"
          description="Hint text for controller Selector"
          defaultMessage="Add a controller..."
        />
      );
    case 'processorHintText':
      return (
        <FormattedMessage
          id="ProcessingDetails.processorAddHintText"
          description="Hint text for processor Selector"
          defaultMessage="Add a processor..."
        />
      );
    case 'addDataSubjectCategoryHintText':
      return (
        <FormattedMessage
          id="RecordDetails.addDataSubjectCategoryHintText"
          description="Hint text for data Subject Categories Selector"
          defaultMessage="Add a data subject category..."
        />
      );
    case 'addSecurityMeasuresDescriptionHintText':
      return (
        <FormattedMessage
          id="RecordDetails.addSecurityMeasuresDescriptionHintText"
          description="Hint text for Security Measures Description Selector"
          defaultMessage="Add a measure"
        />
      );
    case 'addprocessing':
      return (
        <FormattedMessage
          id="Record.addprocessing"
          description="Add an existing processing"
          defaultMessage="Add an existing processing"
        />
      );
    case 'AddAssessment':
      return (
        <FormattedMessage
          id="Record.Addassessment"
          description="Add an existing assessment"
          defaultMessage="Add an existing assessment"
        />
      );
    case 'addPermissionsHintText':
      return (
        <FormattedMessage
          id="RecordDetails.addPermissionsHintText"
          description="Hint text for adding acl users"
          defaultMessage="Add user"
        />
      );
    case 'addReferences':
      return (
        <FormattedMessage
          id="RecordDetails.referenceHintText"
          description="Hint text for References"
          defaultMessage="Add a reference..."
        />
      );
    case 'addLinkedProcessing':
      return (
        <FormattedMessage
          id="RecordDetails.addLinkedProcessing"
          defaultMessage="Add an existing processing"
        />
      );
    case 'addLinkedTia':
      return (
        <FormattedMessage
          id="RecordDetails.addLinkedTia"
          defaultMessage="Add an existing transfer impact assessment"
        />
      );
    case 'addLinkedTIA':
      return (
        <FormattedMessage
          id="RecordDetails.addLinkedTIA"
          defaultMessage="Add an existing TIA"
        />
      );
    case 'addLinkedDPIA':
      return (
        <FormattedMessage
          id="RecordDetails.addLinkedDPIA"
          defaultMessage="Add an existing DPIA"
        />
      );
    case 'AddLinkedBreach':
      return (
        <FormattedMessage
          id="RecordDetails.AddLinkedBreach"
          defaultMessage="Add an existing breach"
        />
      );
    case 'addQualityControl':
      return (
        <FormattedMessage
          id="RecordDetails.qualityControlHintText"
          description="Hint text for Quality control"
          defaultMessage="Add a quality control..."
        />
      );
    case 'policySubtitle':
      return (
        <FormattedMessage
          id="Assessments.policySubtitle"
          description="Policy subtitle"
          defaultMessage="Add policies, codes of conduct and certification schemes"
        />
      );
    case 'dataProtectionSubtitle':
      return (
        <FormattedMessage
          id="Assessments.dataProtectionSubtitle"
          description="Data Protection subtitle"
          defaultMessage="Add data protection measures"
        />
      );
    case 'accountabilityMeasuresSubtitle':
      return (
        <FormattedMessage
          id="Assessments.accountabilityMeasuresSubtitle"
          description="Accountability Measures subtitle"
          defaultMessage="Add accountability measures"
        />
      );
    case 'supervisoryConsultationSubtitle':
      return (
        <FormattedMessage
          id="Assessments.supervisoryConsultationSubtitle"
          description="Supervisory authority subtitle"
          defaultMessage="Add supervisory authorities"
        />
      );
    case 'dataSubjectConsultationSubtitle':
      return (
        <FormattedMessage
          id="Assessments.dataSubjectConsultationSubtitle"
          description="Data subject consultation subtitle"
          defaultMessage="Add data subject consultation findings and data subject consultation decisions"
        />
      );
    case 'dpoConsultationSubtitle':
      return (
        <FormattedMessage
          id="Assessments.dpoConsultationSubtitle"
          defaultMessage="Add DPO consultation findings and DPO consultation decisions"
        />
      );
    case 'addFindingHint':
      return (
        <FormattedMessage
          id="Assessment.addFindingHint"
          description="Add finding..."
          defaultMessage="Add finding..."
        />
      );
    case 'addFinding':
      return (
        <FormattedMessage
          id="Assessment.addFinding"
          description="Add finding"
          defaultMessage="Add finding"
        />
      );
    case 'addmitigationMeasure':
      return (
        <FormattedMessage
          id="MitigationMeasuresDialog.addmitigationMeasure"
          description="Add mitigating measure"
          defaultMessage="Add mitigating measure"
        />
      );
    case 'addItem':
      return (
        <FormattedMessage
          id="AssessmentDetails.addItem.hintText"
          description="Add item"
          defaultMessage="Add item..."
        />
      );
    case 'addBreachGround':
      return (
        <FormattedMessage
          id="Breach.breachGroundHintText"
          description="Hint text for breach ground Selector"
          defaultMessage="Add breach grounds"
        />
      );
    case 'newTenant':
      return (
        <FormattedMessage
          id="TenantDetail.addheader"
          defaultMessage="New Tenant"
        />
      );
    case 'tenantNameHint':
      return (
        <FormattedMessage
          id="TenantDetail.nameHint"
          defaultMessage="Enter tenant name"
        />
      );
    case 'legalEntityNameHint':
      return (
        <FormattedMessage
          id="TenantDetail.legalEntityNameHint"
          defaultMessage="Enter legal entity name"
        />
      );
    case 'groupAdd':
      return (
        <FormattedMessage id="Group.addGroup" defaultMessage="Add group" />
      );
    case 'addTransferGrounds':
      return (
        <FormattedMessage
          id="TransferGroundDialog.header"
          defaultMessage="Add transfer grounds"
        />
      );
    case 'addReportName':
      return (
        <FormattedMessage
          id="SaveDialog.header"
          defaultMessage="Add Report name"
        />
      );
    case 'reportNameHint':
      return (
        <FormattedMessage
          id="ReportName.nameHint"
          defaultMessage="Add a name"
        />
      );

    case 'passwordHint':
      return (
        <FormattedMessage
          id="UserDetail.passwordHint"
          defaultMessage="Enter your password"
        />
      );
    case 'addRetentionTerm':
      return (
        <FormattedMessage
          id="RetentionTermDialog.addRetentionTerm"
          defaultMessage="Add retention term"
        />
      );
    case 'addRetentionOffset':
      return (
        <FormattedMessage
          id="RetentionOffsetDialog.addRetentionOffset"
          defaultMessage="Add Retention Offset"
        />
      );
    case 'addProcessingCategory':
      return (
        <FormattedMessage
          id="ProcessingCategoryDialog.addProcessingCategory"
          defaultMessage="Add processing category"
        />
      );
    case 'addPersonalDataItem':
      return (
        <FormattedMessage
          id="PersonalDataItemDialog.addheader"
          defaultMessage="Add personal data item"
        />
      );
    case 'addPersonalItemCategory':
      return (
        <FormattedMessage
          id="PersonalItemCategoryDialog.header"
          defaultMessage="Add personal item category"
        />
      );
    case 'addDataSubjectCategory':
      return (
        <FormattedMessage
          id="DataSubjectCategoryDialog.addheader"
          defaultMessage="Add data subject category"
        />
      );
    case 'addDataSource':
      return (
        <FormattedMessage
          id="AddDataSourceDialog.addheader"
          defaultMessage="Add data source"
        />
      );
    case 'addDataSourceCategory':
      return (
        <FormattedMessage
          id="AddDataSourceCategoryDialog.addheader"
          defaultMessage="Add data source category"
        />
      );
    case 'addGroup':
      return (
        <FormattedMessage
          id="Link_Group.create"
          defaultMessage="Create group"
        />
      );
    default:
      return key;
  }
};

export const viewTranslations = (key) => {
  switch (key) {
    case 'viewNote':
      return (
        <FormattedMessage
          id="Common.viewNote"
          description="View Note Context Menu"
          defaultMessage="View Note"
        />
      );
    case 'view':
      return (
        <FormattedMessage
          id="PrivacyRecordList.viewRecordContextMenu"
          defaultMessage="View Record"
        />
      );
    case 'viewNoteHeader':
      return (
        <FormattedMessage
          id="Common.viewNoteHeader"
          description="View note for"
          defaultMessage="View note for"
        />
      );
    case 'view-document':
      return (
        <FormattedMessage
          id="Role.viewDocument"
          description="View documents"
          defaultMessage="View Documents"
        />
      );
    case 'view-document-record':
      return (
        <FormattedMessage
          id="Role.viewDocumentRecord"
          description="View document records"
          defaultMessage="View document records"
        />
      );
    case 'view-privacy-records':
      return (
        <FormattedMessage
          id="Role.viewPrivacyRecord"
          description="View Privacy records"
          defaultMessage="View Privacy records"
        />
      );
    case 'view-user':
      return (
        <FormattedMessage
          id="Role.viewUser"
          description="View Users"
          defaultMessage="View Users"
        />
      );
    case 'view-organisation':
      return (
        <FormattedMessage
          id="Role.viewOrganisation"
          description="View Organisation"
          defaultMessage="View Organisation"
        />
      );
    case 'view-permissions':
      return (
        <FormattedMessage
          id="Role.viewPermission"
          description="View Permissions"
          defaultMessage="View Permissions"
        />
      );
    case 'view-dpia':
      return (
        <FormattedMessage
          id="Role.viewDpia"
          description="View Dpia"
          defaultMessage="View dpia"
        />
      );
    case 'view-tenant':
      return (
        <FormattedMessage id="tenantView.header" defaultMessage="View tenant" />
      );
    default:
      return key;
  }
};

export const removeTranslations = (key) => {
  switch (key) {
    case 'delete':
      return (
        <FormattedMessage
          id="Action.Delete"
          description="Delete action"
          defaultMessage="Delete"
        />
      );
    case 'remove':
      return (
        <FormattedMessage
          id="Action.remove"
          description="Remove action"
          defaultMessage="Remove"
        />
      );
    case 'documentRemove':
      return (
        <FormattedMessage
          id="Action.documentRemove"
          defaultMessage="Remove document"
        />
      );
    case 'removeNote':
      return (
        <FormattedMessage
          id="Common.removeNote"
          description="Remove Note Context Menu"
          defaultMessage="Remove Note"
        />
      );
    case 'removeFilters':
      return (
        <FormattedMessage
          id="PrivacyRecords.removeFilters"
          description="Remove filters text"
          defaultMessage="Remove filters"
        />
      );
    case 'deleteReport':
      return (
        <FormattedMessage id="Reports.delete" defaultMessage="Delete report" />
      );
    case 'deleteMessage':
      return (
        <FormattedMessage
          id="MessageCenter.deleteMessage"
          defaultMessage="Delete message"
        />
      );
    case 'deleteMessages':
      return (
        <FormattedMessage
          id="MessageCenter.deleteMessages"
          defaultMessage="Delete messages"
        />
      );
    case 'dataRecipientCategoryDelete':
      return (
        <FormattedMessage
          id="EventCode.dataRecipientCategory_delete"
          defaultMessage="Delete data recipient category"
        />
      );
    case 'processingCategoryBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.processingCategory_bulk_delete"
          defaultMessage="Bulk deletion of processing categories"
        />
      );
    case 'dataSourceBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.dataSource_bulk_delete"
          defaultMessage="Bulk deletion of data sources"
        />
      );
    case 'dataSubjectCategoryBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.dataSubjectCategory_bulk_delete"
          defaultMessage="Bulk deletion of data subject categories"
        />
      );
    case 'personalDataCategoryBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.personalDataCategory_bulk_delete"
          defaultMessage="Bulk deletion of personal data categories"
        />
      );
    case 'personalDataItemBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.personalDataItem_bulk_delete"
          defaultMessage="Bulk deletion of personal data items"
        />
      );
    case 'securityMeasuresDescriptionBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.securityMeasuresDescription_bulk_delete"
          defaultMessage="Bulk deletion of security measures descriptions"
        />
      );
    case 'referenceBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.reference_bulk_delete"
          defaultMessage="Bulk deletion of references"
        />
      );
    case 'retentionTermOffsetBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.retentionTermOffset_bulk_delete"
          description="Bulk deletion of retention term offset"
          defaultMessage="Bulk deletion of retention term offset"
        />
      );
    case 'rightsBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.rights_bulk_delete"
          defaultMessage="Bulk deletion of rights"
        />
      );
    case 'riskDetailsBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.riskDetails_bulk_delete"
          defaultMessage="Bulk deletion of risk details"
        />
      );
    case 'purposeBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.purpose_bulk_delete"
          defaultMessage="Bulk deletion of purpose"
        />
      );
    case 'qualityControlBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.qualityControl_bulk_delete"
          description="Bulk deletion of quality controls"
          defaultMessage="Bulk deletion of quality controls"
        />
      );
    case 'mitigatingMeasureBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.mitigatingMeasure_bulk_delete"
          description="Bulk deletion of mitigating measures"
          defaultMessage="Bulk deletion of mitigating measures"
        />
      );
    case 'threatBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.threat_bulk_delete"
          description="Bulk deletion of threats"
          defaultMessage="Bulk deletion of threats"
        />
      );
    case 'impactBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.impact_bulk_delete"
          description="Bulk deletion of impacts"
          defaultMessage="Bulk deletion of impacts"
        />
      );
    case 'policyBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.policy_bulk_delete"
          description="Bulk deletion of policies"
          defaultMessage="Bulk deletion of policies"
        />
      );
    case 'accountabilityBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.accountability_bulk_delete"
          description="Bulk deletion of accountabilities"
          defaultMessage="Bulk deletion of accountabilities"
        />
      );
    case 'tagBulkDelete':
      return (
        <FormattedMessage
          id="EventCode.tag_bulk_delete"
          description="Bulk deletion of tags"
          defaultMessage="Bulk deletion of tags"
        />
      );
    case 'tagDelete':
      return (
        <FormattedMessage
          id="EventCode.tag_delete"
          defaultMessage="Delete tag"
        />
      );
    case 'taskDelete':
      return (
        <FormattedMessage id="Tasks.delete" defaultMessage="Delete task" />
      );
    case 'tenantDelete':
      return (
        <FormattedMessage
          id="EventCode.tenant_delete"
          defaultMessage="Delete tenant"
        />
      );
    case 'confirmChangeLanguage':
      return (
        <FormattedMessage
          id="Confirm.changeTenantLanguage"
          defaultMessage="Changing the language impact customization done to the tenant, are you sure want to continue?"
        />
      );
    case 'userDelete':
      return (
        <FormattedMessage
          id="EventCode.user_delete"
          defaultMessage="Delete user"
        />
      );
    case 'organisationDelete':
      return (
        <FormattedMessage
          id="EventCode.organisation_delete"
          defaultMessage="Delete organisation"
        />
      );
    case 'assessmentDelete':
      return (
        <FormattedMessage
          id="EventCode.assessment_delete"
          defaultMessage="Delete assessment"
        />
      );
    case 'breachDelete':
      return (
        <FormattedMessage
          id="EventCode.breach_delete"
          description="Delete breach"
          defaultMessage="Delete breach"
        />
      );
    case 'processingDelete':
      return (
        <FormattedMessage
          id="EventCode.processing_delete"
          description="Delete processing"
          defaultMessage="Delete processing"
        />
      );
    case 'templateDelete':
      return (
        <FormattedMessage
          id="EventCode.template_delete"
          description="Delete template"
          defaultMessage="Delete template"
        />
      );
    case 'linkDelete':
      return (
        <FormattedMessage
          id="EventCode.link_delete"
          description="Delete link"
          defaultMessage="Delete link"
        />
      );
    case 'processingCategoryDelete':
      return (
        <FormattedMessage
          id="EventCode.processingCategory_delete"
          defaultMessage="Delete processing category"
        />
      );
    case 'referencesDelete':
      return (
        <FormattedMessage
          id="EventCode.reference_delete"
          defaultMessage="Delete reference"
        />
      );
    case 'dataSourceDelete':
      return (
        <FormattedMessage
          id="EventCode.dataSource_delete"
          defaultMessage="Delete data source"
        />
      );
    case 'personalDataCategoryDelete':
      return (
        <FormattedMessage
          id="EventCode.personalDataCategory_delete"
          defaultMessage="Delete personal data category"
        />
      );
    case 'personalDataItemDelete':
      return (
        <FormattedMessage
          id="EventCode.personalDataItem_delete"
          defaultMessage="Delete personal data item"
        />
      );
    case 'dataSubjectCategoryDelete':
      return (
        <FormattedMessage
          id="EventCode.dataSubjectCategory_delete"
          defaultMessage="Delete data subject category"
        />
      );
    case 'securityMeasuresDescriptionDelete':
      return (
        <FormattedMessage
          id="EventCode.securityMeasuresDescription_delete"
          defaultMessage="Delete security measure description"
        />
      );
    case 'securityMeasuresDelete':
      return (
        <FormattedMessage
          id="EventCode.securityMeasures_delete"
          defaultMessage="Delete security measure"
        />
      );
    case 'technicalSecurityMeasuresDelete':
      return (
        <FormattedMessage
          id="EventCode.technicalSecurityMeasures_delete"
          defaultMessage="Delete technical security measure"
        />
      );
    case 'organisationalSecurityMeasuresDelete':
      return (
        <FormattedMessage
          id="EventCode.organisationalSecurityMeasures_delete"
          defaultMessage="Delete organisational security measure"
        />
      );
    case 'transferPurposeDataDelete':
      return (
        <FormattedMessage
          id="EventCode.transferPurposeData_delete"
          defaultMessage="Delete transfer prupose"
        />
      );
    case 'reportSearchDelete':
      return (
        <FormattedMessage
          id="EventCode.reportSearch_delete"
          defaultMessage="Delete report search"
        />
      );
    case 'documentDelete':
      return (
        <FormattedMessage
          id="EventCode.document_delete"
          defaultMessage="Delete document"
        />
      );
    case 'retentionTermOffsetDelete':
      return (
        <FormattedMessage
          id="EventCode.retentionTermOffset_delete"
          defaultMessage="Delete retention term offset"
        />
      );
    case 'partnerDelete':
      return (
        <FormattedMessage
          id="EventCode.partner_delete"
          defaultMessage="Delete partner"
        />
      );
    case 'dataSubjectRightsDelete':
      return (
        <FormattedMessage
          id="EventCode.rights_delete"
          defaultMessage="Delete right"
        />
      );
    case 'rightsDelete':
      return (
        <FormattedMessage
          id="EventCode.rights_delete"
          defaultMessage="Delete right"
        />
      );
    case 'datasubjectrightsDelete':
      return (
        <FormattedMessage
          id="EventCode.rights_delete"
          defaultMessage="Delete right"
        />
      );
    case 'riskDetailsDelete':
      return (
        <FormattedMessage
          id="EventCode.riskDetails_delete"
          defaultMessage="Delete risk detail"
        />
      );
    case 'purposeDelete':
    case 'purposesDelete':
      return (
        <FormattedMessage
          id="EventCode.purpose_delete"
          defaultMessage="Delete purpose"
        />
      );
    case 'qualityControlsDelete':
    case 'qualityControlDelete':
      return (
        <FormattedMessage
          id="EventCode.qualityControl_delete"
          description="Delete quality control"
          defaultMessage="Delete quality control"
        />
      );
    case 'mitigatingMeasuresDelete':
    case 'mitigatingMeasureDelete':
      return (
        <FormattedMessage
          id="EventCode.mitigatingMeasure_delete"
          defaultMessage="Delete mitigating measure"
        />
      );
    case 'threatsDelete':
      return (
        <FormattedMessage
          id="EventCode.threat_delete"
          defaultMessage="Delete threat"
        />
      );
    case 'threatDelete':
      return (
        <FormattedMessage
          id="EventCode.threat_delete"
          defaultMessage="Delete threat"
        />
      );
    case 'impactsDelete':
    case 'impactDelete':
      return (
        <FormattedMessage
          id="EventCode.impact_delete"
          defaultMessage="Delete impact"
        />
      );
    case 'policiesDelete':
    case 'policyDelete':
      return (
        <FormattedMessage
          id="EventCode.policy_delete"
          description="Delete policy"
          defaultMessage="Delete policy"
        />
      );
    case 'personalDataItemsDelete':
      return (
        <FormattedMessage
          id="EventCode.personalDataItems_delete"
          defaultMessage="Delete data minimisation"
        />
      );
    case 'dataProtectionMeasuresDelete':
      return (
        <FormattedMessage
          id="EventCode.dataProtectionMeasures_delete"
          defaultMessage="Delete data protection by design and by default measure"
        />
      );
    case 'accountabilityMeasuresDelete':
      return (
        <FormattedMessage
          id="EventCode.accountabilityMeasures_delete"
          defaultMessage="Delete accountability measure"
        />
      );
    case 'findingsDelete':
      return (
        <FormattedMessage
          id="EventCode.findings_delete"
          defaultMessage="Delete finding"
        />
      );
    case 'decisionsDelete':
      return (
        <FormattedMessage
          id="EventCode.decisions_delete"
          defaultMessage="Delete decision"
        />
      );
    case 'accountabilityDelete':
      return (
        <FormattedMessage
          id="EventCode.accountability_delete"
          defaultMessage="Delete accountability"
        />
      );
    case 'accountabilitiesDelete':
      return (
        <FormattedMessage
          id="EventCode.accountability_delete"
          defaultMessage="Delete accountability"
        />
      );
    case 'deleteNotifications':
      return (
        <FormattedMessage
          id="MessageCenter.deleteNotifications"
          defaultMessage="Delete notifications"
        />
      );
    case 'deleteNotification':
      return (
        <FormattedMessage
          id="MessageCenter.deleteNotification"
          defaultMessage="Delete notification"
        />
      );
    case 'bulkTaskDeletion':
      return (
        <FormattedMessage
          id="Task.bulkTaskDeletion"
          defaultMessage="Delete tasks"
        />
      );
    case 'removeTenant':
      return (
        <FormattedMessage
          id="TenantList.deleteTenantContextMenu"
          defaultMessage="Delete tenant"
        />
      );
    case 'tenantDeleteHeader':
      return (
        <FormattedMessage
          id="tenantDelete.header"
          defaultMessage="Are you sure want to delete this tenant?"
        />
      );
    case 'confirmChangeModule':
      return (
        <FormattedMessage
          id="changeModule"
          defaultMessage="Are you sure that you want to change the module?"
        />
      );
    case 'removeMasterDataItems':
      return (
        <FormattedMessage
          id="records.removeMasterDataItems"
          defaultMessage="Would you like to remove the individual data items that belongs to this group from the record?"
        />
      );
    case 'removeGroupData':
      return (
        <FormattedMessage
          id="records.removeGroupData"
          defaultMessage="Do you want to delete the group?"
        />
      );
    case 'reject':
      return <FormattedMessage id="Common.reject" defaultMessage="Reject" />;
    default:
      return key;
  }
};

export const saveTranslations = (key) => {
  switch (key) {
    case 'saveAsTemplate':
      return (
        <FormattedMessage
          id="ProcessingDetails.saveAsTemplateButton"
          defaultMessage="Save as template"
        />
      );
    case 'saveModules':
      return (
        <FormattedMessage
          id="PricingPlan.saveButton"
          description="Save Modules"
          defaultMessage="Save Modules"
        />
      );
    case 'saveNote':
      return (
        <FormattedMessage
          id="Common.saveNote"
          description="Save Note"
          defaultMessage="Save note"
        />
      );
    case 'savechanges':
      return (
        <FormattedMessage
          id="Common.savechanges"
          description="Save changes"
          defaultMessage="Save changes"
        />
      );
    case 'saveAndClose':
      return (
        <FormattedMessage
          id="Common.saveAndClose"
          description="Save and Close"
          defaultMessage="Save and close"
        />
      );
    case 'saveAndCreate':
      return (
        <FormattedMessage
          id="Common.saveAndCreateAnother"
          description="Save and create another"
          defaultMessage="Save and create another"
        />
      );
    case 'savesettings':
      return (
        <FormattedMessage
          id="Common.savesettings"
          description="Save settings"
          defaultMessage="Save settings"
        />
      );
    case 'saveTenant':
      return (
        <FormattedMessage
          id="TenantDetail.saveButton"
          defaultMessage="Save tenant"
        />
      );
    case 'saveAndRegenerate':
      return (
        <FormattedMessage
          id="Tokens.saveAndRegenerate"
          defaultMessage="Save and regenerate"
        />
      );
    case 'save':
      return <FormattedMessage id="DSR.Save" defaultMessage="Save" />;
    case 'saveAndSubmit':
      return (
        <FormattedMessage
          id="common.saveAndSubmit"
          defaultMessage="Save and submit"
        />
      );
    default:
      return key;
  }
};

export const selectTranslations = (key) => {
  switch (key) {
    case 'selectCountry':
      return (
        <FormattedMessage
          id="Common.selectCountry"
          description="Select a country"
          defaultMessage="Select a country"
        />
      );
    case 'selectTemplate':
      return (
        <FormattedMessage
          id="Common.selectTemplate"
          defaultMessage="Select framework templates"
        />
      );
    case 'selectAll':
      return (
        <FormattedMessage
          id="Common.selectAll"
          description="Label for select all"
          defaultMessage="Select all"
        />
      );
    case 'startDateHint':
      return (
        <FormattedMessage
          id="TenantDetail.startDateHint"
          description="Hint text for tenant startDate"
          defaultMessage="Select start date..."
        />
      );
    case 'endDateHint':
      return (
        <FormattedMessage
          id="TenantDetail.endDateHint"
          description="Hint text for tenant End date"
          defaultMessage="Select end date..."
        />
      );
    case 'selectControllers':
      return (
        <FormattedMessage
          id="RecordDetails.controllersDialogHeader"
          description="Controller Dialog list header"
          defaultMessage="Select controllers"
        />
      );
    case 'executingEntitiesDialogHeader':
      return (
        <FormattedMessage
          id="RecordDetails.executingEntitiesDialogHeader"
          description="Executing entities Dialog list header"
          defaultMessage="Select executing entities"
        />
      );
    case 'dataRecipientsDialogHeader':
      return (
        <FormattedMessage
          id="RecordDetails.dataRecipientsDialogHeader"
          description="Data Recipients Dialog list header"
          defaultMessage="Select data recipients"
        />
      );
    case 'attachmentsDialogHeader':
      return (
        <FormattedMessage
          id="RecordDetails.attachmentsDialogHeader"
          description="Attachments Dialog list header"
          defaultMessage="Select Documents"
        />
      );
    case 'permissionsDialogHeader':
      return (
        <FormattedMessage
          id="RecordDetails.permissionsDialogHeader"
          description="Permissions Dialog list header"
          defaultMessage="Select Users"
        />
      );
    case 'orgDialogHeader':
      return (
        <FormattedMessage
          id="Record.orgDialogHeader"
          description="Organisation Dialog list header"
          defaultMessage="Select organisation entities"
        />
      );
    case 'optionalChaptersSubtitle':
      return (
        <FormattedMessage
          id="Assessments.optionalChaptersSubtitle"
          description="Assessment optional chapters subtitle"
          defaultMessage="Select optional chapters"
        />
      );
    case 'selectSupervisoryAuthorities':
      return (
        <FormattedMessage
          id="Breach.selectSupervisoryAuthorities"
          description="Select supervisory authorities"
          defaultMessage="Select supervisory authorities"
        />
      );
    case 'selectDpo':
      return (
        <FormattedMessage
          id="Records.selectDPO"
          description="Select as dpo"
          defaultMessage="Select as DPO"
        />
      );
    case 'removeDpo':
      return (
        <FormattedMessage
          id="Records.removeDPO"
          description="Remove dpo"
          defaultMessage="Remove DPO"
        />
      );
    case 'selectPartnerHint':
      return (
        <FormattedMessage
          id="TenantDetail.PartnerHint"
          defaultMessage="Select a partner to associate"
        />
      );
    case 'selectGroups':
      return (
        <FormattedMessage
          id="Groups.select"
          defaultMessage="Select groups to add"
        />
      );
    case 'chooseMergeItem':
      return (
        <FormattedMessage
          id="MasterData.mergeValidationError"
          defaultMessage="Choose an item to merge to"
        />
      );
    case 'select':
      return <FormattedMessage id="Dashboard.select" defaultMessage="Select" />;
    default:
      return key;
  }
};

export const navigationTranslations = (key) => {
  switch (key) {
    case 'goBack':
      return (
        <FormattedMessage
          id="Common.goBack"
          description="Go to the previous page"
          defaultMessage="Go Back"
        />
      );
    default:
      return key;
  }
};
