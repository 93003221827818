import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import { replace as replaceRouter } from 'connected-react-router';
import { push } from 'react-router-redux';
import {
  getParameterValuesFromHash,
  getQueryStrings
} from '@packages/utils/query-parameters';
import {
  getErrorMessage,
  handleServiceDown,
  setLastActivityTime
} from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { recordTranslations } from '@packages/utils/commontranslations';
import { getRecordDataWithUpdatedCustomFieldValues } from '@packages/features/privacy-records/record-utils';
import { isRecordEditable, getRecordData } from '../saga-utils';
import {
  addKeyToRetentionTerm,
  getModifiedRecordData,
  getUserOrg,
  transformCopyRecordData
} from '../common-utils';
import {
  transformRequestData,
  transformResponseData,
  getUpdatedProperty,
  validateLinkItems
} from './utils/processingUtils';

function* getDefaultProcessingData() {
  const user = yield select((state) => state.user);
  const aclOrgs = getUserOrg(user);
  return {
    executingEntities: [],
    controllers: [],
    aclUsers: [],
    aclOrgs: aclOrgs.length > 1 ? [] : aclOrgs,
    processors: [],
    dataRecipients: [],
    dataRecipientCategories: [],
    dataSubjectCategories: [],
    processingCategories: [],
    personalDataItems: [],
    attachments: [],
    dataSources: [],
    processingGrounds: [],
    transferGrounds: [],
    status: 'record_status_draft',
    isExecuteAccess: true,
    internationalTransfer: false,
    links: [],
    retentionTerms: [],
    personalDataCategories: [],
    references: [],
    purpose: [],
    purposesOfTransfer: [],
    dataProtection: {},
    numOfDataSubjects: -1,
    dpo: [],
    description: '',
    text: {},
    htmlText: {},
    pickList: {},
    stakeholder: {},
    checkbox: {},
    picklist: {},
    scoredPicklist: {}
  };
}

const getProcessingDetailFetchUrl = (
  isPublic,
  registryId,
  recordId,
  isTemplateMode,
  isPreviewMode,
  libraryTenantId,
  isFromTemplate
) => {
  let url = '/v1/records';
  if (isPublic)
    return `/v1/public/${registryId}/records/processings/${recordId}`;
  if (isTemplateMode) url += '/templates';
  if (!(isTemplateMode && isFromTemplate)) url += '/processings';
  url += `/${recordId}`;
  if (isPreviewMode) url += `/preview/${libraryTenantId}`;
  return url;
};

export function* initializeProcessingDetail(action) {
  const { isTemplateMode, isPreviewMode, libraryTenantId } = action;
  const queryParams = getQueryStrings();
  const { isFromTemplate, isPreview } = queryParams;
  const URL = window.location;
  const isPublic = URL.pathname.includes('public');
  const { recordId, registryId } = isPublic
    ? getParameterValuesFromHash('/:registryId/processing/:recordId/view')
    : getParameterValuesFromHash('/processing/:recordId/:mode');

  yield put({ type: 'PROCESSING:DETAIL:FETCH', recordId });

  try {
    const response = yield registry
      .get('request')
      .get(
        getProcessingDetailFetchUrl(
          isPublic,
          registryId,
          recordId,
          isTemplateMode,
          isPreviewMode,
          libraryTenantId,
          isFromTemplate
        ),
        null,
        {},
        !isPublic || isPreview === 'true'
      );

    switch (response.status) {
      case 200:
        {
          const recordDetail = response.body;
          const userState = yield select((state) => state.user);
          const currentUser = userState ? userState.get('profile') : null;
          const isEditable = yield isRecordEditable(recordDetail, currentUser);
          const store = registry.get('store');
          const responseData = Object.assign(
            {},
            yield getDefaultProcessingData(),
            recordDetail
          );
          const data = yield transformResponseData(
            responseData,
            isFromTemplate,
            currentUser,
            action.formatMessage
          );

          yield put({
            type: 'PROCESSING:DETAIL:FETCH:SUCCESS',
            isEditable,
            recordName: data.name,
            data
          });

          if (!isPublic) {
            if (recordDetail.status === 'record_status_requested') {
              store.dispatch(
                replaceRouter(`/processing/${recordDetail.id}/view`)
              );
              yield put({
                type: 'PROCESSING:EDIT:RESTRICTED',
                isEditRestricted: true
              });
            }

            const { layoutId } = response.body;
            const { locale, tenantLocale } = action;

            yield put({
              type: 'RECORD_LAYOUT:FETCH_INIT',
              recordType: 'PROCESSING',
              layoutId,
              locale,
              tenantLocale,
              libraryTenantId,
              isPreviewMode
            });
          }
        }
        break;

      case 404:
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'PROCESSING:DETAIL:FETCH:FAIL', error });

        const currentState = yield select((state) => state.processing);
        const store = registry.get('store');
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        if (!isPreviewMode)
          store.dispatch(replaceRouter(currentState.get('prevLocation')));

        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    const error = getErrorMessage(err);
    yield put({ type: 'PROCESSING:DETAIL:FETCH:FAIL', error });
  }
}

export function* upsertProcessingDetail(action) {
  const {
    templateId,
    copyOfRecordId,
    data,
    createFromEdit,
    isTemplateMode,
    isVendor = false,
    templateFromRecord,
    refetchRecords,
    isGlobal
  } = action;

  yield put({ type: 'LOADER:TOGGLE', toggle: true });

  const request = registry.get('request');
  try {
    // If processing is a copy of an existing processing, then get that processing data.
    // If processing is created from template, get template data.
    const defaultReqData =
      copyOfRecordId || templateId
        ? yield getRecordData({ copyOfRecordId, templateId }, 'processings')
        : yield getDefaultProcessingData();
    const modifiedData = getModifiedRecordData(defaultReqData, data);

    const userState = yield select((state) => state.user);
    const aclOrgs = getUserOrg(userState);
    const currentUser = userState ? userState.get('profile') : null;
    const newData =
      copyOfRecordId && !isTemplateMode
        ? transformCopyRecordData(modifiedData, currentUser, aclOrgs)
        : modifiedData;

    const requestData = transformRequestData(newData, isGlobal);
    const requestUrl = `/v1/records${
      isTemplateMode ? '/templates' : ''
    }/processings`;
    const response = yield request.post(requestUrl, requestData);
    yield response;

    yield put({ type: 'LOADER:TOGGLE', toggle: false });

    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        yield put({
          type: 'PROCESSING:DETAIL:UPSERT:SUCCESS',
          data: response.body
        });
        if (isVendor) {
          refetchRecords();
          const location =
            window.location.origin ||
            `${window.location.protocol}//${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ''
            }`;
          yield window.open(
            `${location}/#/processing/${response.body.id}/edit`,
            '_blank'
          );
        } else if (templateFromRecord) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: notificationtranslations.templateCreated,
              type: 'success'
            }
          });
          yield put(replaceRouter('/privacyrecords'));
        } else if (isTemplateMode) {
          yield put(push(`/processing-template/${response.body.id}/edit`));
        } else if (createFromEdit) {
          yield put(push(`/processing/${response.body.id}/view`));
        } else yield put(push(`/processing/${response.body.id}/edit`));
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'PROCESSING:DETAIL:UPSERT:FAIL', error });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      case 409:
        yield put({
          type: 'PROCESSING:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        if (copyOfRecordId) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
        break;
      case 423:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'warning'
          }
        });
        yield put({ type: 'TENANT:PRICING:PLAN:INIT' });
        yield put({
          type: 'PROCESSING:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'PROCESSING:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    const error = getErrorMessage(err);
    yield put({ type: 'PROCESSING:DETAIL:UPSERT:FAIL', error });
  }
}

// Update processing fields
export function* updateProcessingProperty(action) {
  const { updatedItems } = action;
  try {
    setLastActivityTime();
    const processingState = yield select((state) => state.processing);
    const recordId = processingState ? processingState.get('recordId') : '';
    const processingData = processingState
      ? processingState.get('data').toJS()
      : {};
    let data = processingData;

    Object.keys(updatedItems).forEach((property) => {
      if (property.split('.')[0] === 'custom') {
        data = getRecordDataWithUpdatedCustomFieldValues(
          property,
          updatedItems[property],
          data
        );
      } else {
        const requestParams = getUpdatedProperty(
          { property, data: updatedItems[property] },
          recordId,
          processingData.createdBy ? processingData.createdBy.id : ''
        );
        const propertyObj = requestParams.renderData;
        data = Object.assign({}, data, propertyObj);
      }
    });
    yield put({ type: 'PROCESSING:PROPERTY:UPDATE:SUCCESS', data });
  } catch (err) {
    const error = getErrorMessage(err);
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: error,
        type: 'error'
      }
    });
    yield put({ type: 'PROCESSING:PROPERTY:UPDATE:FAIL', error });
  }
}

export function* upsertProcessingRecordDetail(action) {
  const request = registry.get('request');
  const { copyOfRecordId, data, isTemplateMode } = action;
  try {
    // If assessment is a copy of an existing assessment, then get that assessment data.
    const defaultReqData = copyOfRecordId
      ? yield getRecordData({ copyOfRecordId }, 'processings')
      : yield getDefaultProcessingData();
    const modifiedData = Object.assign({}, defaultReqData, data);
    const requestData = transformRequestData(modifiedData);
    const recordId = modifiedData.id || '';
    const response = yield request.put(
      `/v1/records${
        isTemplateMode ? '/templates' : ''
      }/processings/${recordId}`,
      requestData
    );
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        const userState = yield select((state) => state.user);
        const currentUser = userState ? userState.get('profile') : null;
        const responseData = yield transformResponseData(
          response.body,
          false,
          currentUser,
          action.formatMessage
        );
        yield put({
          type: 'PROCESSING:DETAIL:UPSERT:SUCCESS',
          data: responseData
        });

        if (action.promoteInitiated) {
          yield put({ type: 'PRIVACY_RECORDS:ACTION_DIALOG:STATE_CHANGE' });
        }
        if (action.prevLocation !== '')
          yield put(replaceRouter(action.prevLocation));
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'PROCESSING:DETAIL:UPSERT:FAIL', error });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      case 409:
        if (
          response.headers.get('reason') &&
          response.headers.get('reason') === 'version-mismatch'
        ) {
          yield put({
            type: 'PROCESSING:DETAIL:UPSERT:LOCKED'
          });
        } else {
          yield put({
            type: 'PROCESSING:DETAIL:UPSERT:FAIL',
            error: response.body.msg
          });
        }
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'PROCESSING:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    const error = getErrorMessage(err);
    yield put({ type: 'PROCESSING:DETAIL:UPSERT:FAIL', error });
  }
}

export function* createLinkGroup(action) {
  const currentState = yield select((state) => state.processing);
  const request = registry.get('request');
  try {
    const response = yield request.post(`/v1/links`, action.links);
    switch (response.status) {
      case 200: {
        yield put({ type: 'PROCESSING:LINK_GROUP:CREATE:SUCCESS' });
        const currentRecordData = currentState.get('data').toJS();
        const modifiedResponse = transformResponse(
          response.body,
          action.formatMessage
        );
        const currentLinks = [...currentRecordData.links];
        if (action.linkIdToRemove) {
          const indexToRemove = currentLinks.findIndex(
            (eachLink) =>
              (eachLink.value?.id || eachLink.id) === action.linkIdToRemove
          );
          if (indexToRemove !== -1) currentLinks.splice(indexToRemove, 1);
        }
        currentLinks.push({
          value: modifiedResponse,
          valueVersion: modifiedResponse.versions[0].version
        });
        let modifiedRecordData = {
          ...currentRecordData,
          links: [...currentLinks]
        };
        modifiedRecordData = getUpdatedData(
          modifiedRecordData,
          modifiedResponse
        );
        yield put(change('ProcessingForm', 'links', currentLinks));
        yield put({
          type: 'PROCESSING:PROPERTY:UPDATE:SUCCESS',
          data: modifiedRecordData
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: recordTranslations.linkGroupCreated,
            type: 'success'
          }
        });
        break;
      }
      case 409:
        yield put({
          type: 'PROCESSING:LINK_GROUP:UPSERT:FAIL',
          error: `${response.body.msg} - ${response.body.details.duplicateEntity}`
        });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* updateLinkGroup(action) {
  const currentState = yield select((state) => state.processing);
  const currentRecordData = currentState ? currentState.get('data').toJS() : {};
  let modifiedData = { ...currentRecordData };
  const request = registry.get('request');
  try {
    const response = yield request.put(
      `/v1/links/${action.linkId}`,
      action.linkData
    );
    switch (response.status) {
      case 200: {
        yield put({ type: 'PROCESSING:LINK_GROUP:UPDATE:SUCCESS' });
        const index = currentRecordData.links.findIndex(
          (link) => link.value.id === action.linkId
        );
        const modifiedResponse = transformResponse(
          response.body,
          action.formatMessage
        );
        const currentLinks = [...currentRecordData.links];
        if (validateLinkItems(modifiedResponse)) {
          /** Splice and add is deliberately done in separate statements to trigger re-render
           * of the responsivetablewrapper to calculate the individual row height */
          currentLinks.splice(index, 1);
          currentLinks.splice(index, 0, {
            value: modifiedResponse,
            valueVersion: modifiedResponse.versions[0].version,
            note: action.linkData.note || ''
          });
        } else {
          currentLinks.splice(index, 1);
        }

        yield put(change('ProcessingForm', 'links', currentLinks));
        modifiedData = { ...currentRecordData, links: currentLinks };

        yield put({
          type: 'PROCESSING:PROPERTY:UPDATE:SUCCESS',
          data: modifiedData
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: recordTranslations.linkGroupUpdated,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* saveProcessingComment(action) {
  const currentState = yield select((state) => state.processing);
  const currentRecordData = currentState ? currentState.get('data').toJS() : {};
  let modifiedComments = [];
  const { comment, isEdit, id } = action;
  try {
    const request = registry.get('request');
    let response = {};
    if (!isEdit) {
      response = yield request.post(
        `/v1/records/processings/${id}/comments`,
        comment
      );
    } else {
      response = yield request.put(
        `/v1/records/processings/${id}/comments/${comment.id}`,
        comment
      );
    }
    switch (response.status) {
      case 200: {
        if (!isEdit) {
          modifiedComments = [...currentRecordData.comments, response.body];
        } else {
          const currentIndex = currentRecordData.comments.findIndex(
            (item) => item.id === response.body.id
          );
          modifiedComments = [...currentRecordData.comments];
          modifiedComments[currentIndex] = response.body;
        }
        currentRecordData.comments = modifiedComments;
        yield put({
          type: 'PROCESSING:COMMENT:SAVE:SUCCESS',
          data: currentRecordData
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.commentedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* fetchUsersForProcessing() {
  const { recordId } = getParameterValuesFromHash(
    '/processing/:recordId/edit?:is'
  );
  yield put({ type: 'PROCESSING:USERS:LIST:REQUEST:FETCH' });
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/records/processings/${recordId}/users`, {});
    switch (response.status) {
      case 200: {
        yield put({
          type: 'PROCESSING:USERS:LIST:REQUEST:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* deleteProcessingComment(action) {
  const { id, comment } = action;
  const currentState = yield select((state) => state.processing);
  const currentRecordData = currentState ? currentState.get('data').toJS() : {};
  const modifiedComments = [...currentRecordData.comments];
  try {
    const request = registry.get('request');
    const response = yield request.delete(
      `/v1/records/processings/${id}/comments/${comment.id}`
    );
    switch (response.status) {
      case 204: {
        const currentIndex = modifiedComments.findIndex(
          (item) => item.id === comment.id
        );
        modifiedComments.splice(currentIndex, 1);
        currentRecordData.comments = modifiedComments;
        yield put({
          type: 'PROCESSING:COMMENT:DELETE:SUCCESS',
          data: currentRecordData
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.commentDeletedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

const transformResponse = (link, formatMessage) => {
  if (link.retentionTerms) {
    const modifiedTerms = link.retentionTerms.map((term) =>
      addKeyToRetentionTerm(term, formatMessage)
    );
    return {
      ...link,
      retentionTerms: modifiedTerms
    };
  }
  return link;
};

const getUpdatedData = (data, links) => {
  let modifiedData = { ...data };
  const fields = ['special', 'crime_related'];
  const isSensitiveDataUsed = links.personalDataItems.some((pdi) =>
    fields.includes(pdi.value.categoryType)
  );
  if (
    links.personalDataItems?.length > 0 &&
    data.jurisdictions.includes('APPI')
  ) {
    modifiedData = updateAppiPersonalInfo(
      modifiedData,
      links,
      fields,
      'personal_information_special_care_required'
    );
    modifiedData = updateAppiPersonalInfo(
      modifiedData,
      links,
      ['personal_number'],
      'personal_information_my_number'
    );
  }
  if (
    links.personalDataItems?.length > 0 &&
    data.jurisdictions.includes('PDPA')
  ) {
    let pdpaLegalQualifications = { ...modifiedData.pdpaLegalQualifications };
    if (isSensitiveDataUsed && !pdpaLegalQualifications?.specialTypeData) {
      pdpaLegalQualifications = {
        ...pdpaLegalQualifications,
        specialTypeData: ''
      };
      modifiedData = { ...modifiedData, pdpaLegalQualifications };
    }
  }
  if (
    links.personalDataItems?.length > 0 &&
    data.jurisdictions.includes('LGPD')
  ) {
    let lgpdLegalQualifications = { ...modifiedData.lgpdLegalQualifications };
    if (isSensitiveDataUsed && !lgpdLegalQualifications?.specialData) {
      lgpdLegalQualifications = { ...lgpdLegalQualifications, specialData: '' };
      modifiedData = { ...modifiedData, lgpdLegalQualifications };
    }
  }
  if (
    links.personalDataItems?.length > 0 &&
    data.jurisdictions.includes('PIPL')
  ) {
    let piplLegalQualifications = { ...modifiedData.piplLegalQualifications };
    if (isSensitiveDataUsed && !piplLegalQualifications?.specialTypeData) {
      piplLegalQualifications = {
        ...piplLegalQualifications,
        specialDataType: {
          ...piplLegalQualifications.specialDataType,
          isSensitiveDataUsed: true
        }
      };
      modifiedData = { ...modifiedData, piplLegalQualifications };
    }
  }
  return modifiedData;
};

const updateAppiPersonalInfo = (modifiedData, links, fields, type) => {
  const isUpdaterequired = links.personalDataItems.some((pdi) =>
    fields.includes(pdi.value.categoryType)
  );
  let appiLegalQualifications = { ...modifiedData.appiLegalQualifications };
  if (
    isUpdaterequired &&
    !appiLegalQualifications?.personalInformations?.includes(type)
  ) {
    appiLegalQualifications = {
      ...appiLegalQualifications,
      personalInformations: [
        ...appiLegalQualifications.personalInformations,
        type
      ]
    };
    return { ...modifiedData, appiLegalQualifications };
  }
  return modifiedData;
};
