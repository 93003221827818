import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ActionHelp from '@material-ui/icons/Help';
import Error from '@material-ui/icons/Error';
import ArrowTooltip from '@packages/components/tooltip';
import styles from '@packages/ui/styles';
import ProcessorHeirarchy from '../processor-hierarchy';
import { renderRisk } from '../../renderers/renderers';

const defaultStyle = {
  rootStyle: {
    margin: 0
  },
  headerStyle: {
    color: '#9b9b9b',
    fontSize: '15px',
    display: 'flex'
  }
};

/* Align processor tree inalign with other list items */
const getListStyle = (content) => {
  const subProcessorsExist = content.findIndex(obj => obj.value.subProcessors
    && obj.value.subProcessors.length > 0) !== -1;
  return subProcessorsExist ? {} : { marginLeft: '-15px' };
};

class ProcessorList extends React.Component {
  render () {
    const { header, content, helpNotes, risks } = this.props;
    const listStyle = content && getListStyle(content);
    const hasRisk = Object.values(risks).some(item => item.length !== 0);
    return (
      <div style={defaultStyle.rootStyle}>
        <div style={defaultStyle.headerStyle}>
          <div style={{ width: '100%' }}> {header} </div>
          <div style={{ display: 'flex', float: 'right' }}>
            {hasRisk &&
            <ArrowTooltip title={renderRisk(risks)}>
              <span>
                <Error color="error" />
              </span>
            </ArrowTooltip>}
            {helpNotes &&
            <ArrowTooltip title={helpNotes} >
              <IconButton
                style={{ ...styles.rightIcon, marginLeft: '20px', paddingBottom: 5 }}
              >
                <ActionHelp color="primary" />
              </IconButton>
            </ArrowTooltip>}
          </div>
        </div>
        {content && content.length === 0 &&
        <div style={{ marginTop: 10, marginLeft: 30 }}>
          —
        </div>}
        <div style={listStyle}>
          <ProcessorHeirarchy
            showAssignRole={this.props.showAssignRole}
            isView={true}
            selectedItems={content}
          />
        </div>
      </div>
    );
  }
}

ProcessorList.propTypes = {
  showAssignRole: PropTypes.bool,
  content: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.node,
  risks: PropTypes.shape({
    gdpr: PropTypes.arrayOf(PropTypes.shape({})),
    ccpa: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  helpNotes: PropTypes.node
};

ProcessorList.defaultProps = {
  showAssignRole: false,
  header: null,
  content: [],
  risks: {
    gdpr: [],
    ccpa: []
  },
  helpNotes: null
};

export default ProcessorList;
