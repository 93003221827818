import React from 'react';

import { FormattedMessage, defineMessages } from 'react-intl';
import {
  editMasterTypeTranslations,
  removeMasterTypeTranslations,
  bulkDeletetionTranslations,
  sectionHeaderTranslations
} from '@packages/features/environment/masterDataTranslations';
import homeLayoutTranslations from '@packages/features/home/homeLayoutTranslations';
import countryTranslations from './countryTranslations';
import {
  singularTerms as smallcaseSingular,
  pluralTerms as smallcasePlural
} from './smallcaseTranslations';
import {
  singularTerms as uppercaseSingular,
  pluralTerms as uppercasePlural
} from './uppercaseTranslations';
import mergeTranslation, {
  addTranslations,
  editTranslations,
  selectTranslations,
  navigationTranslations,
  viewTranslations,
  removeTranslations,
  saveTranslations
} from './actionTranslations';
import vendorTranslations from './vendorTranslations';

export const navigateAwayMessage =
  'You have unsaved data in this page. Are you sure you want to leave ?';

export const serviceDownError = (service) => {
  const serviceName = uppercaseSingular(service);
  return (
    <FormattedMessage
      id="Error.serviceDown"
      description="Service is down"
      defaultMessage="{ service }  service is down. Please contact the system administrator."
      values={{ service: serviceName }}
    />
  );
};

export const organisationDetailError = (type) => (
  <FormattedMessage
    id="Error.organisationalError"
    description="One of the additional contact has invalid {value}"
    defaultMessage="One of the additional contact has invalid {value}"
    values={{ value: type }}
  />
);

export const masterDataTranslations = {
  offsetItemCreated: (
    <FormattedMessage
      id="MasterData.offsetCreated"
      description="Offset item created successfully"
      defaultMessage="Offset item created successfully"
    />
  ),
  references: uppercaseSingular('references'),
  document: uppercaseSingular('document')
};

export const deleteSuccessError = (
  masterDataType,
  entityType,
  isMany = false
) => {
  // let masterDataName = '';
  // const translationCollection = isMany ? libraryTranslations : masterDataTranslations;

  const type =
    masterDataType !== 'riskDetails'
      ? entityType || masterDataType
      : masterDataType;
  const masterDataName = isMany
    ? uppercasePlural(type)
    : uppercaseSingular(type);
  return (
    <FormattedMessage
      id="DeleteSuccess.masterData"
      description="Delete successfull"
      defaultMessage="{masterData} successfully deleted."
      values={{ masterData: masterDataName }}
    />
  );
};

export const termPeriodTranslations = (period, term) => {
  let termPeriod = '';
  switch (period) {
    case 'year':
      termPeriod = (
        <FormattedMessage
          id="Retention_Term:value_year"
          description="Retention term value holding year"
          defaultMessage="{term, number} {term, plural,
          one {year}
          other {years}
        }"
          values={{ term }}
        />
      );
      break;
    case 'month':
      termPeriod = (
        <FormattedMessage
          id="Retention_Term:value_month"
          description="Retention term value holding month"
          defaultMessage="{term, number} {term, plural,
          one {month}
          other {months}
        }"
          values={{ term }}
        />
      );
      break;
    case 'week':
      termPeriod = (
        <FormattedMessage
          id="Retention_Term:value_week"
          description="Retention term value holding week"
          defaultMessage="{term, number} {term, plural,
          one {week}
          other {weeks}
        }"
          values={{ term }}
        />
      );
      break;
    case 'day':
      termPeriod = (
        <FormattedMessage
          id="Retention_Term:value_day"
          description="Retention term value holding day"
          defaultMessage="{term, number} {term, plural,
          one {day}
          other {days}
        }"
          values={{ term }}
        />
      );
      break;
    case 'hour':
      termPeriod = (
        <FormattedMessage
          id="Retention_Term:value_hour"
          description="Retention term value holding hour"
          defaultMessage="{term, number} {term, plural,
          one {hour}
          other {hours}
        }"
          values={{ term }}
        />
      );
      break;
    case 'minute':
      termPeriod = (
        <FormattedMessage
          id="Retention_Term:value_minute"
          description="Retention term value holding minute"
          defaultMessage="{term, number} {term, plural,
          one {minute}
          other {minutes}
        }"
          values={{ term }}
        />
      );
      break;
    case 'second':
      termPeriod = (
        <FormattedMessage
          id="Retention_Term:value_second"
          description="Retention term value holding second"
          defaultMessage="{term, number} {term, plural,
          one {second}
          other {seconds}
        }"
          values={{ term }}
        />
      );
      break;
    default:
      break;
  }
  return termPeriod;
};

export const commonTranslations = {
  manageUsers: (
    <FormattedMessage
      id="Common.manageUsers"
      defaultMessage="Users can be added and edited in the landing area"
    />
  ),
  landingArea: (
    <FormattedMessage id="Common.landingArea" defaultMessage="Landing area" />
  ),
  selectValue: (
    <FormattedMessage id="Common.selectValue" defaultMessage="Select value" />
  ),
  editDeleteTag: (
    <FormattedMessage id="Action.Tags" defaultMessage="Add or remove tag" />
  ),
  userInviteOrganisationInfo: (
    <FormattedMessage
      id="Info.userInviteOrganisationInfo"
      defaultMessage="You can add only up to 10 organisation while creating a new user.
    Once user registers in the app, other organisations can be added without limit."
    />
  ),
  deleteTemplate: (
    <FormattedMessage
      id="ProcessingTemplate.remove"
      defaultMessage="Remove template"
    />
  ),
  manageDocuments: (
    <FormattedMessage
      id="Documents.manageDocuments"
      defaultMessage="Manage documents"
    />
  ),
  deleteDocumentRecord: (
    <FormattedMessage
      id="Documents.deleteDocumentRecord"
      defaultMessage="Delete document record"
    />
  ),
  disabledDelete: (
    <FormattedMessage
      id="Action.noDelete"
      defaultMessage="You are not allowed to delete this item as it belongs to the global tenant"
    />
  ),
  organisationsMaxCountWarning: (
    <FormattedMessage
      id="Warning.organisationsMaxCount"
      defaultMessage="Maximum of 10 organisation allowed"
    />
  ),
  preDpia: (
    <FormattedMessage
      id="Records.preDpia"
      defaultMessage="Pre-assessment records"
    />
  ),
  restrictParentEditWarning: (
    <FormattedMessage
      id="Warning.restrictParentEdit"
      defaultMessage="Note:  Subtenants cannot change the parent of departments."
    />
  ),
  parentChangeWarning: (
    <FormattedMessage
      id="Warning.departmentParentChange"
      defaultMessage="This will automatically add the parent to the subtenants where it is imported."
    />
  ),
  permissionToggleHelpNote: (
    <FormattedMessage
      id="Documents.permissionToggleHelpNote"
      defaultMessage="Enabling this will make the file public across the tenant"
    />
  ),
  graphLoadertext: (
    <FormattedMessage
      id="dataWeb.loaderText"
      defaultMessage="It might take some time to render the graph. Please wait while the graph is being generated."
    />
  ),
  auditTrail: (
    <FormattedMessage id="AuditTrail.auditTrail" defaultMessage="Audit trail" />
  ),
  addressDetails: (
    <FormattedMessage
      id="Subscription.addressDetails"
      defaultMessage="Address details"
    />
  ),
  showMore: (
    <FormattedMessage id="ItemList.ShowMore" defaultMessage="Show more" />
  ),
  showLess: (
    <FormattedMessage id="ItemList.ShowLess" defaultMessage="Show less" />
  ),
  numOfResults: (
    <FormattedMessage
      id="Pagination.numOfResults"
      defaultMessage="Number of results"
    />
  ),
  totalResults: (
    <FormattedMessage
      id="Pagination.totalResults"
      defaultMessage="Total results"
    />
  ),
  register: <FormattedMessage id="Module.register" defaultMessage="Register" />,
  registry: (
    <FormattedMessage id="AuditTrail.registry" defaultMessage="Registry" />
  ),
  switchModule: (
    <FormattedMessage
      id="NavBarUserMenu.switchModule"
      defaultMessage="Switch module"
    />
  ),
  setPassword: (
    <FormattedMessage id="Profile.setPassword" defaultMessage="Set password" />
  ),
  logoutSuccessful: (
    <FormattedMessage
      id="LogoutForm.header"
      description="You have been successfully logged out."
      defaultMessage="You have been successfully logged out."
    />
  ),
  logout: (
    <FormattedMessage id="NavBarUserMenu.logout" defaultMessage="Logout" />
  ),
  comment: <FormattedMessage id="DSR.comment" defaultMessage="Comment" />,
  addComment: (
    <FormattedMessage id="DSR.addComment" defaultMessage="Add comment" />
  ),
  modifiedFields: (
    <FormattedMessage
      id="Job.modifiedFields"
      defaultMessage="Modified processing fields"
    />
  ),
  invalidLinkError: (
    <FormattedMessage
      id="Link_Group.invalidLinkError"
      defaultMessage="Create a group by selecting at least two items"
    />
  ),
  NA: <FormattedMessage id="common.NA" defaultMessage="N/A" />,
  accessToken: (
    <FormattedMessage
      id="AuditTrail.accessToken"
      defaultMessage="Access token"
    />
  ),
  deletePrivacyRecord: (
    <FormattedMessage
      id="Common.deletePrivacyRecord"
      defaultMessage="Delete privacy record"
    />
  ),
  deleteRecordTemplate: (
    <FormattedMessage
      id="Common.deleteRecordTemplate"
      defaultMessage="Delete record template"
    />
  ),
  editWarning: (
    <FormattedMessage
      id="Organisations.editWarning"
      defaultMessage="The organisation you are trying to update is used in holding tenant
    and may be in other subtenants. Do you want to continue?"
    />
  ),
  RiskAndIsMitigated: (
    <FormattedMessage
      id="Common.RiskAndIsMitigated"
      defaultMessage="Risk and is mitigated"
    />
  ),
  riskAnalysis: smallcaseSingular('riskAnalysis'),
  mitigatingMeasures: smallcasePlural('mitigatingMeasures'),
  RisksAndMeasures: (
    <FormattedMessage
      id="Common.RisksAndMeasures"
      defaultMessage="Risk and measures"
    />
  ),
  risks: (
    <FormattedMessage
      id="PrivacyRecordList.risks"
      description="Privacy Record List Column Risks"
      defaultMessage="Risks"
    />
  ),
  mitigation: (
    <FormattedMessage
      id="PrivacyRecordList.mitigation"
      description="Mitigation Column"
      defaultMessage="Mitigation"
    />
  ),
  IsConsulted: (
    <FormattedMessage id="Common.IsConsulted" defaultMessage="Is consulted" />
  ),
  FindingsAndDecisions: (
    <FormattedMessage
      id="Common.FindingsAndDecisions"
      defaultMessage="Findings and decisions"
    />
  ),
  dataRecipientLocation: (
    <FormattedMessage
      id="Common.dataRecipientLocation"
      description="Data recipient is located in"
      defaultMessage="Data recipient is located in"
    />
  ),
  filterByTag: (
    <FormattedMessage
      id="EntitySelector.select.tag.hintText"
      defaultMessage="Filter by tag"
    />
  ),
  maximumTagLimit: (
    <FormattedMessage
      id="Tags.restriction"
      description="Only maximum of 5 tags allowed."
      defaultMessage="Only maximum of 5 tags allowed."
    />
  ),
  tenantID: uppercaseSingular('tenantID'),
  tenantName: uppercaseSingular('tenantName'),
  url: uppercaseSingular('url'),
  modules: uppercasePlural('modules'),
  legalEntityName: (
    <FormattedMessage
      id="TenantDetail.legalEntityName"
      defaultMessage="Legal entity name"
    />
  ),
  maxUsers: (
    <FormattedMessage
      id="TenantDetail.maxUsers"
      description="Maximum Users"
      defaultMessage="Maximum Users"
    />
  ),
  maxSupervisorUsers: (
    <FormattedMessage
      id="TenantDetail.maxSupervisoryUsers"
      description="Maximum Supervisory Users"
      defaultMessage="Maximum supervisory users"
    />
  ),
  tenantLanguage: (
    <FormattedMessage
      id="TenantDetail.language"
      description="Tenant language"
      defaultMessage="Tenant language"
    />
  ),
  changeLanguage: (
    <FormattedMessage
      id="TenantDetail.changeLanguage"
      defaultMessage="Change tenant language"
    />
  ),
  public: uppercaseSingular('public'),
  private: uppercaseSingular('private'),
  externalLink: uppercaseSingular('externalLink'),
  tags: uppercasePlural('tags'),
  noTags: uppercasePlural('noTags'),
  owner: uppercaseSingular('owner'),
  next: uppercaseSingular('next'),
  message: uppercaseSingular('messages'),
  removeFilters: (
    <FormattedMessage
      id="PrivacyRecords.removeFilters"
      description="Remove filters text"
      defaultMessage="Remove filters"
    />
  ),
  other: uppercaseSingular('other'),
  add: uppercaseSingular('add'),
  replace: uppercaseSingular('replace'),
  sendEmail: (
    <FormattedMessage
      id="Common.sendEmail"
      description="Send invitation"
      defaultMessage="Send invitation"
    />
  ),
  unknown: uppercaseSingular('unknown'),
  indefinite: uppercaseSingular('indefinite'),
  import: <FormattedMessage id="Service.import" defaultMessage="Import" />,
  export: (
    <FormattedMessage id="ExportDialog.exportButton" defaultMessage="Export" />
  ),
  fieldsVisibilitySetting: (
    <FormattedMessage
      id="Common.fieldsVisibilitySetting"
      defaultMessage="Field visibilty"
    />
  ),
  helpNote: (
    <FormattedMessage
      id="Common.helpNoteSettings"
      defaultMessage="Help notes"
    />
  ),
  specific: (
    <FormattedMessage
      id="Common.specific"
      description="Specific"
      defaultMessage="Specific"
    />
  ),
  restoreOtp: (
    <FormattedMessage
      id="Common.ResetOtp"
      description="Link to reset your QR code has been sent to your inbox."
      defaultMessage="Link to reset your QR code has been sent to your inbox."
    />
  ),
  country: uppercaseSingular('country'),
  countries: uppercasePlural('countries'),
  submit: uppercaseSingular('submit'),
  enterOtp: (
    <FormattedMessage
      id="Common.EnterOtp"
      description="Enter OTP here"
      defaultMessage="Your 6-digit code"
    />
  ),
  Yes: uppercaseSingular('Yes'),
  No: uppercaseSingular('No'),
  Cancel: uppercaseSingular('Cancel'),
  searchByName: (
    <FormattedMessage
      id="Common.searchByName"
      description="Search field placeholder"
      defaultMessage="Search by name"
    />
  ),
  Off: uppercaseSingular('Off'),
  Test: uppercaseSingular('Test'),
  Live: uppercaseSingular('Live'),
  SPinitiated: uppercaseSingular('SPinitiated'),
  IdpInitiated: uppercaseSingular('IdpInitiated'),
  ssoConfirmMsg: (
    <FormattedMessage
      id="Common.ssoConfirmMsg"
      description="SSO confirm message"
      defaultMessage={
        'You are about to turn on SSO for all users, effective' +
        ' immediately. Are you sure you want to continue?'
      }
    />
  ),
  users: uppercasePlural('users'),
  minute: uppercaseSingular('minute'),
  hour: uppercaseSingular('hour'),
  day: uppercaseSingular('day'),
  week: uppercaseSingular('week'),
  month: uppercaseSingular('month'),
  year: uppercaseSingular('year'),
  email: (
    <FormattedMessage
      id="Common.email"
      description="Your e-mail address"
      defaultMessage="Your e-mail address"
    />
  ),
  vendorManagement: (
    <FormattedMessage
      id="Vendor.vendorManagement"
      description="Vendor Management"
      defaultMessage="Vendor Management"
    />
  ),
  emailLabel: uppercaseSingular('emailLabel'),
  phonenumber: uppercaseSingular('phonenumber'),
  phoneNumber: uppercaseSingular('phonenumber'),
  phoneNumberHelpnote: (
    <FormattedMessage
      id="Common.phoneNumberHelpnote"
      defaultMessage="Sample phone number formats:
      <newline> (123) 456-7890</newline>
      <newline>+(123) 456-7890</newline>
      <newline>+(123)-456-7890</newline>
      <newline>+(123) - 456-7890</newline>
      <newline>+(123) - 456-78-90</newline>
      <newline>123-456-7890</newline>
      <newline>123.456.7890</newline>
      <newline>1234567890</newline>
      <newline>+31636363634</newline>
      <newline>075-63546725</newline>
      <newline>Extension can be provided as 123.456.7890 - 987</newline>"
      values={{
        newline: (text) => <div>{text}</div>
      }}
    />
  ),
  noRisks: (
    <FormattedMessage
      id="Common.noRisks"
      description="No risks identified"
      defaultMessage="No risks identified"
    />
  ),
  DateFormat: (
    <FormattedMessage
      id="Common.DateFormat"
      description="dd-mm-yyyy"
      defaultMessage="dd-mm-yyyy"
    />
  ),
  search: uppercaseSingular('search'),
  category: uppercaseSingular('category'),
  firstname: uppercaseSingular('firstname'),
  lastname: uppercaseSingular('lastname'),
  NoData: (
    <FormattedMessage
      id="Common.data.notFound"
      description="No items found"
      defaultMessage="No items found ..."
    />
  ),
  notSelected: (
    <FormattedMessage
      id="ProcessedData.noSelectedItems"
      defaultMessage="No items selected to display"
    />
  ),
  NoDataToDisplay: (
    <FormattedMessage
      id="Overview.NoDataToDisplay"
      description="No data to display"
      defaultMessage="No data to display"
    />
  ),
  Ok: uppercaseSingular('Ok'),
  addNote: addTranslations('addNote'),
  selectAll: selectTranslations('selectAll'),
  addnow: addTranslations('addNow'),
  editNote: editTranslations('editNote'),
  view: viewTranslations('view'),
  viewNote: viewTranslations('viewNote'),
  removeNote: removeTranslations('removeNote'),
  saveNote: saveTranslations('saveNote'),
  savechanges: saveTranslations('savechanges'),
  savesettings: saveTranslations('savesettings'),
  customHelpTexts: uppercaseSingular('customHelpTexts'),
  editCustomHelpTexts: editTranslations('editCustomHelpTexts'),
  password: (
    <FormattedMessage
      id="Common.password"
      description="Your password"
      defaultMessage="Your password"
    />
  ),
  repeatpassword: (
    <FormattedMessage
      id="Common.repeatpassword"
      description="Repeat your password"
      defaultMessage="Repeat your password"
    />
  ),
  resetpassword: (
    <FormattedMessage
      id="Common.resetpassword"
      description="Reset password"
      defaultMessage="Reset password"
    />
  ),
  reinviteUser: (
    <FormattedMessage
      id="Common.reinviteUser"
      description="Reinvite user"
      defaultMessage="Reinvite user"
    />
  ),
  reinviteUserButton: (
    <FormattedMessage
      id="Common.reinviteUserButton"
      description="Reinvite user"
      defaultMessage="Reinvite user"
    />
  ),
  oldpassword: (
    <FormattedMessage
      id="Common.oldpassword"
      description="Your old password"
      defaultMessage="Your old password"
    />
  ),
  forgotpassword: (
    <FormattedMessage
      id="Common.forgotpassword"
      description="I forgot my password"
      defaultMessage="I forgot my password"
    />
  ),
  passwordValidityPeriod: (
    <FormattedMessage
      id="PasswordSettings.validityPeriod"
      defaultMessage="Password validity period"
    />
  ),
  passwordExpiry: (
    <FormattedMessage
      id="PasswordSettings.enablepasswordexpiry"
      defaultMessage="Enable password expiry"
    />
  ),
  passwordSettings: (
    <FormattedMessage
      id="PasswordSettings"
      defaultMessage="Password settings"
    />
  ),
  dataSubjectRequest: (
    <FormattedMessage
      id="DSR.DataSubjectRequest"
      defaultMessage="Data subject request"
    />
  ),
  dataSubjectRequestForm: (
    <FormattedMessage
      id="DSR.DataSubjectRequestForm"
      defaultMessage="Data subject request form"
    />
  ),
  dataSubject: (
    <FormattedMessage id="DSR.DataSubject" defaultMessage="Data subject" />
  ),
  documentDownload: (
    <FormattedMessage
      id="EventCode.document_download"
      defaultMessage="Document download"
    />
  ),
  openLink: (
    <FormattedMessage
      id="Document.openLink"
      defaultMessage="Open external link"
    />
  ),
  addUser: (
    <FormattedMessage
      id="OrganisationUserDetailDialog.addUser"
      defaultMessage="Add user"
    />
  ),
  status: uppercaseSingular('status'),
  activeUsers: uppercasePlural('activeUsers'),
  activeReadOnlyUsers: uppercasePlural('activeReadOnlyUsers'),
  entity: uppercaseSingular('entity'),
  name: uppercaseSingular('name'),
  dataSubjectCategories: uppercasePlural('dataSubjectCategories'),
  measure: uppercaseSingular('measure'),
  saveAndClose: saveTranslations('saveAndClose'),
  saveAndCreate: saveTranslations('saveAndCreate'),
  addNoteHeader: addTranslations('addNoteHeader'),
  editNoteHeader: editTranslations('editNoteHeader'),
  viewNoteHeader: viewTranslations('viewNoteHeader'),
  goBack: navigationTranslations('goBack'),
  user: uppercaseSingular('user'),
  userAgent: uppercaseSingular('userAgent'),
  web_application: uppercaseSingular('web_application'),
  direct_api: uppercaseSingular('direct_api'),
  source: (
    <FormattedMessage
      id="Common.Source"
      description="Source"
      defaultMessage="Source"
    />
  ),
  proceed: (
    <FormattedMessage
      id="Common.Proceed"
      description="Proceed"
      defaultMessage="Proceed"
    />
  ),
  file: (
    <FormattedMessage
      id="Documents.File"
      description="File"
      defaultMessage="File"
    />
  ),
  addExternalLink: (
    <FormattedMessage
      id="Document.externalLinkHint"
      description="Hint text for external link"
      defaultMessage="Add external link..."
    />
  ),
  fileName: (
    <FormattedMessage
      id="Common.fileName"
      description="File name"
      defaultMessage="File name"
    />
  ),
  addFileName: (
    <FormattedMessage
      id="Document.fileName"
      description="Hint text for file name"
      defaultMessage="Add file name..."
    />
  ),
  addDescription: (
    <FormattedMessage
      id="Document.description"
      description="Hint text for description"
      defaultMessage="Add description..."
    />
  ),
  permission: (
    <FormattedMessage
      id="Common.permission"
      description="Permission"
      defaultMessage="Permission"
    />
  ),
  addTags: (
    <FormattedMessage
      id="Document.tags"
      description="Hint text for tags"
      defaultMessage="Add or select tags..."
    />
  ),
  uploadFile: (
    <FormattedMessage
      id="Documents.changeFile"
      description="Do you want to upload file?"
      defaultMessage="This action would remove already associated external link. Are you sure you want to proceed ?"
    />
  ),
  provideLink: (
    <FormattedMessage
      id="Documents.changeLink"
      description="Do you want to provide external link?"
      defaultMessage="This action would remove already associated document. Are you sure you want to proceed ?"
    />
  ),
  chooseFile: (
    <FormattedMessage
      id="Documents.chooseFile"
      description="Choose file to upload"
      defaultMessage="Choose file to upload"
    />
  ),
  drag: (
    <FormattedMessage
      id="Documents.dragndrop"
      description="or Drag and drop"
      defaultMessage="or Drag and drop"
    />
  ),
  notificationTime: (
    <FormattedMessage id="Notification.createdAt" defaultMessage="Created on" />
  ),
  time: (
    <FormattedMessage
      id="Common.Time"
      description="Time"
      defaultMessage="Time"
    />
  ),
  measures: uppercasePlural('measures'),
  overview: (
    <FormattedMessage
      id="Common.overview"
      description="Overview"
      defaultMessage="Overview"
    />
  ),
  notifications: uppercasePlural('notifications'),
  tasks: uppercasePlural('tasks'),
  selectCountry: selectTranslations('selectCountry'),
  associatedCountries: (
    <FormattedMessage
      id="Common.associatedCountries"
      defaultMessage="Tia associated countries"
    />
  ),
  frameworkTemplates: (
    <FormattedMessage
      id="Common.frameworkTemplates"
      defaultMessage="Framework templates"
    />
  ),
  selectTemplate: selectTranslations('selectTemplate'),
  current: (
    <FormattedMessage
      id="Common.current"
      description="Current"
      defaultMessage="Current"
    />
  ),
  filterOnType: (
    <FormattedMessage
      id="PrivacyRecordsFilter.filterTypes"
      description="Filter on type"
      defaultMessage="Filter on type"
    />
  ),
  close: (
    <FormattedMessage
      id="ViewAllDialog.close"
      description="Dialog close"
      defaultMessage="Close"
    />
  ),
  confirm: <FormattedMessage id="Common.confirm" defaultMessage="Confirm" />,
  retry: (
    <FormattedMessage
      id="Jobs.retry"
      description="retry"
      defaultMessage="Retry"
    />
  ),
  terminate: (
    <FormattedMessage
      id="Jobs.terminate"
      description="Terminate label"
      defaultMessage="Terminate"
    />
  ),
  invited: (
    <FormattedMessage
      id="Common.invited"
      description="Invited"
      defaultMessage="Invited"
    />
  ),
  registered: (
    <FormattedMessage
      id="Common.registered"
      description="Registered"
      defaultMessage="Registered"
    />
  ),
  content: (
    <FormattedMessage
      id="Common.content"
      description="Content"
      defaultMessage="Content"
    />
  ),
  jobDetails: (
    <FormattedMessage
      id="Common.jobDetails"
      description="Job Details"
      defaultMessage="Job details"
    />
  ),
  oldValue: (
    <FormattedMessage
      id="Common.oldValue"
      description="Old values"
      defaultMessage="Old values"
    />
  ),
  newValue: (
    <FormattedMessage
      id="Common.newValue"
      description="New values"
      defaultMessage="New values"
    />
  ),
  details: (
    <FormattedMessage
      id="Common.details"
      description="Details"
      defaultMessage="Details"
    />
  ),
  linkItemUpdateConfirm: (
    <FormattedMessage
      id="Link_Data_Item.update_confirm"
      description="Confirm message while updating item associated with a link"
      defaultMessage="Updating this item will also update the associated group. Do you still want to proceed ?"
    />
  ),
  linkItemDeleteConfirm: (
    <FormattedMessage
      id="Link_Data_Item.remove_confirm"
      description="Confirm message while removing item associated with a link"
      defaultMessage="Removing this item will also remove it from the associated group. Do you still want to proceed ?"
    />
  ),
  linkItemDeletefromGroup: (
    <FormattedMessage
      id="Link_Data_Item.remove_fromGroup"
      description="Notification message while removing item associated with a link"
      defaultMessage="You are not allowed to remove this item as it is associated with a group.
      Please remove it from data item groups."
    />
  ),
  linkItemUpdatefromGroup: (
    <FormattedMessage
      id="Link_Data_Item.update_fromGroup"
      description="Notification message while update item associated with a link"
      defaultMessage="You are not allowed to update this item as it is associated with a group.
      Please update it from data item groups."
    />
  ),
  whatsThis: (
    <FormattedMessage
      id="ProcessingDetails.linkInstruction"
      description="Additional instructions"
      defaultMessage="What's this?"
    />
  ),
  adminEmail: addTranslations('adminEmail'),
  startDate: (
    <FormattedMessage
      id="TenantDetail.startDate"
      description="Start Date"
      defaultMessage="Start date"
    />
  ),
  startDateHint: selectTranslations('startDateHint'),
  endDate: (
    <FormattedMessage
      id="TenantDetail.endDate"
      description="End date"
      defaultMessage="End date"
    />
  ),
  endDateHint: selectTranslations('endDateHint'),
  note: uppercaseSingular('note'),
  passwordStrength: (
    <FormattedMessage
      id="passwordStrength.rules"
      description="Help notes for password strength"
      defaultMessage={
        '1. Avoid repeated words and characters. \n2. Avoid common words.\n3. Avoid keyboard patterns ' +
        'and sequences. \n4. Use longer keyboard pattern with more turns.'
      }
    />
  ),
  deleteConfirm: (
    <FormattedMessage
      id="Delete.deleteConfirm"
      description="This action can not be undone"
      defaultMessage="This action can not be undone"
    />
  ),
  unused: uppercaseSingular('unused'),
  all: uppercaseSingular('all'),
  assessmentOutcome: (
    <FormattedMessage
      id="Record.assessmentOutcome"
      description="Outcome of the assessment"
      defaultMessage="Outcome of the assessment"
    />
  ),
  usageInRecords: (
    <FormattedMessage
      id="Record.usageInRecords"
      description="Usage in other records"
      defaultMessage="Usage in other records"
    />
  ),
  enableBypassSSO: (
    <FormattedMessage
      id="TenantSetting.enableSSObypass"
      description="SSO bypass"
      defaultMessage="Enable SSO bypass"
    />
  ),
  bypassSSO: (
    <FormattedMessage
      id="TenantSetting.SSObypass"
      description="SSO bypass"
      defaultMessage="SSO bypass"
    />
  ),
  SSOtoggleLabel: (
    <FormattedMessage
      id="TenantSetting.SSOtoggleLabel"
      description="SSO configuration"
      defaultMessage="SSO configuration"
    />
  ),
  abortText: (
    <FormattedMessage
      id="Common.download"
      description="download"
      defaultMessage="You may"
    />
  ),
  download: (
    <FormattedMessage
      id="Common.download"
      description="download"
      defaultMessage="the download instead"
    />
  ),
  abort: (
    <FormattedMessage
      id="Reports.abort"
      description="abort"
      defaultMessage="abort"
    />
  ),
  fullExport: (
    <FormattedMessage
      id="Records.fullExport"
      description="Full export"
      defaultMessage="Full export"
    />
  ),
  article301: (
    <FormattedMessage
      id="Records.article301"
      description="Article 30.1"
      defaultMessage="Article 30.1"
    />
  ),
  article302: (
    <FormattedMessage
      id="Records.article302"
      description="Article 30.2"
      defaultMessage="Article 30.2"
    />
  ),
  with_Empty_Fields: (
    <FormattedMessage
      id="Records.with_Empty_Fields"
      description="With empty fields"
      defaultMessage="With empty fields"
    />
  ),
  passwordStatus: (
    <FormattedMessage
      id="Record.passwordStatus"
      description="Password invitation status"
      defaultMessage="Password invitation status"
    />
  ),
  importRestricted: (
    <FormattedMessage
      id="Import.restricted"
      description="Limit exceeded"
      defaultMessage="You cannot import files as the maximum count has exceeded.Please try again later."
    />
  ),
  showProgressing: (
    <FormattedMessage
      id="Import.showProgressing"
      defaultMessage="Another user has initiated an import for this entity and is currently in-progress.
    Kindly try again later."
    />
  ),
  requesterId: (
    <FormattedMessage
      id="create.requesterId"
      description="Requester Id"
      defaultMessage="Requester Id"
    />
  ),
  firstName: (
    <FormattedMessage
      id="firstName"
      description="First name"
      defaultMessage="First name"
    />
  ),
  lastName: (
    <FormattedMessage
      id="lastName"
      description="Last name"
      defaultMessage="Last name"
    />
  ),
  address: (
    <FormattedMessage
      id="Common.address"
      description="Address"
      defaultMessage="Address"
    />
  ),
  postCode: (
    <FormattedMessage
      id="postCode"
      description="Postcode"
      defaultMessage="Post code"
    />
  ),
  city: <FormattedMessage id="city" description="City" defaultMessage="City" />,
  emailAddress: (
    <FormattedMessage
      id="emailAddress"
      description="Email address"
      defaultMessage="Email address"
    />
  ),
  countryName: (
    <FormattedMessage
      id="Common.country"
      description="Country"
      defaultMessage="Country"
    />
  ),
  processingName: (
    <FormattedMessage
      id="CreateProcessings.nameHint"
      defaultMessage="Processing name"
    />
  ),
  csvExportDialogContent: (
    <FormattedMessage
      id="MasterData.loader"
      defaultMessage="Please wait while your CSV file is being generated"
    />
  ),
  makeStartNode: (
    <FormattedMessage
      id="graph.makeStartNode"
      defaultMessage="Make start node"
    />
  ),
  expandNode: (
    <FormattedMessage id="graph.expandNode" defaultMessage="Expand node" />
  ),
  privacyRecords: (
    <FormattedMessage
      id="Header.privacyRecords"
      defaultMessage="Privacy Records"
    />
  ),
  displayNames: (
    <FormattedMessage id="Graph.displayNames" defaultMessage="Display names" />
  ),
  webDepth: <FormattedMessage id="Graph.webDepth" defaultMessage="Depth" />,
  selectMessageType: (
    <FormattedMessage
      id="MessageBanner.typeHint"
      defaultMessage="Select message type"
    />
  ),
  bulkImport: (
    <FormattedMessage id="BulkImport.progress" defaultMessage="Bulk import " />
  ),
  continue: <FormattedMessage id="Group.Continue" defaultMessage="Continue " />,
  subscriptionExpired: (
    <FormattedMessage
      id="settings.subscriptionExpired"
      defaultMessage="The subscription plan for this tenant has been expired. Please contact Administrator."
    />
  ),
  createNow: (
    <FormattedMessage
      id="CreateRecord.addButtonLabel"
      defaultMessage="Create now"
    />
  ),
  filters: (
    <FormattedMessage
      id="ReportSearch.filterTitle"
      description="Filters"
      defaultMessage="Filters"
    />
  ),
  maximumOrgLimit: (
    <FormattedMessage
      id="Registry.maximumOrgLimit"
      defaultMessage="Maximum of 5 organisations allowed"
    />
  ),
  registryFieldsVisibilitySetting: (
    <FormattedMessage
      id="auditTrail.registryFieldsVisibilitySetting"
      defaultMessage="Registry field visibility"
    />
  ),
  changeSubscriptionManagerHeader: (
    <FormattedMessage
      id="DeleteUser.changeSubscriptionManagerHeader"
      defaultMessage="Choose subscription manager"
    />
  ),
  changeSubscriptionManageUser: (
    <FormattedMessage
      id="DeleteUser.changeSubscriptionManageUser"
      defaultMessage="The user you are trying to delete is registered as the Subscription Manager.
          Choose another user to become the Subscription Manager"
    />
  ),
  changeSubscriptionManager: (
    <FormattedMessage
      id="DeleteAdmin.changeSubScriptionManager"
      defaultMessage="The admin you are trying to delete is registered as the Subscription Manager.
          Choose another admin to become the Subscription Manager"
    />
  ),
  NoAdminAdminDeletion: (
    <FormattedMessage
      id="DeleteAdmin.NoAdminAdminDeletion"
      defaultMessage="You are not allowed to delete admin registered as the
            Subscription Manager if no other admin is added"
    />
  ),
  isInternalOrExternal: (
    <FormattedMessage
      id="OrganisationEntity.isInternalOrExternal"
      defaultMessage="Entity is an"
    />
  ),
  internalCompany: (
    <FormattedMessage
      id="OrganisationEntity.internalCompany"
      defaultMessage="Internal company"
    />
  ),
  externalCompany: (
    <FormattedMessage
      id="OrganisationEntity.externalCompany"
      defaultMessage="External company"
    />
  ),
  selectEntity: (
    <FormattedMessage
      id="OrganisationEntity.selectEntity"
      defaultMessage="Select entity"
    />
  ),
  entityIsPartOf: (
    <FormattedMessage
      id="OrganisationEntity.entityIsPartOf"
      defaultMessage="Entity is a part of"
    />
  ),
  parentImportWarning: (
    <FormattedMessage
      id="Warning.importParent"
      defaultMessage="Importing this department will also import the Legal entity containing it"
    />
  ),
  contactData: (
    <FormattedMessage
      id="Organisation.contactData"
      defaultMessage="Contact data"
    />
  ),
  isPartOfHint: (
    <FormattedMessage
      id="OrganisationEntity.isPartOfHint"
      defaultMessage="Select entity..."
    />
  ),
  subTenantJobTitle: (
    <FormattedMessage
      id="Jobs.subTenantJobTitle"
      defaultMessage="Sub tenant jobs"
    />
  ),
  noSubTenantJob: (
    <FormattedMessage
      id="Jobs.noSubTenantJob"
      defaultMessage="No sub tenants associated with this."
    />
  ),
  dialogHint: (
    <FormattedMessage
      id="Merge.dialogHint"
      description="Please bear in mind that"
      defaultMessage="Please bear in mind that"
    />
  ),
  irreversibleHint: (
    <FormattedMessage
      id="Merge.irreversibleHint"
      description="Merging is irreversible"
      defaultMessage="Merging is irreversible"
    />
  ),
  timingHint: (
    <FormattedMessage
      id="Merge.timingHint"
      description="Merging might take up a few minutes"
      defaultMessage="Merging might take up a few minutes"
    />
  ),
  noActionHint: (
    <FormattedMessage
      id="Merge.noActionHint"
      description="Any actions relating to the items will not be possible until the job has completed"
      defaultMessage="Any actions relating to the items will not be possible until the job has completed"
    />
  ),
  warningProceed: (
    <FormattedMessage
      id="Merge.warningProceed"
      description="Do you want to proceed?"
      defaultMessage="Do you want to proceed?"
    />
  ),
  impacts: (
    <FormattedMessage
      id="Records.impacts"
      description="Impacts"
      defaultMessage="Impacts"
    />
  ),
  lastUpdated: (
    <FormattedMessage id="Record.lastUpdated" defaultMessage="Last updated" />
  ),
  overWriteCurrentVersion: (
    <FormattedMessage
      id="Document.overWriteCurrentVersion"
      defaultMessage="Over write current version"
    />
  ),
  downloadDisabled: (
    <FormattedMessage
      id="Document.downloadDisabled"
      defaultMessage="The document has been deleted"
    />
  ),
  documentNotAvailable: (
    <FormattedMessage
      id="Document.documentNotAvailable"
      defaultMessage="Document not available"
    />
  ),
  previewForm: (
    <FormattedMessage id="Common.previewForm" defaultMessage="Preview form" />
  ),
  customLayout: (
    <FormattedMessage id="Custom.customLayout" defaultMessage="Custom layout" />
  ),
  customField: (
    <FormattedMessage id="Common.customField" defaultMessage="Custom field" />
  ),
  customItemList: (
    <FormattedMessage
      id="Common.customItemList"
      defaultMessage="Custom item list"
    />
  ),
  customOption: (
    <FormattedMessage id="Common.customOption" defaultMessage="Custom option" />
  ),
  filter: (
    <FormattedMessage
      id="Filter.Filter"
      description="Filter"
      defaultMessage="Filter"
    />
  ),
  deleteWarning: (
    <FormattedMessage
      id="aclUser.deleteWarning"
      defaultMessage="Cannot delete this user as atleast one user or organisation must be added"
    />
  ),
  promoteRecordCategory: (
    <FormattedMessage
      id="Common.promoteRecordCategory"
      defaultMessage="Promote"
    />
  ),
  upgradeRecordCategory: (
    <FormattedMessage
      id="Common.upgradeRecordCategory"
      defaultMessage="Upgrade"
    />
  ),
  dsrsetting: (
    <FormattedMessage id="Common.dsrsetting" defaultMessage="DSR Setting" />
  ),
  seeMore: (
    <FormattedMessage id="Common.seeMore" defaultMessage="See more..." />
  ),
  createNew: (
    <FormattedMessage id="Common.createNew" defaultMessage="Create new" />
  ),
  selectALanguage: (
    <FormattedMessage
      id="Common.selectALanguage"
      defaultMessage="Select a language"
    />
  ),
  customDashboardLayout: (
    <FormattedMessage
      id="Common.customDashboardLayout"
      defaultMessage="Custom dashboard layout"
    />
  ),
  adminUpdates: (
    <FormattedMessage id="Common.adminUpdates" defaultMessage="Admin updates" />
  ),
  accessGroups: (
    <FormattedMessage id="Common.accessGroups" defaultMessage="Access Groups" />
  ),
  whitelist: (
    <FormattedMessage id="Common.whitelist" defaultMessage="Whitelist" />
  ),
  blacklist: (
    <FormattedMessage id="Common.blacklist" defaultMessage="Blacklist" />
  ),
  auditSetting: (
    <FormattedMessage
      id="Common.auditSetting"
      defaultMessage="Audit trail setting"
    />
  ),
  recordTemplates: (
    <FormattedMessage
      id="Common.recordTemplates"
      defaultMessage="Record Templates"
    />
  ),
  customPrivacyRecordTemplate: (
    <FormattedMessage
      id="Common.customPrivacyRecordTemplate"
      defaultMessage="Custom Record Templates"
    />
  ),
  dataLibrary: (
    <FormattedMessage id="Common.dataLibrary" defaultMessage="Data library" />
  ),
  fullMasterData: (
    <FormattedMessage
      id="Common.fullMasterData"
      defaultMessage="Full master data"
    />
  ),
  layouts: <FormattedMessage id="Common.Layouts" defaultMessage="Layouts" />,
  layout: <FormattedMessage id="Common.Layout" defaultMessage="Layout" />,
  template: <FormattedMessage id="Common.Template" defaultMessage="Template" />,
  records: <FormattedMessage id="Record.records" defaultMessage="Records" />,
  selectRecords: (
    <FormattedMessage
      id="Records.dialogheader"
      defaultMessage="Select records"
    />
  ),
  selectLayouts: (
    <FormattedMessage
      id="Common.selectLayouts"
      defaultMessage="Select layouts"
    />
  ),
  update: <FormattedMessage id="linkGroup.update" defaultMessage="Update" />,
  copy: <FormattedMessage id="Common.copy" defaultMessage="Copy" />,
  sync: (
    <FormattedMessage id="tenantConfiguration.sync" defaultMessage="Sync" />
  ),
  viewPreview: (
    <FormattedMessage id="Common.viewPreview" defaultMessage="View preview" />
  ),
  purchaseItem: (
    <FormattedMessage id="Common.purchaseItem" defaultMessage="Purchase item" />
  ),
  importItem: (
    <FormattedMessage id="Common.importItem" defaultMessage="Import item" />
  ),
  syncItem: (
    <FormattedMessage id="Common.syncItem" defaultMessage="Sync item" />
  ),
  resetItem: (
    <FormattedMessage id="Common.resetItem" defaultMessage="Reset item" />
  ),
  updateItem: (
    <FormattedMessage id="Common.updateItem" defaultMessage="Update item" />
  ),
  disconnectSync: (
    <FormattedMessage
      id="Common.disconnectSync"
      defaultMessage="Disconnect sync"
    />
  ),
  copyItem: (
    <FormattedMessage id="Common.copyItem" defaultMessage="Copy item" />
  ),
  failureReason: (
    <FormattedMessage
      id="Common.failureReason"
      defaultMessage=" Failure reason"
    />
  ),
  updateAccess: (
    <FormattedMessage
      id="Common.updateAccess"
      defaultMessage=" Update access"
    />
  ),
  save: <FormattedMessage id="DSR.Save" defaultMessage="Save" />,
  goToLandingArea: (
    <FormattedMessage
      id="NavBarUserMenu.Home"
      description="Go to Landing Page"
      defaultMessage="Go to Landing Page"
    />
  )
};

export const changeStatusTranslation = (status) => {
  const message = (
    <FormattedMessage
      id="changeStatus.confirmstatuschange"
      description="Confirm Status Change"
      defaultMessage={
        'You are about to update the status of' +
        'this record to {status} . This will prevent further editing' +
        'until the record is approved or rejected. Are you sure?'
      }
      values={{ status: statusTranslations[status] }}
    />
  );
  return message;
};

export const roleMapper = (role) => recordTranslations[role] || role;

export const environmentHeaderTranslation = (type) => {
  switch (type) {
    case 'legalGrounds':
      return uppercaseSingular('legalGround');
    case 'supervisoryAuthorities':
      return uppercaseSingular('supervisoryAuthority');
    case 'organisations':
      return recordTranslations.aclOrgs;
    case 'controllers':
      return recordTranslations.controller;
    case 'dataSubjectCategories':
      return recordTranslations.dataSubjectCategory;
    case 'executingEntities':
      return recordTranslations.executingEntity;
    case 'dataRecipients':
      return recordTranslations.dataRecipient;
    case 'personalDataItems':
      return recordTranslations.personaldata;
    case 'processingCategories':
      return recordTranslations.processingCategoryHeader;
    case 'personalDataCategories':
      return (
        <FormattedMessage
          id="PersonalDataItems.personalItemCategory"
          description="Personal item category"
          defaultMessage="personal item category"
        />
      );
    case 'dataSources':
      return recordTranslations.datasource;
    case 'dataSourceCategories':
      return uppercasePlural('dataSourceCategories');
    case 'user':
      return recordTranslations.User;
    case 'technicalSecurityMeasures':
    case 'OrganisationalSecurityMeasures':
      return recordTranslations.securityMeasure;
    case 'securityMeasures':
      return bulkDeletetionTranslations.securityMeasures;
    case 'retentionTermOffsets':
      return (
        <FormattedMessage
          id="Record.retentionTermOffset"
          description="retention term offset"
          defaultMessage="retention term offset"
        />
      );
    case 'references':
      return bulkDeletetionTranslations.references;
    case 'qualityControls':
      return bulkDeletetionTranslations.qualityControls;
    case 'purposes':
      return bulkDeletetionTranslations.purposes;
    case 'mitigatingMeasures':
      return bulkDeletetionTranslations.mitigatingMeasures;
    case 'riskDetails':
      return bulkDeletetionTranslations.riskDetails;
    case 'dataSubjectRights':
      return bulkDeletetionTranslations.consentRights;
    case 'policies':
      return bulkDeletetionTranslations.policies;
    case 'confidentialityThreats':
      return bulkDeletetionTranslations.confidentialityThreats;
    case 'integrityThreats':
      return bulkDeletetionTranslations.integrityThreats;
    case 'availabilityThreats':
      return bulkDeletetionTranslations.availabilityThreats;
    case 'confidentialityImpacts':
      return bulkDeletetionTranslations.confidentialityImpacts;
    case 'availabilityImpacts':
      return bulkDeletetionTranslations.availabilityImpacts;
    case 'integrityImpacts':
      return bulkDeletetionTranslations.integrityImpacts;
    case 'supervisoryAuthoritiesFinding':
      return bulkDeletetionTranslations.supervisoryAuthoritiesFinding;
    case 'dataSubjectCategoriesFinding':
      return bulkDeletetionTranslations.dataSubjectCategoriesFinding;
    case 'dataProtectionMeasures':
      return bulkDeletetionTranslations.dataProtectionMeasures;
    case 'accountabilityMeasures':
      return bulkDeletetionTranslations.accountabilityMeasures;
    case 'fairnessOfDecisions':
      return (
        <FormattedMessage
          id="Record.fairnessOfDecisions"
          defaultMessage="fairness of decisions"
        />
      );
    case 'documentRecordTypes':
      return (
        <FormattedMessage
          id="Record.documentRecordType"
          defaultMessage="document type"
        />
      );
    case 'specialCharacteristics':
      return (
        <FormattedMessage
          id="Record.specialCharacteristics"
          defaultMessage="special characteristics"
        />
      );
    default:
      return type;
  }
};

export const getRiskTranslation = (type) => {
  switch (type) {
    case 'risk_estimate_type_none':
      return recordTranslations.noRiskLevel;
    case 'risk_estimate_type_low':
      return recordTranslations.lowRiskLevel;
    case 'risk_estimate_type_medium':
      return recordTranslations.mediumRiskLevel;
    case 'risk_estimate_type_high':
      return recordTranslations.highRiskLevel;
    default:
      return type;
  }
};

export const deleteRecord = (recordName) => (
  <FormattedMessage
    id="Record.deleteRecord"
    description="Do you want to delete the privacy record?"
    defaultMessage="Do you want to delete the privacy record {record}?"
    values={{ record: `'${recordName}'` }}
  />
);

export const deleteTemplate = (templateName) => (
  <FormattedMessage
    id="Record.deleteTemplate"
    defaultMessage="Do you want to delete the template {template}?"
    values={{ template: `'${templateName}'` }}
  />
);

export const recordTranslations = {
  additionalInfo: (
    <FormattedMessage
      id="Records.additionalInfo"
      defaultMessage="Additional information"
    />
  ),
  selectSubRecordType: (
    <FormattedMessage
      id="Records.selectSubRecordType"
      defaultMessage="Please select a subrecord type"
    />
  ),
  roleInDocument: (
    <FormattedMessage
      id="Records.roleInDocument"
      defaultMessage="Role in document"
    />
  ),
  dpa: <FormattedMessage id="Records.dpa" defaultMessage="DPA" />,
  downloadDocument: (
    <FormattedMessage
      id="Document.download"
      defaultMessage="Download document"
    />
  ),
  documentDeleteConfirmation: (
    <FormattedMessage
      id="Document.documentDeleteConfirmation"
      defaultMessage="Are you sure that you want to remove the document?"
    />
  ),
  hasDpa: <FormattedMessage id="Document.hasDpa" defaultMessage="Has DPA" />,
  noDpa: <FormattedMessage id="Document.noDpa" defaultMessage="No DPA" />,
  linkDocument: (
    <FormattedMessage
      id="Document.linkDocument"
      defaultMessage="Link new document record"
    />
  ),
  selectDocument: (
    <FormattedMessage
      id="Document.selectDocument"
      defaultMessage="Select document record"
    />
  ),
  itemUsage: (
    <FormattedMessage id="Record.itemUsage" defaultMessage="This is used in" />
  ),
  jobRunningNotification: (
    <FormattedMessage
      id="PublicRecords.jobRunningNotification"
      defaultMessage="Records displayed might be inconsistent due to updates happening currently."
    />
  ),
  lawsAndPractices: (
    <FormattedMessage
      id="Records.tia.lawsAndPractices"
      defaultMessage="Laws and practices"
    />
  ),
  supplementaryMeasures: (
    <FormattedMessage
      id="Records.tia.supplementaryMeasures"
      defaultMessage="Supplementary measures"
    />
  ),
  details: (
    <FormattedMessage id="Records.tia.details" defaultMessage="Details" />
  ),
  finalDecision: (
    <FormattedMessage
      id="Records.tia.finalDecision"
      defaultMessage="Final decision"
    />
  ),
  introduction: (
    <FormattedMessage
      id="Records.tia.introduction"
      defaultMessage="Introduction"
    />
  ),
  specificCircumstance: (
    <FormattedMessage
      id="Records.tia.specificCircumstance"
      defaultMessage="Specific circumstance"
    />
  ),
  specificCircumstanceDescription: (
    <FormattedMessage
      id="Records.tia.specificCircumstanceDescription"
      defaultMessage={`In this step we will consider the potential risk or harm that may be caused to a data subject
    as a consequence of their data being transferred to the destination country, taking into account the 
    circumstances of the transfer identified 
    in step 1, and the level of protection provided for in the safeguards as identified in steps 2 and 3,
    and considering any potential shortfall thereof and other real-life variables. This element of the assessment
    is important to help place context to the relative risks posed to the data subject given the particularities
    of the data transfer and whether (n practice) there is a meaningful risk to the safeguards provided to
    the data subject in the EEA/UK being undermined due to these transfers. 
    <newline> In order to perform this assessment please take into consideration any relevant practical experience 
    with prior instances, or the absence of requests for disclosure from public authorities received by the data 
    importer for the type of data transferred, if applicable. </newline>`}
      values={{
        newline: (text) => <div>{text}</div>
      }}
    />
  ),
  severityOfHarm: (
    <FormattedMessage
      id="Records.tia.severityOfHarm"
      defaultMessage="Severity of harm"
    />
  ),
  severityOfHarmDescription: (
    <FormattedMessage
      id="Records.tia.severityOfHarmDescription"
      defaultMessage="
    The potential severity of harm that could occur to the data subject taking into consideration relevant
    factors identified in step 1, such as the nature of the data / data subject and the identified shortfalls in
    steps 2 and 3, including the likely distress an individual might suffer due to the loss of privacy in the
    data, possible sanction faced as a result of processing, such as capital or corporal punishment,
    length and severity of custodial sentence, size of financial penalty, imposition of financial sanctions,
    etc., with a score assigned depending on the perceived severity of the risks
    "
    />
  ),
  severityOfHarmLow: (
    <FormattedMessage
      id="Records.tia.severityOfHarmLow"
      defaultMessage="Low severity of harm"
    />
  ),
  severityOfHarmMedium: (
    <FormattedMessage
      id="Records.tia.severityOfHarmMedium"
      defaultMessage="Medium severity of harm"
    />
  ),
  severityOfHarmHigh: (
    <FormattedMessage
      id="Records.tia.severityOfHarmHigh"
      defaultMessage="High severity of harm"
    />
  ),

  likelihoodOfHarm: (
    <FormattedMessage
      id="Records.tia.likelihoodOfHarm"
      defaultMessage="Probability of harm"
    />
  ),

  likelihoodOfHarmLow: (
    <FormattedMessage
      id="Records.tia.likelihoodOfHarmLow"
      defaultMessage="Low probability of harm"
    />
  ),
  likelihoodOfHarmMedium: (
    <FormattedMessage
      id="Records.tia.likelihoodOfHarmMedium"
      defaultMessage="Medium probability of harm"
    />
  ),
  likelihoodOfHarmHigh: (
    <FormattedMessage
      id="Records.tia.likelihoodOfHarmHigh"
      defaultMessage="High probability of harm"
    />
  ),

  likelihoodOfHarmDescription: (
    <FormattedMessage
      id="Records.tia.likelihoodOfHarmDescription"
      defaultMessage={`The likelihood/probability of harm arising to the data subject, given the circumstances
    in which the transfer Is made and in light of the third country law and practices. This will take into
    consideration relevant factors identified in step 1, such as the nature of the data/data subject and its 
    interest to law enforcement/security establishments, and further elements such as: <newline>-the likelihood 
    that law enforcement/security establishment would request the personal data from the importer or a processor 
    sub-processor rather than from the exporter directly; </newline><newline>-whether the data importer 
    will (to the extent the law permits it) successfully exercise any rights it has to challenge the order 
    for disclosure issued by law enforcement/security establishment (by legal means or otherwise) causing 
    such authorities to give up their requests for the data in plain text;</newline><newline>-the probability 
    that employees of the data importer, or subsequent recipients (subcontractors. affiliates/subsidiaries) 
    technically have access to personal data in plain text outside the envisaged scenarios (e.g. beyond maintenance 
    purposes using admin privileges) or are able to obtain such access (e.g. by installing a backdoor or similar  
    programming to access the system and/or personal data)</newline>`}
      values={{
        newline: (text) => <div>{text}</div>
      }}
    />
  ),

  lowSeverityLevel: (
    <FormattedMessage
      id="Records.tia.lowSeverityLevel"
      defaultMessage="Low severity of harm"
    />
  ),
  mediumSeverityLevel: (
    <FormattedMessage
      id="Records.tia.mediumSeverityLevel"
      defaultMessage="Medium severity of harm"
    />
  ),
  highSeverityLevel: (
    <FormattedMessage
      id="Records.tia.highSeverityLevel"
      defaultMessage="High severity of harm"
    />
  ),

  regulationOnProcessing: (
    <FormattedMessage
      id="Records.tia.regulationOnProcessing"
      defaultMessage="Regulation on processing"
    />
  ),
  regulationOnAccess: (
    <FormattedMessage
      id="Records.tia.regulationOnAccess"
      defaultMessage="Regulation on access"
    />
  ),
  regulatorySupervision: (
    <FormattedMessage
      id="Records.tia.regulatorySupervision"
      defaultMessage="Regulatory supervision"
    />
  ),
  rightsOfRedress: (
    <FormattedMessage
      id="Records.tia.rightsOfRedress"
      defaultMessage="Rights of redress"
    />
  ),
  internationalTreaties: (
    <FormattedMessage
      id="Records.tia.internationalTreaties"
      defaultMessage="International treaties"
    />
  ),
  countryAnalysis: (
    <FormattedMessage
      id="Records.tia.countryAnalysis"
      defaultMessage="Country analysis"
    />
  ),
  autoFillNotification: (
    <FormattedMessage
      id="Records.tia.autoFillNotification"
      defaultMessage="All information will be overwritten by default value. Do you want to continue?"
    />
  ),
  score: <FormattedMessage id="Records.tia.score" defaultMessage="Score" />,
  scoring: (
    <FormattedMessage id="Records.tia.scoring" defaultMessage="Scoring" />
  ),
  currentScoring: (
    <FormattedMessage
      id="Records.tia.currentScoring"
      defaultMessage="Current scoring"
    />
  ),
  enableEditWarning: (
    <FormattedMessage
      id="Records.tia.enableEditWarning"
      defaultMessage="By editing the prefilled information you understand that you are deviating
    from the approved answers from the law firm"
    />
  ),
  autoFillFromCountries: (
    <FormattedMessage
      id="Records.tia.autoFillFromCountries"
      defaultMessage="Autofill from known countries"
    />
  ),
  enableEditing: (
    <FormattedMessage
      id="Records.tia.enableEditing"
      defaultMessage="Enable editing"
    />
  ),
  newTia: (
    <FormattedMessage
      id="Records.newTia"
      defaultMessage="New transfer impact assessment"
    />
  ),
  highestScore: (
    <FormattedMessage
      id="Records.tia.highestScore"
      defaultMessage="Highest score"
    />
  ),
  highest: (
    <FormattedMessage id="Records.tia.highest" defaultMessage="Highest" />
  ),
  average: (
    <FormattedMessage id="Records.tia.average" defaultMessage="Average" />
  ),
  averageScore: (
    <FormattedMessage
      id="Records.tia.averageScore"
      defaultMessage="Average score"
    />
  ),
  dataExporter: (
    <FormattedMessage
      id="Records.tia.dataExporter"
      defaultMessage="Data exporter"
    />
  ),
  dataImporters: (
    <FormattedMessage
      id="Records.tia.dataImporters"
      defaultMessage="Data importers"
    />
  ),
  addDataImporter: (
    <FormattedMessage
      id="Records.tia.addDataImporter"
      defaultMessage="Add data importer"
    />
  ),
  importerCountWarning: (
    <FormattedMessage
      id="Records.tia.importerCountWarning"
      defaultMessage="The accuracy of the assessment may be impacted when having multiple importers"
    />
  ),
  importer: (
    <FormattedMessage id="Records.tia.importer" defaultMessage="Importer" />
  ),
  sector: <FormattedMessage id="Records.tia.sector" defaultMessage="Sector" />,
  contractHolder: (
    <FormattedMessage
      id="Records.tia.contractHolder"
      defaultMessage="Contract holder"
    />
  ),
  includeInCalculation: (
    <FormattedMessage
      id="Records.tia.includeInCalculation"
      defaultMessage="Include in assessment calculation"
    />
  ),
  contractualCounterparty: (
    <FormattedMessage
      id="Records.tia.contractualCounterparty"
      defaultMessage="Contractual counterparty"
    />
  ),
  relationshipOfCounterparty: (
    <FormattedMessage
      id="Records.tia.relationshipOfCounterparty"
      defaultMessage="Relationship of counterparty"
    />
  ),
  riskScoreToUse: (
    <FormattedMessage
      id="Records.tia.riskScoreToUse"
      defaultMessage="Risk score to use"
    />
  ),
  riskScoring: (
    <FormattedMessage
      id="Records.tia.riskScoring"
      defaultMessage="Risk scoring"
    />
  ),
  tiaOverview: (
    <FormattedMessage
      id="Records.tia.Overview"
      defaultMessage="Overview of all sections"
    />
  ),
  PrivacyRole: (
    <FormattedMessage
      id="Records.tia.PrivacyRole"
      defaultMessage="Privacy Role"
    />
  ),
  sectorOfOperation: (
    <FormattedMessage
      id="Records.tia.sectorOfOperation"
      defaultMessage="Sector of operation"
    />
  ),
  dataSourcesAndLocation: (
    <FormattedMessage
      id="Records.tia.dataSourcesAndLocation"
      defaultMessage="Data sources and location"
    />
  ),

  inherentRiskScore: (
    <FormattedMessage
      id="Records.tia.inherentRiskScore"
      defaultMessage="Inherent risk score"
    />
  ),
  calculatedSecMeasuresModifier: (
    <FormattedMessage
      id="Records.tia.calculatedSecMeasuresModifier"
      defaultMessage="Calculated security measures modifier"
    />
  ),
  residualRiskScore: (
    <FormattedMessage
      id="Records.tia.residualRiskScore"
      defaultMessage="Residual risk score"
    />
  ),
  specificCircumstanceModifier: (
    <FormattedMessage
      id="Records.tia.specificCircumstanceModifier"
      defaultMessage="Specific circumstance modifier"
    />
  ),
  finalCalibratedRisk: (
    <FormattedMessage
      id="Records.tia.finalCalibratedRisk"
      defaultMessage="Final calibrated risk"
    />
  ),
  finalOutcome: (
    <FormattedMessage
      id="Records.tia.finalOutcome"
      defaultMessage="Final outcome"
    />
  ),
  riskLevelLow: (
    <FormattedMessage
      id="Records.tia.riskLevelLow"
      defaultMessage="Likely to be a LOW risk"
    />
  ),
  tiaScoreRangeLow: (
    <FormattedMessage id="Records.tia.tiaScoreRangeLow" defaultMessage="Low" />
  ),
  tiaScoreRangeMediumLow: (
    <FormattedMessage
      id="Records.tia.tiaScoreRangeMediumLow"
      defaultMessage="Medium/Low"
    />
  ),
  tiaScoreRangeMediumHigh: (
    <FormattedMessage
      id="Records.tia.tiaScoreRangeMediumHigh"
      defaultMessage="Medium/High"
    />
  ),
  tiaScoreRangeHigh: (
    <FormattedMessage
      id="Records.tia.tiaScoreRangeHigh"
      defaultMessage="High"
    />
  ),
  riskLevelLowGuidance: (
    <FormattedMessage
      id="Records.tia.riskLevelLowGuidance"
      defaultMessage="The residual risk is likely to be low. You may
  decide to proceed with the transfer given the
  safeguards that are in place, but ensure the
  supplementary measures that have been adopted
  are maintained at all times"
    />
  ),
  riskLevelMediumLow: (
    <FormattedMessage
      id="Records.tia.riskLevelMediumLow"
      defaultMessage="Likely to be a MEDIUM/LOW risk"
    />
  ),
  riskLevelMediumLowGuidance: (
    <FormattedMessage
      id="Records.tia.riskLevelMediumLowGuidance"
      defaultMessage="The transfer is likely to carry limited risk. You may
    still proceed with the transfer if you are satisfied
    that measures are sufficient to address identified
    risk and/or have no reason to believe that relevant
    legislation/practices impinging on the transfer
    tool's contractual guarantees will be applied, in
    practice, to your transferred data and/or data
    importer. As appropriate, consider the application
    of derogations in Article 49"
    />
  ),
  riskLevelMediumHigh: (
    <FormattedMessage
      id="Records.tia.riskLevelMediumLow"
      defaultMessage="Likely to be a MEDIUM/HIGH risk"
    />
  ),
  riskLevelMediumHighGuidance: (
    <FormattedMessage
      id="Records.tia.riskLevelMediumHighGuidance"
      defaultMessage="The transfer is unlikely to have adequate
    safeguards in place. You should suspend the
    transfer unless you are satisfied that you can
    apply and maintain additional supplementary
    measures to mitigate risk to acceptable levels.
    Once such additional measures are adopted, the
    assessment shall be run again to verify whether
    the risk is lowered at least to a limited risk. As
    appropriate, consider the application of
    derogations in Article 49"
    />
  ),
  riskLevelHigh: (
    <FormattedMessage
      id="Records.tia.riskLevelHigh"
      defaultMessage="Likely to be a HIGH risk"
    />
  ),
  riskLevelHighGuidance: (
    <FormattedMessage
      id="Records.tia.riskLevelMediumHighGuidance"
      defaultMessage="The transfer will not have appropriate safeguards
    in place and you should not proceed without
    taking further professional advice"
    />
  ),
  noOutcomeWarning: (
    <FormattedMessage
      id="Records.tia.noOutcomeWarning"
      defaultMessage="No data importers included for calculation, final outcome cannot be inferred"
    />
  ),
  transferRisk: (
    <FormattedMessage
      id="Records.tia.transferRisk"
      defaultMessage="Transfer risk"
    />
  ),
  conclusion: (
    <FormattedMessage id="Records.tia.conclusion" defaultMessage="Conclusion" />
  ),
  riskLevel: (
    <FormattedMessage id="Records.tia.riskLevel" defaultMessage="Risk level" />
  ),
  createAssessmentSubTitle: (
    <FormattedMessage
      id="CreateAssessments.nameLabel"
      defaultMessage="Please enter a name for the assessment"
    />
  ),
  createAssessmentHintText: (
    <FormattedMessage
      id="CreateAssessments.nameHint"
      defaultMessage="Assessment name..."
    />
  ),
  upgradeNotification: (
    <FormattedMessage
      id="Plan.upgradeNotification"
      defaultMessage="subscription package needs to be upgraded to have this feature"
    />
  ),
  toggleChangeConfirm: (
    <FormattedMessage
      id="Records.toggleChangeConfirm"
      defaultMessage="You may have some unsaved changes. Please continue after saving."
    />
  ),
  CcpaPurposeProcessing: uppercaseSingular('processingPurpose'),
  outstandingRisks: (
    <FormattedMessage
      id="Records.outstandingRisks"
      defaultMessage="Outstanding risks"
    />
  ),
  unmitigatedDpiaRisks: (
    <FormattedMessage
      id="Records.unmitigatedDpiaRisks"
      defaultMessage="Unmitigated DPIA risks"
    />
  ),
  unresolvedRisks: (
    <FormattedMessage
      id="Records.unresolvedRisks"
      defaultMessage="Unresolved processing risks"
    />
  ),
  appiRisks: (
    <FormattedMessage id="Records.appiRisks" defaultMessage="APPI risks" />
  ),
  pdpaRisks: (
    <FormattedMessage id="Records.pdpaRisks" defaultMessage="PDPA (TW) risks" />
  ),
  ccpaRisks: (
    <FormattedMessage id="Records.ccpaRisks" defaultMessage="CCPA risks" />
  ),
  gdprRisks: (
    <FormattedMessage id="Records.gdprRisks" defaultMessage="GDPR risks" />
  ),
  piplRisks: (
    <FormattedMessage id="Records.piplRisks" defaultMessage="PIPL risks" />
  ),
  lgpdRisks: (
    <FormattedMessage id="Records.lgpdRisks" defaultMessage="LGPD risks" />
  ),
  pdpRisks: (
    <FormattedMessage id="Records.pdpRisks" defaultMessage="PDP risks" />
  ),
  sgpdpaRisks: (
    <FormattedMessage
      id="Records.sgpdpaRisks"
      defaultMessage="PDPA (SG) risks"
    />
  ),
  fdplRisks: (
    <FormattedMessage id="Records.fdplRisks" defaultMessage="FDPL risks" />
  ),
  CcpaHeader: <FormattedMessage id="CCPA.CcpaHeader" defaultMessage="CCPA" />,
  viewRecord: (
    <FormattedMessage
      id="PrivacyRecordList.viewRecordContextMenu"
      defaultMessage="View Record"
    />
  ),
  hasRisks: (
    <FormattedMessage id="riskFilter.hasRisks" defaultMessage="Has risks" />
  ),
  personalDataSaleCompensationDescription: (
    <FormattedMessage
      id="CCPA.financialCompensationDescription"
      defaultMessage="Do you provide financial compensation or incentives to data subjects for the sale of their data"
    />
  ),
  CCPAPersonalDataSaleDataViewHeader: (
    <FormattedMessage
      id="Records.CCPAPersonalDataViewHeader"
      defaultMessage="Financial compensation or incentives "
    />
  ),
  describeHere: (
    <FormattedMessage
      id="CCPA.financialCompensationHint"
      defaultMessage="Description here"
    />
  ),
  underSixteenOptInMeasuresHeader: (
    <FormattedMessage
      id="CCPA.processingUnderAgeHeader"
      defaultMessage="Does this processing activity involves data subjects under age of 16"
    />
  ),
  CCPAUnderSixteenOptInMeasuresView: (
    <FormattedMessage
      id="CCPA.processingUnderAgeHeaderView"
      defaultMessage="Opted measures for data subjects under 16"
    />
  ),
  underSixteenOptInMeasuresDescription: (
    <FormattedMessage
      id="CCPA.processingUnderAgeDescription"
      defaultMessage="Describe opt in measures used for data subjects under 16"
    />
  ),
  provisionRightsToOptOutDescription: (
    <FormattedMessage
      id="CCPA.provisionRightsToOptOutDescription"
      defaultMessage="Specify the provisions for the right to opt out"
    />
  ),
  provisionRightsToOptOutView: (
    <FormattedMessage
      id="CCPA.provisionRightsToOptOutView"
      defaultMessage="Provisions for the right to opt out"
    />
  ),
  DSRMethods: (
    <FormattedMessage
      id="CCPA.DSRMethods"
      defaultMessage="Specify the methods available for DSR"
    />
  ),
  ConspicuousWebsiteDescription: (
    <FormattedMessage
      id="CCPA.ConspicuousWebsiteDescription"
      defaultMessage="Clear and conspicuous link on website"
    />
  ),
  ConspicuousUserRightsDescription: (
    <FormattedMessage
      id="CCPA.ConspicuousUserRightsDescription"
      defaultMessage="Provide link to clearly state user rights"
    />
  ),
  EnterLinkHere: (
    <FormattedMessage
      id="CCPA.EnterLinkHere"
      defaultMessage="Enter Link here"
    />
  ),
  DSRWebsiteLinkDescription: (
    <FormattedMessage
      id="CCPA.DSRWebsiteLinkDescription"
      defaultMessage="Link on website"
    />
  ),
  DSRPhysicalMailAddressDescription: (
    <FormattedMessage
      id="CCPA.DSRPhysicalMailAddressDescription"
      defaultMessage="Physical mailing address"
    />
  ),
  DSRPhysicalAddressTextBoxLabel: (
    <FormattedMessage
      id="CCPA.DSRPhysicalMailAddressTextBoxLabel"
      defaultMessage="Enter details here"
    />
  ),
  DSRPhoneNumberDescription: (
    <FormattedMessage
      id="CCPA.DSRPhoneNumberDescription"
      defaultMessage="Toll free phone number"
    />
  ),
  DSRPhoneNumberTextBoxLabel: (
    <FormattedMessage
      id="CCPA.DSRPhoneNumberTextBoxLabel"
      defaultMessage="Enter number here"
    />
  ),
  DSREmailAddressDescription: (
    <FormattedMessage
      id="CCPA.DSREmailAddressDescription"
      defaultMessage="Email address"
    />
  ),
  DSREmailAddressTextBoxLabel: (
    <FormattedMessage
      id="CCPA.DSREmailAddressTextBoxLabel"
      defaultMessage="Enter email here"
    />
  ),
  DSRPhysicalLocationDescription: (
    <FormattedMessage
      id="CCPA.DSRPhysicalLocationDescription"
      defaultMessage="Physical address"
    />
  ),
  DSROtherDescription: uppercaseSingular('other'),
  DSROtherTextBoxLabel: (
    <FormattedMessage
      id="CCPA.DSROtherTextBoxLabel"
      defaultMessage="Please specify"
    />
  ),
  descriptionHere: (
    <FormattedMessage
      id="CCPA.financialCompensationHint"
      defaultMessage="Description here"
    />
  ),
  riskIdentified: (
    <FormattedMessage
      id="ProcessingDetails.risksHeader"
      defaultMessage="Risks identified"
    />
  ),
  Risks: (
    <FormattedMessage id="PrivacyRecordList.risks" defaultMessage="Risks" />
  ),
  ignoreRisk: (
    <FormattedMessage id="Risks.ignore" defaultMessage="Ignore for now" />
  ),
  gdpr: (
    <FormattedMessage
      id="Processing.Jurisdiction.gdpr"
      defaultMessage="Europe (GDPR)"
    />
  ),
  idpr: (
    <FormattedMessage
      id="Processing.Jurisdiction.idpr"
      defaultMessage="Europe (IDPR)"
    />
  ),
  ukgdpr: (
    <FormattedMessage
      id="Processing.Jurisdiction.ukgdpr"
      defaultMessage="United Kingdom (UK GDPR)"
    />
  ),
  appi: (
    <FormattedMessage
      id="Processing.Jurisdiction.appi"
      defaultMessage="Japan (APPI)"
    />
  ),
  pipl: (
    <FormattedMessage
      id="Processing.Jurisdiction.pipl"
      defaultMessage="China (PIPL)"
    />
  ),
  pdpa: (
    <FormattedMessage
      id="Processing.Jurisdiction.pdpa"
      defaultMessage="Taiwan (PDPA)"
    />
  ),
  sgpdpa: (
    <FormattedMessage
      id="Processing.Jurisdiction.sgpdpa"
      defaultMessage="Singapore (PDPA)"
    />
  ),
  ccpa: (
    <FormattedMessage
      id="Processing.Jurisdiction.ccpa"
      defaultMessage="California (CCPA)"
    />
  ),
  lgpd: (
    <FormattedMessage
      id="Processing.Jurisdiction.lgpd"
      defaultMessage="Brazil (LGPD)"
    />
  ),
  pdp: (
    <FormattedMessage
      id="Processing.Jurisdiction.pdp"
      defaultMessage="India (PDP)"
    />
  ),
  fdpl: (
    <FormattedMessage
      id="Processing.Jurisdiction.fdpl"
      defaultMessage="Mexico (FDPL)"
    />
  ),
  addJurisdiction: (
    <FormattedMessage
      id="Records.addJurisdictions"
      defaultMessage="Add jurisdictions"
    />
  ),
  jurisdictions: (
    <FormattedMessage
      id="Records.Jurisdictions"
      defaultMessage="Jurisdictions"
    />
  ),
  assignRoles: (
    <FormattedMessage id="Records.assignRoles" defaultMessage="Assign roles" />
  ),
  AssignProcessorRoles: (
    <FormattedMessage
      id="Records.AssignProcessorRoles"
      defaultMessage="Assign processor roles"
    />
  ),
  serviceProvider: (
    <FormattedMessage
      id="Processor.role.serviceProvider"
      defaultMessage="Service provider"
    />
  ),
  none: uppercaseSingular('none'),
  thirdPartyVendor: (
    <FormattedMessage
      id="Processor.role.thirdPartyVendor"
      defaultMessage="Third party vendor"
    />
  ),
  contractor: (
    <FormattedMessage
      id="Processor.role.contractor"
      defaultMessage="Contractor"
    />
  ),
  descriptionHint: (
    <FormattedMessage
      id="AssessmentDetails.descriptionHint"
      description="Hint text for assessment description"
      defaultMessage="Description of the assessment project..."
    />
  ),
  purposeLimitation: (
    <FormattedMessage
      id="Record.purposesHeader"
      description="Purpose limitation"
      defaultMessage="Purpose limitation"
    />
  ),
  legalBasis: (
    <FormattedMessage
      id="AssessmentDetails.legalBasisHeader"
      description="Legal basis"
      defaultMessage="Legal basis"
    />
  ),
  dataMinimization: (
    <FormattedMessage
      id="AssessmentDetails.dataMinimisationHeader"
      description="Data minimisation"
      defaultMessage="Data minimisation"
    />
  ),
  storageLimitation: (
    <FormattedMessage
      id="AssessmentDetails.storageLimitations"
      description="Storage limitation"
      defaultMessage="Storage limitation"
    />
  ),
  accuracy: (
    <FormattedMessage
      id="AssessmentDetails.dataQualityHeader"
      description="Data quality"
      defaultMessage="Data quality"
    />
  ),
  processingHint: (
    <FormattedMessage
      id="ProcessingDetails.nameHint"
      description="Hint text for processing name"
      defaultMessage="Enter the name of your processing"
    />
  ),
  groupRemovalWarning: (
    <FormattedMessage
      id="Records.groupRemoveWarning"
      defaultMessage="Groups related to this record, if any, would also get removed."
    />
  ),
  changeJurisdiction: (
    <FormattedMessage
      id="Records.changeJurisdiction"
      defaultMessage="Change jurisdiction"
    />
  ),
  removeGroupItemsConsent: (
    <FormattedMessage
      id="Masterdata.removeGroupItems.consent"
      defaultMessage="Remove items that belongs to groups from the record"
    />
  ),
  bulkUpdate: (
    <FormattedMessage id="Records.bulkUpdate" defaultMessage="Bulk update" />
  ),
  changeUpdateTypeConfirmation: (
    <FormattedMessage
      id="BulkUpdate.changeUpdateType.confirmation"
      defaultMessage="Changing the update type would clear the selected data. Are you sure you want to continue?"
    />
  ),
  changeUpdateType: (
    <FormattedMessage
      id="BulkUpdate.changeUpdateType"
      defaultMessage="Change update type"
    />
  ),
  modifiedRecords: (
    <FormattedMessage
      id="Job.bulkUpdate.modifiedRecords"
      defaultMessage="Modified records"
    />
  ),
  complianceAndRisk: (
    <FormattedMessage
      id="Records.complianceAndRisk"
      defaultMessage="Compliance & Risk"
    />
  ),
  recordsSelected: (
    <FormattedMessage
      id="Records.recordsSelected"
      defaultMessage="Total records selected"
    />
  ),
  selectGroups: (
    <FormattedMessage
      id="Records.selectGroups"
      defaultMessage="Select groups"
    />
  ),
  updateType: (
    <FormattedMessage id="Records.updateType" defaultMessage="Update type" />
  ),
  CCPAPersonalDataSaleCompensation: (
    <FormattedMessage
      id="Records.CCPAPersonalDataSaleCompensation"
      defaultMessage="Personal data sale compensation"
    />
  ),
  CCPAUnderSixteenOptInMeasures: (
    <FormattedMessage
      id="Records.CCPAUnderSixteenOptInMeasures"
      defaultMessage="Under sixteen opt in measures"
    />
  ),
  CCPAOptOutWebsiteLink: (
    <FormattedMessage
      id="Records.CCPAOptOutWebsiteLink"
      defaultMessage="Opt out website link"
    />
  ),
  CCPAOptOutWebsiteLinkView: (
    <FormattedMessage
      id="Records.CCPAOptOutWebsiteLinkView"
      defaultMessage="Opted website link"
    />
  ),
  CCPAOptOutUserRightsLink: (
    <FormattedMessage
      id="Records.CCPAOptOutUserRightsLink"
      defaultMessage="Opt out user rights link"
    />
  ),
  CCPAOptOutUserRightsLinkView: (
    <FormattedMessage
      id="Records.CCPAOptOutUserRightsLinkView"
      defaultMessage="Link to state user Rights"
    />
  ),
  CCPADsrMethods: (
    <FormattedMessage
      id="Records.CCPADsrMethods"
      defaultMessage="DSR Methods"
    />
  ),
  processingStatus: (
    <FormattedMessage
      id="ProcessingDetails.status"
      defaultMessage="Processing status"
    />
  ),
  updateTypeEmpty: (
    <FormattedMessage
      id="BulkUpdate.updateTypeEmpty"
      defaultMessage="An update type is not selected, please select one before continuing"
    />
  ),
  preDpiaRiskEstimate: (
    <FormattedMessage
      id="DPIA.preDpiaRiskEstimate"
      defaultMessage="Pre-DPIA risk estimate"
    />
  ),
  residualRisks: (
    <FormattedMessage id="DPIA.residualRisks" defaultMessage="Residual risks" />
  ),
  overallOutcome: (
    <FormattedMessage
      id="DPIA.overallOutcome"
      defaultMessage="Current overall outcome"
    />
  ),
  noRisks: (
    <FormattedMessage
      id="PrivacyRecordList.noRisks"
      defaultMessage="No risks"
    />
  ),
  groups: (
    <FormattedMessage
      id="LibraryFilter.personaldatalinks"
      defaultMessage="Groups"
    />
  ),
  requestedRecords: (
    <FormattedMessage
      id="Records.bulkUpdateImpossible"
      defaultMessage="Please select records of status other than requested. "
    />
  ),
  differentJurisdictions: (
    <FormattedMessage
      id="Records.differentJurisdictions"
      defaultMessage="Please select records of same jurisdiction. "
    />
  ),
  bulkUpdateDescription: (
    <FormattedMessage
      id="Records.bulkUpdateDescription"
      defaultMessage="The following records will be updated based on your selection."
    />
  ),
  bulkUpdateMasterdata: (
    <FormattedMessage
      id="Records.bulkUpdateMasterdata"
      defaultMessage="The following items will be updated in records."
    />
  ),
  bulkEmptyMasterdata: (
    <FormattedMessage
      id="Records.bulkEmptyMasterdata"
      defaultMessage="The following items will be emptied in records."
    />
  ),
  bulkUpdateCancelConfirmation: (
    <FormattedMessage
      id="Records.bulkUpdateCancelConfirmation"
      defaultMessage="You may lose the selected master data items. Are you sure that you want to cancel?"
    />
  ),
  bulkUpdateExplanation: (
    <FormattedMessage
      id="bulkUpdate.featureExplanation"
      defaultMessage="With bulk updates you can make the same edits in up to 25 processings records at once.
    For instance, to remove one or several personal data items you no longer process from multiple records.
    Or to replace the processor in multiple records with a new one.
    There are four types of bulk updates: add, remove, replace and clear
      <newline>Add: will add items you include in the bulk update to the selected fields.</newline>
      <newline>Remove: will remove items you include in the bulk update from the selected fields.</newline>
      <newline>Replace: will replace all items from the selected fields with the
        items you include in the bulk update.​</newline>
      <newline>Clear: will clear all items from the selected fields.</newline>"
      values={{
        newline: (text) => <div>{text}</div>
      }}
    />
  ),
  skipAndContinue: (
    <FormattedMessage
      id="Records.skipAndSubmit"
      defaultMessage="Skip and continue"
    />
  ),
  cannotUpdate: (
    <FormattedMessage
      id="Records.cannotUpdate"
      defaultMessage="Following records cannot be updated due to the below mentioned reasons."
    />
  ),
  updateNone: (
    <FormattedMessage
      id="Records.updateNone"
      defaultMessage="None of the selected records can be updated due to the below mentioned reasons."
    />
  ),
  internationalTransfer: uppercasePlural('internationalTransfers'),
  intlHintText: (
    <FormattedMessage
      id="bulkUpdate.intlTransferHintText"
      defaultMessage="Selected processings includes transfer to a third country or international organisation."
    />
  ),
  processorCategoryAddHintText: (
    <FormattedMessage
      id="ProcessingDetails.processorCategoryAddHintText"
      defaultMessage="Add a processing category..."
    />
  ),
  selectStatusHintText: (
    <FormattedMessage
      id="bulkUpdates.selectStatusHintText"
      defaultMessage="Select status of the record"
    />
  ),
  selectUpdateTypeHintText: (
    <FormattedMessage
      id="bulkUpdates.selectUpdateTypeHintText"
      defaultMessage="Select an update type"
    />
  ),
  chooseYesOrNo: (
    <FormattedMessage
      id="bulkUpdates.chooseYesOrNo"
      defaultMessage="Choose yes or no"
    />
  ),
  organisationRights: uppercasePlural('permissionsOrgHeader'),
  userRights: uppercasePlural('users'),
  attachmentsAndPermissions: (
    <FormattedMessage
      id="Records.attachmentsAndPermissions"
      defaultMessage="Attachments and Permissions"
    />
  ),
  permissions: (
    <FormattedMessage id="Records.permissions" defaultMessage="Permissions" />
  ),
  pleaseNote: (
    <FormattedMessage id="Records.pleaseNote" defaultMessage="Please note" />
  ),
  addRemoveGroupConfirmation: (
    <FormattedMessage
      id="Records.addRemoveGroupConfirmation"
      defaultMessage="When a group is added/removed, respective changes are made to the associated record as well."
    />
  ),
  removeProcessorConfirmation: (
    <FormattedMessage
      id="Records.removeProcessorConfirmation"
      defaultMessage="When Processors are removed, their sub-processors will also be removed. "
    />
  ),
  fieldsEmptyError: (
    <FormattedMessage
      id="Error.fieldsEmptyError"
      defaultMessage="At least one field must be selected"
    />
  ),
  NecessityAndProportionalityRiskSummary: (
    <FormattedMessage
      id="Records.NecessityAndProportionalityRiskSummary"
      defaultMessage="Necessity and proportionality risk summary"
    />
  ),
  DataSubjectRightsRiskSummary: (
    <FormattedMessage
      id="Records.DataSubjectRightsRiskSummary"
      defaultMessage="Data subject rights risk summary"
    />
  ),
  SecurityMeasuresRiskSummary: (
    <FormattedMessage
      id="Records.SecurityMeasuresRiskSummary"
      defaultMessage="Security measures risk summary"
    />
  ),
  ThreatImpactRiskSummary: (
    <FormattedMessage
      id="Records.ThreatImpactRiskSummary"
      defaultMessage="Threat impact risk summary"
    />
  ),
  OverallRiskSummary: (
    <FormattedMessage
      id="Records.OverallRiskSummary"
      defaultMessage="Overall risk summary"
    />
  ),
  dpoConsultation: (
    <FormattedMessage
      id="Records.dpoConsultation"
      defaultMessage="Data Protection Officers"
    />
  ),
  dpoOrg: (
    <FormattedMessage id="Records.dpoOrg" defaultMessage="DPO organisations" />
  ),
  reviewDetails: (
    <FormattedMessage
      id="Records.reviewDetails"
      defaultMessage="Review details"
    />
  ),
  enableReviewRecord: (
    <FormattedMessage
      id="Records.enableReviewRecord"
      defaultMessage="Enable review for this record"
    />
  ),
  dateLastApproval: (
    <FormattedMessage
      id="Records.dateLastApproval"
      defaultMessage="Date of last approval"
    />
  ),
  dateNextReview: (
    <FormattedMessage
      id="Records.dateNextReview"
      defaultMessage="Date of next review"
    />
  ),
  dateNextReviewError: (nextDate) => (
    <FormattedMessage
      id="Records.dateNextReviewError"
      defaultMessage="Date of next review should be on or after {date}"
      values={{ date: nextDate }}
    />
  ),
  quickSet: (
    <FormattedMessage id="Records.quickSet" defaultMessage="Quick set" />
  ),
  sixMonths: (
    <FormattedMessage id="Records.sixMonths" defaultMessage="6 months" />
  ),
  oneYear: <FormattedMessage id="Records.oneYear" defaultMessage="1 year" />,
  threeYears: (
    <FormattedMessage id="Records.threeYears" defaultMessage="3 years" />
  ),
  setReview: (
    <FormattedMessage id="Records.setReview" defaultMessage="Set review" />
  ),
  setReviewDate: (
    <FormattedMessage
      id="Records.setReviewDate"
      defaultMessage="Would you like to set a review date for this record?"
    />
  ),
  approveWithReview: (
    <FormattedMessage
      id="Records.approveWithReview"
      defaultMessage="Approve with review"
    />
  ),
  approveWithoutReview: (
    <FormattedMessage
      id="Records.approveWithoutReview"
      defaultMessage="Approve without review"
    />
  ),
  companyAccess: uppercaseSingular('companyAccess'),
  general: uppercaseSingular('general'),
  controller: smallcaseSingular('controller'),
  dpoHeader: uppercasePlural('dpoHeader'),
  dataProtectionOfficer: uppercaseSingular('dataProtectionOfficer'),
  selectDpo: selectTranslations('selectDpo'),
  removeDpo: selectTranslations('removeDpo'),
  dpoRemoveConfirmation: (
    <FormattedMessage
      id="Records.dpoRemoveConfirmation"
      defaultMessage="The responsible person associated with this organisation will be removed"
    />
  ),
  noDpoName: (
    <FormattedMessage
      id="Records.dpoName"
      description="No name"
      defaultMessage="No name provided"
    />
  ),
  representative: uppercaseSingular('representative'),
  dpoPermission: (
    <FormattedMessage
      id="Records.dpoPermission"
      description="Dpo permission"
      defaultMessage="You do not have enough permission to create or update contact details.
    Please contact your administrator."
    />
  ),
  changeDpo: (
    <FormattedMessage
      id="Records.changeDpo"
      description="Change Dpo"
      defaultMessage="You are about to change the contact details of the data protection officer for this records.
    Do you wish to proceed?"
    />
  ),
  additional_info: (
    <FormattedMessage
      id="DSR.additionalInformation"
      description="Additional information on the requester"
      defaultMessage="Additional information on the requester"
    />
  ),
  pdfExportHint: (
    <FormattedMessage
      id="Records.pdfExportHint"
      description="Export record as PDF file"
      defaultMessage="Export record as PDF file"
    />
  ),
  pdfBulkExportHint: (
    <FormattedMessage
      id="Records.pdfBulkExportHint"
      description="Export as PDF archive file"
      defaultMessage="Export as PDF archive file"
    />
  ),
  graphicalView: uppercaseSingular('graphicalView'),
  formView: uppercaseSingular('formView'),
  showRiskHint: uppercaseSingular('showRiskHint'),
  hideRiskHint: uppercaseSingular('hideRiskHint'),
  Document: smallcaseSingular('Document'),
  organisation: smallcaseSingular('aclOrgs'),
  fieldVisiblityNotification: (
    <FormattedMessage
      id="Records.fieldVisiblityNotification"
      defaultMessage="Unchecking this item will disable its sub items"
    />
  ),
  involvedOrgAndDataSource: (
    <FormattedMessage
      id="Records.involvedOrgAndDataSource"
      description="Organisations and data sources involved with the data transfer"
      defaultMessage="Organisations and data sources involved with the data transfer"
    />
  ),
  companyAccessHelpNotes: (
    <FormattedMessage
      id="Records.helpNotes.companyAccess"
      description="Help notes for company access"
      defaultMessage="With this option you can give all users in your tenant access to this record."
    />
  ),
  confirmSaveAndClose: (
    <FormattedMessage
      id="Records.SaveAndCloseMessage"
      description="Message to confirm save and close"
      defaultMessage="Please be aware no organisation has been added at permissions, this means
     the record will not be public and only visible to you. Do you want to proceed?"
    />
  ),
  companyAccessConfirmMessage: (
    <FormattedMessage
      id="Records.companyAccess.confirm"
      description="Confirm message for company access"
      defaultMessage={
        'You are about to make this record available to all users in your organisation. ' +
        'Do you want to continue?'
      }
    />
  ),
  lastChanged: uppercaseSingular('lastChanged'),
  dataRecipientCategory: uppercaseSingular('dataRecipientCategory'),
  defaultEntity: uppercasePlural('defaultEntity'),
  defaultController: uppercasePlural('defaultController'),
  defaultProcessor: uppercasePlural('defaultProcessor'),
  defaultExporters: (
    <FormattedMessage
      id="PrivacyRecordList.NoExporters"
      defaultMessage="No exporters"
    />
  ),
  defaultImporters: (
    <FormattedMessage
      id="PrivacyRecordList.NoImporters"
      defaultMessage="No importers"
    />
  ),
  deleteNote: (
    <FormattedMessage
      id="Record.deleteNote"
      description="Do you want to delete the note?"
      defaultMessage="Do you want to delete the note?"
    />
  ),
  AddAssessment: addTranslations('AddAssessment'),
  Stakeholders: uppercasePlural('Stakeholders'),
  stakeholdersSubtitle: (
    <FormattedMessage
      id="Record.stakeholdersSubTitle"
      description="Stakeholders subtitle"
      defaultMessage={
        'Enter controllers, processors, executing entities,' +
        'data recipients, number of data subjects, etc'
      }
    />
  ),
  processedDataSubtitle: (
    <FormattedMessage
      id="ProcessingDetails.processedDataSubTitle"
      description="Processed data subtitle"
      defaultMessage={
        'Enter data subject categories, personal data categories, personal data items,' +
        'data sources, retention terms, etc'
      }
    />
  ),
  legalQualificationSubTitle: (
    <FormattedMessage
      id="ProcessingDetails.legalQualificationSubTitle"
      description="Legal Qualification subtitle"
      defaultMessage={
        'Enter Purpose for processing, Processing grounds,' +
        ' Purpose for transfer, Transfer grounds'
      }
    />
  ),
  preAssessment: uppercaseSingular('preAssessment'),
  fullAssessment: uppercaseSingular('fullAssessment'),
  lowRiskLevelText: (
    <FormattedMessage
      id="Record.lowRiskLevelText"
      description="Low risk text"
      defaultMessage="We always recommend to do a full assessment, but there seems to be a low risk."
    />
  ),
  mediumRiskLevelText: (
    <FormattedMessage
      id="Record.mediumRiskLevelText"
      description="Medium risk text"
      defaultMessage="We always recommend to do a full assessment, but there seems to be a medium risk."
    />
  ),
  highRiskLevelText: (
    <FormattedMessage
      id="Record.highRiskLevelText"
      description="High risk text"
      defaultMessage="We highly recommend you do a full assessment."
    />
  ),
  mediumRisk: (
    <FormattedMessage id="Record.mediumRisk" defaultMessage="medium risk" />
  ),
  highRisk: (
    <FormattedMessage id="Record.highRisk" defaultMessage="high risk" />
  ),
  LinksComment: (
    <FormattedMessage id="Record.linksComment" defaultMessage="Links comment" />
  ),
  PurposesComment: (
    <FormattedMessage
      id="Record.purposesComment"
      defaultMessage="Purposes comment"
    />
  ),
  ProcessingGroundsComment: (
    <FormattedMessage
      id="Record.processingGroundsComment"
      defaultMessage="Processing grounds comment"
    />
  ),
  PersonalDataItemsComment: (
    <FormattedMessage
      id="Record.personalDataItemsComment"
      defaultMessage="Personal data items comment"
    />
  ),
  RetentionTermsComment: (
    <FormattedMessage
      id="Record.retentionTermsComment"
      defaultMessage="Retention terms comment"
    />
  ),
  PurposesOfTransferComment: (
    <FormattedMessage
      id="Record.purposesOfTransferComment"
      defaultMessage="Purposes of transfer comment"
    />
  ),
  TransferGroundsComment: (
    <FormattedMessage
      id="Record.transferGroundsComment"
      defaultMessage="Transfer grounds comment"
    />
  ),
  QualityControlsComment: (
    <FormattedMessage
      id="Record.qualityControlsComment"
      defaultMessage="Quality controls comment"
    />
  ),
  InformationRightsComment: (
    <FormattedMessage
      id="Record.informationRightsComment"
      defaultMessage="Information rights comment"
    />
  ),
  ConsentRightsComment: (
    <FormattedMessage
      id="Record.consentRightsComment"
      defaultMessage="Consent rights comment"
    />
  ),
  AccessRightsComment: (
    <FormattedMessage
      id="Record.accessRightsComment"
      defaultMessage="Access rights comment"
    />
  ),
  ObjectionRightsComment: (
    <FormattedMessage
      id="Record.objectionRightsComment"
      defaultMessage="Objection rights comment"
    />
  ),
  DeletionRightsComment: (
    <FormattedMessage
      id="Record.deletionRightsComment"
      defaultMessage="Deletion rights comment"
    />
  ),
  DataPortabilityRightsComment: (
    <FormattedMessage
      id="Record.dataPortabilityRightsComment"
      defaultMessage="Data portability rights comment"
    />
  ),
  TechnicalSecurityMeasuresComment: (
    <FormattedMessage
      id="Record.TechnicalSecurityMeasuresComment"
      defaultMessage="Technical security measures comment"
    />
  ),
  OrganisationalSecurityMeasuresComment: (
    <FormattedMessage
      id="Record.organisationalSecurityMeasuresComment"
      defaultMessage="Organisational security measures comment"
    />
  ),
  ConfidentialityThreatsComment: (
    <FormattedMessage
      id="Record.confidentialityThreatsComment"
      defaultMessage="Comment for confidentiality"
    />
  ),
  IntegrityThreatsComment: (
    <FormattedMessage
      id="Record.integrityThreatsComment"
      defaultMessage="Comment for integrity"
    />
  ),
  AvailabilityThreatsComment: (
    <FormattedMessage
      id="Record.availabilityThreatsComment"
      defaultMessage="Comment for availability"
    />
  ),
  PoliciesComment: (
    <FormattedMessage
      id="Record.policiesComment"
      defaultMessage="Policies comment"
    />
  ),
  DataProtectionMeasuresComment: (
    <FormattedMessage
      id="Record.dataProtectionMeasuresComment"
      defaultMessage="Data protection measures comment"
    />
  ),
  AccountabilityMeasuresComment: (
    <FormattedMessage
      id="Record.accountabilityMeasuresComment"
      defaultMessage="Accountability measures comment"
    />
  ),
  DataSubjectCategoriesComment: (
    <FormattedMessage
      id="Record.dataSubjectCategoriesComment"
      defaultMessage="Data subject categories comment"
    />
  ),
  DPOComment: (
    <FormattedMessage id="Record.dpoComment" defaultMessage="DPO comment" />
  ),
  SupervisoryAuthoritiesComment: (
    <FormattedMessage
      id="Record.supervisoryAuthoritiesComment"
      defaultMessage="Supervisory authorities comment"
    />
  ),
  risk: uppercaseSingular('risk'),
  noRiskLevel: uppercaseSingular('noRiskLevel'),
  lowRiskLevel: uppercaseSingular('lowRiskLevel'),
  mediumRiskLevel: uppercaseSingular('mediumRiskLevel'),
  highRiskLevel: uppercaseSingular('highRiskLevel'),
  highRiskText: uppercaseSingular('highRiskText'),
  mediumRiskText: uppercaseSingular('mediumRiskText'),
  mitigated: uppercaseSingular('mitigated'),
  unMitigated: uppercaseSingular('unMitigated'),
  riskEstimate: uppercaseSingular('riskEstimate'),
  riskDetails: smallcaseSingular('riskDetails'),
  riskDetail: uppercasePlural('riskDetail'),
  mitigatingMeasures: uppercasePlural('mitigatingMeasures'),
  item: uppercaseSingular('item'),
  acceptRecord: uppercaseSingular('acceptRecord'),
  rejectRecord: uppercaseSingular('rejectRecord'),
  csvFull: uppercaseSingular('csvFull'),
  addprocessing: addTranslations('addprocessing'),
  Datarecipient: uppercaseSingular('Datarecipient'),
  Permission: uppercasePlural('Permission'),
  DataRecipientCategory: uppercasePlural('dataRecipientCategories'),
  NoLikelyRiskForDataSubject: (
    <FormattedMessage
      id="SupervisoryAuthorityReasonType.NoLikelyRiskForDataSubject"
      description="No likely risk for the data subject"
      defaultMessage="No likely risk for the data subject"
    />
  ),
  permissionDescription: (
    <FormattedMessage
      id="Record.permissionsDescription"
      description="Privacy record Details permission description"
      defaultMessage={
        'Add users below to give them access to this privacy record ' +
        'and to change it . Each added user will receive a notification by e-mail..'
      }
    />
  ),
  attachmentDescription: (
    <FormattedMessage
      id="Record.attachmentsSubTitle"
      description="Attachments subtitle"
      defaultMessage="Attach documents that support this privacy record."
    />
  ),
  nameRequired: (
    <FormattedMessage
      id="AssessmentDetails.nameRequired"
      description="Providing a name for this privacy record is required"
      defaultMessage="Providing a name for this privacy record is required"
    />
  ),
  dataItemGroup: (
    <FormattedMessage
      id="linkGroup.dataItemGroup"
      description="Data Item Group"
      defaultMessage="data item group"
    />
  ),
  viewChangeLog: uppercaseSingular('viewChangeLog'),
  copyRecord: uppercaseSingular('copyRecord'),
  selectControllers: selectTranslations('selectControllers'),
  controllerHintText: addTranslations('controllerHintText'),
  processorHintText: addTranslations('processorHintText'),
  dataSubjectCategory: smallcaseSingular('dataSubjectCategory'),
  addDataSubjectCategoryHintText: addTranslations(
    'addDataSubjectCategoryHintText'
  ),
  addSecurityMeasuresDescriptionHintText: addTranslations(
    'addSecurityMeasuresDescriptionHintText'
  ),
  legitimatetransferPurposeHint: uppercaseSingular(
    'legitimatetransferPurposeHint'
  ),
  transferPurpose: uppercaseSingular('transferPurpose'),
  TransferGrounds: smallcaseSingular('TransferGrounds'),
  transferGroundHintText: addTranslations('transferGroundHintText'),
  executingEntitiesDialogHeader: selectTranslations(
    'executingEntitiesDialogHeader'
  ),
  executingEntity: smallcaseSingular('executingEntity'),
  promoteToProcessing: uppercaseSingular('promoteToProcessing'),
  promoteToTia: (
    <FormattedMessage
      id="RecordDetails.promoteToTia"
      defaultMessage="Promote to TIA"
    />
  ),
  promoteToDpia: (
    <FormattedMessage
      id="RecordDetails.promoteToDpia"
      defaultMessage="Promote to DPIA"
    />
  ),
  recordLockedMessage: (
    <FormattedMessage
      id="ProcessingDetails.recordLockedMessage"
      description="Privacy record has has been changed by another user"
      defaultMessage={
        'This privacy record has has been changed by another user. ' +
        'Therefore you can\'t save the changes to this record. Do you want to discard the changes or ' +
        'do you want to create a new privacy record which contain the changes you\'ve made?'
      }
    />
  ),
  promoteToAssessment: uppercaseSingular('promoteToAssessment'),
  promoteToBreach: uppercaseSingular('promoteToBreach'),
  executingEntityType: uppercaseSingular('executingEntityType'),
  executingEntityHintText: addTranslations('executingEntityHintText'),
  dpoHintText: addTranslations('dpoHintText'),
  dataRecipientsDialogHeader: selectTranslations('dataRecipientsDialogHeader'),
  dataRecipient: smallcaseSingular('dataRecipient'),
  securityMeasure: smallcaseSingular('securityMeasure'),
  attachments: uppercasePlural('attachments'),
  attachmentsLabel: smallcasePlural('attachmentsLabel'),
  addAttachmentsHintText: addTranslations('addAttachmentsHintText'),
  attachmentsDialogHeader: selectTranslations('attachmentsDialogHeader'),
  dataRecipientsHintText: addTranslations('dataRecipientsHintText'),
  processedData: uppercaseSingular('processedData'),
  documents: uppercasePlural('documents'),
  personalDataItemsHintText: addTranslations('personalDataItemsHintText'),
  personalDataCategoriesHintText: addTranslations(
    'personalDataCategoriesHintText'
  ),
  dataSourcesHintText: addTranslations('dataSourcesHintText'),
  retentionTermHintText: addTranslations('retentionTermHintText'),
  dataSourceCategoriesHintText: addTranslations('dataSourceCategoriesHintText'),
  legalQualifications: uppercasePlural('legalQualifications'),
  legalQualificationsJurisdiction: uppercaseSingular(
    'legalQualificationsJurisdiction'
  ),
  legalQualificationsLaw: uppercaseSingular('legalQualificationsLaw'),
  linkGroupCreated: (
    <FormattedMessage
      id="RecordDetails.linkGroupCreated"
      description="Link group created successfully"
      defaultMessage="Link group created successfully"
    />
  ),
  linkGroupUpdated: (
    <FormattedMessage
      id="RecordDetails.linkGroupUpdated"
      description="Link group updated successfully"
      defaultMessage="Link group updated successfully"
    />
  ),
  processingGround: smallcaseSingular('processingGround'),
  processingGroundHintText: addTranslations('processingGroundHintText'),
  internationalTransfers: uppercasePlural('internationalTransfers'),
  permissionsOrgHeader: uppercasePlural('permissionsOrgHeader'),
  aclOrgs: smallcaseSingular('aclOrgs'),
  addPermissionsOrgHintText: addTranslations('addPermissionsOrgHintText'),
  User: smallcaseSingular('User'),
  permissionsDialogHeader: selectTranslations('permissionsDialogHeader'),
  type: uppercaseSingular('type'),
  recordId: uppercaseSingular('recordId'),
  dataItemType: uppercaseSingular('dataItemType'),
  dataItemName: uppercaseSingular('dataItemName'),
  noOfRecords: uppercasePlural('noOfRecords'),
  operation: uppercaseSingular('operation'),
  controllers: uppercasePlural('controllers'),
  Controller: uppercaseSingular('Controller'),
  executingEntities: uppercasePlural('executingEntities'),
  'Executing entity': uppercaseSingular('Executing entity'),
  'Data recipient': uppercaseSingular('Data recipient'),
  Processor: uppercaseSingular('Processor'),
  status: uppercaseSingular('status'),
  personalDataItem: uppercaseSingular('personalDataItem'),
  personalDataItems: uppercasePlural('personalDataItems'),
  personalDataCategories: uppercasePlural('personalDataCategories'),
  personalDataLinks: uppercasePlural('personalDataLinks'),
  dataItemGroups: uppercasePlural('dataItemGroups'),
  dataRecipients: uppercasePlural('dataRecipients'),
  dataRecipientCategories: uppercasePlural('dataRecipientCategories'),
  dataSources: uppercasePlural('dataSources'),
  dataSource: uppercaseSingular('dataSource'),
  retentionTerm: uppercaseSingular('retentionTerm'),
  retentionTerms: uppercasePlural('retentionTerms'),
  dataSourceCategories: uppercasePlural('dataSourceCategories'),
  hostedBy: uppercaseSingular('hostedBy'),
  contactPerson: uppercaseSingular('contactPerson'),
  dataStorageCountry: uppercaseSingular('dataStorageCountry'),
  requestApproval: uppercaseSingular('requestApproval'),
  markProvisional: uppercaseSingular('markProvisional'),
  dataSubjectCategories: uppercasePlural('dataSubjectCategories'),
  subProcessors: uppercasePlural('subProcessors'),
  subProcessor: uppercaseSingular('subProcessor'),
  processingCategories: uppercasePlural('processingCategories'),
  processingCategory: uppercaseSingular('processingCategory'),
  transferGrounds: uppercasePlural('transferGrounds'),
  processingGrounds: uppercasePlural('processingGrounds'),
  processinggrounds: addTranslations('processinggrounds'),
  numOfDataSubjects: uppercasePlural('numOfDataSubjects'),
  processingCharacteristics: uppercasePlural('processingCharacteristics'),
  startDate: uppercaseSingular('startDate'),
  endDate: uppercaseSingular('endDate'),
  processingRisks: uppercasePlural('processingRisks'),
  necessities: uppercaseSingular('necessities'),
  processors: uppercasePlural('processors'),
  name: uppercaseSingular('name'),
  description: uppercaseSingular('description'),
  outcome: uppercaseSingular('outcome'),
  outcomeHeader: uppercaseSingular('outcomeHeader'),
  sectionOutcome: uppercaseSingular('sectionOutcome'),
  chapterOutcome: uppercaseSingular('chapterOutcome'),
  assessmentPurpose: uppercaseSingular('assessmentPurpose'),
  processingPurpose: uppercaseSingular('processingPurpose'),
  addProcessingPurpose: addTranslations('addProcessingPurpose'),
  addAssessmentPurpose: addTranslations('addAssessmentPurpose'),
  addTransferPurpose: addTranslations('addTransferPurpose'),
  addPermissionsHintText: addTranslations('addPermissionsHintText'),
  orgDialogHeader: selectTranslations('orgDialogHeader'),
  sendForApproval: (
    <FormattedMessage
      id="Record.claimedByUser"
      description="Record already claimed."
      defaultMessage="The record you are trying to edit has already been claimed."
    />
  ),
  addNoteHintText: (
    <FormattedMessage
      id="Record.addNoteHint"
      description="Add note hint text"
      defaultMessage="Type your note and click Enter to save"
    />
  ),
  purpose: uppercaseSingular('purpose'),
  purposes: uppercasePlural('purposes'),
  purposesOfTransfer: uppercasePlural('purposesOfTransfer'),
  qualityControls: uppercasePlural('qualityControls'),
  purposeOfTransfer: uppercaseSingular('purposeOfTransfer'),
  technicalSecurityMeasures: uppercasePlural('technicalSecurityMeasures'),
  organisationalSecurityMeasures: uppercasePlural(
    'organisationalSecurityMeasures'
  ),
  breach: smallcaseSingular('breach'),
  template: uppercasePlural('template'),
  linkedBreaches: uppercasePlural('linkedBreaches'),
  natureOfBreach: uppercaseSingular('natureOfBreach'),
  breachConsequences: uppercasePlural('breachConsequences'),
  supervisoryNotification: uppercaseSingular('supervisoryNotification'),
  supervisoryAuthorities: uppercasePlural('supervisoryAuthorities'),
  supervisoryAuthority: smallcaseSingular('supervisoryAuthority'),
  dataSubjectNotification: uppercaseSingular('dataSubjectNotification'),
  breachGrounds: uppercasePlural('breachGrounds'),
  awareness: uppercaseSingular('awareness'),
  awarenessDate: uppercaseSingular('awarenessDate'),
  awarenessTime: uppercaseSingular('awarenessTime'),
  numOfPersonalDataRecords: (
    <FormattedMessage
      id="Record.numOfPersonalDataRecords"
      description="Select the number of breach personal data records"
      defaultMessage="Number of personal data records"
    />
  ),
  securityMeasuresDescription: uppercasePlural('securityMeasuresDescription'),
  securityMeasures: uppercasePlural('securityMeasures'),
  protectionMeasures: (
    <FormattedMessage
      id="Record.protectionMeasures"
      description="Protection measures before the breach"
      defaultMessage="Protection measures before the breach"
    />
  ),
  measuresAddressingBreach: (
    <FormattedMessage
      id="Record.measuresAddressingBreach"
      description="Measures taken to address the breach"
      defaultMessage="Measures taken to address the breach"
    />
  ),
  supervisoryNotificationDate: (
    <FormattedMessage
      id="Record.supervisoryNotificationDate"
      description="Supervisory authority notification date"
      defaultMessage="Supervisory authority notification date"
    />
  ),
  supervisoryAuthorityReasonNotToNotify: (
    <FormattedMessage
      id="Record.supervisoryAuthorityReasonNotToNotify"
      description="Reason not to notify the supervisory authorities"
      defaultMessage="Reason not to notify the supervisory authorities"
    />
  ),
  supervisoryNotificationText: (
    <FormattedMessage
      id="Record.supervisoryNotificationText"
      description="Supervisory authority notification text"
      defaultMessage="Supervisory authority notification text"
    />
  ),
  supervisoryNotificationReference: (
    <FormattedMessage
      id="Record.supervisoryNotificationReference"
      description="Supervisory authority notification reference"
      defaultMessage="Supervisory authority notification reference"
    />
  ),
  dataSubjectNotificationDate: (
    <FormattedMessage
      id="Record.dataSubjectNotificationDate"
      description="Data subject notification date"
      defaultMessage="Data subject notification date"
    />
  ),
  dataSubjectNotificationText: (
    <FormattedMessage
      id="Record.dataSubjectNotificationText"
      description="Data subject notification text"
      defaultMessage="Data subject notification text"
    />
  ),
  dataSubjectNotificationReference: (
    <FormattedMessage
      id="Record.dataSubjectNotificationReference"
      description="Data subject notification reference"
      defaultMessage="Data subject notification reference"
    />
  ),
  dataSubjectReasonNotToNotify: (
    <FormattedMessage
      id="Record.dataSubjectReasonNotToNotify"
      description="Reason not to notify the data subjects"
      defaultMessage="Reason not to notify the data subjects"
    />
  ),
  risks: uppercasePlural('risks'),
  linkBoxInstructionHeader: uppercasePlural('linkBoxInstructionHeader'),
  createdBy: uppercasePlural('createdBy'),
  permittedUsers: uppercasePlural('permittedUsers'),
  permittedOrganisations: uppercasePlural('permittedOrganisations'),
  linkBoxInstructionSubHeader: (
    <FormattedMessage
      id="Record.linkBoxInstructionSubHeader"
      description="The group editor subHeader"
      defaultMessage="The group editor allows for grouping of related data items.
    A group consists of one or more items which can be freely chosen from the different fields.
    Create a group by clicking on the items you want to add and save the group using the save button."
    />
  ),
  createLinkSubHeader: (
    <FormattedMessage
      id="Record.createLinkSubHeader"
      defaultMessage="Related data items can be arranged into groups here."
    />
  ),
  whatAreGroups: (
    <FormattedMessage
      id="Record.whatAreGroups"
      defaultMessage="What are groups?"
    />
  ),
  addReferences: addTranslations('addReferences'),
  AddLinkedProcessing: addTranslations('addLinkedProcessing'),
  AddLinkedTia: addTranslations('addLinkedTia'),
  AddLinkedTIA: addTranslations('addLinkedTIA'),
  AddLinkedDPIA: addTranslations('addLinkedDPIA'),
  AddLinkedBreach: addTranslations('AddLinkedBreach'),
  references: uppercasePlural('references'),
  documentType: uppercaseSingular('documentType'),
  specialCharacteristics: uppercaseSingular('specialCharacteristics'),
  qualitycontrols: uppercasePlural('qualitycontrols'),
  addQualityControl: uppercasePlural('addQualityControl'),
  reference: uppercaseSingular('reference'),
  assessmentType: uppercaseSingular('assessmentType'),
  jurisdiction: uppercaseSingular('jurisdiction'),
  edit: editTranslations('edit'),
  delete: removeTranslations('delete'),
  merge: mergeTranslation('merge'),
  personaldatacategory: environmentHeaderTranslation('personalDataCategories'),
  retentionTermOffset: environmentHeaderTranslation('retentionTermOffsets'),
  securitymeasure: smallcaseSingular('securitymeasure'),
  configuration: uppercaseSingular('configuration'),
  processingDescription: uppercaseSingular('processingDescription'),
  optionalChapters: uppercasePlural('optionalChapters'),
  optionalChaptersSubtitle: selectTranslations('optionalChaptersSubtitle'),
  necessityProportionality: uppercaseSingular('necessityProportionality'),
  purposeSubtitle: (
    <FormattedMessage
      id="Assessments.purposeSubtitle"
      description="Purpose limitation subtitle"
      defaultMessage="Add risk details and mitigating measures for purpose"
    />
  ),
  legalSubtitle: (
    <FormattedMessage
      id="Assessments.legalSubtitle"
      description="Legal ground subtitle"
      defaultMessage="Add risk details and mitigating measures for legal grounds"
    />
  ),
  dataMinimisationSubtitle: (
    <FormattedMessage
      id="Assessments.dataMinimisationSubtitle"
      description="Data minimisation subtitle"
      defaultMessage="Add risk details and mitigating measures for personal data items"
    />
  ),
  storageSubtitle: (
    <FormattedMessage
      id="Assessments.storageSubtitle"
      description="Storage subtitle"
      defaultMessage="Add risk details and mitigating measures for retention terms"
    />
  ),
  internationalSubtitle: (
    <FormattedMessage
      id="Assessments.internationalSubtitle"
      description="International transfer subtitle"
      defaultMessage="Add risk details and mitigating measures for international transfers"
    />
  ),
  dataQualitySubtitle: (
    <FormattedMessage
      id="AssessmentDetails.dataQualitySubtitle"
      description="Data quality subtitle"
      defaultMessage="Add quality controls, risk details and mitigating measures"
    />
  ),
  outcomeSubtitle: (
    <FormattedMessage
      id="Assessments.outcomeSubtitle"
      description="Outcome subtitle"
      defaultMessage="Add outcome of this chapter"
    />
  ),
  informationSubtitle: (
    <FormattedMessage
      id="Assessments.informationSubtitle"
      defaultMessage="Add elements of the rights to information, risk details and mitigating measures"
    />
  ),
  consentSubtitle: (
    <FormattedMessage
      id="Assessments.consentSubtitle"
      description="Validity consent subtitle"
      defaultMessage="Add validity of consent, risk details and mitigating measures"
    />
  ),
  accessSubtitle: (
    <FormattedMessage
      id="Assessments.accessSubtitle"
      defaultMessage="Add elements of the right to access and rectification, risk details and mitigating measures"
    />
  ),
  objectionSubtitle: (
    <FormattedMessage
      id="Assessments.objectionSubtitle"
      defaultMessage="Add elements of the rights to objection and restriction, risk details and mitigating measures"
    />
  ),
  deletionSubtitle: (
    <FormattedMessage
      id="Assessments.deletionSubtitle"
      defaultMessage="Add elements of the rights to erasure, risk details and mitigating measures"
    />
  ),
  dataPortabilitySubtitle: (
    <FormattedMessage
      id="Assessments.dataPortabilitySubtitle"
      defaultMessage="Add elements of the right to data portability, risk details and mitigating measures"
    />
  ),
  securityControls: uppercasePlural('securityControls'),
  contractualSecuritySubtitle: (
    <FormattedMessage
      id="Assessments.contractualSecuritySubtitle"
      defaultMessage="Add contractual security measure, risk details and mitigating measures"
    />
  ),
  technicalSecuritySubtitle: (
    <FormattedMessage
      id="Assessments.technicalSecuritySubtitle"
      description="Technical security measures subtitle"
      defaultMessage="Add technical security measure, risk details and mitigating measures"
    />
  ),
  organisationalSecuritySubtitle: (
    <FormattedMessage
      id="Assessments.organisationalSecuritySubtitle"
      description="Organisational security measures subtitle"
      defaultMessage="Add organisational security measure, risk details and mitigating measures"
    />
  ),
  dataSubjectRights: uppercasePlural('dataSubjectRights'),
  threatImpact: uppercaseSingular('threatImpact'),
  confidentialitySubtitle: (
    <FormattedMessage
      id="Assessments.confidentialitySubtitle"
      description="Confidentiality section subtitle"
      defaultMessage="Add threats to confidentiality, potential impacts and mitigating measures"
    />
  ),
  integritySubtitle: (
    <FormattedMessage
      id="Assessments.integritySubtitle"
      description="Integrity section subtitle"
      defaultMessage="Add threats to integrity, potential impacts and mitigating measures"
    />
  ),
  availabilitySubtitle: (
    <FormattedMessage
      id="Assessments.availabilitySubtitle"
      description="Availability section subtitle"
      defaultMessage="Add threats to availability, potential impacts and mitigating measures"
    />
  ),
  accountability: uppercaseSingular('accountability'),
  policySubtitle: addTranslations('policySubtitle'),
  dataProtectionSubtitle: addTranslations('dataProtectionSubtitle'),
  accountabilityMeasuresSubtitle: addTranslations(
    'accountabilityMeasuresSubtitle'
  ),
  supervisoryConsultationSubtitle: addTranslations(
    'supervisoryConsultationSubtitle'
  ),
  dataSubjectConsultationSubtitle: addTranslations(
    'dataSubjectConsultationSubtitle'
  ),
  dpoConsultationSubtitle: addTranslations('dpoConsultationSubtitle'),
  summary: uppercaseSingular('summary'),
  editAssessment: editTranslations('editAssessment'),
  editDocumentRecord: editTranslations('editDocumentRecord'),
  generalCharacteristics: uppercasePlural('generalCharacteristics'),
  generalCharacteristicsSubtitle: (
    <FormattedMessage
      id="AssessmentDetails.generalCharacteristicsSubtitle"
      description="Assessment Details general characteristics subtitle"
      defaultMessage="Enter name, description, linked records, references, etc"
    />
  ),
  processingCharacteristicsSubtitle: (
    <FormattedMessage
      id="AssessmentDetails.processingCharacteristicsSubtitle"
      description="Assessment Details  processing characteristics subtitle"
      defaultMessage="Select EDPB characteristics, ICO characteristics"
    />
  ),
  ICOCharacteristicsSubHeader: (
    <FormattedMessage
      id="AssessmentDetails.iCOCharacteristicsSubHeader"
      defaultMessage="Will any of the following criteria determined by the ICO apply in the assessment project?"
    />
  ),
  APCharacteristicsSubHeader: (
    <FormattedMessage
      id="AssessmentDetails.aPCharacteristicsSubHeader"
      defaultMessage="Will any of the following criteria determined by the AP apply in the assessment project?"
    />
  ),
  GBACharacteristicsSubHeader: (
    <FormattedMessage
      id="AssessmentDetails.gbaCharacteristicsSubHeader"
      defaultMessage="Will any of the following criteria determined by the GBA apply in the assessment project?"
    />
  ),
  CNILCharacteristicsSubHeader: (
    <FormattedMessage
      id="AssessmentDetails.cnilCharacteristicsSubHeader"
      defaultMessage="Will any of the following criteria determined by the CNIL apply in the assessment project?"
    />
  ),
  AEPDCharacteristicsSubHeader: (
    <FormattedMessage
      id="AssessmentDetails.aepdCharacteristicsSubHeader"
      defaultMessage="Will any of the following criteria determined by the AEPD apply in the assessment project?"
    />
  ),
  DSKCharacteristicsSubHeader: (
    <FormattedMessage
      id="AssessmentDetails.DSKCharacteristicsSubHeader"
      defaultMessage="Will any of the following criteria determined by the DSK apply in the assessment project?"
    />
  ),
  ICOCharacteristics: uppercasePlural('ICOCharacteristics'),
  APCharacteristics: uppercasePlural('APCharacteristics'),
  GBACharacteristics: uppercasePlural('GBACharacteristics'),
  CNILCharacteristics: uppercasePlural('CNILCharacteristics'),
  AEPDCharacteristics: uppercasePlural('AEPDCharacteristics'),
  EDPBCharacteristics: uppercasePlural('EDPBCharacteristics'),
  DSKCharacteristics: uppercasePlural('DSKCharacteristics'),
  PIPLCharacteristics: uppercasePlural('PIPLCharacteristics'),
  PIPLCharacteristicsSubHeader: (
    <FormattedMessage
      id="AssessmentDetails.PIPLCharacteristicsSubHeader"
      defaultMessage="Will any of the following criteria determined by the PIPL apply in the assessment project?"
    />
  ),
  EDPBCharacteristicsSubheader: (
    <FormattedMessage
      id="AssessmentDetails.EDPBCharacteristicsSubHeader"
      description="Processing characteristics subheader"
      defaultMessage={
        'Will any of the following criteria determined by the EDPB ' +
        'apply in the assessment project?'
      }
    />
  ),
  EDPBRisk: (
    <FormattedMessage
      id="AssessmentDetails.EDPBRisk"
      defaultMessage="EDPB Risk"
    />
  ),
  ICORisk: (
    <FormattedMessage
      id="AssessmentDetails.iCORisk"
      defaultMessage="ICO Risk"
    />
  ),
  APRisk: (
    <FormattedMessage id="AssessmentDetails.aPRisk" defaultMessage="AP Risk" />
  ),
  GBARisk: (
    <FormattedMessage
      id="AssessmentDetails.gbaRisk"
      defaultMessage="GBA Risk"
    />
  ),
  CNILRisk: (
    <FormattedMessage
      id="AssessmentDetails.cnilRisk"
      defaultMessage="CNIL Risk"
    />
  ),
  AEPDRisk: (
    <FormattedMessage
      id="AssessmentDetails.aepdRisk"
      defaultMessage="AEPD Risk"
    />
  ),
  DSKRisk: (
    <FormattedMessage
      id="AssessmentDetails.DSKRisk"
      defaultMessage="DSK Risk"
    />
  ),
  PIPLRisk: (
    <FormattedMessage
      id="AssessmentDetails.PIPLRisk"
      defaultMessage="PIPL Risk"
    />
  ),
  overallCharacteristicsRisk: (
    <FormattedMessage
      id="AssessmentDetails.overallCharacteristicsRisk"
      defaultMessage="Overall risk"
    />
  ),
  commentary: uppercaseSingular('commentary'),
  commentaryHint: (
    <FormattedMessage
      id="AssessmentDetails.commentaryHint"
      description="Commentary"
      defaultMessage="Provide your comments"
    />
  ),
  searchTemplate: (
    <FormattedMessage
      id="CreateRecord.searchTemplate"
      description="Search templates..."
      defaultMessage="Search templates..."
    />
  ),
  addDetailsHint: (
    <FormattedMessage
      id="Assessment.addRiskDetailHint"
      description="Add details..."
      defaultMessage="Add risk detail..."
    />
  ),
  addFindingHint: addTranslations('addFindingHint'),
  addFinding: addTranslations('addFinding'),
  consulted: (
    <FormattedMessage
      id="Assessment.consulted"
      description="Consulted"
      defaultMessage="Consulted"
    />
  ),
  threatImpactAssessment: uppercaseSingular('impactAssessment'),
  impactAssessment: uppercaseSingular('impactAssessment'),
  outcomeLabel: (
    <FormattedMessage
      id="Assessments.outcomeLabel"
      description="outcome label"
      defaultMessage="Outcome of"
    />
  ),
  necessityAndProportionality: uppercaseSingular('necessityAndProportionality'),
  addmitigationMeasure: addTranslations('addmitigationMeasure'),
  addItem: addTranslations('addItem'),
  riskInfo: (
    <FormattedMessage
      id="AssessmentDetails.riskInfo"
      description="Check for detailed explanation message item"
      defaultMessage={
        'This risk level is merely an indication; we advise you to refer to the ' +
        'detailed list with specifications provided by your Supervisory Authority.'
      }
    />
  ),
  years: uppercasePlural('years'),
  months: uppercasePlural('months'),
  weeks: uppercasePlural('weeks'),
  days: uppercasePlural('days'),
  hours: uppercasePlural('hours'),
  minutes: uppercasePlural('minutes'),
  environmentOrganisation: uppercasePlural('environmentOrganisation'),
  rights: uppercaseSingular('rights'),
  generalSection: uppercaseSingular('generalSection'),
  purposeCommentary: uppercasePlural('purposeCommentary'),
  attachmentsPermission: uppercaseSingular('attachmentsPermission'),
  previousChapter: uppercaseSingular('PreviousChapter'),
  nextChapter: uppercaseSingular('NextChapter'),
  privacyRecordId: uppercaseSingular('privacyRecordId'),
  bulkDeletionWarning: (
    <FormattedMessage
      id="BulkDelete.bulkDeletionWarning"
      defaultMessage="Warning: You are about to delete the following records. This cannot be undone. Are you sure?"
    />
  ),
  identifiedRisks: (
    <FormattedMessage
      id="ProcessingDetails.risksHeader"
      defaultMessage="Risks identified"
    />
  ),
  tagUser: (
    <FormattedMessage
      id="SummaryPanel.tagUser"
      defaultMessage="To tag a user, they need to be added in Permissions section of the record"
    />
  ),
  NotRequired: (
    <FormattedMessage id="Record.NotRequired" defaultMessage="Not Required" />
  ),
  RequiredButNotComplete: (
    <FormattedMessage
      id="Record.RequiredButNotComplete"
      defaultMessage="Required But Not Complete"
    />
  ),
  RequiredAndCompleted: (
    <FormattedMessage
      id="Record.RequiredAndCompleted"
      defaultMessage="Required And Completed"
    />
  ),
  organisationsInvolved: (
    <FormattedMessage
      id="Record.Public.organisationsInvolved"
      defaultMessage="Organisations Involved"
    />
  ),
  dataProcessed: (
    <FormattedMessage
      id="Record.Public.dataProcessed"
      defaultMessage="Data Processed"
    />
  ),
  storageAndProtection: (
    <FormattedMessage
      id="Record.Public.storageAndProtection"
      defaultMessage="Storage And Protection"
    />
  ),
  applicableDataProctectionLaw: (
    <FormattedMessage
      id="Record.Public.applicableDataProctectionLaw"
      defaultMessage="Applicable Data Proctection Law"
    />
  ),
  overallRisk: (
    <FormattedMessage
      id="Record.Public.overallRisk"
      defaultMessage="Overall Risk Assessment"
    />
  ),
  personalInfoType: (
    <FormattedMessage
      id="Record.APPI.personalInfoType"
      defaultMessage="The processing activity includes the following types of personal information:"
    />
  ),
  retainedPersonalInfo: (
    <FormattedMessage
      id="Record.APPI.retainedPersonalInfo"
      defaultMessage="Retained personal information"
    />
  ),
  specialCarePersonalInfo: (
    <FormattedMessage
      id="Record.APPI.specialCarePersonalInfo"
      defaultMessage="Special-care required personal information "
    />
  ),
  personallyReferableInfo: (
    <FormattedMessage
      id="Record.APPI.personallyReferableInfo"
      defaultMessage="Personally referable information"
    />
  ),
  personalNumber: (
    <FormattedMessage
      id="Record.APPI.personalNumber"
      defaultMessage="My number (Social Security and Tax Number)"
    />
  ),
  anonymouslyProcessedInfo: (
    <FormattedMessage
      id="Record.APPI.anonymouslyProcessedInfo"
      defaultMessage="Anonymously processed information"
    />
  ),
  pseudonymouslyProcessedInfo: (
    <FormattedMessage
      id="Record.APPI.pseudonymouslyProcessedInfo"
      defaultMessage="Pseudonymously processed information"
    />
  ),
  inboundData: (
    <FormattedMessage
      id="Record.APPI.inboundData"
      defaultMessage="Inbound data from third parties"
    />
  ),
  dataFromThirdParty: (
    <FormattedMessage
      id="Record.APPI.dataFromThirdParty"
      defaultMessage="Data is received from third parties"
    />
  ),
  dataReceivedFrom: (
    <FormattedMessage
      id="Record.APPI.dataReceivedFrom"
      defaultMessage="Third parties data is received from"
    />
  ),
  receivedDataTransferGround: (
    <FormattedMessage
      id="Record.APPI.receivedDataTransferGround"
      defaultMessage="Transfer ground for received data"
    />
  ),
  transferTimePeriod: (
    <FormattedMessage
      id="Record.APPI.timePeriod"
      defaultMessage="Time period transfer occurs over"
    />
  ),
  domesticTransferDetails: (
    <FormattedMessage
      id="Record.APPI.domesticTransferDetails"
      defaultMessage="Domestic transfer details"
    />
  ),
  domesticTransfer: (
    <FormattedMessage
      id="Record.APPI.domesticTransfer"
      defaultMessage="Does a domestic transfer take place"
    />
  ),
  domesticTransferPurpose: (
    <FormattedMessage
      id="Record.APPI.domesticTransferPurpose"
      defaultMessage="Purpose for the domestic transfer"
    />
  ),
  domesticTransferGround: (
    <FormattedMessage
      id="Record.APPI.domesticTransferGround"
      defaultMessage="Domestic transfer ground"
    />
  ),
  internationalTransferDetails: (
    <FormattedMessage
      id="Record.APPI.internationalTransferDetails"
      defaultMessage="International transfer details"
    />
  ),
  doesIntlTransfertakePlace: (
    <FormattedMessage
      id="Record.APPI.doesIntlTransfertakePlace"
      defaultMessage="Does international transfer take place"
    />
  ),
  dataTransferCountries: (
    <FormattedMessage
      id="Record.APPI.dataTransferCountries"
      defaultMessage="Countries data is transfered to"
    />
  ),
  intlTransferPurpose: (
    <FormattedMessage
      id="Record.APPI.intlTransferPurpose"
      defaultMessage="Purpose for the international transfer"
    />
  ),
  intlTransferGround: (
    <FormattedMessage
      id="Record.APPI.intlTransferGround"
      defaultMessage="International transfer ground"
    />
  ),
  complianceRequirements: (
    <FormattedMessage
      id="Record.APPI.complianceRequirements"
      defaultMessage="Compliance requirements"
    />
  ),
  dataObtainedVia: (
    <FormattedMessage
      id="Record.APPI.dataObtainedVia"
      defaultMessage="How was the personal information collected "
    />
  ),
  importDataStakeholders: (
    <FormattedMessage
      id="Record.APPI.importDataStakeholders"
      defaultMessage="Import data from stakeholders "
    />
  ),
  importStakeholdersWarning: (
    <FormattedMessage
      id="Record.APPI.importStakeholdersWarning"
      defaultMessage="This will reset the data to default. Do you want to proceed? "
    />
  ),
  editContact: (
    <FormattedMessage
      id="Record.APPI.editContact"
      defaultMessage="Edit contact details"
    />
  ),
  addNewContact: (
    <FormattedMessage
      id="Record.APPI.addNewContact"
      defaultMessage="Add new contact"
    />
  ),
  selectContactType: (
    <FormattedMessage
      id="Record.APPI.selectContactType"
      defaultMessage="Select contact data type :"
    />
  ),
  noContact: (
    <FormattedMessage
      id="Record.APPI.noContact"
      defaultMessage="No contact to display"
    />
  ),
  selectAssessmentType: (
    <FormattedMessage
      id="Record.selectAssessmentType"
      defaultMessage="Select assessment data type :"
    />
  ),
  jurisdictionDisclaimer: (
    <FormattedMessage
      id="Record.Assessment.jurisdictionDisclaimer"
      defaultMessage="Note that PrivacyPerfect is built upon the legal requirements of the European General Data
    Protection Regulation (GDPR). This means that performing an assessment on a new processing and/ or keeping a
    Register of Processing activities up-to-date, may only be a best practice and not a legal requirement in
    the country you operate in. However, PrivacyPerfect strongly advises always to perform a
    (pre)assessment on a new processing and to keep a record of processing activities up-to-date due to the
    following reasons: 1) The National (data protection) Authority may summon a copy of a Data Protection
    Impact Assessment (DPIA) or the Record of Processing activities 2) By performing a DPIA, you identify
    and mitigate possible risks that come with a new processing of personal data and thereby prevent
    possible data breaches and/or damage to your brand reputation."
    />
  ),
  selectRoleTaken: (
    <FormattedMessage
      id="Record.PIPL.selectRoleTaken"
      defaultMessage="Role taken in this processing activity"
    />
  ),
  methodUsedForNotifying: (
    <FormattedMessage
      id="Record.PIPL.methodUsedForNotifying"
      defaultMessage="Methods used for notifying data subjects"
    />
  ),
  isSensitiveDataUsed: (
    <FormattedMessage
      id="Record.PIPL.isSensitiveDataUsed"
      defaultMessage="Is sensitive data used in this processing activity"
    />
  ),
  doesDataIncludeDataSubjects: (
    <FormattedMessage
      id="Record.PIPL.doesDataIncludeDataSubjects"
      defaultMessage="Does the data include data subjects under the age of 14"
    />
  ),
  specialisedPersonalInfoRules: (
    <FormattedMessage
      id="Record.PIPL.specialisedPersonalInfoRules"
      defaultMessage="Specialised personal information handling rules"
    />
  ),
  chooseOne: (
    <FormattedMessage id="Record.PIPL.chooseOne" defaultMessage="Choose one" />
  ),
  automatedDecisionMaking: (
    <FormattedMessage
      id="Record.PIPL.automatedDecisionMaking"
      defaultMessage="Automated decision making"
    />
  ),
  doesUseAutomatedDecision: (
    <FormattedMessage
      id="Record.PIPL.doesUseAutomatedDecision"
      defaultMessage="Does this process use automated decision making"
    />
  ),
  methodsToEnsureFairness: (
    <FormattedMessage
      id="Record.PIPL.methodsToEnsureFairness"
      defaultMessage="Methods used to ensure fairness of decisions"
    />
  ),
  methodsToOptOut: (
    <FormattedMessage
      id="Record.PIPL.methodsToOptOut"
      defaultMessage="Methods available for data subjects to opt out"
    />
  ),
  methodsToObjectDecisions: (
    <FormattedMessage
      id="Record.PIPL.methodsToObjectDecisions"
      defaultMessage="Methods available for data subjects to object decisions"
    />
  ),
  jointController: (
    <FormattedMessage
      id="Record.PIPL.jointController"
      defaultMessage="Joint controller"
    />
  ),
  jointControllership: (
    <FormattedMessage
      id="Record.PIPL.jointControllership"
      defaultMessage="Joint controllership"
    />
  ),
  methodsToExerciseRight: (
    <FormattedMessage
      id="Record.PIPL.methodsToExerciseRight"
      defaultMessage="Methods available for data subjects to exercise right to transferred data"
    />
  ),
  regulatoryProcedureRequirement: (
    <FormattedMessage
      id="Record.PIPL.regulatoryProcedureRequirement"
      defaultMessage="Regulatory procedure requirement and status"
    />
  ),
  onwardTransferThirdParty: (
    <FormattedMessage
      id="Record.PIPL.onwardTransferThirdParty"
      defaultMessage="Is there an onward transfer by third party recipients"
    />
  ),
  publicDisclosure: (
    <FormattedMessage
      id="Record.PIPL.publicDisclosure"
      defaultMessage="Public disclosure"
    />
  ),
  isDataPubliclyDisclosed: (
    <FormattedMessage
      id="Record.PIPL.isDataPubliclyDisclosed"
      defaultMessage="Is some data publicly disclosed"
    />
  ),
  purposePublicDisclosure: (
    <FormattedMessage
      id="Record.PIPL.purposePublicDisclosure"
      defaultMessage="Purpose for public disclosure"
    />
  ),
  specificItemsDisclosed: (
    <FormattedMessage
      id="Record.PIPL.specificItemsDisclosed"
      defaultMessage="Specific data items disclosed"
    />
  ),
  finalComments: (
    <FormattedMessage
      id="Record.PIPL.finalComments"
      defaultMessage="Final comments"
    />
  ),
  dataFromMinors: (
    <FormattedMessage
      id="Record.LGPD.dataFromMinors"
      defaultMessage="Includes data from minors under 16"
    />
  ),
  requiredDocumentation: (
    <FormattedMessage
      id="Record.PDP.requiredDocumentation"
      defaultMessage="Required documentation"
    />
  ),
  privacyAgreement: (
    <FormattedMessage
      id="Record.PDP.privacyAgreement"
      defaultMessage="Privacy agreement"
    />
  ),
  securityInfoProgram: (
    <FormattedMessage
      id="Record.PDP.securityInfoProgram"
      defaultMessage="Security Information program"
    />
  ),
  includeMinorsData: (
    <FormattedMessage
      id="Record.PDP.includeMinorsData"
      defaultMessage="Includes data from minors"
    />
  ),
  intlTransferHintText: (
    <FormattedMessage
      id="ProcessingDetails.internationalTransfersHint"
      defaultMessage="This processing includes transfer to a third country or international organisation"
    />
  ),
  commentForPurpose: (
    <FormattedMessage
      id="ProcessingDetails.purposes.commentaries"
      defaultMessage="Comment for purpose"
    />
  ),
  specialTypesOfData: (
    <FormattedMessage
      id="Record.PDPA.specialTypesOfData"
      defaultMessage="Special types of data"
    />
  ),
  specialtypeDataHintText: (
    <FormattedMessage
      id="Record.PDPA.specialtypeDataHintText"
      defaultMessage="Are special types of data used in this processing activity"
    />
  ),
  specialProcessingGrounds: (
    <FormattedMessage
      id="Record.PDPA.specialProcessingGrounds"
      defaultMessage="Special processing ground"
    />
  ),
  methodOfNotification: (
    <FormattedMessage
      id="Record.PDPA.methodOfNotification"
      defaultMessage="Method of notification"
    />
  ),
  hasSpecialTypeData: (
    <FormattedMessage
      id="Record.PDPA.hasSpecialTypeData"
      defaultMessage="Has special type data"
    />
  ),
  commentForSpecialTypeOfData: (
    <FormattedMessage
      id="ProcessingDetails.commentForSpecialTypeOfData"
      defaultMessage="Comment for special type of data"
    />
  ),
  commentForNotificationMethod: (
    <FormattedMessage
      id="ProcessingDetails.commentForNotificationMethod"
      defaultMessage="Comment for notification method"
    />
  ),
  notRequired: (
    <FormattedMessage
      id="Record.PIPL.notRequired"
      defaultMessage="Not required"
    />
  ),
  requiredButNotComplete: (
    <FormattedMessage
      id="Record.PIPL.requiredButNotComplete"
      defaultMessage="Required but not complete"
    />
  ),
  requiredAndCompleted: (
    <FormattedMessage
      id="Record.PIPL.requiredAndCompleted"
      defaultMessage="Required and completed"
    />
  ),
  facsimile: (
    <FormattedMessage
      id="Notification.Method.Facsimile"
      defaultMessage="Facsimile"
    />
  ),
  inPerson: <FormattedMessage id="DSR.inPerson" defaultMessage="In Person" />,
  onlineFormOrPost: (
    <FormattedMessage
      id="Notification.Method.onlineFormOrPost"
      defaultMessage="Online form or post"
    />
  ),
  post: (
    <FormattedMessage id="Notification.Method.post" defaultMessage="Post" />
  ),
  telephone: (
    <FormattedMessage
      id="Notification.Method.telephone"
      defaultMessage="Telephone"
    />
  ),
  selectNotificationMethod: (
    <FormattedMessage
      id="Records.PDPA.selectNotificationMethod"
      defaultMessage="Select notification method"
    />
  ),
  government: (
    <FormattedMessage
      id="Records.PDPA.government"
      defaultMessage="Government"
    />
  ),
  nonGovernment: (
    <FormattedMessage
      id="Records.PDPA.nonGovernment"
      defaultMessage="Non government"
    />
  ),
  additionalComment: (
    <FormattedMessage
      id="Records.PDP.additionalComment"
      defaultMessage="Additional comment "
    />
  ),
  transferDetails: (
    <FormattedMessage
      id="Record.FDPL.transferDetails"
      defaultMessage="Transfer details"
    />
  ),
  doesTransfertakePlace: (
    <FormattedMessage
      id="Record.FDPL.doesTransfertakePlace"
      defaultMessage="Does transfer take place"
    />
  ),
  primaryPurpose: (
    <FormattedMessage
      id="Record.FDPL.primaryPurpose"
      defaultMessage="Primary purpose"
    />
  ),
  legalTransferGrounds: (
    <FormattedMessage
      id="Record.legalTransferGrounds"
      defaultMessage="Legal transfer grounds"
    />
  ),
  tiaNameHint: (
    <FormattedMessage
      id="Record.TIA.nameHint"
      defaultMessage="Enter the name of your Transfer impact assessment"
    />
  ),
  selectTias: (
    <FormattedMessage
      id="Record.TIA.selectTias"
      defaultMessage="Select transfer impact assessments"
    />
  ),
  applicableLawAndPractices: (
    <FormattedMessage
      id="Record.TIA.applicableLawAndPractices"
      defaultMessage="Applicable laws and practices specific to the transfer"
    />
  ),
  dataStorageAndLimitationToAccessData: (
    <FormattedMessage
      id="Record.TIA.dataStorageAndLimitationToAccessData"
      defaultMessage="Data storage and limitation of access to data"
    />
  ),
  dataFormat: (
    <FormattedMessage id="Record.TIA.dataFormat" defaultMessage="Data format" />
  ),
  usage: <FormattedMessage id="Record.Document.usage" defaultMessage="Usage" />,
  documentDetails: (
    <FormattedMessage
      id="Record.Document.details"
      defaultMessage="Document details"
    />
  ),
  documentName: (
    <FormattedMessage
      id="Record.Document.name"
      defaultMessage="Document name"
    />
  ),
  uploadNew: (
    <FormattedMessage id="Document.uploadNew" defaultMessage="Upload new" />
  ),
  DPA: <FormattedMessage id="Document.DPA" defaultMessage="DPA" />,
  taskFor: (
    <FormattedMessage id="Common.taskSubjectNumber" defaultMessage="Task for" />
  ),
  customRecordType: (
    <FormattedMessage
      id="Common.customRecordType"
      defaultMessage="Custom record type"
    />
  ),
  customPrivacyRecord: (
    <FormattedMessage
      id="Common.customPrivacyRecord"
      defaultMessage="Custom privacy record category"
    />
  ),
  selectedPurpose: (
    <FormattedMessage
      id="Assessment.selectedPurpose"
      defaultMessage="Selected purpose for the processing"
    />
  ),
  selectedDataSubjectCategories: (
    <FormattedMessage
      id="Assessment.selectedDataSubjectCategories"
      defaultMessage="Selected data subject categories"
    />
  ),
  selectedDataProtectionOfficers: (
    <FormattedMessage
      id="Assessment.selectedDataProtectionOfficers"
      defaultMessage="Selected data protection officers"
    />
  ),
  purposeRiskMitigation: (
    <FormattedMessage
      id="Assessment.purposeRiskMitigation"
      defaultMessage="Risk details and mitigation measures of selected purposes"
    />
  ),
  processingGroundRiskMitigation: (
    <FormattedMessage
      id="Assessment.processingGroundRiskMitigation"
      defaultMessage="Risk details and mitigation measures of selected processing grounds"
    />
  ),
  dataItemRiskMitigation: (
    <FormattedMessage
      id="Assessment.dataItemsRiskMitigation"
      defaultMessage="Risk details and mitigation measures of selected data items"
    />
  ),
  retensionPeriodRiskMitigation: (
    <FormattedMessage
      id="Assessment.retensionPeriodRiskMitigation"
      defaultMessage="Risk details and mitigation measures of selected retension periods"
    />
  ),
  transferPurposeRiskMitigation: (
    <FormattedMessage
      id="Assessment.transferPurposeRiskMitigation"
      defaultMessage="Risk details and mitigation measures of selected purposes of transfer"
    />
  ),
  transferGroundRiskMitigation: (
    <FormattedMessage
      id="Assessment.transferGroundRiskMitigation"
      defaultMessage="Risk details and mitigation measures of selected transfer grounds"
    />
  ),
  promoteRecord: (
    <FormattedMessage
      id="Record.promoteRecord"
      defaultMessage="Promote record"
    />
  ),
  upgradeRecord: (
    <FormattedMessage
      id="Record.upgradeRecord"
      defaultMessage="Upgrade record"
    />
  ),
  recordLayout: (
    <FormattedMessage id="Record.recordLayout" defaultMessage="Record layout" />
  ),
  newRecordName: (
    <FormattedMessage
      id="Record.newRecordName"
      defaultMessage="New record name"
    />
  ),
  newTemplateName: (
    <FormattedMessage
      id="Record.newTemplateName"
      defaultMessage="New template name"
    />
  ),
  nameFlag: (
    <FormattedMessage id="Record.nameFlag" defaultMessage="Flag as upgraded" />
  ),
  keepStatus: (
    <FormattedMessage
      id="Record.keepStatus"
      defaultMessage="Keep current status"
    />
  ),
  removeCurrentRecord: (
    <FormattedMessage
      id="Record.removeCurrentRecord"
      defaultMessage="Delete original record"
    />
  ),
  nameHelpText: (
    <FormattedMessage
      id="Record.nameHelpText"
      defaultMessage="This option will append the record with the text “(Upgraded)” "
    />
  ),
  keepStatusHelpText: (
    <FormattedMessage
      id="Record.keepStatusHelpText"
      defaultMessage="If selected this will keep the current status of the original record, 
      otherwise it will be set to draft"
    />
  ),
  removeCurrentRecordHelpText: (
    <FormattedMessage
      id="Record.removeCurrentRecordHelpText"
      defaultMessage="If selected, the original record will be 
      deleted after the upgrade of the record has been completed"
    />
  ),
  recordActionWarning: (
    <FormattedMessage
      id="recordActionWarning"
      defaultMessage="If a matching field is not found in the new record layout for an existing field, 
      the data in that field will be lost. 
      Please note that deleting the original record will also delete the record’s changelog."
    />
  ),
  chooseRecordType: (
    <FormattedMessage
      id="Record.chooseRecordType"
      defaultMessage="Choose record type"
    />
  ),
  chooseRecordLayout: (
    <FormattedMessage
      id="Record.chooseRecordLayout"
      defaultMessage="Choose record layout"
    />
  ),
  noActiveLayout: (
    <FormattedMessage
      id="Record.noActiveLayout"
      defaultMessage="No active layouts"
    />
  ),
  createRecordDefaultLabel: (
    <FormattedMessage
      id="Record.createRecordDefaultLabel"
      defaultMessage="Please enter a name for the record"
    />
  ),
  createTemplateDefaultLabel: (
    <FormattedMessage
      id="Record.createTemplateDefaultLabel"
      defaultMessage="Please enter a name for the template"
    />
  ),
  linkNewRecord: (
    <FormattedMessage
      id="Record.linkNewRecord"
      defaultMessage="Link new record"
    />
  ),
  linkExistingRecord: (
    <FormattedMessage
      id="Record.linkExistingRecord"
      defaultMessage="Link existing record"
    />
  ),
  linkRecords: (
    <FormattedMessage id="Record.linkRecords" defaultMessage="Link records" />
  ),
  records: commonTranslations.records,
  chooseRecords: (
    <FormattedMessage
      id="Record.chooseRecords"
      defaultMessage="Choose records"
    />
  ),
  linkedRecords: (
    <FormattedMessage
      id="Record.linkedRecords"
      defaultMessage="Linked records"
    />
  ),
  templateNameRequired: (
    <FormattedMessage
      id="Record.templateNameRequired"
      defaultMessage="Providing a name for this template is required"
    />
  ),
  selectTemplate: (
    <FormattedMessage
      id="Template.selectTemplate"
      defaultMessage="Select template"
    />
  ),
  emptyRecord: (
    <FormattedMessage id="Template.emptyRecord" defaultMessage="Empty record" />
  ),
  copyTemplate: (
    <FormattedMessage
      id="Template.copyTemplate"
      defaultMessage="Copy template"
    />
  ),
  itemPreviewMessage: (
    <FormattedMessage
      id="Record.itemPreviewMessage"
      defaultMessage={
        'This is a preview of the item.' +
        ' To access all chapters and content, please consider purchasing the full version.'
      }
    />
  ),
  itemIsInSync: (
    <FormattedMessage
      id="Record.itemIsInSync"
      defaultMessage="Due to the fact that this record has been in In Sync status,
          it cannot be edited or deleted"
    />
  )
};

export const tenantTranslations = {
  admins: uppercasePlural('admins'),
  Activated: uppercaseSingular('Activated'),
  Created: uppercaseSingular('Created'),
  Failed: uppercaseSingular('Failed'),
  Expired: uppercaseSingular('Expired'),
  Deleted: uppercaseSingular('Deleted'),
  tenantType: (
    <FormattedMessage id="TenantList.type" defaultMessage="Tenant type" />
  ),
  subscriptionStatus: (
    <FormattedMessage
      id="TenantList.subscriptionStatus"
      defaultMessage="Subscription status"
    />
  ),
  subscription: (
    <FormattedMessage
      id="settings.subscription"
      defaultMessage="Subscription"
    />
  ),
  contract: (
    <FormattedMessage id="tenantType.Contract" defaultMessage="Contract" />
  ),
  holdingTenants: (
    <FormattedMessage
      id="tenantType.holdingTenants"
      defaultMessage="Holding tenants"
    />
  ),
  addTenant: (
    <FormattedMessage id="Tenants.addTenant" defaultMessage="Add Tenant" />
  ),
  subTenants: (
    <FormattedMessage id="Tenants.subTenants" defaultMessage="Sub tenants" />
  ),
  editHoldingTenant: (
    <FormattedMessage
      id="Tenants.editHoldingTenant"
      defaultMessage="Edit global organisation"
    />
  ),
  newHoldingTenant: (
    <FormattedMessage
      id="Tenants.newHoldingTenant"
      defaultMessage="New global organisation"
    />
  ),
  maxTenants: (
    <FormattedMessage
      id="PartnerDetail.maxTenants"
      defaultMessage="Maximum tenants"
    />
  ),
  setTenantsCount: (
    <FormattedMessage
      id="PartnerDetail.maxTenantsHint"
      defaultMessage="Set number of tenants"
    />
  ),
  subTenantCountError: (
    <FormattedMessage
      id="HoldingTenantDetail.subTenantCountError"
      defaultMessage="Maximum sub tenant count should not be less than previous value. "
    />
  ),
  disableEdit: (
    <FormattedMessage
      id="Tenant.disableEdit"
      defaultMessage="Tenant creation is in progress. Refresh after sometime to edit"
    />
  ),
  disableDelete: (
    <FormattedMessage
      id="Tenant.disableDelete"
      defaultMessage="Tenant creation is in progress. Refresh after sometime to delete"
    />
  )
};

export const breachTranslations = {
  breachStatus: uppercaseSingular('breachStatus'),
  scope: uppercaseSingular('scope'),
  awarenessMethod: uppercaseSingular('awarenessMethod'),
  awarenessDate: uppercaseSingular('awarenessDate'),
  personalDataItems: (
    <FormattedMessage
      id="Breach.personalDataItems"
      description="Personal data involved in the breach"
      defaultMessage="Personal data involved in the breach"
    />
  ),
  dataSources: (
    <FormattedMessage
      id="Breach.dataSources"
      description="Data sources involved in the breach"
      defaultMessage="Data sources involved in the breach"
    />
  ),
  characteristics: uppercasePlural('characteristics'),
  notificationDate: uppercaseSingular('notificationDate'),
  notificationReferences: uppercaseSingular('notificationReferences'),
  notificationText: uppercaseSingular('notificationText'),
  nameHint: (
    <FormattedMessage
      id="Breach.nameHint"
      description="Hint text for breach record name"
      defaultMessage="Name for the breach"
    />
  ),
  numOfDataSubjects: (
    <FormattedMessage
      id="Breach.numOfDataSubjects"
      description="Number of data subjects involved"
      defaultMessage="Number of data subjects involved"
    />
  ),
  selectSupervisoryAuthorities: selectTranslations(
    'selectSupervisoryAuthorities'
  ),
  supervisoryAuthorityNotification: (
    <FormattedMessage
      id="Breach.supervisoryNotification"
      description="Supervisory authority notification"
      defaultMessage="Was a notification about the breach send to the supervisory authority?"
    />
  ),
  dataSubjectNotification: (
    <FormattedMessage
      id="Breach.dataSubjectNotification"
      description="data subject authority notification"
      defaultMessage="Was a notification about the breach send to the affected data subjects?"
    />
  ),
  addBreachGround: addTranslations('addBreachGround'),
  description: (
    <FormattedMessage
      id="Breach.descriptionHint"
      description="Hint text for breach description"
      defaultMessage="Description of the breach..."
    />
  ),
  linkedBreachesHint: (
    <FormattedMessage
      id="Breach.addLinkedBreaches"
      description="Help notes for  Adding linked Breaches"
      defaultMessage="Add an existing breach..."
    />
  ),
  natureOfBreachHint: (
    <FormattedMessage
      id="Breach.natureOfBreachHint"
      description="Hint text for breach nature explanation"
      defaultMessage="Explain the nature of the breach..."
    />
  ),
  breachConsequencesHint: (
    <FormattedMessage
      id="Breach.breachConsequencesHint"
      description="Hint text for breach consequences explanation"
      defaultMessage="Explain the possible consequences of the breach..."
    />
  ),
  breachGrounds: (
    <FormattedMessage
      id="Breach.breachGrounds"
      description="Breach grounds"
      defaultMessage="Breach registration/notification grounds"
    />
  ),
  measuresAddressingBreachHint: (
    <FormattedMessage
      id="Breach.measuresAddressingBreachHint"
      description="Hint text for measures addressing breach"
      defaultMessage={
        'Describe the measures (proposed to be) taken' +
        ' to address the breach, including any measures to' +
        '  mitigate its possible adverse effects...'
      }
    />
  ),
  breachAwareness: (
    <FormattedMessage
      id="Breach.awareness"
      description="Awareness about the breach"
      defaultMessage="Breach awareness"
    />
  ),
  supervisoryNotificationTextHint: (
    <FormattedMessage
      id="Breach.supervisoryNotificationTextHint"
      description="Hint text for breach notification text"
      defaultMessage="Provide the notification text for the supervisory authorities..."
    />
  ),
  dataSubjectNotificationTextHint: (
    <FormattedMessage
      id="Breach.dataSubjectNotificationTextHint"
      description="Hint text for data subject notification text"
      defaultMessage="Provide the notification text for the data subjects..."
    />
  )
};

export const statusTranslations = {
  record_status_provisional: uppercaseSingular('record_status_provisional'),
  record_status_requested: uppercaseSingular('record_status_requested'),
  record_status_approved: uppercaseSingular('record_status_approved'),
  record_status_disapproved: uppercaseSingular('record_status_disapproved'),
  record_status_withdrawn: uppercaseSingular('record_status_withdrawn'),
  record_status_redundant: uppercaseSingular('record_status_redundant'),
  record_status_draft: uppercaseSingular('record_status_draft'),
  completed: uppercaseSingular('completed'),
  failed: uppercaseSingular('failed'),
  in_progress: uppercaseSingular('in_progress'),
  submitted: uppercaseSingular('submitted'),
  terminated: uppercaseSingular('terminated'),
  completedWithErrors: uppercaseSingular('completedWithErrors'),
  notStarted: uppercaseSingular('notStarted'),
  record_status_review_soon: uppercaseSingular('record_status_review_soon'),
  record_status_review_overdue: uppercaseSingular(
    'record_status_review_overdue'
  ),
  unavailable: uppercaseSingular('unavailable'),
  available: uppercaseSingular('available'),
  in_sync: uppercaseSingular('inSync'),
  up_to_date: uppercaseSingular('upToDate'),
  update_available: uppercaseSingular('updateAvailable'),
  edited: uppercaseSingular('edited')
};

export const categoryTypeTranslations = {
  normal: uppercaseSingular('normal'),
  special: uppercaseSingular('special'),
  personal_number: uppercaseSingular('personal_number'),
  crime_related: uppercaseSingular('crime_related'),
  other_sensitive_data: uppercaseSingular('other_sensitive_data')
};

export const legalGroundTypeTranslations = {
  normal: categoryTypeTranslations.normal,
  special: categoryTypeTranslations.special,
  id_number: categoryTypeTranslations.personal_number,
  crime_related: categoryTypeTranslations.crime_related,
  government: recordTranslations.government,
  non_government: recordTranslations.nonGovernment,
  transfer: uppercaseSingular('transfer')
};

export const recordTypeTranslations = {
  processing: uppercasePlural('processing'),
  assessment: uppercasePlural('dpiaRecords'),
  tia: uppercasePlural('tiaRecords'),
  breach: uppercasePlural('breach'),
  custom: uppercasePlural('custom')
};

export const internalTypeMapper = (type) => {
  switch (type) {
    case true:
      return orgTranslations.internal;
    case false:
      return orgTranslations.external;
    default:
      return type;
  }
};

export const userStatusMapper = (type) => {
  switch (type) {
    case 'Invited':
      return commonTranslations.invited;
    case 'Registered':
      return commonTranslations.registered;
    default:
      return type;
  }
};

export const ssoBypassStatusMapper = (type) => {
  switch (type) {
    case true:
      return commonTranslations.Yes;
    case false:
      return commonTranslations.No;
    default:
      return type;
  }
};

export const ssoPasswordStatusMapper = (type) => {
  switch (type) {
    case 'InvitedToSetPassword':
      return commonTranslations.invited;
    case 'SuccessfullySetPassword':
      return commonTranslations.registered;
    default:
      return '-';
  }
};

export const userRoleMapper = (role) => {
  switch (role) {
    case 'PrivacyOfficer':
      return rolesTranslation.PrivacyOfficer;
    case 'ChiefPrivacyOfficer':
      return rolesTranslation.ChiefPrivacyOfficer;
    case 'DSRAdministrator':
      return rolesTranslation.DSRAdministrator;
    case 'BusinessUser':
      return rolesTranslation.BusinessUser;
    case 'Administrator':
      return rolesTranslation.administrator;
    case 'SuperAdministrator':
      return rolesTranslation.SuperAdministrator;
    case 'PartnerAdministrator':
      return rolesTranslation.PartnerAdministrator;
    case 'Supervisor':
      return rolesTranslation.Supervisor;
    case 'HoldingAdministrator':
      return rolesTranslation.HoldingAdministrator;
    case 'Coordinator':
      return rolesTranslation.Coordinator;
    default:
      return role;
  }
};

export const orgTypesValueMapper = (orgType) => {
  switch (orgType) {
    case 'legalentity':
      return orgTypesTranslations.legalEntity;
    case 'department':
      return orgTypesTranslations.department;
    default:
      return orgType;
  }
};

export const orgTypesTranslations = {
  legalEntity: (
    <FormattedMessage
      id="OrgTypes.legalEntity"
      description="Legal entity"
      defaultMessage="Legal entity"
    />
  ),
  department: (
    <FormattedMessage
      id="OrgTypes.department"
      description="Department"
      defaultMessage="Department"
    />
  ),
  internalOrg: (
    <FormattedMessage
      id="OrgTypes.internalOrg"
      description="Internal organisation entities"
      defaultMessage="Internal organisation entities"
    />
  ),
  externalOrg: (
    <FormattedMessage
      id="OrgTypes.externalOrg"
      description="External organisation entities"
      defaultMessage="External organisation entities"
    />
  )
};

export const orgTranslations = {
  contactType: uppercaseSingular('contactType'),
  internal: uppercaseSingular('internal'),
  external: uppercaseSingular('external'),
  entityType: uppercaseSingular('entityType'),
  internalOrExternal: uppercaseSingular('internalOrExternal'),
  entityName: uppercaseSingular('entityName'),
  organisationEntity: smallcaseSingular('organisationEntity'),
  isPartOf: uppercaseSingular('isPartOf'),
  tags: uppercasePlural('tags'),
  email: uppercaseSingular('email'),
  address: uppercaseSingular('address'),
  workPhone: uppercaseSingular('workPhone'),
  name: uppercaseSingular('name'),
  vatNumber: uppercaseSingular('vatNumber')
};

const graphTranslations = defineMessages({
  processingcategories: {
    id: 'Graph.processingCategories',
    defaultMessage: 'Processing categories'
  },
  processingcategory: {
    id: 'Graph.processingCategories',
    defaultMessage: 'Processing categories'
  },
  controllers: {
    id: 'Graph.controllers',
    defaultMessage: 'Controllers'
  },
  controller: {
    id: 'Graph.controllers',
    defaultMessage: 'Controllers'
  },
  executingentities: {
    id: 'Graph.executingEntities',
    defaultMessage: 'Executing entities'
  },
  executingentity: {
    id: 'Graph.executingEntities',
    defaultMessage: 'Executing entities'
  },
  processors: {
    id: 'Graph.processors',
    defaultMessage: 'Processors'
  },
  processor: {
    id: 'Graph.processors',
    defaultMessage: 'Processors'
  },
  datasubjectcategorys: {
    id: 'Graph.dataSubjectCategories',
    defaultMessage: 'Data subject categories'
  },
  datasubjectcategory: {
    id: 'Graph.dataSubjectCategories',
    defaultMessage: 'Data subject categories'
  },
  datarecipients: {
    id: 'Graph.dataRecipients',
    defaultMessage: 'Data recipients'
  },
  datarecipient: {
    id: 'Graph.dataRecipients',
    defaultMessage: 'Data recipients'
  },
  personaldatacategory: {
    id: 'PersonalDataItems.categories',
    defaultMessage: 'Personal data categories'
  },
  personaldataitems: {
    id: 'Graph.personalDataItems',
    defaultMessage: 'Personal data items'
  },
  personaldataitem: {
    id: 'Graph.personalDataItems',
    defaultMessage: 'Personal data items'
  },
  datasources: {
    id: 'Graph.dataSources',
    defaultMessage: 'Data sources'
  },
  datasource: {
    id: 'Graph.dataSources',
    defaultMessage: 'Data sources'
  },
  datasourceshost: {
    id: 'Graph.dataSourcesHost',
    defaultMessage: 'Data sources host'
  },
  datasourcesdatastorage: {
    id: 'Graph.dataSourcesDataStorage',
    defaultMessage: 'Data sources data storage'
  },
  processinggrounds: {
    id: 'Graph.processingGrounds',
    defaultMessage: 'Processing grounds'
  },
  legalground: {
    id: 'Graph.LegalGround',
    defaultMessage: 'Legal grounds'
  },
  securitymeasuresdescription: {
    id: 'Record.securityMeasuresDescription',
    defaultMessage: 'Technical and organisational security measures'
  },
  transfergroundcontrollers: {
    id: 'Graph.transferGrounds',
    defaultMessage: 'Transfer grounds'
  },
  exportercountry: {
    id: 'Graph.exportercountry',
    defaultMessage: 'Exporter country'
  },
  importercountry: {
    id: 'Graph.importercountry',
    defaultMessage: 'Importer country'
  }
});
export const graphValueMapper = (nodeType) =>
  graphTranslations[nodeType.toLowerCase()];

export const PartnerTypeMapper = (partnerType) =>
  partnerTranslations[partnerType];

export const partnerTranslations = {
  Reseller: uppercaseSingular('Reseller'),
  Referrer: uppercaseSingular('Referrer'),
  Consultant: uppercaseSingular('Consultant'),
  DPO: uppercaseSingular('DPO'),
  Integrator: uppercaseSingular('Integrator'),
  SalesRep: uppercaseSingular('SalesRep')
};
export const statusValueMapper = (status) =>
  statusTranslations[status] || status;

export const jurisdictionValueMapper = (jurisdiction) =>
  recordTranslations[jurisdiction.toLowerCase()] || jurisdiction;

export const countryValueMapper = (country) =>
  countryTranslations[country] || country;

export const typeTranslations = {
  processing: uppercaseSingular('processing'),
  processings: uppercasePlural('processings'),
  processors: uppercasePlural('processors'),
  assessment: uppercaseSingular('dpia'),
  assessments: uppercasePlural('assessments'),
  consultation: uppercaseSingular('consultation'),
  breaches: uppercasePlural('breaches'),
  transferImpactAssessment: uppercaseSingular('transferImpactAssessment'),
  breach: uppercaseSingular('breach'),
  transfer: uppercaseSingular('transfer'),
  dpia: uppercaseSingular('dpia'),
  tia: uppercasePlural('tia'),
  document: sectionHeaderTranslations.documentRecords
};
export const typeValueMapper = (type) => typeTranslations[type] || type;

export const stakeholdersValueMapper = (type) =>
  graphTranslations[type] || type;

const personalDataCategoryTypeTranslations = defineMessages({
  normal: {
    id: 'PersonalDataItem.Type.normal',
    defaultMessage: 'Normal'
  },
  special: {
    id: 'PersonalDataItem.Type.special',
    defaultMessage: 'Special'
  },
  personal_number: {
    id: 'PersonalDataItem.Type.Idnumber',
    defaultMessage: 'ID Number'
  },
  crime_related: {
    id: 'PersonalDataItem.Type.crimeRelated',
    defaultMessage: 'Crime-related'
  },
  other_sensitive_data: {
    id: 'PersonalDataItem.Type.otherSensitiveData',
    defaultMessage: 'Other sensitive data (CCPA)'
  }
});

export const personalDataCategoryTypeMapper = (type) =>
  personalDataCategoryTypeTranslations[type] || type;

export const personalCategoryTypeMapper = (type) => {
  if (type === 'ID number') return categoryTypeTranslations.personal_number;
  return categoryTypeTranslations[type];
};

// This mapper is used for translating runtime data
export const categoryTypeMapper = (type) =>
  personalDataCategoryTypeTranslations[type.toLowerCase()];

// This mapper is used for translating record versions
export const versionMapper = (version) => {
  if (version === 'Current') return commonTranslations.current;
  return version;
};

export const accessMapper = (access) =>
  access ? commonTranslations.public : commonTranslations.private;

export const legalGroundMapper = (type) => legalGroundTypeTranslations[type];

export const rolesTranslation = {
  Administrator: uppercasePlural('Administrator'),
  PartnerAdministrator: uppercaseSingular('PartnerAdministrator'),
  role: uppercaseSingular('role'),
  administrator: uppercaseSingular('administrator'),
  privacyOfficer: uppercasePlural('privacyOfficer'),
  Supervisor: uppercaseSingular('Supervisor'),
  PrivacyOfficer: uppercaseSingular('PrivacyOfficer'),
  ChiefPrivacyOfficer: uppercaseSingular('ChiefPrivacyOfficer'),
  businessUser: uppercasePlural('businessUser'),
  BusinessUser: uppercaseSingular('BusinessUser'),
  DSRAdministrator: uppercaseSingular('DSRAdministrator'),
  SuperAdministrator: uppercaseSingular('SuperAdministrator'),
  HoldingAdministrator: homeLayoutTranslations.HoldingAdministrator,
  Coordinator: homeLayoutTranslations.Coordinator,
  'tenant-administrator': editTranslations('tenant-administrator'),
  reporting: editTranslations('reporting'),
  'upload-document': editTranslations('upload-document'),
  'view-document': viewTranslations('view-document'),
  'view-document-record': viewTranslations('view-document-record'),
  'create-edit-document-record': editTranslations(
    'create-edit-document-record'
  ),
  'create-edit-privacy-record': editTranslations('create-edit-privacy-record'),
  'view-privacy-records': viewTranslations('view-privacy-records'),
  'create-edit-user': editTranslations('create-edit-user'),
  'view-user': viewTranslations('view-user'),
  'create-edit-organisation': editTranslations('create-edit-organisation'),
  'view-organisation': viewTranslations('view-organisation'),
  'create-edit-permissions': editTranslations('create-edit-permissions'),
  'view-permissions': viewTranslations('view-permissions'),
  'change-legal-entities': editTranslations('change-legal-entities'),
  'create-edit-data-sources': editTranslations('create-edit-data-sources'),
  'create-edit-personal-data-item': editTranslations(
    'create-edit-personal-data-item'
  ),
  'create-edit-data-subject-categories': editTranslations(
    'create-edit-data-subject-categories'
  ),
  'create-edit-processing-categories': editTranslations(
    'create-edit-processing-categories'
  ),
  'create-edit-security-measures-description': editTranslations(
    'create-edit-security-measures-description'
  ),
  'create-edit-personal-data-categories': editTranslations(
    'create-edit-personal-data-categories'
  ),
  'create-edit-reference': editTranslations('create-edit-reference'),
  'view-dpia': viewTranslations('view-dpia'),
  'create-edit-dpia': editTranslations('create-edit-dpia'),
  'create-edit-data-recipient-category': editTranslations(
    'create-edit-data-recipient-category'
  ),
  'create-edit-assessment': editTranslations('create-edit-assessment'),
  'create-edit-processing': editTranslations('create-edit-processing'),
  'create-edit-breach': editTranslations('create-edit-breach'),
  'create-edit-data-source-category': editTranslations(
    'create-edit-data-source-category'
  ),
  'create-edit-vendor-forms': editTranslations('create-edit-vendor-forms')
};

export const passwordTranslations = {
  veryWeak: (
    <FormattedMessage
      id="Password.veryWeak"
      description="Very Weak"
      defaultMessage="Very Weak"
    />
  ),
  weak: (
    <FormattedMessage
      id="Password.weak"
      description="Weak"
      defaultMessage="Weak"
    />
  ),
  fair: (
    <FormattedMessage
      id="Password.fair"
      description="Fair"
      defaultMessage="Fair"
    />
  ),
  good: (
    <FormattedMessage
      id="Password.good"
      description="Good"
      defaultMessage="Good"
    />
  ),
  strong: (
    <FormattedMessage
      id="Password.strong"
      description="Strong"
      defaultMessage="Strong"
    />
  )
};

export const auditViewTranslations = {
  eventDescription: (
    <FormattedMessage
      id="AuditTrailView.description"
      description="Event description"
      defaultMessage="Event description"
    />
  ),
  auditEvent: (
    <FormattedMessage
      id="AuditTrailView.auditEvent"
      defaultMessage="Audit event"
    />
  )
};

export const eventCategoryTranslations = (type) => {
  switch (type) {
    case 'user':
      return commonTranslations.user;
    case 'notification':
      return commonTranslations.notifications;
    case 'task':
      return commonTranslations.tasks;
    case 'organisation':
      return recordTranslations.permissionsOrgHeader;
    case 'fieldsVisibilitySetting':
      return commonTranslations.fieldsVisibilitySetting;
    case 'helpNote':
      return commonTranslations.helpNote;
    case 'message':
      return commonTranslations.message;
    case 'passwordExpirySetting':
      return commonTranslations.passwordSettings;
    case 'token_user':
      return commonTranslations.accessToken;
    case 'tenant':
      return (
        <FormattedMessage
          id="TenantList.name"
          description="Tenant"
          defaultMessage="Tenant"
        />
      );
    case 'template':
      return commonTranslations.template;
    case 'link':
      return (
        <FormattedMessage
          id="Common.Link"
          description="Link"
          defaultMessage="Link"
        />
      );
    case 'masterdata':
      return (
        <FormattedMessage
          id="Common.masterData"
          description="Master data"
          defaultMessage="Master data"
        />
      );
    case 'import':
      return commonTranslations.import;
    case 'breach':
      return recordTranslations.breach;
    case 'records':
      return uppercaseSingular('records');
    case 'reports':
      return uppercaseSingular('report');
    case 'document':
      return uppercaseSingular('document');
    case 'partner':
      return (
        <FormattedMessage
          id="TenantDetail.Partner"
          description="Partner"
          defaultMessage="Partner"
        />
      );
    case 'job':
      return uppercaseSingular('jobs');
    case 'dataSubjectRequest':
      return commonTranslations.dataSubjectRequest;
    case 'dataSubjectRequestForm':
      return commonTranslations.dataSubjectRequestForm;
    case 'dataSubject':
      return commonTranslations.dataSubject;
    case 'document_download':
      return commonTranslations.documentDownload;
    case 'subscription':
      return tenantTranslations.subscription;
    case 'vendorForms':
      return vendorTranslations.vendorForms;
    case 'vendor':
      return vendorTranslations.vendor;
    case 'vendorform':
      return vendorTranslations.vendorForm;
    case 'registryFieldsVisibilitySetting':
      return commonTranslations.registryFieldsVisibilitySetting;
    case 'registry':
      return commonTranslations.registry;
    case 'global':
      return <FormattedMessage id="Jobs.global" defaultMessage="Global" />;
    case 'customLayout':
      return commonTranslations.customLayout;
    case 'customField':
      return commonTranslations.customField;
    case 'customItemList':
      return commonTranslations.customItemList;
    case 'customOption':
      return commonTranslations.customOption;
    case 'promote_record_category':
      return commonTranslations.promoteRecordCategory;
    case 'upgrade_record_category':
      return commonTranslations.upgradeRecordCategory;
    case 'dsrsetting':
      return commonTranslations.dsrsetting;
    case 'customDashboardLayout':
      return commonTranslations.customDashboardLayout;
    case 'adminUpdates':
      return commonTranslations.adminUpdates;
    case 'auditSetting':
      return commonTranslations.auditSetting;
    case 'record_templates':
      return commonTranslations.recordTemplates;
    case 'customPrivacyRecordTemplate':
      return commonTranslations.customPrivacyRecordTemplate;
    case 'data_library_job':
      return (
        <FormattedMessage
          id="dataLibrary.dataLibraryJob"
          defaultMessage="Data library job"
        />
      );
    case 'record_data_library':
      return (
        <FormattedMessage
          id="dataLibrary.recordDataLibrary"
          defaultMessage="Record data library"
        />
      );
    case 'template_data_library':
      return (
        <FormattedMessage
          id="dataLibrary.templateDataLibrary"
          defaultMessage="Template data library"
        />
      );
    default:
      return recordTranslations[type];
  }
};

export const eventCategories = [
  { label: commonTranslations.user, value: 'user' },
  { label: commonTranslations.tasks, value: 'task' },
  { label: recordTranslations.permissionsOrgHeader, value: 'organisation' },
  {
    label: (
      <FormattedMessage
        id="TenantList.name"
        description="Tenant"
        defaultMessage="Tenant"
      />
    ),
    value: 'tenant'
  },
  {
    label: (
      <FormattedMessage
        id="Common.Link"
        description="Link"
        defaultMessage="Link"
      />
    ),
    value: 'link'
  },
  {
    label: (
      <FormattedMessage
        id="Common.masterData"
        description="Master data"
        defaultMessage="Master data"
      />
    ),
    value: 'masterdata'
  },
  { label: uppercaseSingular('record'), value: 'records' },
  { label: uppercaseSingular('report'), value: 'reports' },
  { label: uppercaseSingular('document'), value: 'document' },
  {
    label: (
      <FormattedMessage
        id="PartnerList.name"
        description="Partner"
        defaultMessage="Partner"
      />
    ),
    value: 'partner'
  },
  { label: uppercaseSingular('jobs'), value: 'job' },
  { label: commonTranslations.import, value: 'import' },
  {
    label: commonTranslations.fieldsVisibilitySetting,
    value: 'fieldsVisibilitySetting'
  },
  { label: commonTranslations.helpNote, value: 'helpNote' },
  { label: commonTranslations.message, value: 'message' },
  { label: commonTranslations.notifications, value: 'notification' },
  {
    label: commonTranslations.passwordSettings,
    value: 'passwordExpirySetting'
  },
  { label: commonTranslations.dataSubjectRequest, value: 'dataSubjectRequest' },
  { label: commonTranslations.dataSubject, value: 'dataSubject' },
  { label: commonTranslations.documentDownload, value: 'document_download' },
  { label: commonTranslations.accessToken, value: 'token_user' },
  { label: tenantTranslations.subscription, value: 'subscription' },
  { label: vendorTranslations.vendorForms, value: 'vendorForms' },
  { label: vendorTranslations.vendor, value: 'vendor' },
  {
    label: commonTranslations.registryFieldsVisibilitySetting,
    value: 'registryFieldsVisibilitySetting'
  },
  { label: commonTranslations.registry, value: 'registry' },
  { label: recordTranslations.customRecordType, value: 'customRecordType' },
  {
    label: recordTranslations.customPrivacyRecord,
    value: 'customPrivacyRecord'
  },
  { label: commonTranslations.customLayout, value: 'customLayout' },

  { label: commonTranslations.customField, value: 'customField' },
  { label: commonTranslations.customItemList, value: 'customItemList' },
  { label: commonTranslations.customOption, value: 'customOption' },
  {
    label: commonTranslations.promoteRecordCategory,
    value: 'promote_record_category'
  },
  {
    label: commonTranslations.upgradeRecordCategory,
    value: 'upgrade_record_category'
  },
  { label: commonTranslations.dsrsetting, value: 'dsrsetting' },
  {
    label: commonTranslations.customDashboardLayout,
    value: 'customDashboardLayout'
  },
  { label: commonTranslations.adminUpdates, value: 'adminUpdates' },
  { label: commonTranslations.auditSetting, value: 'auditSetting' },
  { label: commonTranslations.recordTemplates, value: 'record_templates' },
  {
    label: commonTranslations.customPrivacyRecordTemplate,
    value: 'customPrivacyRecordTemplate'
  }
];

export const eventCodeTranslations = {
  processing_pdf_export: (
    <FormattedMessage
      id="EventCode.processing_pdf_export"
      defaultMessage="Pdf export for processing records"
    />
  ),
  vendorform_xlsx_export: (
    <FormattedMessage
      id="EventCode.vendorform_xlsx_export"
      defaultMessage="XLSX export for vendor form"
    />
  ),
  vendorform_import: (
    <FormattedMessage
      id="EventCode.vendorform_import"
      defaultMessage="Vendor form import"
    />
  ),
  assessment_pdf_export: (
    <FormattedMessage
      id="EventCode.assessment_pdf_export"
      defaultMessage="Pdf export for assessment records"
    />
  ),
  processing_bulk_pdf_export: (
    <FormattedMessage
      id="EventCode.processing_bulk_pdf_export"
      defaultMessage="Bulk pdf export for processing records"
    />
  ),
  processing_bulk_delete: (
    <FormattedMessage
      id="EventCode.processing_bulk_delete"
      defaultMessage="Bulk delete of processing records"
    />
  ),
  assessment_bulk_pdf_export: (
    <FormattedMessage
      id="EventCode.assessment_bulk_pdf_export"
      defaultMessage="Bulk pdf export for assessment records"
    />
  ),
  link_csv_export: (
    <FormattedMessage
      id="EventCode.link_csv_export"
      defaultMessage="CSV export for links"
    />
  ),
  tenant_partner_csv_export: (
    <FormattedMessage
      id="EventCode.tenant_partner_csv_export"
      defaultMessage="CSV export for tenant partners"
    />
  ),
  partner_csv_export: (
    <FormattedMessage
      id="EventCode.partner_csv_export"
      defaultMessage="CSV export for partners"
    />
  ),
  tenant_csv_export: (
    <FormattedMessage
      id="EventCode.tenant_csv_export"
      defaultMessage="CSV export for tenants"
    />
  ),
  privacyrecord_csv_export: (
    <FormattedMessage
      id="EventCode.privacyrecord_csv_export"
      defaultMessage="CSV export for privacy records"
    />
  ),
  document: (
    <FormattedMessage
      id="EventCode.document_download"
      defaultMessage="Document download"
    />
  ),
  record_document: (
    <FormattedMessage
      id="EventCode.record_document"
      defaultMessage="Download of record document"
    />
  ),
  processingCategories_csv_export: (
    <FormattedMessage
      id="EventCode.processingCategories_csv_export"
      defaultMessage="CSV export for processing categories"
    />
  ),
  references_csv_export: (
    <FormattedMessage
      id="EventCode.references_csv_export"
      defaultMessage="CSV export for references"
    />
  ),
  dataSubjectCategories_csv_export: (
    <FormattedMessage
      id="EventCode.dataSubjectCategories_csv_export"
      defaultMessage="CSV export for data subject categories"
    />
  ),
  personalDataCategories_csv_export: (
    <FormattedMessage
      id="EventCode.personalDataCategories_csv_export"
      defaultMessage="CSV export for personal data categories"
    />
  ),
  personalDataItems_csv_export: (
    <FormattedMessage
      id="EventCode.personalDataItems_csv_export"
      defaultMessage="CSV export for personal data items"
    />
  ),
  dataSources_csv_export: (
    <FormattedMessage
      id="EventCode.dataSources_csv_export"
      defaultMessage="CSV export for data sources"
    />
  ),
  retentionTermOffsets_csv_export: (
    <FormattedMessage
      id="EventCode.retentionTermOffsets_csv_export"
      defaultMessage="CSV export for retention term offsets"
    />
  ),
  securityMeasures_csv_export: (
    <FormattedMessage
      id="EventCode.securityMeasures_csv_export"
      defaultMessage="CSV export for security measures"
    />
  ),
  purposes_csv_export: (
    <FormattedMessage
      id="EventCode.purposes_csv_export"
      defaultMessage="CSV export for purposes"
    />
  ),
  mitigatingMeasures_csv_export: (
    <FormattedMessage
      id="EventCode.mitigatingMeasures_csv_export"
      defaultMessage="CSV export for mitigating measures"
    />
  ),
  riskDetails_csv_export: (
    <FormattedMessage
      id="EventCode.riskDetails_csv_export"
      defaultMessage="CSV export for risk details"
    />
  ),
  qualityControls_csv_export: (
    <FormattedMessage
      id="EventCode.qualityControls_csv_export"
      defaultMessage="CSV export for quality controls"
    />
  ),
  dataSubjectRights_csv_export: (
    <FormattedMessage
      id="EventCode.dataSubjectRights_csv_export"
      defaultMessage="CSV export for data subject rights"
    />
  ),
  threats_csv_export: (
    <FormattedMessage
      id="EventCode.threats_csv_export"
      defaultMessage="CSV export for threats"
    />
  ),
  impacts_csv_export: (
    <FormattedMessage
      id="EventCode.impacts_csv_export"
      defaultMessage="CSV export for impacts"
    />
  ),
  policies_csv_export: (
    <FormattedMessage
      id="EventCode.policies_csv_export"
      defaultMessage="CSV export for policies"
    />
  ),
  dsr_csv_export: (
    <FormattedMessage
      id="EventCode.dsr_csv_export"
      defaultMessage="CSV export for DSR"
    />
  ),
  dataSubjectRequest_update: (
    <FormattedMessage
      id="EventCode.dataSubjectRequest_update"
      defaultMessage="Data subject request updated"
    />
  ),
  dataSubjectRequest_create: (
    <FormattedMessage
      id="EventCode.dataSubjectRequest_create"
      defaultMessage="Data subject request created"
    />
  ),
  dataSubjectRequest_delete: (
    <FormattedMessage
      id="EventCode.dataSubjectRequest_delete"
      defaultMessage="Data subject request deleted"
    />
  ),
  dataSubject_update: (
    <FormattedMessage
      id="EventCode.dataSubject_update"
      defaultMessage="Data subject updated"
    />
  ),
  dataSubject_create: (
    <FormattedMessage
      id="EventCode.dataSubject_create"
      defaultMessage="Data subject created"
    />
  ),
  dataSubject_delete: (
    <FormattedMessage
      id="EventCode.dataSubject_delete"
      defaultMessage="Data subject deleted"
    />
  ),
  accountabilitySecurityMeasures_csv_export: (
    <FormattedMessage
      id="EventCode.accountabilitySecurityMeasures_csv_export"
      defaultMessage="CSV export for accountability security measures"
    />
  ),
  accountabilities_csv_export: (
    <FormattedMessage
      id="EventCode.accountabilities_csv_export"
      defaultMessage="CSV export for accountabilities"
    />
  ),
  dataRecipientCategories_csv_export: (
    <FormattedMessage
      id="EventCode.dataRecipientCategories_csv_export"
      defaultMessage="CSV export for data recipient categories"
    />
  ),
  exportResults: (
    <FormattedMessage
      id="ExportDialog.header"
      defaultMessage="Export Results"
    />
  ),
  import_create: (
    <FormattedMessage
      id="EventCode.import_create"
      description="Create master data import"
      defaultMessage="Create master data import"
    />
  ),
  message_create: (
    <FormattedMessage
      id="EventCode.message_create"
      defaultMessage="Create message"
    />
  ),
  message_delete: removeTranslations('deleteMessage'),
  message_update: (
    <FormattedMessage
      id="EventCode.message_update"
      defaultMessage="Update message"
    />
  ),
  masterdata_import: (
    <FormattedMessage
      id="EventCode.masterdata_import"
      description="Process master data import"
      defaultMessage="Process master data import"
    />
  ),
  dataRecipientCategory_create: (
    <FormattedMessage
      id="EventCode.dataRecipientCategory_create"
      description="Create data recipient category"
      defaultMessage="Create data recipient category"
    />
  ),
  dataRecipientCategory_update: (
    <FormattedMessage
      id="EventCode.dataRecipientCategory_update"
      description="Update data recipient category"
      defaultMessage="Update data recipient category"
    />
  ),
  dataSourceCategory_create: (
    <FormattedMessage
      id="EventCode.dataSourceCategory_create"
      defaultMessage="Create data source category"
    />
  ),
  dataSourceCategory_update: (
    <FormattedMessage
      id="EventCode.dataSourceCategory_update"
      defaultMessage="Update data source category"
    />
  ),
  dataSourceCategory_delete: (
    <FormattedMessage
      id="EventCode.dataSourceCategory_delete"
      defaultMessage="Delete data source category"
    />
  ),
  dataSourceCategory_bulk_delete: (
    <FormattedMessage
      id="EventCode.dataSourceCategory_bulk_delete"
      defaultMessage="Bulk delete data source category"
    />
  ),
  dataSourceCategory_csv_export: (
    <FormattedMessage
      id="EventCode.dataSourceCategory_csv_export"
      defaultMessage="CSV export for data source category"
    />
  ),
  token_regenerate: (
    <FormattedMessage
      id="EventCode.token_regenerate"
      defaultMessage="Regenerate token"
    />
  ),
  token_create: (
    <FormattedMessage
      id="EventCode.token_create"
      defaultMessage="Create token"
    />
  ),
  token_update: (
    <FormattedMessage
      id="EventCode.token_update"
      defaultMessage="Update token"
    />
  ),
  token_delete: (
    <FormattedMessage
      id="EventCode.token_delete"
      defaultMessage="Delete token"
    />
  ),
  user_authenticationFail: (
    <FormattedMessage
      id="EventCode.user_authenticationFail"
      defaultMessage="Direct API authentication fail"
    />
  ),
  user_identityAccessTokenAuthentication: (
    <FormattedMessage
      id="EventCode.user_identityAccessTokenAuthentication"
      defaultMessage="Direct API authentication"
    />
  ),
  organisation_csv_export: (
    <FormattedMessage
      id="EventCode.organisation_csv_export"
      defaultMessage="CSV export for organisations"
    />
  ),
  organisation_import: (
    <FormattedMessage
      id="EventCode.organisation_import"
      defaultMessage="Import organisations"
    />
  ),
  dataRecipientCategory_delete: removeTranslations(
    'dataRecipientCategoryDelete'
  ),
  processingCategory_bulk_delete: removeTranslations(
    'processingCategoryBulkDelete'
  ),
  dataSource_bulk_delete: removeTranslations('dataSourceBulkDelete'),
  dataSubjectCategory_bulk_delete: removeTranslations(
    'dataSubjectCategoryBulkDelete'
  ),
  personalDataCategory_bulk_delete: removeTranslations(
    'personalDataCategoryBulkDelete'
  ),
  personalDataItem_bulk_delete: removeTranslations(
    'personalDataItemBulkDelete'
  ),
  securityMeasuresDescription_bulk_delete: removeTranslations(
    'securityMeasuresDescriptionBulkDelete'
  ),
  reference_bulk_delete: removeTranslations('referenceBulkDelete'),
  retentionTermOffset_bulk_delete: removeTranslations(
    'retentionTermOffsetBulkDelete'
  ),
  rights_bulk_delete: removeTranslations('rightsBulkDelete'),
  riskDetails_bulk_delete: removeTranslations('riskDetailsBulkDelete'),
  purpose_bulk_delete: removeTranslations('purposeBulkDelete'),
  qualityControl_bulk_delete: removeTranslations('qualityControlBulkDelete'),
  mitigatingMeasure_bulk_delete: removeTranslations(
    'mitigatingMeasureBulkDelete'
  ),
  threat_bulk_delete: removeTranslations('threatBulkDelete'),
  impact_bulk_delete: removeTranslations('impactBulkDelete'),
  policy_bulk_delete: removeTranslations('policyBulkDelete'),
  accountability_bulk_delete: removeTranslations('accountabilityBulkDelete'),
  tag_bulk_delete: removeTranslations('tagBulkDelete'),
  tag_create: (
    <FormattedMessage
      id="EventCode.tag_create"
      description="Create tag"
      defaultMessage="Create tag"
    />
  ),
  tag_update: (
    <FormattedMessage
      id="EventCode.tag_update"
      description="Update tag"
      defaultMessage="Update tag"
    />
  ),
  tag_delete: removeTranslations('tagDelete'),
  banner_create: (
    <FormattedMessage
      id="EventCode.banner_create"
      description="Banner message created"
      defaultMessage="Banner message created"
    />
  ),
  banner_delete: (
    <FormattedMessage
      id="EventCode.banner_delete"
      description="Banner message deleted"
      defaultMessage="Banner message deleted"
    />
  ),
  fieldsVisibilitySetting_update: (
    <FormattedMessage
      id="EventCode.fieldsVisibilitySetting_update"
      description="Update fields visibility setting"
      defaultMessage="Update fields visibility setting"
    />
  ),
  helpNote_update: (
    <FormattedMessage
      id="EventCode.helpNote_update"
      defaultMessage="Update help notes"
    />
  ),
  tenant_create: (
    <FormattedMessage
      id="EventCode.tenant_create"
      description="Create tenant"
      defaultMessage="Create tenant"
    />
  ),
  task_update: (
    <FormattedMessage
      id="EventCode.task_update"
      description="Update task"
      defaultMessage="Update task"
    />
  ),
  task_delete: removeTranslations('taskDelete'),
  tenant_update: (
    <FormattedMessage
      id="EventCode.tenant_update"
      description="Update tenant"
      defaultMessage="Update tenant"
    />
  ),
  tenant_delete: removeTranslations('tenantDelete'),
  tenant_permissionUpdate: (
    <FormattedMessage
      id="EventCode.tenant_permissionUpdate"
      description="Update tenant permission settings"
      defaultMessage="Update tenant permission settings"
    />
  ),
  tenant_mfaUpdate: (
    <FormattedMessage
      id="EventCode.tenant_mfaUpdate"
      description="Update tenant MFA settings"
      defaultMessage="Update tenant MFA settings"
    />
  ),
  tenant_ssoUpdate: (
    <FormattedMessage
      id="EventCode.tenant_ssoUpdate"
      description="Update tenant SSO settings"
      defaultMessage="Update tenant SSO settings"
    />
  ),
  tenant_whitelabellingUpdate: (
    <FormattedMessage
      id="EventCode.tenant_whitelabellingUpdate"
      description="Update tenant whitelabelling settings"
      defaultMessage="Update tenant whitelabelling settings"
    />
  ),
  user_identityLogin: (
    <FormattedMessage
      id="EventCode.user_identityLogin"
      description="User login"
      defaultMessage="User login"
    />
  ),
  user_identityOtpValidate: (
    <FormattedMessage
      id="EventCode.user_identityOtpValidate"
      description="User validates OTP code"
      defaultMessage="User validates OTP code"
    />
  ),
  user_create: (
    <FormattedMessage
      id="EventCode.user_create"
      description="Create user"
      defaultMessage="Create user"
    />
  ),
  user_invite: (
    <FormattedMessage
      id="EventCode.user_invite"
      description="Invite user"
      defaultMessage="Invite user"
    />
  ),
  user_register: (
    <FormattedMessage
      id="EventCode.user_register"
      description="User registration"
      defaultMessage="User registration"
    />
  ),
  user_reinvite: (
    <FormattedMessage
      id="EventCode.user_reinvite"
      description="Reinvite user"
      defaultMessage="Reinvite user"
    />
  ),
  user_IdentityOtpValidate_Fail: (
    <FormattedMessage
      id="EventCode.user_IdentityOtpValidate_Fail"
      description="MFA login fail"
      defaultMessage="MFA login fail"
    />
  ),
  user_IdentityAccountLocked: (
    <FormattedMessage
      id="EventCode. user_IdentityAccountLocked"
      description="Account locked"
      defaultMessage="Account locked"
    />
  ),
  user_update: (
    <FormattedMessage
      id="EventCode.user_update"
      description="Update user"
      defaultMessage="Update user"
    />
  ),
  user_delete: removeTranslations('userDelete'),
  user_passwordChange: (
    <FormattedMessage
      id="EventCode.user_passwordChange"
      description="Change user's password"
      defaultMessage="Change user's password"
    />
  ),
  user_passwordReset: (
    <FormattedMessage
      id="EventCode.user_passwordReset"
      description="User password reset"
      defaultMessage="User password reset"
    />
  ),
  user_passwordForgot: (
    <FormattedMessage
      id="EventCode.user_passwordForgot"
      description="User forgot password"
      defaultMessage="User forgot password"
    />
  ),
  organisation_create: (
    <FormattedMessage
      id="EventCode.organisation_create"
      description="Create organisation"
      defaultMessage="Create organisation"
    />
  ),
  organisation_update: (
    <FormattedMessage
      id="EventCode.organisation_update"
      description="Update organisation"
      defaultMessage="Update organisation"
    />
  ),
  organisation_delete: removeTranslations('organisationDelete'),
  assessment_create: (
    <FormattedMessage
      id="EventCode.assessment_create"
      description="Create assessment"
      defaultMessage="Create assessment"
    />
  ),
  assessment_update: (
    <FormattedMessage
      id="EventCode.assessment_update"
      description="Update assessment"
      defaultMessage="Update assessment"
    />
  ),
  assessment_delete: removeTranslations('assessmentDelete'),
  assessment_promoteToProcessing: (
    <FormattedMessage
      id="EventCode.assessment_promoteToProcessing"
      description="Promote assessment to processing"
      defaultMessage="Promote assessment to processing"
    />
  ),
  breach_create: (
    <FormattedMessage
      id="EventCode.breach_create"
      description="Create breach"
      defaultMessage="Create breach"
    />
  ),
  breach_update: (
    <FormattedMessage
      id="EventCode.breach_update"
      description="Update breach"
      defaultMessage="Update breach"
    />
  ),
  breach_delete: removeTranslations('breachDelete'),
  processing_create: (
    <FormattedMessage
      id="EventCode.processing_create"
      description="Create processing"
      defaultMessage="Create processing"
    />
  ),
  processing_update: (
    <FormattedMessage
      id="EventCode.processing_update"
      description="Update processing"
      defaultMessage="Update processing"
    />
  ),
  processing_delete: removeTranslations('processingDelete'),
  processing_promoteToAssessment: (
    <FormattedMessage
      id="EventCode.processing_promoteToAssessment"
      description="Promote processing to assessment"
      defaultMessage="Promote processing to assessment"
    />
  ),
  processing_promoteToBreach: (
    <FormattedMessage
      id="EventCode.processing_promoteToBreach"
      description="Promote processing to breach"
      defaultMessage="Promote processing to breach"
    />
  ),
  template_create: (
    <FormattedMessage
      id="EventCode.template_create"
      description="Create template"
      defaultMessage="Create template"
    />
  ),
  template_delete: removeTranslations('templateDelete'),
  template_update: (
    <FormattedMessage
      id="EventCode.template_update"
      description="Update template"
      defaultMessage="Update template"
    />
  ),
  link_create: (
    <FormattedMessage
      id="EventCode.link_create"
      description="Create link"
      defaultMessage="Create link"
    />
  ),
  link_delete: removeTranslations('linkDelete'),
  link_update: (
    <FormattedMessage
      id="EventCode.link_update"
      description="Update link"
      defaultMessage="Update link"
    />
  ),
  processingCategory_create: (
    <FormattedMessage
      id="EventCode.processingCategory_create"
      description="Create processing category"
      defaultMessage="Create processing category"
    />
  ),
  processingCategory_update: (
    <FormattedMessage
      id="EventCode.processingCategory_update"
      description="Update processing category"
      defaultMessage="Update processing category"
    />
  ),
  processingCategory_delete: removeTranslations('processingCategoryDelete'),
  reference_create: (
    <FormattedMessage
      id="EventCode.reference_create"
      description="Create reference"
      defaultMessage="Create reference"
    />
  ),
  reference_update: (
    <FormattedMessage
      id="EventCode.reference_update"
      description="Update reference"
      defaultMessage="Update reference"
    />
  ),
  reference_delete: removeTranslations('referencesDelete'),
  dataSource_create: (
    <FormattedMessage
      id="EventCode.dataSource_create"
      description="Create data source"
      defaultMessage="Create data source"
    />
  ),
  dataSource_update: (
    <FormattedMessage
      id="EventCode.dataSource_update"
      description="Update data source"
      defaultMessage="Update data source"
    />
  ),
  dataSource_delete: removeTranslations('dataSourceDelete'),
  personalDataCategory_create: (
    <FormattedMessage
      id="EventCode.personalDataCategory_create"
      description="Create personal data category"
      defaultMessage="Create personal data category"
    />
  ),
  personalDataCategory_update: (
    <FormattedMessage
      id="EventCode.personalDataCategory_update"
      description="Update personal data category"
      defaultMessage="Update personal data category"
    />
  ),
  personalDataCategory_delete: removeTranslations('personalDataCategoryDelete'),
  personalDataItem_create: (
    <FormattedMessage
      id="EventCode.personalDataItem_create"
      description="Create personal data item"
      defaultMessage="Create personal data item"
    />
  ),
  personalDataItem_update: (
    <FormattedMessage
      id="EventCode.personalDataItem_update"
      description="Update personal data item"
      defaultMessage="Update personal data item"
    />
  ),
  personalDataItem_delete: removeTranslations('personalDataItemDelete'),
  dataSubjectCategory_create: (
    <FormattedMessage
      id="EventCode.dataSubjectCategory_create"
      description="Create data subject category"
      defaultMessage="Create data subject category"
    />
  ),
  dataSubjectCategory_update: (
    <FormattedMessage
      id="EventCode.dataSubjectCategory_update"
      description="Update data subject category"
      defaultMessage="Update data subject category"
    />
  ),
  dataSubjectCategory_delete: removeTranslations('dataSubjectCategoryDelete'),
  securityMeasuresDescription_create: (
    <FormattedMessage
      id="EventCode.securityMeasuresDescription_create"
      description="Create security measure description"
      defaultMessage="Create security measure description"
    />
  ),
  securityMeasuresDescription_update: (
    <FormattedMessage
      id="EventCode.securityMeasuresDescription_update"
      description="Update security measure description"
      defaultMessage="Update security measure description"
    />
  ),
  securityMeasuresDescription_delete: removeTranslations(
    'securityMeasuresDescriptionDelete'
  ),
  securityMeasures_delete: removeTranslations('securityMeasuresDelete'),
  technicalSecurityMeasures_delete: removeTranslations(
    'technicalSecurityMeasuresDelete'
  ),
  organisationalSecurityMeasures_delete: removeTranslations(
    'organisationalSecurityMeasuresDelete'
  ),
  transferPurposeData_delete: removeTranslations('transferPurposeDataDelete'),
  reportSearch_create: (
    <FormattedMessage
      id="EventCode.reportSearch_create"
      description="Create report search"
      defaultMessage="Create report search"
    />
  ),
  reportSearch_update: (
    <FormattedMessage
      id="EventCode.reportSearch_update"
      description="Update report search"
      defaultMessage="Update report search"
    />
  ),
  reportSearch_delete: removeTranslations('reportSearchDelete'),
  document_delete: removeTranslations('documentDelete'),
  document_upload: (
    <FormattedMessage
      id="EventCode.document_upload"
      description="Upload document"
      defaultMessage="Upload document"
    />
  ),
  document_update: (
    <FormattedMessage
      id="EventCode.document_update"
      description="Update document"
      defaultMessage="Update document"
    />
  ),
  document_replace: (
    <FormattedMessage
      id="Document.replaceDocument"
      description="Replace document"
      defaultMessage="Replace document"
    />
  ),
  document_create: (
    <FormattedMessage
      id="Document.createDocument"
      defaultMessage="Create document"
    />
  ),
  document_bulk_delete: (
    <FormattedMessage
      id="Document.bulkDelete"
      defaultMessage="Bulk delete document"
    />
  ),
  task_delegate: (
    <FormattedMessage
      id="EventCode.task_delegate"
      description="Delegate task"
      defaultMessage="Delegate task"
    />
  ),
  partner_create: (
    <FormattedMessage
      id="EventCode.partner_create"
      description="Create partner"
      defaultMessage="Create partner"
    />
  ),
  partner_update: (
    <FormattedMessage
      id="EventCode.partner_update"
      description="Update partner"
      defaultMessage="Update partner"
    />
  ),
  partner_delete: removeTranslations('partnerDelete'),
  retentionTermOffset_create: (
    <FormattedMessage
      id="EventCode.retentionTermOffset_create"
      description="Create retention term offset"
      defaultMessage="Create retention term offset"
    />
  ),
  retentionTermOffset_update: (
    <FormattedMessage
      id="EventCode.retentionTermOffset_updates"
      description="Update retention term offset"
      defaultMessage="Update retention term offset"
    />
  ),
  retentionTermOffset_delete: removeTranslations('retentionTermOffsetDelete'),
  job_create: (
    <FormattedMessage
      id="EventCode.job_create"
      description="Create job"
      defaultMessage="Create job"
    />
  ),
  job_view: (
    <FormattedMessage
      id="EventCode.job_view"
      description="View job"
      defaultMessage="View job"
    />
  ),
  user_identityLogin_Fail: (
    <FormattedMessage
      id="EventCode.user_identityLogin_Fail"
      description="User login fail"
      defaultMessage="User login fail"
    />
  ),
  job_restart: (
    <FormattedMessage
      id="EventCode.job_restart"
      description="Restart job"
      defaultMessage="Restart job"
    />
  ),
  task_claim: (
    <FormattedMessage
      id="EventCode.task_claim"
      description="Claim task"
      defaultMessage="Claim task"
    />
  ),
  task_unclaim: (
    <FormattedMessage
      id="EventCode.task_unclaim"
      description="Unclaim task"
      defaultMessage="Unclaim task"
    />
  ),
  rights_create: (
    <FormattedMessage
      id="EventCode.rights_create"
      description="Create right"
      defaultMessage="Create right"
    />
  ),
  rights_update: (
    <FormattedMessage
      id="EventCode.rights_update"
      description="Update right"
      defaultMessage="Update right"
    />
  ),
  dataSubjectRights_delete: removeTranslations('dataSubjectRightsDelete'),
  rights_delete: removeTranslations('rightsDelete'),
  datasubjectrights_delete: removeTranslations('datasubjectrightsDelete'),
  riskDetails_create: (
    <FormattedMessage
      id="EventCode.riskDetails_create"
      description="Create risk detail"
      defaultMessage="Create risk detail"
    />
  ),
  riskDetails_update: (
    <FormattedMessage
      id="EventCode.riskDetails_update"
      description="Update risk detail"
      defaultMessage="Update risk detail"
    />
  ),
  riskDetails_delete: removeTranslations('riskDetailsDelete'),
  purpose_create: (
    <FormattedMessage
      id="EventCode.purpose_create"
      description="Create purpose"
      defaultMessage="Create purpose"
    />
  ),
  purpose_update: (
    <FormattedMessage
      id="EventCode.purpose_update"
      description="Update purpose"
      defaultMessage="Update purpose"
    />
  ),
  purpose_delete: removeTranslations('purposeDelete'),
  purposes_delete: removeTranslations('purposesDelete'),
  qualityControl_create: (
    <FormattedMessage
      id="EventCode.qualityControl_create"
      description="Create qualityControl"
      defaultMessage="Create quality control"
    />
  ),
  qualityControl_update: (
    <FormattedMessage
      id="EventCode.qualityControl_update"
      description="Update quality control"
      defaultMessage="Update quality control"
    />
  ),
  qualityControls_delete: removeTranslations('qualityControlsDelete'),
  qualityControl_delete: removeTranslations('qualityControlDelete'),
  mitigatingMeasure_create: (
    <FormattedMessage
      id="EventCode.mitigatingMeasure_create"
      description="Create mitigating measure"
      defaultMessage="Create mitigating measure"
    />
  ),
  mitigatingMeasure_update: (
    <FormattedMessage
      id="EventCode.mitigatingMeasure_update"
      description="Update mitigating measure"
      defaultMessage="Update mitigating measure"
    />
  ),
  mitigatingMeasures_delete: removeTranslations('mitigatingMeasuresDelete'),
  mitigatingMeasure_delete: removeTranslations('mitigatingMeasureDelete'),
  threat_create: (
    <FormattedMessage
      id="EventCode.threat_create"
      description="Create threat"
      defaultMessage="Create threat"
    />
  ),
  threat_update: (
    <FormattedMessage
      id="EventCode.threat_update"
      description="Update threat"
      defaultMessage="Update threat"
    />
  ),
  threats_delete: removeTranslations('threatsDelete'),
  threat_delete: removeTranslations('threatDelete'),
  impact_create: (
    <FormattedMessage
      id="EventCode.impact_create"
      description="Create impact"
      defaultMessage="Create impact"
    />
  ),
  impact_update: (
    <FormattedMessage
      id="EventCode.impact_update"
      description="Update impact"
      defaultMessage="Update impact"
    />
  ),
  impacts_delete: removeTranslations('impactsDelete'),
  impact_delete: removeTranslations('impactDelete'),
  user_ssoLogin: (
    <FormattedMessage
      id="EventCode.user_ssoLogin"
      description="SSO login"
      defaultMessage="SSO login"
    />
  ),
  user_ssoLogin_InvalidSAML: (
    <FormattedMessage
      id="EventCode.user_ssoLogin_InvalidSAML"
      description="SSO login fail due to invalid SAML"
      defaultMessage="SSO login failed due to invalid SAML"
    />
  ),
  user_ssoLogin_SSODisabled: (
    <FormattedMessage
      id="EventCode.user_ssoLogin_SSODisabled"
      description="SSO login failed as it has been disabled"
      defaultMessage="SSO login failed as it has been disabled"
    />
  ),
  user_ssoLogin_InvalidConfig: (
    <FormattedMessage
      id="EventCode.user_ssoLogin_InvalidConfig"
      description="SSO login failed due to invalid configuration"
      defaultMessage="SSO login failed due to invalid configuration"
    />
  ),
  entities_usage: (
    <FormattedMessage
      id="EventCode.entities_usage"
      description="View entity usage"
      defaultMessage="View entity usage"
    />
  ),
  policies_delete: removeTranslations('policiesDelete'),
  policy_delete: removeTranslations('policyDelete'),
  personalDataItems_delete: removeTranslations('personalDataItemsDelete'),
  dataProtectionMeasures_delete: removeTranslations(
    'dataProtectionMeasuresDelete'
  ),
  accountabilityMeasures_delete: removeTranslations(
    'accountabilityMeasuresDelete'
  ),
  findings_delete: removeTranslations('findingsDelete'),
  decisions_delete: removeTranslations('decisionsDelete'),
  policy_create: (
    <FormattedMessage
      id="EventCode.policy_create"
      description="Create policy"
      defaultMessage="Create policy"
    />
  ),
  accountability_delete: removeTranslations('accountabilityDelete'),
  accountabilities_delete: removeTranslations('accountabilitiesDelete'),
  accountability_create: (
    <FormattedMessage
      id="EventCode.accountability_create"
      description="Create accountability"
      defaultMessage="Create accountability"
    />
  ),
  accountability_update: (
    <FormattedMessage
      id="EventCode.accountability_update"
      description="Update accountability"
      defaultMessage="Update accountability"
    />
  ),
  policy_update: (
    <FormattedMessage
      id="EventCode.policy_update"
      description="Update policy"
      defaultMessage="Update policy"
    />
  ),
  notification_status_change: (
    <FormattedMessage
      id="EventCode.notification_status_change"
      defaultMessage="Notification status changed"
    />
  ),
  passwordExpirySetting_update: (
    <FormattedMessage
      id="EventCode.passwordExpirySetting_update"
      defaultMessage="Password expiry settings updated"
    />
  ),
  subscription_create: (
    <FormattedMessage
      id="EventCode.subscription_create"
      defaultMessage="Create subscription"
    />
  ),
  subscription_update: (
    <FormattedMessage
      id="EventCode.subscription_update"
      defaultMessage="Update subscription"
    />
  ),
  subscription_upgrade: (
    <FormattedMessage
      id="EventCode.subscription_upgrade"
      defaultMessage="Upgrade subscription"
    />
  ),
  subscription_cancel: (
    <FormattedMessage
      id="EventCode.subscription_cancel"
      defaultMessage="Cancel subscription"
    />
  ),
  subscription_page_delete: (
    <FormattedMessage
      id="EventCode.subscription_page_delete"
      defaultMessage="Subscription payment aborted midway"
    />
  ),
  vendorForms_create: (
    <FormattedMessage
      id="EventCode.vendorForms_create"
      defaultMessage="Create vendor form"
    />
  ),
  vendorForms_update: (
    <FormattedMessage
      id="EventCode.vendorForms_update"
      defaultMessage="Update vendor form"
    />
  ),
  vendorForms_template_create: (
    <FormattedMessage
      id="EventCode.vendorForms_template_create"
      defaultMessage="Create vendor form template"
    />
  ),
  vendorForms_template_update: (
    <FormattedMessage
      id="EventCode.vendorForms_template_update"
      defaultMessage="Update vendor form template"
    />
  ),
  vendorForms_message_create: (
    <FormattedMessage
      id="EventCode.vendorForms_message_create"
      defaultMessage="Create vendor form message"
    />
  ),
  vendorForms_delete: (
    <FormattedMessage
      id="EventCode.vendorForms_delete"
      defaultMessage="Delete vendor form"
    />
  ),
  vendorForms_template_delete: (
    <FormattedMessage
      id="EventCode.vendorForms_template_delete"
      defaultMessage="Delete vendor form template"
    />
  ),
  vendorForm_submit: (
    <FormattedMessage
      id="EventCode.vendorForm_submit"
      defaultMessage="Submit vendor form"
    />
  ),
  registryFieldsVisibilitySetting_update: (
    <FormattedMessage
      id="EventCode.registryFieldsVisibilitySetting_update"
      defaultMessage="Update registry field visibility settings"
    />
  ),
  registry_delete: (
    <FormattedMessage
      id="EventCode.registry_delete"
      defaultMessage="Delete registry"
    />
  ),
  registry_update: (
    <FormattedMessage
      id="EventCode.registry_update"
      defaultMessage="Update registry"
    />
  ),
  registry_create: (
    <FormattedMessage
      id="EventCode.registry_create"
      defaultMessage="Create registry"
    />
  ),
  global_processingCategory_create: (
    <FormattedMessage
      id="EventCode.global_processingCategory_create"
      defaultMessage="Global create processing category"
    />
  ),
  global_processingCategory_update: (
    <FormattedMessage
      id="EventCode.global_processingCategory_update"
      defaultMessage="Global update processing category"
    />
  ),
  global_processingCategory_delete: (
    <FormattedMessage
      id="EventCode.global_processingCategory_delete"
      defaultMessage="Global delete processing category"
    />
  ),
  global_dataSource_create: (
    <FormattedMessage
      id="EventCode.global_dataSource_create"
      defaultMessage="Global create data source"
    />
  ),
  global_dataSource_update: (
    <FormattedMessage
      id="EventCode.global_dataSource_update"
      defaultMessage="Global update data source"
    />
  ),
  global_dataSource_delete: (
    <FormattedMessage
      id="EventCode.global_dataSource_delete"
      defaultMessage="Global delete data source"
    />
  ),
  global_dataSubjectCategory_create: (
    <FormattedMessage
      id="EventCode.global_dataSubjectCategory_create"
      defaultMessage="Global create data subject category"
    />
  ),
  global_dataSubjectCategory_update: (
    <FormattedMessage
      id="EventCode.global_dataSubjectCategory_update"
      defaultMessage="Global update data subject category"
    />
  ),
  global_dataSubjectCategory_delete: (
    <FormattedMessage
      id="EventCode.global_dataSubjectCategory_delete"
      defaultMessage="Global delete data subject category"
    />
  ),
  global_personalDataCategory_create: (
    <FormattedMessage
      id="EventCode.global_personalDataCategory_create"
      defaultMessage="Global create personal data category"
    />
  ),
  global_personalDataCategory_update: (
    <FormattedMessage
      id="EventCode.global_personalDataCategory_update"
      defaultMessage="Global update personal data category"
    />
  ),
  global_personalDataCategory_delete: (
    <FormattedMessage
      id="EventCode.global_personalDataCategory_delete"
      defaultMessage="Global delete personal data category"
    />
  ),
  global_personalDataItem_create: (
    <FormattedMessage
      id="EventCode.global_personalDataItem_create"
      defaultMessage="Global create personal data item"
    />
  ),
  global_personalDataItem_update: (
    <FormattedMessage
      id="EventCode.global_personalDataItem_update"
      defaultMessage="Global update personal data item"
    />
  ),
  global_personalDataItem_delete: (
    <FormattedMessage
      id="EventCode.global_personalDataItem_delete"
      defaultMessage="Global delete personal data item"
    />
  ),
  global_securityMeasuresDescription_create: (
    <FormattedMessage
      id="EventCode.global_securityMeasuresDescription_create"
      defaultMessage="Global create security measures description"
    />
  ),
  global_securityMeasuresDescription_update: (
    <FormattedMessage
      id="EventCode.global_securityMeasuresDescription_update"
      defaultMessage="Global update security measures description"
    />
  ),
  global_securityMeasuresDescription_delete: (
    <FormattedMessage
      id="EventCode.global_securityMeasuresDescription_delete"
      defaultMessage="Global delete security measures description"
    />
  ),
  global_reference_create: (
    <FormattedMessage
      id="EventCode.global_reference_create"
      defaultMessage="Global create reference"
    />
  ),
  global_reference_update: (
    <FormattedMessage
      id="EventCode.global_reference_update"
      defaultMessage="Global update reference"
    />
  ),
  global_reference_delete: (
    <FormattedMessage
      id="EventCode.global_reference_delete"
      defaultMessage="Global delete reference"
    />
  ),
  global_dataSourceCategory_create: (
    <FormattedMessage
      id="EventCode.global_dataSourceCategory_create"
      defaultMessage="Global create data source category"
    />
  ),
  global_dataSourceCategory_update: (
    <FormattedMessage
      id="EventCode.global_dataSourceCategory_update"
      defaultMessage="Global update data source category"
    />
  ),
  global_dataSourceCategory_delete: (
    <FormattedMessage
      id="EventCode.global_dataSourceCategory_delete"
      defaultMessage="Global delete data source category"
    />
  ),
  global_retentionTermOffset_create: (
    <FormattedMessage
      id="EventCode.global_retentionTermOffset_create"
      defaultMessage="Global create retention term offset"
    />
  ),
  global_retentionTermOffset_update: (
    <FormattedMessage
      id="EventCode.global_retentionTermOffset_update"
      defaultMessage="Global update retention term offset"
    />
  ),
  global_retentionTermOffset_delete: (
    <FormattedMessage
      id="EventCode.global_retentionTermOffset_delete"
      defaultMessage="Global delete retention term offset"
    />
  ),
  global_rights_create: (
    <FormattedMessage
      id="EventCode.global_rights_create"
      defaultMessage="Global create rights"
    />
  ),
  global_rights_update: (
    <FormattedMessage
      id="EventCode.global_rights_update"
      defaultMessage="Global update rights"
    />
  ),
  global_rights_delete: (
    <FormattedMessage
      id="EventCode.global_rights_delete"
      defaultMessage="Global delete rights"
    />
  ),
  global_riskDetails_create: (
    <FormattedMessage
      id="EventCode.global_riskDetails_create"
      defaultMessage="Global create risk details"
    />
  ),
  global_riskDetails_update: (
    <FormattedMessage
      id="EventCode.global_riskDetails_update"
      defaultMessage="Global update risk details"
    />
  ),
  global_riskDetails_delete: (
    <FormattedMessage
      id="EventCode.global_riskDetails_delete"
      defaultMessage="Global delete risk details"
    />
  ),
  global_purpose_create: (
    <FormattedMessage
      id="EventCode.global_purpose_create"
      defaultMessage="Global create purpose"
    />
  ),
  global_purpose_update: (
    <FormattedMessage
      id="EventCode.global_purpose_update"
      defaultMessage="Global update purpose"
    />
  ),
  global_purpose_delete: (
    <FormattedMessage
      id="EventCode.global_purpose_delete"
      defaultMessage="Global delete purpose"
    />
  ),
  global_qualityControl_create: (
    <FormattedMessage
      id="EventCode.global_qualityControl_create"
      defaultMessage="Global create quality control"
    />
  ),
  global_qualityControl_update: (
    <FormattedMessage
      id="EventCode.global_qualityControl_update"
      defaultMessage="Global update quality control"
    />
  ),
  global_qualityControl_delete: (
    <FormattedMessage
      id="EventCode.global_qualityControl_delete"
      defaultMessage="Global delete quality control"
    />
  ),
  global_mitigatingMeasure_create: (
    <FormattedMessage
      id="EventCode.global_mitigatingMeasure_create"
      defaultMessage="Global create mitigating measure"
    />
  ),
  global_mitigatingMeasure_update: (
    <FormattedMessage
      id="EventCode.global_mitigatingMeasure_update"
      defaultMessage="Global update mitigating measure"
    />
  ),
  global_mitigatingMeasure_delete: (
    <FormattedMessage
      id="EventCode.global_mitigatingMeasure_delete"
      defaultMessage="Global delete mitigating measure"
    />
  ),
  global_threat_create: (
    <FormattedMessage
      id="EventCode.global_threat_create"
      defaultMessage="Global create threat"
    />
  ),
  global_threat_update: (
    <FormattedMessage
      id="EventCode.global_threat_update"
      defaultMessage="Global update threat"
    />
  ),
  global_threat_delete: (
    <FormattedMessage
      id="EventCode.global_threat_delete"
      defaultMessage="Global delete threat"
    />
  ),
  global_impact_create: (
    <FormattedMessage
      id="EventCode.global_impact_create"
      defaultMessage="Global create impact"
    />
  ),
  global_impact_update: (
    <FormattedMessage
      id="EventCode.global_impact_update"
      defaultMessage="Global update impact"
    />
  ),
  global_impact_delete: (
    <FormattedMessage
      id="EventCode.global_impact_delete"
      defaultMessage="Global delete impact"
    />
  ),
  global_policy_create: (
    <FormattedMessage
      id="EventCode.global_policy_create"
      defaultMessage="Global create policy"
    />
  ),
  global_policy_update: (
    <FormattedMessage
      id="EventCode.global_policy_update"
      defaultMessage="Global update policy"
    />
  ),
  global_policy_delete: (
    <FormattedMessage
      id="EventCode.global_policy_delete"
      defaultMessage="Global delete policy"
    />
  ),
  global_dataRecipientCategories_create: (
    <FormattedMessage
      id="EventCode.global_dataRecipientCategories_create"
      defaultMessage="Global create data recipient categories"
    />
  ),
  global_dataRecipientCategories_update: (
    <FormattedMessage
      id="EventCode.global_dataRecipientCategories_update"
      defaultMessage="Global update data recipient categories"
    />
  ),
  global_dataRecipientCategories_delete: (
    <FormattedMessage
      id="EventCode.global_dataRecipientCategories_delete"
      defaultMessage="Global delete data recipient categories"
    />
  ),
  global_accountability_create: (
    <FormattedMessage
      id="EventCode.global_accountability_create"
      defaultMessage="Global create accountability"
    />
  ),
  global_accountability_update: (
    <FormattedMessage
      id="EventCode.global_accountability_update"
      defaultMessage="Global update accountability"
    />
  ),
  global_accountability_delete: (
    <FormattedMessage
      id="EventCode.global_accountability_delete"
      defaultMessage="Global delete accountability"
    />
  ),
  global_user_create: (
    <FormattedMessage
      id="EventCode.global_user_create"
      defaultMessage="Global create user"
    />
  ),
  global_user_update: (
    <FormattedMessage
      id="EventCode.global_user_update"
      defaultMessage="Global update user"
    />
  ),
  global_user_delete: (
    <FormattedMessage
      id="EventCode.global_user_delete"
      defaultMessage="Global delete user"
    />
  ),
  user_globalLogin: (
    <FormattedMessage
      id="EventCode.user_globalLogin"
      defaultMessage="Global user login"
    />
  ),
  global_organisation_create: (
    <FormattedMessage
      id="EventCode.global_organisation_create"
      defaultMessage="Global create organisation"
    />
  ),
  global_organisation_update: (
    <FormattedMessage
      id="EventCode.global_organisation_update"
      defaultMessage="Global update organisation"
    />
  ),
  global_organisation_delete: (
    <FormattedMessage
      id="EventCode.global_organisation_delete"
      defaultMessage="Global delete organisation"
    />
  ),
  global_organisation_import: (
    <FormattedMessage
      id="EventCode.global_organisation_import"
      defaultMessage="Global import organisation"
    />
  ),
  global_job_create: (
    <FormattedMessage
      id="EventCode.global_job_create"
      defaultMessage="Global create job"
    />
  ),
  fairnessOfDecision_create: (
    <FormattedMessage
      id="EventCode.fairnessOfDecision_create"
      defaultMessage="Create fairness of decision"
    />
  ),
  fairnessOfDecision_update: (
    <FormattedMessage
      id="EventCode.fairnessOfDecision_update"
      defaultMessage="Update fairness of decision"
    />
  ),
  fairnessOfDecision_delete: (
    <FormattedMessage
      id="EventCode.fairnessOfDecision_delete"
      defaultMessage="Delete fairness of decision"
    />
  ),
  fairnessOfDecision_csv_export: (
    <FormattedMessage
      id="EventCode.fairnessOfDecision_csv_export"
      defaultMessage="CSV export for fairness of decision"
    />
  ),
  fairnessOfDecision_bulk_delete: (
    <FormattedMessage
      id="EventCode.fairnessOfDecision_bulk_delete"
      defaultMessage="Bulk delete fairness of decisions"
    />
  ),
  tia_create: (
    <FormattedMessage id="EventCode.tia_create" defaultMessage="Create TIA" />
  ),
  tiaTemplate_create: (
    <FormattedMessage
      id="EventCode.tiaTemplate_create"
      defaultMessage="Create TIA template"
    />
  ),
  tia_update: (
    <FormattedMessage id="EventCode.tia_update" defaultMessage="Update TIA" />
  ),
  tia_delete: (
    <FormattedMessage id="EventCode.tia_delete" defaultMessage="Delete TIA" />
  ),
  tia_bulk_delete: (
    <FormattedMessage
      id="EventCode.tia_bulk_delete"
      defaultMessage="Bulk delete TIA"
    />
  ),
  tia_pdf_export: (
    <FormattedMessage
      id="EventCode.tia_pdf_export"
      defaultMessage="TIA pdf export"
    />
  ),
  tia_bulk_pdf_export: (
    <FormattedMessage
      id="EventCode.tia_bulk_pdf_export"
      defaultMessage="TIA bulk pdf export"
    />
  ),
  tia_promoteToProcessing: (
    <FormattedMessage
      id="EventCode.tia_promoteToProcessing"
      defaultMessage="Promote TIA to processing"
    />
  ),
  tia_promoteToAssessment: (
    <FormattedMessage
      id="EventCode.tia_promoteToAssessment"
      defaultMessage="Promote TIA to DPIA"
    />
  ),
  processing_promoteToTia: (
    <FormattedMessage
      id="EventCode.processing_promoteToTia"
      defaultMessage="Promote processing to TIA"
    />
  ),
  assessment_promoteToTia: (
    <FormattedMessage
      id="EventCode.assessment_promoteToTia"
      defaultMessage="Promote assessment to TIA"
    />
  ),
  documentType_create: (
    <FormattedMessage
      id="EventCode.documentType_create"
      defaultMessage="Create document type"
    />
  ),
  documentType_update: (
    <FormattedMessage
      id="EventCode.documentType_update"
      defaultMessage="Update document type"
    />
  ),
  documentType_delete: (
    <FormattedMessage
      id="EventCode.documentType_delete"
      defaultMessage="Delete document type"
    />
  ),
  documentType_bulk_delete: (
    <FormattedMessage
      id="EventCode.documentType_bulk_delete"
      defaultMessage="Bulk delete document type"
    />
  ),
  documentType_csv_export: (
    <FormattedMessage
      id="EventCode.documentType_csv_export"
      defaultMessage="Document type CSV export"
    />
  ),
  specialCharacteristics_create: (
    <FormattedMessage
      id="EventCode.specialCharacteristics_create"
      defaultMessage="Create special characteristics"
    />
  ),
  specialCharacteristics_update: (
    <FormattedMessage
      id="EventCode.specialCharacteristics_update"
      defaultMessage="Update special characteristics"
    />
  ),
  specialCharacteristics_delete: (
    <FormattedMessage
      id="EventCode.specialCharacteristics_delete"
      defaultMessage="Delete special characteristics"
    />
  ),
  specialCharacteristics_bulk_delete: (
    <FormattedMessage
      id="EventCode.specialCharacteristics_bulk_delete"
      defaultMessage="Bulk delete special characteristics"
    />
  ),
  specialCharacteristics_csv_export: (
    <FormattedMessage
      id="EventCode.specialCharacteristics_csv_export"
      defaultMessage="Special characteristics CSV export"
    />
  ),
  dataSubjectRequestForm_create: (
    <FormattedMessage
      id="EventCode.dataSubjectRequestForm_create"
      defaultMessage="Data subject request form creation"
    />
  ),
  dataSubjectRequestForm_update: (
    <FormattedMessage
      id="EventCode.dataSubjectRequestForm_update"
      defaultMessage="Data subject request form update"
    />
  ),
  dataSubjectRequestForm_delete: (
    <FormattedMessage
      id="EventCode.dataSubjectRequestForm_delete"
      defaultMessage="Data subject request form deletion"
    />
  ),
  custom_text_field_create: (
    <FormattedMessage
      id="EventCode.custom_text_field_create"
      defaultMessage="Custom text field creation"
    />
  ),
  custom_text_field_update: (
    <FormattedMessage
      id="EventCode.custom_text_field_update"
      defaultMessage="Custom text field update"
    />
  ),
  custom_text_field_delete: (
    <FormattedMessage
      id="EventCode.custom_text_field_delete"
      defaultMessage="Custom text field deletion"
    />
  ),
  custom_html_text_field_create: (
    <FormattedMessage
      id="EventCode.custom_html_text_field_create"
      defaultMessage="Custom rich text creation"
    />
  ),
  custom_html_text_field_update: (
    <FormattedMessage
      id="EventCode.custom_html_text_field_update"
      defaultMessage="Custom rich text update"
    />
  ),
  custom_html_text_field_delete: (
    <FormattedMessage
      id="EventCode.custom_html_text_field_delete"
      defaultMessage="Custom rich text deletion"
    />
  ),
  customLayout_create: (
    <FormattedMessage
      id="EventCode.customLayout_create"
      defaultMessage="Custom layout creation"
    />
  ),
  customLayout_update: (
    <FormattedMessage
      id="EventCode.customLayout_update"
      defaultMessage="Custom layout update"
    />
  ),
  customLayout_delete: (
    <FormattedMessage
      id="EventCode.customLayout_delete"
      defaultMessage="Custom layout deletion"
    />
  ),
  customSimpleMasterData_create: (
    <FormattedMessage
      id="EventCode.customSimpleMasterData_create"
      defaultMessage="Custom simple masterdata creation"
    />
  ),
  customSimpleMasterData_update: (
    <FormattedMessage
      id="EventCode.customSimpleMasterData_update"
      defaultMessage="Custom simple masterdata updation"
    />
  ),
  customSimpleMasterData_delete: (
    <FormattedMessage
      id="EventCode.customSimpleMasterData_delete"
      defaultMessage="Custom simple masterdata deletion"
    />
  ),
  customSimpleMasterData_bulk_delete: (
    <FormattedMessage
      id="EventCode.customSimpleMasterData_bulk_delete"
      defaultMessage="Custom simple masterdata bulk update"
    />
  ),
  customSimpleMasterData_csv_export: (
    <FormattedMessage
      id="EventCode.customSimpleMasterData_csv_export"
      defaultMessage="Custom simple masterdata csv export"
    />
  ),
  custom_simple_master_data_field_create: (
    <FormattedMessage
      id="EventCode.custom_simple_master_data_field_create"
      defaultMessage="Custom simple masterdata field creation"
    />
  ),
  custom_simple_master_data_field_update: (
    <FormattedMessage
      id="EventCode.custom_simple_master_data_field_update"
      defaultMessage="Custom simple masterdata field updation"
    />
  ),
  custom_simple_master_data_field_delete: (
    <FormattedMessage
      id="EventCode.custom_simple_master_data_field_delete"
      defaultMessage="Custom simple masterdata field deletion"
    />
  ),
  customRecordType_create: (
    <FormattedMessage
      id="EventCode.customRecordType_create"
      defaultMessage="CustomRecordType Create"
    />
  ),
  customRecordType_update: (
    <FormattedMessage
      id="EventCode.customRecordType_update"
      defaultMessage="Custom record type update"
    />
  ),
  customRecordType_delete: (
    <FormattedMessage
      id="EventCode.customRecordType_delete"
      defaultMessage="Custom record type delete"
    />
  ),
  custom_picklist_field_create: (
    <FormattedMessage
      id="EventCode.custom_picklist_field_create"
      defaultMessage="Custom picklist field create"
    />
  ),
  custom_picklist_field_update: (
    <FormattedMessage
      id="EventCode.custom_picklist_field_update"
      defaultMessage="Custom picklist field update"
    />
  ),
  custom_picklist_field_delete: (
    <FormattedMessage
      id="EventCode.custom_picklist_field_delete"
      defaultMessage="Custom picklist field delete"
    />
  ),
  custom_scored_picklist_field_create: (
    <FormattedMessage
      id="EventCode.custom_scored_picklist_field_create"
      defaultMessage="Custom scored picklist field create"
    />
  ),
  custom_scored_picklist_field_update: (
    <FormattedMessage
      id="EventCode.custom_scored_picklist_field_update"
      defaultMessage="Custom scored picklist field update"
    />
  ),
  custom_scored_picklist_field_delete: (
    <FormattedMessage
      id="EventCode.custom_scored_picklist_field_delete"
      defaultMessage="Custom scored picklist field delete"
    />
  ),
  customPrivacyRecord_create: (
    <FormattedMessage
      id="EventCode.customPrivacyRecord_create"
      defaultMessage="CustomPrivacyRecord Create"
    />
  ),
  customPrivacyRecord_update: (
    <FormattedMessage
      id="EventCode.customPrivacyRecord_update"
      defaultMessage="CustomPrivacyRecord Update"
    />
  ),
  customPrivacyRecord_delete: (
    <FormattedMessage
      id="EventCode.customPrivacyRecord_delete"
      defaultMessage="CustomPrivacyRecord Delete"
    />
  ),
  customOption_create: (
    <FormattedMessage
      id="EventCode.customOption_create"
      defaultMessage="Custom option create"
    />
  ),
  customOption_update: (
    <FormattedMessage
      id="EventCode.customOption_update"
      defaultMessage="Custom option update"
    />
  ),
  customOption_delete: (
    <FormattedMessage
      id="EventCode.customOption_delete"
      defaultMessage="Custom option delete"
    />
  ),
  customItemList_create: (
    <FormattedMessage
      id="EventCode.customItemList_create"
      defaultMessage="Custom item list create"
    />
  ),
  customItemList_update: (
    <FormattedMessage
      id="EventCode.customItemList_update"
      defaultMessage="Custom item list update"
    />
  ),
  customItemList_delete: (
    <FormattedMessage
      id="EventCode.customItemList_delete"
      defaultMessage="Custom item list delete"
    />
  ),
  promote_record: recordTranslations.promoteRecord,
  upgrade_record: recordTranslations.upgradeRecord,
  'dsrsetting-update': (
    <FormattedMessage
      id="EventCode.dsrsetting_update"
      defaultMessage="DSR Setting Update"
    />
  ),
  user_xlsx_export: (
    <FormattedMessage
      id="EventCode.user_xlsx_export"
      defaultMessage="User export"
    />
  ),
  breach_bulk_pdf_export: (
    <FormattedMessage
      id="EventCode.breach_bulk_pdf_export"
      defaultMessage="Bulk pdf export for breach records"
    />
  ),
  breach_pdf_export: (
    <FormattedMessage
      id="EventCode.breach_pdf_export"
      defaultMessage="pdf export for breach records"
    />
  ),
  custom_dashboard_layout_create: (
    <FormattedMessage
      id="EventCode.custom_dashboard_layout_create"
      defaultMessage="Custom dashboard layout create"
    />
  ),
  custom_dashboard_layout_update: (
    <FormattedMessage
      id="EventCode.custom_dashboard_layout_update"
      defaultMessage="Custom dashboard layout update"
    />
  ),
  custom_dashboard_layout_share: (
    <FormattedMessage
      id="EventCode.custom_dashboard_layout_share"
      defaultMessage="Custom dashboard layout share"
    />
  ),
  custom_dashboard_layout_delete: (
    <FormattedMessage
      id="EventCode.custom_dashboard_layout_delete"
      defaultMessage="Custom dashboard layout delete"
    />
  ),
  adminUpdate_create: (
    <FormattedMessage
      id="EventCode.adminUpdate_create"
      defaultMessage="Admin updates create event code"
    />
  ),
  global_customSimpleMasterData_create: (
    <FormattedMessage
      id="EventCode.global_customSimpleMasterData_create"
      defaultMessage="Global create custom master data"
    />
  ),
  global_customSimpleMasterData_update: (
    <FormattedMessage
      id="EventCode.global_customSimpleMasterData_update"
      defaultMessage="Global update custom master data"
    />
  ),
  global_customSimpleMasterData_delete: (
    <FormattedMessage
      id="EventCode.global_customSimpleMasterData_delete"
      defaultMessage="Global delete custom master data"
    />
  ),
  auditSetting_update: (
    <FormattedMessage
      id="EventCode.auditSetting_update"
      defaultMessage="Audit trail setting Update"
    />
  ),
  custom_stakeholder_field_create: (
    <FormattedMessage
      id="EventCode.custom_stakeholder_field_create"
      defaultMessage="Custom stakeholder field Create"
    />
  ),
  custom_stakeholder_field_update: (
    <FormattedMessage
      id="EventCode.custom_stakeholder_field_update"
      defaultMessage="Custom stakeholder field Update"
    />
  ),
  custom_stakeholder_field_delete: (
    <FormattedMessage
      id="EventCode.custom_stakeholder_field_delete"
      defaultMessage="Custom stakeholder field Delete"
    />
  ),
  processing_template_create: (
    <FormattedMessage
      id="EventCode.processing_template_create"
      defaultMessage="Create processing template"
    />
  ),
  processing_template_update: (
    <FormattedMessage
      id="EventCode.processing_template_update"
      defaultMessage="Update processing template"
    />
  ),
  processing_template_delete: (
    <FormattedMessage
      id="EventCode.processing_template_delete"
      defaultMessage="Delete processing template"
    />
  ),
  tia_template_create: (
    <FormattedMessage
      id="EventCode.tia_template_create"
      defaultMessage="Create TIA template"
    />
  ),
  tia_template_update: (
    <FormattedMessage
      id="EventCode.tia_template_update"
      defaultMessage="Update TIA template"
    />
  ),
  tia_template_delete: (
    <FormattedMessage
      id="EventCode.tia_template_delete"
      defaultMessage="Delete TIA template"
    />
  ),
  assessment_template_create: (
    <FormattedMessage
      id="EventCode.assessment_template_create"
      defaultMessage="Create assessment template"
    />
  ),
  assessment_template_update: (
    <FormattedMessage
      id="EventCode.assessment_template_update"
      defaultMessage="Update assessmet template"
    />
  ),
  assessment_template_delete: (
    <FormattedMessage
      id="EventCode.assessment_template_delete"
      defaultMessage="Delete assessment template"
    />
  ),
  breach_template_create: (
    <FormattedMessage
      id="EventCode.breach_template_create"
      defaultMessage="Create breach template"
    />
  ),
  breach_template_update: (
    <FormattedMessage
      id="EventCode.breach_template_update"
      defaultMessage="Update breach template"
    />
  ),
  breach_template_delete: (
    <FormattedMessage
      id="EventCode.breach_template_delete"
      defaultMessage="Delete breach template"
    />
  ),
  customPrivacyRecordTemplate_create: (
    <FormattedMessage
      id="EventCode.customPrivacyRecordTemplate_create"
      defaultMessage="Create custom record template"
    />
  ),
  customPrivacyRecordTemplate_update: (
    <FormattedMessage
      id="EventCode.customPrivacyRecordTemplate_update"
      defaultMessage="Update custom record template"
    />
  ),
  customPrivacyRecordTemplate_delete: (
    <FormattedMessage
      id="EventCode.customPrivacyRecordTemplate_delete"
      defaultMessage="Delete custom record template"
    />
  ),
  document_template_create: (
    <FormattedMessage
      id="EventCode.document_template_create"
      defaultMessage="Create document template"
    />
  ),
  document_template_update: (
    <FormattedMessage
      id="EventCode.document_template_update"
      defaultMessage="Update document template"
    />
  ),
  document_template_delete: (
    <FormattedMessage
      id="EventCode.document_template_delete"
      defaultMessage="Delete document template"
    />
  ),
  data_library_job_create: (
    <FormattedMessage
      id="EventCode.data_library_job_create"
      defaultMessage="Create data library job"
    />
  ),
  record_sync_disconnect: (
    <FormattedMessage
      id="EventCode.record_sync_disconnect"
      defaultMessage="Disconnect record sync"
    />
  ),
  template_sync_disconnect: (
    <FormattedMessage
      id="EventCode.template_sync_disconnect"
      defaultMessage="Disconnect template sync"
    />
  )
};

export const riskTranslations = (item) => {
  switch (item.key) {
    case 'privacyRecord.lgpdRisks.controllers':
    case 'privacyRecord.pdpaRisks.controllers':
    case 'privacyRecord.risks.controllers':
    case 'privacyRecord.ukgdprRisks.controllers':
    case 'privacyRecord.fdplRisks.controllers':
    case 'privacyRecord.pdpRisks.controllers': {
      return (
        <FormattedMessage
          id="privacyRecord.risks.controllers"
          description="Risks for controllers"
          defaultMessage="Add a controller"
        />
      );
    }
    case 'privacyRecord.pdpaRisks.compliances.pdpa':
    case 'privacyRecord.lgpdRisks.compliances.lgpd':
    case 'privacyRecord.pdpRisks.compliances.pdp':
    case 'privacyRecord.fdplRisks.compliances.fdpl':
    case 'privacyRecord.appiRisks.compliances.appi': {
      return (
        <FormattedMessage
          id="privacyRecord.appiRisks.complianceRequirements"
          defaultMessage="Not all compliance requirements have been completed and marked off"
        />
      );
    }
    case 'privacyRecord.appiRisks.controllers': {
      return (
        <FormattedMessage
          id="privacyRecord.appiRisks.controllers"
          defaultMessage="No 'Personal Information Handling Business' (Controller) has been selected"
        />
      );
    }
    case 'privacyRecord.appiRisks.personalDataCategories': {
      return (
        <FormattedMessage
          id="privacyRecord.appiRisks.personalDataCategories"
          defaultMessage="When performing a domestic transfer you must register personal data
        items transferred. There are none in this record"
        />
      );
    }
    case 'privacyRecord.appiRisks.personalDataItems': {
      return (
        <FormattedMessage
          id="privacyRecord.appiRisks.personalDataItems"
          defaultMessage="Special or crime related information  is being processed
        but “Special-care required” has been deselected "
        />
      );
    }
    case 'privacyRecord.fdplRisks.personalDataItems': {
      return (
        <FormattedMessage
          id="privacyRecord.fdplRisks.personalDataItems"
          defaultMessage="Special data is being processed but the Express in writing legal ground is not used"
        />
      );
    }
    case 'privacyRecord.appiRisks.appiInternationalTransferCountries': {
      return (
        <FormattedMessage
          id="privacyRecord.appiRisks.appiInternationalTransferCountries"
          defaultMessage="There are no selected countries where an international transfer is occurring"
        />
      );
    }
    case 'privacyRecord.appiRisks.appiInternationalTransferGrounds': {
      return (
        <FormattedMessage
          id="privacyRecord.appiRisks.appiInternationalTransferGrounds"
          defaultMessage="An International Transfer is occurring but does not have a corresponding transfer ground"
        />
      );
    }
    case 'privacyRecord.appiRisks.appiThirdPartyDataTransferGrounds': {
      return (
        <FormattedMessage
          id="privacyRecord.appiRisks.appiThirdPartyDataTransferGrounds"
          defaultMessage="A Data is received from third parties but does not have a corresponding transfer ground"
        />
      );
    }
    case 'privacyRecord.appiRisks.appiDomesticTransferGrounds': {
      return (
        <FormattedMessage
          id="privacyRecord.appiRisks.appiDomesticTransferGrounds"
          defaultMessage="A Domestic transfer is occurring but does not have a corresponding transfer ground"
        />
      );
    }
    case 'privacyRecord.fdplRisks.fdplInternationalTransferPurposes': {
      return (
        <FormattedMessage
          id="privacyRecord.fdplRisks.fdplInternationalTransferPurposes"
          defaultMessage="No purpose has been selected for a transfer"
        />
      );
    }
    case 'privacyRecord.lgpdRisks.lgpdProcessingGrounds':
    case 'privacyRecord.risks.processingGrounds':
    case 'privacyRecord.ukgdprRisks.ukgdprProcessingGrounds':
    case 'privacyRecord.piplRisks.piplProcessingGrounds':
    case 'privacyRecord.fdplRisks.fdplProcessingGrounds':
    case 'privacyRecord.sgpdpaRisks.sgpdpaProcessingGrounds':
    case 'privacyRecord.pdpRisks.pdpProcessingGrounds': {
      return (
        <FormattedMessage
          id="privacyRecord.risks.processingGrounds"
          description="Risks for processing grounds"
          defaultMessage="Add a processing ground"
        />
      );
    }
    case 'privacyRecord.lgpdRisks.lgpdInternationalTransferGrounds':
    case 'privacyRecord.risks.transferGrounds':
    case 'privacyRecord.ukgdprRisks.ukgdprInternationalTransferGrounds':
    case 'privacyRecord.sgpdpaRisks.sgpdpaTransferGrounds': {
      return (
        <FormattedMessage
          id="privacyRecord.risks.transferGrounds"
          description="Risks for transfer grounds"
          defaultMessage="Add a transfer ground"
        />
      );
    }
    case 'privacyRecord.ukgdprRisks.personalDataItems':
    case 'privacyRecord.risks.personalDataItems': {
      return (
        <FormattedMessage
          id="privacyRecord.risks.personalDataItems"
          description="Risks for personal data items"
          defaultMessage={
            'Add a processing ground for ' +
            'special personal data item {personalDataItemName}'
          }
          values={{ personalDataItemName: item.values[0] }}
        />
      );
    }
    case 'privacyRecord.risks.dataSources': {
      return (
        <FormattedMessage
          id="privacyRecord.risks.dataSources"
          description="Risks for data sources"
          defaultMessage={
            'Add a transfer ground as data source ' +
            '{dataSourceName} has data stored outside the EU'
          }
          values={{ dataSourceName: item.values[0] }}
        />
      );
    }
    case 'privacyRecord.ukgdprRisks.dataSources': {
      return (
        <FormattedMessage
          id="privacyRecord.ukgdprRisks.dataSources"
          defaultMessage={
            'Add a transfer ground as data source ' +
            '{dataSourceName} has data stored outside the UK'
          }
          values={{ dataSourceName: item.values[0] }}
        />
      );
    }
    case 'privacyRecord.risks.dataRecipientCategories': {
      return (
        <FormattedMessage
          id="privacyRecord.risks.dataRecipientCategories"
          description="Risks for data recipient categories"
          defaultMessage="Add a data recipient or a data recipient category"
        />
      );
    }
    case 'privacyRecord.ukgdprRisks.globalRisk1':
    case 'privacyRecord.risks.globalRisk1': {
      return (
        <FormattedMessage
          id="privacyRecord.risks.globalRisk1"
          description="Global risks 1"
          defaultMessage={
            'The following field or fields are obligatory' +
            ' under art. 30 GDPR: {values}'
          }
          values={{ values: item.values }}
        />
      );
    }
    case 'privacyRecord.ukgdprRisks.globalRisk2':
    case 'privacyRecord.risks.globalRisk2': {
      return (
        <FormattedMessage
          id="privacyRecord.risks.globalRisk2"
          description="Global risks 2"
          defaultMessage={
            'Where applicable, the following field or fields' +
            'are obligatory under art. 30 GDPR: {values}'
          }
          values={{ values: item.values }}
        />
      );
    }
    case 'privacyRecord.ukgdprRisks.globalRisk3':
    case 'privacyRecord.risks.globalRisk3': {
      return (
        <FormattedMessage
          id="privacyRecord.risks.globalRisk3"
          description="Global risks 3"
          defaultMessage={
            'Where possible, the following field or fields' +
            'should be filled in under art. 30 GDPR: {values}'
          }
          values={{ values: item.values }}
        />
      );
    }
    case 'privacyRecord.lgpdRisks.purposes':
    case 'privacyRecord.pdpaRisks.purposes':
    case 'privacyRecord.ccpaRisks.purposes':
    case 'privacyRecord.pdpRisks.purposes':
    case 'privacyRecord.fdplRisks.purposes':
    case 'privacyRecord.ukgdprRisks.purposes':
    case 'privacyRecord.risks.purposes':
    case 'privacyRecord.piplRisks.purposes':
    case 'privacyRecord.sgpdpaRisks.purposes': {
      return (
        <FormattedMessage
          id="privacyRecord.risks.purposes"
          defaultMessage="Record contains no purposes"
        />
      );
    }
    case 'privacyRecord.ccpaRisks.processors': {
      return (
        <FormattedMessage
          id="privacyRecord.ccpaRisks.processor"
          defaultMessage="Record contains no Processor/Third Party categories"
        />
      );
    }
    case 'privacyRecord.ccpaRisks.personalDataItems': {
      return (
        <FormattedMessage
          id="privacyRecord.ccpaRisks.personalDataItems"
          defaultMessage="Record contains no Personal Data Items "
        />
      );
    }
    case 'privacyRecord.ccpaRisks.optOutWebsiteLink': {
      return (
        <FormattedMessage
          id="privacyRecord.ccpaRisks.optOutWebsiteLink"
          defaultMessage="Record does not indicate a clear opt out link on the website"
        />
      );
    }
    case 'privacyRecord.ccpaRisks.optOutUserRightsLink': {
      return (
        <FormattedMessage
          id="privacyRecord.ccpaRisks.optOutUserRightsLink"
          defaultMessage="Record does not indicate link for consumer rights on the website "
        />
      );
    }
    case 'privacyRecord.ccpaRisks.dataSourceCategories': {
      return (
        <FormattedMessage
          id="privacyRecord.ccpaRisks.dataSourceCategories"
          defaultMessage="Record contains no Data source categories "
        />
      );
    }
    case 'privacyRecord.ccpaRisks.dsrMethods.phoneNumber': {
      return (
        <FormattedMessage
          id="privacyRecord.ccpaRisks.dsrMethods.phoneNumber"
          defaultMessage="Record does not specify a toll free number for submitting requests "
        />
      );
    }
    case 'privacyRecord.ccpaRisks.dsrMethods.link': {
      return (
        <FormattedMessage
          id="privacyRecord.ccpaRisks.dsrMethods.link"
          defaultMessage="Record does not specify a website address for submitting requests "
        />
      );
    }
    case 'privacyRecord.ccpaRisks.underSixteenOptInMeasures': {
      return (
        <FormattedMessage
          id="privacyRecord.ccpaRisks.underSixteenOptInMeasures"
          defaultMessage="No opt in specified for data subjects under 16 "
        />
      );
    }
    case 'privacyRecord.piplRisks.dataSubjectNotificationMethod': {
      return (
        <FormattedMessage
          id="privacyRecord.piplRisks.dataSubjectNotificationMethod"
          defaultMessage="No method selected for notifying data subjects"
        />
      );
    }
    case 'privacyRecord.piplRisks.automatedDecisionMaking': {
      return (
        <FormattedMessage
          id="privacyRecord.piplRisks.automatedDecisionMaking"
          defaultMessage="Automated decision making must have both a method for a data subject opt out and objection"
        />
      );
    }
    case 'privacyRecord.piplRisks.dataSubjectAuthorityMethod': {
      return (
        <FormattedMessage
          id="privacyRecord.piplRisks.dataSubjectAuthorityMethod"
          defaultMessage="No method selected for data subjects right for controller data transfer"
        />
      );
    }
    case 'privacyRecord.piplRisks.piplDisclosurePurposes': {
      return (
        <FormattedMessage
          id="privacyRecord.piplRisks.piplDisclosurePurposes"
          defaultMessage="A specific purpose for public disclosure is missing"
        />
      );
    }
    case 'privacyRecord.piplRisks.piplDisclosedPersonalDataItems': {
      return (
        <FormattedMessage
          id="privacyRecord.piplRisks.piplDisclosedPersonalDataItems"
          defaultMessage="No personal data items are selected for public disclosure"
        />
      );
    }
    case 'privacyRecord.piplRisks.piplInternationalTransferGrounds': {
      return (
        <FormattedMessage
          id="privacyRecord.piplRisks.piplInternationalTransferGrounds"
          defaultMessage="An appropriate transfer ground is not selected"
        />
      );
    }
    case 'privacyRecord.piplRisks.piplSpecialHandlingRules': {
      return (
        <FormattedMessage
          id="privacyRecord.piplRisks.piplSpecialHandlingRules"
          defaultMessage="No additional security measures are added for sensitive data"
        />
      );
    }
    case 'privacyRecord.piplRisks.isSensitiveDataUsed': {
      return (
        <FormattedMessage
          id="privacyRecord.piplRisks.isSensitiveDataUsed"
          defaultMessage="Special or crime related information is being processed but “Sensitive data” has been deselected"
        />
      );
    }
    case 'privacyRecord.pdpaRisks.personalDataCategories':
    case 'privacyRecord.pdpRisks.personalDataCategories':
    case 'privacyRecord.ccpaRisks.personalDataCategories': {
      return (
        <FormattedMessage
          id="privacyRecord.pdpaRisks.personalDataCategories"
          defaultMessage="Records doesn't have any personal data registered "
        />
      );
    }
    case 'privacyRecord.lgpdRisks.processors':
    case 'privacyRecord.pdpaRisks.processors':
    case 'privacyRecord.pdpRisks.processors': {
      return (
        <FormattedMessage
          id="privacyRecord.pdpaRisks.processors"
          defaultMessage="Record contains no Processor"
        />
      );
    }
    case 'privacyRecord.pdpaRisks.pdpaSpecialProcessingGrounds': {
      return (
        <FormattedMessage
          id="privacyRecord.pdpaRisks.pdpaSpecialProcessingGrounds"
          defaultMessage="No special processing ground has been selected"
        />
      );
    }
    case 'privacyRecord.lgpdRisks.personalDataCategories': {
      return (
        <FormattedMessage
          id="privacyRecord.lgpdRisks.personalDataCategories"
          defaultMessage="No personal data is registered"
        />
      );
    }
    case 'privacyRecord.lgpdRisks.dpo': {
      return (
        <FormattedMessage
          id="privacyRecord.lgpdRisks.dpo"
          defaultMessage="No Data Protection Officer is registered"
        />
      );
    }
    case 'privacyRecord.lgpdRisks.lgpdSpecialProcessingGrounds': {
      return (
        <FormattedMessage
          id="privacyRecord.lgpdRisks.lgpdSpecialProcessingGrounds"
          defaultMessage="No Special processing ground is selected"
        />
      );
    }
    case 'privacyRecord.pdpRisks.securityInfoAttachments':
    case 'privacyRecord.pdpRisks.linkedDocumentRecords': {
      return (
        <FormattedMessage
          id="privacyRecord.pdpRisks.securityInfoAttachments"
          defaultMessage="You have not uploaded any documentation for this processing activity"
        />
      );
    }
    case 'privacyRecord.pdpRisks.privacyAgreementAttachments': {
      return (
        <FormattedMessage
          id="privacyRecord.pdpRisks.privacyAgreementAttachments"
          defaultMessage="No Privacy Agreement has been attached to the document"
        />
      );
    }
    case 'privacyRecord.sgpdpaRisks.technicalSecurityMeasures': {
      return (
        <FormattedMessage
          id="privacyRecord.sgpdpaRisks.technicalSecurityMeasures"
          defaultMessage="Security measures are missing: Reasonable protection and security of personal data is not demonstrated"
        />
      );
    }
    case 'privacyRecord.sgpdpaRisks.retentionTerms': {
      return (
        <FormattedMessage
          id="privacyRecord.sgpdpaRisks.retentionTerms"
          defaultMessage="Retention period is missing"
        />
      );
    }
    default:
      return item;
  }
};

export const documentInfected = (
  <FormattedMessage
    id="Document.Upload.Infected"
    description="Uploaded document is infected"
    defaultMessage="Cannot upload. Document seems to be infected."
  />
);

export const eventTypeTranslations = {
  Registry: commonTranslations.registry,
  VendorForm: vendorTranslations.vendorForms,
  SecurityMeasure: uppercaseSingular('securityMeasure'),
  assessment: typeTranslations.assessments,
  processing: typeTranslations.processings,
  tia: uppercasePlural('tias'),
  document: typeTranslations.document,
  DataItemGroup: recordTranslations.dataItemGroups,
  breach: typeTranslations.breaches,
  DataSource: recordTranslations.dataSource,
  DataSourceCategory: uppercaseSingular('dataSourceCategory'),
  DataSubjectCategory: uppercaseSingular('dataSubjectCategory'),
  PersonalDataItem: recordTranslations.personalDataItem,
  PersonalDataCategory: uppercaseSingular('personalDataCategory'),
  ProcessingCategory: uppercaseSingular('processingCategory'),
  template: recordTranslations.template,
  User: uppercasePlural('users'),
  RetentionTermOffset: uppercaseSingular('retentionTermOffset'),
  SecurityMeasuresDescription: uppercaseSingular('securityMeasureDescription'),
  DataRecipientCategory: uppercaseSingular('dataRecipientCategory'),
  Reference: recordTranslations.reference,
  CustomSimpleMasterData: uppercaseSingular('customSimpleMasterData'),
  tiaTemplate: (
    <FormattedMessage id="records.tiaTemplate" defaultMessage="Tia templates" />
  ),
  sso: (
    <FormattedMessage
      id="SSO.defaultOrganisation"
      description="Organisation for SSO users"
      defaultMessage="Organisation for SSO users"
    />
  ),
  ProcessingUpdate: (
    <FormattedMessage
      id="Job.ProcessingUpdate"
      defaultMessage="Bulk update of processing records"
    />
  ),
  Right: (
    <FormattedMessage
      id="Right.right"
      description="Right"
      defaultMessage="Right"
    />
  ),
  Threat: (
    <FormattedMessage
      id="Threat"
      description="Threat"
      defaultMessage="Threat"
    />
  ),
  Impact: (
    <FormattedMessage
      id="Impact.impact"
      description="Impact"
      defaultMessage="Impact"
    />
  ),
  DataSubjectRight: (
    <FormattedMessage
      id="RecordDetails.DataSubjectRight"
      description="Data subject right"
      defaultMessage="Data subject right"
    />
  ),
  QualityControl: (
    <FormattedMessage
      id="Record.qualityControls"
      defaultMessage="Elements of accuracy"
    />
  ),
  Purpose: (
    <FormattedMessage
      id="Record.purpose"
      description="Purpose"
      defaultMessage="Purpose"
    />
  ),
  MitigatingMeasure: (
    <FormattedMessage
      id="DPIA.mitigation"
      description="Mitigating measure"
      defaultMessage="Mitigating measure"
    />
  ),
  Policy: (
    <FormattedMessage
      id="Record.policy"
      description="Policy"
      defaultMessage="Policy"
    />
  ),
  Accountability: (
    <FormattedMessage
      id="Assessments.accountability"
      description="Accountability for assessment"
      defaultMessage="Accountability"
    />
  ),
  RiskDetail: (
    <FormattedMessage
      id="Assessments.riskDetail"
      description="Risk detail"
      defaultMessage="Risk detail"
    />
  ),
  DataSubject: (
    <FormattedMessage
      id="DSR.DataSubject"
      description="Data subject"
      defaultMessage="Data subject"
    />
  ),
  DataSubjectRequest: (
    <FormattedMessage
      id="DSR.DataSubjectRequest"
      description="Data subject request"
      defaultMessage="Data subject request"
    />
  ),
  DataSubjectRequestForm: commonTranslations.dataSubjectRequestForm,
  DocumentRecordType: recordTranslations.documentType,
  SpecialCharacteristics: recordTranslations.specialCharacteristics,
  custom: recordTypeTranslations.custom,
  'custom-record-layout': recordTranslations.recordLayout
};

export const eventTypeTranslationsMapper = (entityType) =>
  eventTypeTranslations[entityType] || entityType;

export const jobsStatusTranslation = (status) => {
  switch (status) {
    case 'Submitted':
      return statusTranslations.submitted;
    case 'In Progress':
      return statusTranslations.in_progress;
    case 'Completed':
      return statusTranslations.completed;
    case 'Failed':
      return statusTranslations.failed;
    case 'Terminated':
      return statusTranslations.terminated;
    case 'Completed with Errors':
      return statusTranslations.completedWithErrors;
    default:
      return status;
  }
};

export const operationTypeMapper = (operationType) => {
  switch (operationType) {
    case 'Edit':
      return recordTranslations.edit;
    case 'Delete':
      return recordTranslations.delete;
    case 'Merge':
      return recordTranslations.merge;
    case 'Bulk_Update':
      return uppercaseSingular('add');
    case 'Bulk_Remove':
      return removeTranslations('remove');
    case 'Bulk_Replace':
      return uppercaseSingular('replace');
    case 'Bulk_Clear_Fields':
      return uppercaseSingular('emptyFields');
    default:
      return operationType;
  }
};

export const jobsOperationTypes = [
  { label: recordTranslations.edit, value: 'Edit' },
  { label: recordTranslations.delete, value: 'Delete' },
  { label: recordTranslations.merge, value: 'Merge' },
  { label: uppercaseSingular('add'), value: 'Bulk_Update' },
  { label: removeTranslations('remove'), value: 'Bulk_Remove' },
  { label: uppercaseSingular('replace'), value: 'Bulk_Replace' },
  { label: uppercaseSingular('emptyFields'), value: 'Bulk_Clear_Fields' }
];

export const editTypeTranslations = (dataItemType) => {
  switch (dataItemType) {
    case 'dataSubjectCategories':
      return (
        <FormattedMessage
          id="DataSubjectCategoryDialog.editheader"
          description="Edit data subject category Context Menu"
          defaultMessage="Edit data subject category"
        />
      );
    case 'controllers':
      return (
        <FormattedMessage
          id="Records.editController"
          description="Edit controller context menu"
          defaultMessage="Edit controller"
        />
      );
    case 'organisation':
      return (
        <FormattedMessage
          id="OrgList.editOrganisation"
          defaultMessage="Edit organisation"
        />
      );
    case 'processors':
      return (
        <FormattedMessage
          id="Records.editProcessor"
          description="Edit processor context menu"
          defaultMessage="Edit processor"
        />
      );
    case 'executingEntities':
      return (
        <FormattedMessage
          id="Records.editExecutingEntity"
          description="Edit executing entity context menu"
          defaultMessage="Edit executing entity"
        />
      );
    case 'dataRecipients':
      return (
        <FormattedMessage
          id="Records.editDataRecipient"
          description="Edit data recipient context menu"
          defaultMessage="Edit data recipient"
        />
      );
    case 'processingCategories':
      return (
        <FormattedMessage
          id="ProcessingCategoryDialog.editProcessingCategory"
          description="Edit Processing Category"
          defaultMessage="Edit processing category"
        />
      );
    case 'references':
      return (
        <FormattedMessage
          id="ReferenceDialog.editreference"
          description="Edit reference"
          defaultMessage="Edit reference"
        />
      );
    case 'personalDataCategories':
      return (
        <FormattedMessage
          id="PersonalItemCategoryDialog.editheader"
          description="Edit personal data category context menu"
          defaultMessage="Edit personal data category"
        />
      );
    case 'personalDataItems':
      return (
        <FormattedMessage
          id="PersonalDataItemDialog.editheader"
          description="Personal Data Item Dialog Header"
          defaultMessage="Edit personal data item"
        />
      );
    case 'retentionTerms':
      return (
        <FormattedMessage
          id="Records.editRetentionTerm"
          description="Retention terms edit label"
          defaultMessage="Edit retention term"
        />
      );
    case 'dataSources':
      return (
        <FormattedMessage
          id="AddDataSourceDialog.editheader"
          description="Edit data source"
          defaultMessage="Edit data source"
        />
      );
    case 'securityMeasuresDescription':
      return (
        <FormattedMessage
          id="SecurityMeasureDialog.editsecurityMeasure"
          description="Edit security measure"
          defaultMessage="Edit security measure description"
        />
      );
    case 'fdplInternationalTransferPurposes':
    case 'ukgdprInternationalTransferPurposes':
    case 'appiInternationalTransferPurposes':
      return (
        <FormattedMessage
          id="SecurityMeasureDialog.editTransferPurpose"
          description="Edit transfer purpose"
          defaultMessage="Edit transfer purpose"
        />
      );
    case 'privacyAgreementAttachments':
    case 'securityInfoAttachments':
    case 'attachments':
      return (
        <FormattedMessage
          id="Document.editheader"
          description="Edit document"
          defaultMessage="Edit document"
        />
      );
    default:
      return editMasterTypeTranslations(dataItemType);
  }
};

export const removeTypeTranslations = (dataItemType) => {
  switch (dataItemType) {
    case 'documentRecordTypes':
      return (
        <FormattedMessage
          id="Records.removeDocumentType"
          defaultMessage="Remove document type"
        />
      );
    case 'specialCharacteristics':
      return (
        <FormattedMessage
          id="Records.removeSpecialCharacteristics"
          defaultMessage="Remove special characteristics"
        />
      );
    case 'processings':
    case 'linkedProcessings':
      return (
        <FormattedMessage
          id="Records.removeProcessing"
          description="Processing Remove label"
          defaultMessage="Remove processing"
        />
      );
    case 'assessments':
    case 'linkedAssessments':
      return (
        <FormattedMessage
          id="Records.removeAssessment"
          description="Assessment Remove label"
          defaultMessage="Remove assessment"
        />
      );
    case 'breaches':
    case 'linkedBreaches':
      return (
        <FormattedMessage
          id="Records.removeBreach"
          description="Breach Remove label"
          defaultMessage="Remove breach"
        />
      );
    case 'dataSubjectCategories':
      return (
        <FormattedMessage
          id="Records.removeDataSubjectCategory"
          description="Remove data subject category Context Menu"
          defaultMessage="Remove data subject category"
        />
      );
    case 'processingCategories':
      return (
        <FormattedMessage
          id="Records.removeProcessingCategory"
          description="Processing Category Remove label"
          defaultMessage="Remove processing category"
        />
      );
    case 'references':
      return (
        <FormattedMessage
          id="Records.removeReference"
          description="Reference Remove label"
          defaultMessage="Remove reference"
        />
      );
    case 'personalDataCategories':
      return (
        <FormattedMessage
          id="Records.removePersonalDataCategory"
          description="Personal Data Categories Remove label"
          defaultMessage="Remove personal data category"
        />
      );
    case 'personalDataItems':
      return (
        <FormattedMessage
          id="Records.removePersonalDataItem"
          description="Personal Data Item Remove label"
          defaultMessage="Remove personal data item"
        />
      );
    case 'retentionTerms':
      return (
        <FormattedMessage
          id="Records.removeRetentionTerm"
          description="Retention term Item Remove label"
          defaultMessage="Remove retention term"
        />
      );
    case 'dataSources':
      return (
        <FormattedMessage
          id="Records.removeDataSource"
          description="Remove data source"
          defaultMessage="Remove data source"
        />
      );
    case 'dataSourceCategories':
      return (
        <FormattedMessage
          id="Records.removeDataSourceCategory"
          defaultMessage="Remove data source category"
        />
      );
    case 'securityMeasuresDescription':
      return (
        <FormattedMessage
          id="Records.removeSecurityMeasure"
          description="Remove security measure"
          defaultMessage="Remove security measure description"
        />
      );
    case 'organisation':
    case 'aclOrgs':
      return (
        <FormattedMessage
          id="Records.removeOrganisation"
          description="Remove organisation"
          defaultMessage="Remove organisation"
        />
      );
    case 'appiDomesticTransferGrounds':
    case 'appiInternationalTransferGrounds':
    case 'appiThirdPartyDataTransferGrounds':
    case 'lgpdInternationalTransferGrounds':
    case 'fdplInternationalTransferGrounds':
    case 'piplInternationalTransferGrounds':
    case 'ukgdprInternationalTransferGrounds':
    case 'sgpdpaTransferGrounds':
    case 'transferGrounds':
      return (
        <FormattedMessage
          id="Records.removeTransferGround"
          description="Remove transfer ground"
          defaultMessage="Remove transfer ground"
        />
      );
    case 'fdplInternationalTransferPurposes':
    case 'ukgdprInternationalTransferPurposes':
    case 'appiInternationalTransferPurposes':
      return (
        <FormattedMessage
          id="Records.removeTransferPurpose"
          description="Remove transfer purpose"
          defaultMessage="Remove transfer purpose"
        />
      );
    case 'lgpdProcessingGrounds':
    case 'pdpProcessingGrounds':
    case 'pdpaProcessingGrounds':
    case 'fdplProcessingGrounds':
    case 'piplProcessingGrounds':
    case 'sgpdpaProcessingGrounds':
    case 'ukgdprProcessingGrounds':
    case 'processingGrounds':
      return (
        <FormattedMessage
          id="Records.removeProcessingGround"
          description="Remove processing ground"
          defaultMessage="Remove processing ground"
        />
      );
    case 'lgpdSpecialProcessingGrounds':
    case 'pdpaSpecialProcessingGrounds':
      return (
        <FormattedMessage
          id="Records.removeSpecialProcessingGround"
          defaultMessage="Remove special processing ground"
        />
      );
    case 'breachGrounds':
      return (
        <FormattedMessage
          id="Records.removeBreachGround"
          description="Remove breach ground"
          defaultMessage="Remove breach ground"
        />
      );
    case 'acl':
    case 'aclUsers':
      return (
        <FormattedMessage
          id="Records.removeUser"
          description="Remove user label"
          defaultMessage="Remove user"
        />
      );
    case 'supervisoryAuthorities':
      return (
        <FormattedMessage
          id="Records.removeSupervisoryAuthority"
          description="Remove supervisory authority label"
          defaultMessage="Remove supervisory authority"
        />
      );
    case 'controllers':
      return (
        <FormattedMessage
          id="Records.removeController"
          description="Remove controller context menu"
          defaultMessage="Remove controller"
        />
      );
    case 'processors':
      return (
        <FormattedMessage
          id="Records.removeProcessor"
          description="Remove processor context menu"
          defaultMessage="Remove processor"
        />
      );
    case 'executingEntities':
      return (
        <FormattedMessage
          id="Records.removeExecutingEntity"
          description="Remove executing entity context menu"
          defaultMessage="Remove executing entity"
        />
      );
    case 'dataRecipients':
      return (
        <FormattedMessage
          id="Records.removeDataRecipient"
          description="Remove data recipient context menu"
          defaultMessage="Remove data recipient"
        />
      );
    case 'privacyAgreementAttachments':
    case 'securityInfoAttachments':
    case 'attachments':
      return (
        <FormattedMessage
          id="Records.removeAttachment"
          description="Remove attachment context menu"
          defaultMessage="Remove attachment"
        />
      );
    case 'dataProtection':
      return (
        <FormattedMessage
          id="Records.removedataProtection"
          description="Remove dpo context menu"
          defaultMessage="Remove data protection officer"
        />
      );
    case 'requestTypes':
      return (
        <FormattedMessage
          id="DSR.removeRequestTypes"
          defaultMessage="Remove request type"
        />
      );
    case 'assignedUsers':
      return (
        <FormattedMessage
          id="DSR.removeAssignedUser"
          defaultMessage="Remove assigned user"
        />
      );
    case 'approvers':
      return (
        <FormattedMessage
          id="DSR.removeApprover"
          defaultMessage="Remove approver"
        />
      );
    default:
      return removeMasterTypeTranslations(dataItemType);
  }
};

export const jobDetailPropertyTranslations = {
  administrator: rolesTranslation.administrator,
  dataStorageCountry: recordTranslations.dataStorageCountry,
  name: commonTranslations.name,
  organisation: recordTranslations.hostedBy,
  categories: recordTranslations.personalDataCategories,
  categoryType: recordTranslations.type,
  dataSubjectCategories: uppercasePlural('dataSubjectCategories'),
  personalDataCategories: uppercasePlural('personalDataCategories'),
  personalDataItems: uppercasePlural('personalDataItems'),
  dataSources: uppercasePlural('dataSources'),
  dataSourceCategories: uppercasePlural('dataSourceCategories'),
  retentionTerms: uppercasePlural('retentionTerms'),
  purposes: uppercasePlural('purposes'),
  purposesOfTransfer: uppercasePlural('purposesOfTransfer'),
  technicalSecurityMeasures: uppercasePlural('technicalSecurityMeasures'),
  organisationalSecurityMeasures: uppercasePlural(
    'organisationalSecurityMeasures'
  ),
  tags: uppercasePlural('tags'),
  processingCategories: recordTranslations.processingCategories,
  status: recordTranslations.processingStatus,
  references: recordTranslations.references,
  controllers: recordTranslations.controllers,
  processors: recordTranslations.processors,
  executingEntities: recordTranslations.executingEntities,
  dataRecipients: recordTranslations.dataRecipients,
  dataRecipientCategories: recordTranslations.dataRecipientCategories,
  processingGrounds: recordTranslations.processingGrounds,
  transferGrounds: recordTranslations.transferGrounds,
  attachments: recordTranslations.attachments,
  userRights: commonTranslations.users,
  organisationRights: recordTranslations.organisationRights,
  companyAccess: recordTranslations.companyAccess,
  links: recordTranslations.dataItemGroups,
  internationalTransfer: recordTranslations.internationalTransfer,
  jurisdictions: recordTranslations.jurisdictions,
  contractualSecurityMeasures:
    sectionHeaderTranslations.contractualSecurityMeasures
};

export const jobDetailPropertyTranslationsMapper = (property) =>
  jobDetailPropertyTranslations[property] || property;

export const jobEventTypes = [
  {
    label: uppercaseSingular('dataSubjectCategory'),
    value: 'DataSubjectCategory'
  },
  {
    label: recordTranslations.personalDataItem,
    value: 'PersonalDataItem'
  },
  {
    label: uppercaseSingular('personalDataCategory'),
    value: 'PersonalDataCategory'
  },
  {
    label: uppercaseSingular('processingCategory'),
    value: 'ProcessingCategory'
  },
  {
    label: recordTranslations.dataSource,
    value: 'DataSource'
  },
  {
    label: uppercaseSingular('retentionTermOffset'),
    value: 'RetentionTermOffset'
  },
  {
    label: uppercaseSingular('references'),
    value: 'Reference'
  },
  {
    label: uppercaseSingular('dataRecipientCategory'),
    value: 'DataRecipientCategory'
  },
  {
    label: recordTranslations.accountability,
    value: 'Accountability'
  },
  {
    label: eventTypeTranslations.Threat,
    value: 'Threat'
  },
  {
    label: eventTypeTranslations.Impact,
    value: 'Impact'
  },
  {
    label: eventTypeTranslations.MitigatingMeasure,
    value: 'MitigatingMeasure'
  },
  {
    label: eventTypeTranslations.Policy,
    value: 'Policy'
  },
  {
    label: uppercaseSingular('purpose'),
    value: 'Purposes'
  },
  {
    label: uppercaseSingular('securityMeasure'),
    value: 'SecurityMeasure'
  },
  {
    label: eventTypeTranslations.RiskDetail,
    value: 'RiskDetail'
  },
  {
    label: uppercaseSingular('dataSourceCategory'),
    value: 'DataSourceCategory'
  },
  {
    label: uppercaseSingular('qualityControl'),
    value: 'QualityControl'
  },
  {
    label: eventTypeTranslations.DataSubjectRight,
    value: 'DataSubjectRight'
  },
  {
    label: uppercaseSingular('document'),
    value: 'Document'
  },
  {
    label: eventTypeTranslations.DataItemGroup,
    value: 'DataItemGroup'
  },
  {
    label: uppercaseSingular('documentType'),
    value: 'DocumentRecordType'
  },
  {
    label: sectionHeaderTranslations.specialCharacteristics,
    value: 'SpecialCharacteristics'
  },
  {
    label: uppercaseSingular('customSimpleMasterData'),
    value: 'CustomSimpleMasterData'
  },
  {
    label: recordTranslations.bulkUpdate,
    value: 'ProcessingUpdate'
  }
];

export const dataTypeTranslations = {
  DataSource: recordTranslations.dataSource,
  DataSubjectCategory: recordTranslations.dataSubjectCategory,
  PersonalDataItem: uppercaseSingular('personalDataItem'),
  PersonalDataCategory: (
    <FormattedMessage
      id="PersonalDataItems.personalItemCategory"
      description="Personal item category"
      defaultMessage="personal item category"
    />
  ),
  ProcessingCategory: uppercaseSingular('processingCategory'),
  RetentionTermOffset: (
    <FormattedMessage
      id="Record.retentionTermOffset"
      description="retention term offset"
      defaultMessage="retention term offset"
    />
  ),
  SecurityMeasure: uppercaseSingular('securityMeasure'),
  Reference: uppercaseSingular('references')
};

export const messageTypeMapper = (type) => {
  switch (type) {
    case 'notification_record_right':
      return (
        <FormattedMessage
          id="Notification.record_right"
          description="Record right"
          defaultMessage="Record right"
        />
      );
    case 'notification_task_update':
      return (
        <FormattedMessage
          id="Notification.task_update"
          description="Task update"
          defaultMessage="Task update"
        />
      );
    case 'notification_status_change':
      return (
        <FormattedMessage
          id="Notification.status_change"
          description="Status change"
          defaultMessage="Status change"
        />
      );
    case 'notification_dsr_access_user':
      return (
        <FormattedMessage
          id="Notification.notification_dsr_access_user"
          defaultMessage="DSR access right"
        />
      );
    case 'notification_ds_retention_period':
      return (
        <FormattedMessage
          id="Notification.notification_ds_retention_period"
          defaultMessage="DS retention period expiry"
        />
      );
    case 'notification_dsr_deadline_expiry':
      return (
        <FormattedMessage
          id="Notification.notification_dsr_deadline_expiry"
          defaultMessage="DSR deadline expiry"
        />
      );
    case 'notification_dsr_user_mention':
      return (
        <FormattedMessage
          id="Notification.notification_dsr_user_mention"
          defaultMessage="DSR user mention"
        />
      );
    case 'notification_dsr_user_assigned':
      return (
        <FormattedMessage
          id="Notification.notification_dsr_user_assigned"
          defaultMessage="DSR user assigned"
        />
      );
    case 'notification_dsr_approver_assigned':
      return (
        <FormattedMessage
          id="Notification.notification_dsr_approver_assigned"
          defaultMessage="DSR approver assigned"
        />
      );
    case 'notification_dsr_waiting_on_approval':
      return (
        <FormattedMessage
          id="Notification.notification_dsr_waiting_on_approval"
          defaultMessage="DSR awaiting approval"
        />
      );
    case 'notification_dsr_task_completed':
      return (
        <FormattedMessage
          id="Notification.notification_dsr_task_completed"
          defaultMessage="DSR tasks completed"
        />
      );
    case 'notification_dsr_task_deleted':
      return (
        <FormattedMessage
          id="Notification.notification_dsr_task_deleted"
          defaultMessage="DSR task deleted"
        />
      );
    case 'notification_dsr_ignore_approval_notification':
      return (
        <FormattedMessage
          id="Notification.notification_dsr_ignore_approval_notification"
          defaultMessage="DSR ignore approval notification"
        />
      );
    case 'notification_dsr_cancelled':
      return (
        <FormattedMessage
          id="Notification.notification_dsr_cancelled"
          defaultMessage="DSR cancelled"
        />
      );
    case 'notification_record_user_mention':
      return (
        <FormattedMessage
          id="Notification.notification_record_user_mention"
          defaultMessage="Record user mention"
        />
      );
    case 'notification_vendor_form_message':
      return (
        <FormattedMessage
          id="Notification.notification_vendor_form_message"
          defaultMessage="Vendor form message"
        />
      );
    default:
      return type;
  }
};

export const customTranslation = (id, type) => {
  switch (id) {
    case 'import':
      return (
        <FormattedMessage
          id="vendor.import"
          defaultMessage="Import {value}"
          values={{ value: type }}
        />
      );
    case 'export':
      return (
        <FormattedMessage
          id="vendor.export"
          defaultMessage="Export {value}"
          values={{ value: type }}
        />
      );
    case 'deleteTemplate':
      return (
        <FormattedMessage
          id="vendorTemplate.delete"
          defaultMessage="Do you want to delete the template {templateName}?"
          values={{ templateName: `'${type}'` }}
        />
      );
    case 'removeOrganisation':
      return (
        <FormattedMessage
          id="Organisation.delete"
          defaultMessage="Do you want to delete the organisation entry {organisation}?"
          values={{ organisation: type }}
        />
      );
    case 'removeDocument':
      return (
        <FormattedMessage
          id="Document.Attachment.delete"
          defaultMessage="Do you want to remove the document entry {document}?"
          values={{ document: type }}
        />
      );
    case 'createNewRecordHeader':
      return (
        <FormattedMessage
          id="Record_createNewRecordHeader"
          defaultMessage="New {type}"
          values={{ type }}
        />
      );
    case 'createNewTemplateHeader':
      return (
        <FormattedMessage
          id="Record_createNewTemplateHeader"
          defaultMessage="New {type} template"
          values={{ type }}
        />
      );
    case 'createNewRecordSubHeader':
      return (
        <FormattedMessage
          id="Record_createNewRecordSubHeader"
          defaultMessage="Please enter a name for the {type}"
          values={{ type }}
        />
      );
    case 'createNewTemplateSubHeader':
      return (
        <FormattedMessage
          id="Record_createNewTemplateSubHeader"
          defaultMessage="Please enter a name for the {type} template"
          values={{ type }}
        />
      );
    case 'nameHint':
      return (
        <FormattedMessage
          id="Record_nameHint"
          defaultMessage="{value} name"
          values={{ value: type }}
        />
      );
    case 'dsrDisclaimer':
      return (
        <FormattedMessage
          id="DSR.validationsDisclaimer"
          defaultMessage="This is merely an indication; we advise you to refer to the full text of the {value}."
          values={{ value: type }}
        />
      );
    case 'promotedFrom':
      return (
        <FormattedMessage
          id="Record.promottedFrom"
          defaultMessage="Promoted from {value}"
          values={{ value: type }}
        />
      );
    case 'noActiveLayoutsWarning':
      return (
        <FormattedMessage
          id="Record.noActiveLayoutsWarning"
          defaultMessage="Making this layout inactive will leave {value} record type with no active layouts.
              Are you sure you want to continue?"
          values={{ value: type }}
        />
      );
    case 'pdfBulkExportDisabledHint':
      return (
        <FormattedMessage
          id="Records.pdfBulkExportDisabledHint"
          defaultMessage="You can only select maximum of {value} records for export"
          values={{ value: type }}
        />
      );
    case 'copyOfName':
      return (
        <FormattedMessage
          id="Records.copyOfName"
          defaultMessage="Copy of {value}"
          values={{ value: type }}
        />
      );
    case 'expiryWarning':
      return (
        <FormattedMessage
          id="DSR.expiryWarning"
          defaultMessage="{days} remaining"
          values={{ days: type }}
        />
      );
    case 'overDueWarning':
      return (
        <FormattedMessage
          id="DSR.overDueWarning"
          defaultMessage="Overdue by {days}"
          values={{ days: type }}
        />
      );
    case 'share':
      return (
        <FormattedMessage
          id="Dashboard.share"
          defaultMessage="Share {dashboard}"
          values={{ dashboard: type }}
        />
      );
    case 'currentStep':
      return (
        <FormattedMessage
          id="DSR.currentStatus"
          defaultMessage="step {currentStatus} of 6"
          values={{ currentStatus: type }}
        />
      );
    case 'dsrExpiryWarning':
      return (
        <FormattedMessage
          id="DSR.expiryWarning"
          defaultMessage="{days} remaining"
          values={{ days: type }}
        />
      );
    case 'emptyRecord':
      return (
        <FormattedMessage
          id="Template.emptyLayout"
          defaultMessage="Empty {name}"
          values={{ name: type }}
        />
      );
    case 'featureNotPurchased':
      return (
        <FormattedMessage
          id="DataLibrary.featureNotPurchased"
          defaultMessage="{feature} has not been purchased for your tenant."
          values={{ feature: type }}
        />
      );
    default:
      return '';
  }
};

export const customFieldTranslations = {
  simplemasterdata: (
    <FormattedMessage
      id="Common.customSimpleMasterdata"
      defaultMessage="Custom simple data"
    />
  )
};
