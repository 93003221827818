import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import ActionHelp from '@material-ui/icons/Help';
import ArrowTooltip from '@packages/components/tooltip';
import styles from '@packages/ui/styles';
import defaultStyle from './style';

class Card extends React.Component {
  constructor(props) {
    super(props);
    const expanded = this.props.expanded === undefined ? true : this.props.expanded;
    this.state = {
      expanded
    };
    this.handleExpandChange = this.handleExpandChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.expanded !== nextProps.expanded) {
      this.setState({
        expanded: nextProps.expanded
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.expanded === nextProps.expanded) ||
      !(this.props.title === nextProps.title) ||
      !(this.props.children === nextProps.children) ||
      !(this.state === nextState);
  }

  handleExpandChange() {
    this.setState(prev => ({ expanded: !prev.expanded }));
  }

  getDefaultCardHeader() {
    const { subTitle, title, headerStyle, showIcon, icon, helpNotes, cardStyle } = this.props;
    const { expanded } = this.state;
    const headerTitle = (
      <div style={headerStyle}><FontAwesome
        name={expanded ? 'caret-down' : 'caret-right'}
        style={({ ...defaultStyle.collapseIcon, ...headerStyle})}
      />{title} {!expanded && subTitle && <span style={defaultStyle.subtitle}>{subTitle}</span>}
        {showIcon && <div style={{ paddingLeft: 12 }}>{icon}</div>}
        {helpNotes &&
        <div style={{ paddingLeft: 30 }}>
          <ArrowTooltip title={helpNotes}>
            <span>
              <IconButton style={styles.rightIcon} onClick={event => event.stopPropagation()}>
                <ActionHelp color="primary" />
              </IconButton>
            </span>
          </ArrowTooltip>
        </div>}
      </div>
    );
    return (
      <div
        onClick={this.handleExpandChange}
        role="presentation"
        style={{ ...defaultStyle.subHeader, marginTop: '45px', cursor: 'pointer', ...cardStyle }}
      >{headerTitle}
      </div>);
  }

  render () {
    const cardHeader = this.props.showCardTitle && this.getDefaultCardHeader();

    return (
      <div>
        {cardHeader}
        <Collapse
          in={this.state.expanded}
        >
          {this.props.children}
        </Collapse>
      </div>
    );
  }
}

Card.propTypes = {
  title: PropTypes.node,
  headerStyle: PropTypes.shape({}),
  rootStyle: PropTypes.shape({}),
  cardStyle: PropTypes.shape({}),
  subTitle: PropTypes.node,
  children: PropTypes.node,
  expanded: PropTypes.bool,
  showCardTitle: PropTypes.bool,
  showIcon: PropTypes.bool,
  icon: PropTypes.shape({}),
  helpNotes: PropTypes.string
};

Card.defaultProps = {
  expanded: true,
  headerStyle: {},
  rootStyle: {},
  cardStyle: {},
  title: null,
  subTitle: null,
  children: null,
  showCardTitle: true,
  showIcon: false,
  helpNotes: '',
  icon: {}
};

export default Card;
