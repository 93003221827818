/* eslint-disable default-param-last */
import Immutable from 'immutable';
import { filterExistingFieldItems } from '../tenantConfigUtils';

const defaultState = Immutable.fromJS({
  currentFilter: undefined,
  newFields: Immutable.List([]),
  availableFields: undefined,
  existingFields: [],
  currentChapterInfo: [],
  isFetching: false,
  unSavedData: false,
  prevLocation: '/tenant-configuration',
  recordName: undefined,
  derivedFrom: undefined,
  position: 0,
  isLoading: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PICK_FIELDS:ITEM:FETCH:SUCCESS': {
      if (action.data.length === 0) return state.set('isLoading', false);
      else {
        const updatedItems = state.get('newFields').concat(action.data);
        return state
          .set('newFields', updatedItems)
          .set(
            'availableFields',
            filterExistingFieldItems(updatedItems, [
              ...state.get('existingFields')
            ])
          )
          .set('position', state.get('position') + action.data.length)
          .set('isLoading', false);
      }
    }
    case 'PICK_FIELDS:FETCH:SET:LOADER':
      return state.set('isLoading', true);
    case 'LAYOUT:EDITOR:UPDATE:AVAILABLE:PICKFIELDS':
      return state.set('availableFields', action.availableFields);
    case 'PICK_FIELDS:ITEM:FETCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'LAYOUT:EDITOR:UPDATE:PICKFIELD:ITEMS':
      return state
        .set(
          'availableFields',
          Immutable.List(
            filterExistingFieldItems(
              [...state.get('newFields')],
              action.existingIds
            )
          )
        )
        .set('existingFields', action.existingIds);
    case 'LAYOUT:EDITOR:FILTERS:CHANGE':
      return state.set('currentFilter', action.filterName);
    case 'PICK_FIELDS:FETCH:RESET':
      return state
        .set('position', 0)
        .set('newFields', defaultState.get('newFields'))
        .set('availableFields', defaultState.get('availableFields'));
    case 'LAYOUT:EDITOR:RESET':
      return state
        .set('currentFilter', defaultState.get('currentFilter'))
        .set('recordName', defaultState.get('recordName'))
        .set('derivedFrom', defaultState.get('derivedFrom'))
        .set('currentChapterInfo', defaultState.get('currentChapterInfo'));

    case 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:INIT':
      return state.set('isFetching', true);
    case 'LAYOUT:EDITOR:UPDATE:FILTER:MENU': {
      return state
        .set('currentChapterInfo', {
          ...state.get('currentChapterInfo'),
          chapters: action.chapters
        })
        .set('currentFilter', action.filterName);
    }

    case 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:SUCCESS':
      return state
        .set('currentChapterInfo', action.data)
        .set('recordName', action.data.names)
        .set('derivedFrom', action.data.derivedFrom.names)
        .set('isFetching', false);

    case 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:FAIL':
      return state.set('isFetching', false);

    case 'LAYOUT:EDITOR:UNSAVED_DATA:SET':
      return state.set('unSavedData', action.value);
    case 'LAYOUT:EDITOR:UPDATE:DATA': {
      return state.set('currentChapterInfo', action.data);
    }
    case 'LAYOUT_EDITOR:DETAILS:SET': {
      const { fields, chapters, ...recordDetails } = action.data;
      return state
        .set('currentChapterInfo', {
          ...state.get('currentChapterInfo'),
          ...recordDetails
        })
        .set('recordName', action.data.names);
    }
    default:
      return state;
  }
};
