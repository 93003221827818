import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';

import DataRecipientDialog from '../../../environment/components/data-recipient-categories/components/dataRecipientCategoryDialog';
import MultipleSelectorDialog from './components/multiple-selector/multipleSelectorDialog';
import { multipleSelectorTypeTranslations } from '../../../environment/masterDataTranslations';
import { EEACountries } from '../../common-utils';

const DataRecipientCategorySelector = (props) => {
  const [open, setOpen] = useState(false);
  const [updated, setUpdated] = useState(false); // eslint-disable-line no-unused-vars
  const [multiple, setMultiple] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dataRecipientCategoryList, setDataRecipientCategoryList] = useState(
    []
  );
  const [
    multipleDataRecipientCategoryList,
    setMultipleDataRecipientCategoryList
  ] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [inputValue, setInputValue] = useState({});

  useEffect(() => {
    if (props.init()) {
      props.init();
    }
  }, []);

  useEffect(() => {
    const itemList = handleListItems(
      props.searchResults,
      props.dataRecipientCategories,
      props.fields,
      props.position
    );
    const { list, multipleList } = itemList;
    setDataRecipientCategoryList(list);
    setMultipleDataRecipientCategoryList(multipleList);
  }, [
    props.fields.getAll(),
    props.dataRecipientCategories,
    props.searchResults
  ]);

  const filterDataRecipientCategories = (selectedItems, searchedItems) => {
    const filteredData = searchedItems.filter((item) => {
      const index = selectedItems.findIndex(
        (selectedItem) => selectedItem.value.id === item.id
      );
      return index === -1;
    });
    return filteredData;
  };

  const handleGetMultipleItems = (newFields, items) => {
    const selectedItems = newFields.getAll();
    const multipleList =
      selectedItems && selectedItems.length > 0
        ? filterDataRecipientCategories(selectedItems, items)
        : items;
    const modifiedData = multipleList.map((item) => ({
      ...item,
      key:
        item.countries && item.countries.length > 0
          ? `${item.name}${item.countries.map((value) => ` (${value})`)}`
          : `${item.name}`
    }));
    return modifiedData;
  };

  const handleListItems = (
    searchResults,
    dataRecipientCategories,
    fields,
    position
  ) => {
    const searchedItems =
      searchText !== '' || searchResults.length > 0
        ? searchResults
        : dataRecipientCategories;
    const list = handleGetMultipleItems(fields, searchedItems);
    const multipleList = handleGetMultipleItems(
      fields,
      dataRecipientCategories
    );
    if (
      (multipleList.size < 15 || list.size < 15) &&
      dataRecipientCategories.size > 0 &&
      searchText === '' &&
      props.position !== position
    ) {
      props.getNextData(position);
    }
    const itemList = { list, multipleList };
    return itemList;
  };

  const handleAddClick = (searchTextValue) => {
    setOpen(true);
    setIsEdit(false);
    setMultiple(false);
    setSelectedIndex(-1);
    setInputValue({
      name: searchTextValue
    });
  };

  const handleEditClick = (index) => {
    setOpen(true);
    setIsEdit(true);
    setMultiple(false);
    setSelectedIndex(index);
    setInputValue(props.fields.get(index).value);
  };

  const handleMultipleSelect = () => {
    setOpen(false);
    setMultiple(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
    setMultiple(false);
    setSelectedIndex(-1);
    setSearchText('');
  };

  const handleClose = () => {
    props.resetSearchFilter();
    setMultiple(false);
    setSearchText('');
    if (props.init) {
      props.init();
    }
  };
  // Handler to remove item
  const handleRemoveItem = (selectedIndexValue) => {
    const selectedItems = props.fields.length ? [...props.fields.getAll()] : [];
    if (selectedIndexValue !== -1) {
      selectedItems.splice(selectedIndexValue, 1);
    }
    updateDataRecipientCategories(selectedItems);
  };

  // Handler to add/edit multiple items
  const handleMultipleItems = (selectedItems) => {
    let intlChecked = false;
    const items = props.fields.getAll() || [];
    const selectedEntities = [...items, ...selectedItems];
    if (selectedItems.size && selectedItems.size > 0) {
      // eslint-disable-next-line array-callback-return
      selectedItems.map((item) => {
        if (checkforIntlTransfer(item)) {
          intlChecked = true;
        }
      });
    }
    updateDataRecipientCategories(selectedEntities, intlChecked);
  };

  const checkforIntlTransfer = (item) => {
    let isIntlChecked = false;
    if (item.value.countries && item.value.countries.length > 0) {
      item.value.countries.forEach((country) => {
        if (!EEACountries.includes(country)) isIntlChecked = true;
      });
    }
    return isIntlChecked;
  };

  // Handler to add/edit items
  const handleSelectedItem = (selectedItem, closeDialog) => {
    const modifiedItem = { ...selectedItem };
    if (selectedIndex !== -1) {
      modifiedItem.note = props.fields.get(selectedIndex).note;
    }
    handleEditItem(modifiedItem, selectedIndex, closeDialog);
  };

  // Update the changed items.
  const handleEditItem = (selectedItem, selectedIndexValue, closeDialog) => {
    let intlChecked = false;
    const selectedEntities = props.fields.length
      ? [...props.fields.getAll()]
      : [];
    if (selectedIndexValue === -1) {
      selectedEntities.push(selectedItem);
    } else {
      selectedEntities.splice(selectedIndexValue, 1);
      selectedEntities.splice(selectedIndexValue, 0, selectedItem);
    }
    if (checkforIntlTransfer(selectedItem)) {
      intlChecked = true;
    }
    updateDataRecipientCategories(selectedEntities, intlChecked);

    if (closeDialog) {
      handleRequestClose();
    }
  };

  const updateDataRecipientCategories = (selectedItems, intlChecked) => {
    // There are cases where re-render is not invoked.
    setSearchText(''); // eslint-disable-line react/no-unused-state
    setUpdated(true);
    if (props.isRegistry) {
      props.updateRegistryFilters(selectedItems);
    } else {
      props.updateDataRecipientCategories(selectedItems, intlChecked);
    }
  };

  const handleSearch = (searchTextValue) => {
    setSearchText(searchTextValue);
    const searchParams = { ...searchTextValue, searchKey: 'name' };
    props.onSearch(searchParams);
  };

  const handleScrollEnd = () => {
    props.getNextData(props.position);
  };

  const handleUsageClick = (index) => {
    const { id } = props.fields.get(index).value;
    props.handleUsageClick(id, props.dataItemType);
  };

  const { userPermissions, isGlobal } = props;
  const selectedEntities = props.fields.getAll();

  const { createEditDataRecipientCategory } = userPermissions.toJS();

  return (
    <div>
      {selectedEntities && selectedEntities.length > 0 && (
        <ItemList
          id="item_list"
          isEditable={createEditDataRecipientCategory}
          {...props}
          type={props.label}
          handleRemoveItem={handleRemoveItem}
          handleEditClick={handleEditClick}
          handleEditItem={handleEditItem}
          selectedItems={selectedEntities}
          handleUsageClick={handleUsageClick}
        />
      )}
      <ItemSelector
        id="item_selector"
        multiValue={props.multiValue}
        createNewMenuItem={
          createEditDataRecipientCategory && props.createNewMenuItem
        }
        selectFromListMenuItem={props.selectFromListMenuItem || false}
        selectedItem={props.selectedItems}
        dataSource={dataRecipientCategoryList}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        hintTextLabel={props.hintTextLabel}
        handleSelectedItem={handleSelectedItem}
        handleAddClick={handleAddClick}
        handleMultipleSelect={handleMultipleSelect}
        onSearch={handleSearch}
      />
      <span>
        {open && (
          <DataRecipientDialog
            open={open}
            isEdit={isEdit}
            dataItemId={inputValue && inputValue.id}
            inputValue={inputValue}
            selectedItems={selectedEntities}
            onRequestClose={handleRequestClose}
            onSave={handleSelectedItem}
            source="records"
            hideTags={isGlobal}
          />
        )}
        {multiple && (
          <MultipleSelectorDialog
            id="multiple_selector"
            title={multipleSelectorTypeTranslations('dataRecipientCategories')}
            open={multiple}
            searchTextValue={searchText.searchText}
            onScrollStop={handleScrollEnd}
            onChooseFilter={props.onChooseFilter}
            filteredData={multipleDataRecipientCategoryList}
            onRequestClose={handleClose}
            handleMultipleItems={handleMultipleItems}
          />
        )}
      </span>
    </div>
  );
};

DataRecipientCategorySelector.propTypes = {
  dataRecipientCategories: PropTypes.instanceOf(Immutable.List),
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  resetSearchFilter: PropTypes.func,
  init: PropTypes.func,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  position: PropTypes.number,
  getNextData: PropTypes.func,
  isItemInLinkGroup: PropTypes.func,
  removeItemFromLinkGroup: PropTypes.func,
  toggleLinkGroupItem: PropTypes.func,
  updateItemInLinkGroup: PropTypes.func,
  multiValue: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  onChooseFilter: PropTypes.func,
  updateDataRecipientCategories: PropTypes.func,
  prepareLinkGroup: PropTypes.bool,
  label: PropTypes.node,
  hintTextLabel: PropTypes.node,
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  handleUsageClick: PropTypes.func,
  dataItemType: PropTypes.string,
  isRegistry: PropTypes.bool,
  updateRegistryFilters: PropTypes.func,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  isGlobal: PropTypes.bool
};

DataRecipientCategorySelector.defaultProps = {
  multiValue: true,
  resetSearchFilter: (e) => e,
  dataRecipientCategories: Immutable.List(),
  createNewMenuItem: true,
  updateDataRecipientCategories: (e) => e,
  label: null,
  selectedItems: null,
  hintTextLabel: null,
  getNextData: (e) => e,
  position: 0,
  init: (e) => e,
  isItemInLinkGroup: () => {},
  prepareLinkGroup: false,
  updateItemInLinkGroup: () => {},
  removeItemFromLinkGroup: () => {},
  toggleLinkGroupItem: () => {},
  selectFromListMenuItem: false,
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  onChooseFilter: (e) => e,
  handleUsageClick: (e) => e,
  dataItemType: '',
  isRegistry: false,
  updateRegistryFilters: (e) => e,
  userPermissions: Immutable.Map(),
  isGlobal: false
};

export default DataRecipientCategorySelector;
