/* eslint-disable require-yield */
/* eslint-disable no-unused-vars */
import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';

import { handleServiceDown } from '@packages/utils/common-utils';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import {
  getLayoutWithSectionFields,
  getSectionsAndFields,
  getUpdatedObject,
  getChildChapter
} from '../tenantConfigUtils';
import { tenantConfigurationTranslations } from '../tenantConfigurationTranslations';

export function* fetchLayoutChapter(action) {
  const { chapter, mode } = action;
  const isPreviewMode = mode === 'preview';
  const { id, libraryTenantId } = getParameterValuesFromHash(
    `/layout-${isPreviewMode ? 'preview' : 'editor'}/:id/:libraryTenantId`
  );

  yield put({ type: 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:INIT' });

  try {
    const modifiedChapter = chapter.split('_')[0];
    const response = yield registry
      .get('request')
      .get(
        `v1/custom-record-layout${
          isPreviewMode ? `/${libraryTenantId}/data-library` : ''
        }/${id}/chapter/${modifiedChapter}${isPreviewMode ? '/preview' : ''}`
      );

    switch (response.status) {
      case 200: {
        yield put({
          type: 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:SUCCESS',
          data: {
            ...getLayoutWithSectionFields(response.body),
            chapters: getSetDefaultFilters(
              transformReqChapters(response.body.chapters)
            )
          }
        });
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield put({ type: 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:FAIL' });
    yield handleServiceDown(err, 'custom');
  }
}

export function* saveChapter(action) {
  const store = registry.get('store');
  const { chapter, chapterData, nextChapter, prevLocation } = action;
  const modifiedChapterData = {
    ...chapterData,
    ...getSectionsAndFields(chapterData.fields),
    chapters: transformResChapters(chapterData.chapters)
  };

  const { id } = getParameterValuesFromHash('/layout-editor/:id');
  try {
    const modifiedChapter = chapter.split('_')[0];
    const response = yield registry
      .get('request')
      .put(
        `v1/custom-record-layout/${id}/chapter/${modifiedChapter}`,
        modifiedChapterData
      );

    switch (response.status) {
      case 200: {
        if (nextChapter)
          yield put({
            type: 'LAYOUT:EDITOR:FILTERS:CHANGE',
            filterName: nextChapter
          });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: tenantConfigurationTranslations.recordLayoutUpdated,
            type: 'success'
          }
        });
        yield put({
          type: 'LAYOUT:EDITOR:UNSAVED_DATA:SET',
          value: false
        });
        if (prevLocation) store.dispatch(replaceRouter(prevLocation));
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

const transformNewFields = (data) =>
  data.map((item) => ({
    field: item,
    width: item.fieldType === 'htmlText' ? 2 : 1,
    layoutFieldUniqueId: item.uniqueId,
    fieldLabel: item.names || {}
  }));

export function* getFields(action) {
  yield put({ type: 'PICK_FIELDS:FETCH:RESET' });
  yield getFilteredFields(action);
}

export function* getFilteredFields(action) {
  const { filter, searchValue = null, position = 0, rowCount = 10 } = action;
  const params = [`numberOfResults=${rowCount}&offset=${position}`];
  yield put({ type: 'PICK_FIELDS:FETCH:SET:LOADER' });

  try {
    if (filter) params.push(`filter=fieldType=${filter.key}`);
    if (searchValue) params.push(`search=names=${searchValue}`);
    const url = `/v1/custom-fields?${params.join('&')}`;
    const response = yield registry.get('request').get(url);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'PICK_FIELDS:ITEM:FETCH:SUCCESS',
          data: transformNewFields(response.body)
        });
        break;
      }
      default: {
        yield put({
          type: 'PICK_FIELDS:ITEM:FETCH:FAIL'
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

const transformReqChapters = (menu, parent) => {
  const modifiedItems = menu.map((item, index) => ({
    ...getUpdatedObject(item, parent),
    position: index + 1,
    layoutUniqueId: item.id || item.layoutUniqueId,
    ...(item.subChapters && {
      subMenu: transformReqChapters(
        item.subChapters,
        getUpdatedObject(item).key
      )
    })
  }));
  return modifiedItems;
};

const transformResChapters = (menu, parent) => {
  const modifiedItems = menu.map((item, index) => ({
    ...getUpdatedObject(item, parent),
    position: index + 1,
    layoutUniqueId: item.id || item.layoutUniqueId,
    ...(item.subMenu && {
      subChapters: transformResChapters(
        item.subMenu,
        getUpdatedObject(item).key
      )
    })
  }));
  return modifiedItems;
};

// set Default filter for all parents
const getSetDefaultFilters = (menu) => {
  const modifiedItems = menu.map((item) => ({
    ...item,
    defaultFilter: getChildChapter(item),
    enable: true,
    ...(item.subMenu && {
      subMenu: getSetDefaultFilters(item.subMenu)
    })
  }));
  return modifiedItems;
};
