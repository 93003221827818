import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
  unmarkFavouriteSuccess: (
    <FormattedMessage
      id="Dashboard.setUnFavouriteSuccess"
      defaultMessage="Dashboard has been unmarked as favourite"
    />
  ),
  markFavouriteSuccess: (
    <FormattedMessage
      id="Dashboard.setFavouriteSuccess"
      defaultMessage="Dashboard has been marked as favourite"
    />
  ),
  unmarkFavourite: (
    <FormattedMessage
      id="Dashboard.unmarkFavourite"
      defaultMessage="Unmark as favourite"
    />
  ),
  markFavourite: (
    <FormattedMessage
      id="Dashboard.markFavourite"
      defaultMessage="Mark as favourite"
    />
  ),
  noWidgetsMsg: (
    <FormattedMessage
      id="Dashboard.noWidgetsMsg"
      defaultMessage="To include new widgets, please switch into edit mode."
    />
  ),
  waitingForYou: (
    <FormattedMessage
      id="Dashboard.waitingForYou"
      defaultMessage="Waiting for you"
    />
  ),
  unreadMessages: (
    <FormattedMessage
      id="Dashboard.unreadMessages"
      defaultMessage="Unread messages"
    />
  ),
  openTaskAssigned: (
    <FormattedMessage
      id="Dashboard.openTaskAssigned"
      defaultMessage="Open task assigned to you"
    />
  ),
  unreadNotification: (
    <FormattedMessage
      id="Dashboard.unreadNotification"
      defaultMessage="Unread notifications"
    />
  ),
  unclaimedTask: (
    <FormattedMessage
      id="Dashboard.unclaimedTask"
      defaultMessage="Unclaimed tasks"
    />
  ),
  recordUpdates: (
    <FormattedMessage
      id="Dashboard.recordUpdates"
      defaultMessage="Record updates"
    />
  ),
  recordBreakdown: (
    <FormattedMessage
      id="Dashboard.recordBreakdown"
      defaultMessage="Record breakdown"
    />
  ),
  geoFlow: (
    <FormattedMessage
      id="Overview.thirdcountries.title"
      defaultMessage="Geo-flow"
    />
  ),
  recordsWithRisk: (
    <FormattedMessage
      id="Dashboard.recordsWithRisk"
      defaultMessage="Records with risks"
    />
  ),
  totalRisk: (
    <FormattedMessage id="Dashboard.totalRisk" defaultMessage="Total risks" />
  ),
  noTasks: (
    <FormattedMessage
      id="Dashboard.noTasks"
      defaultMessage="No tasks to be displayed"
    />
  ),
  noDeadlines: (
    <FormattedMessage
      id="Dashboard.noDeadlines"
      defaultMessage="No pending deadlines"
    />
  ),
  records: (
    <FormattedMessage
      id="Overview.Countries.Marker"
      defaultMessage="record(s)"
    />
  ),
  geoFlowSubtitle: (
    <FormattedMessage
      id="Overview.thirdcountries.subtitle"
      defaultMessage="Click a country to see its role in your privacy administration"
    />
  ),
  addWidget: (
    <FormattedMessage id="Dashboard.addWidget" defaultMessage="Add widget" />
  ),
  createNewDashboard: (
    <FormattedMessage
      id="Dashboard.createNewDashboard"
      defaultMessage="Create new dashboard"
    />
  ),
  editDashboard: (
    <FormattedMessage
      id="Dashboard.editDashboard"
      defaultMessage="Edit dashboard"
    />
  ),
  editWidget: (
    <FormattedMessage id="Dashboard.editWidget" defaultMessage="Edit widget" />
  ),
  addNewWidget: (
    <FormattedMessage
      id="Dashboard.addNewWidget"
      defaultMessage="Add new widget"
    />
  ),
  personal: (
    <FormattedMessage id="Dashboard.personal" defaultMessage="Personal" />
  ),
  tenant: <FormattedMessage id="Dashboard.tenant" defaultMessage="Tenant" />,
  dashboardName: (
    <FormattedMessage
      id="Dashboard.dashboardName"
      defaultMessage="Dashboard name"
    />
  ),
  dashboardType: (
    <FormattedMessage
      id="Dashboard.dashboardType"
      defaultMessage="Dashboard type"
    />
  ),
  layoutLabel: (
    <FormattedMessage
      id="Dashboard.layoutLabel"
      defaultMessage="Layout label"
    />
  ),
  widgetNameWarning: (
    <FormattedMessage
      id="Dashboard.widgetNameWarning"
      defaultMessage="You can enter an optional name for the widget here:"
    />
  ),
  widgetType: (
    <FormattedMessage id="Dashboard.widgetType" defaultMessage="Widget type" />
  ),
  view: <FormattedMessage id="Dashboard.view" defaultMessage="View" />,
  shareDashboardMessage: (
    <FormattedMessage
      id="Dashboard.shareDashboardMessage"
      defaultMessage="Dashboard shared successfully"
    />
  ),
  confirmDeleteDashboard: (
    <FormattedMessage
      id="Dashboard.confirmDeleteDashboard"
      defaultMessage="Are you sure that you want to delete this dashboard ?"
    />
  ),
  deleteDashboard: (
    <FormattedMessage
      id="Dashboard.deleteDashboard"
      defaultMessage="Delete dashboard"
    />
  ),
  saveDashboardMessage: (
    <FormattedMessage
      id="Dashboard.saveDashboardMessage"
      defaultMessage="Dashboard saved successfully"
    />
  ),
  deleteDashboardMessage: (
    <FormattedMessage
      id="Dashboard.deleteDashboardMessage"
      defaultMessage="Dashboard successfully deleted"
    />
  ),
  rejectDashboardSuccess: (
    <FormattedMessage
      id="Dashboard.rejectDashboardSuccess"
      defaultMessage="Dashboard successfully rejected"
    />
  ),
  dashboards: (
    <FormattedMessage id="Dashboard.dashboards" defaultMessage="Dashboards" />
  ),
  noAccess: (
    <FormattedMessage
      id="Dashboard.noAccess"
      defaultMessage="You don't have enough rights to view this widget"
    />
  ),
  rejectDashboard: (
    <FormattedMessage
      id="Dashboard.rejectDashboard"
      defaultMessage="Reject dashboard"
    />
  ),
  confirmRejectDashboard: (
    <FormattedMessage
      id="Dashboard.confirmRejectDashboard"
      defaultMessage="Are you sure that you want to reject this dashboard ?"
    />
  ),
  confirmDeleteWidget: (
    <FormattedMessage
      id="Dashboard.confirmDeleteWidget"
      defaultMessage="Are you sure that you want to remove this widget ?"
    />
  ),
  deleteWidget: (
    <FormattedMessage
      id="Dashboard.deleteWidget"
      defaultMessage="Delete widget"
    />
  ),
  unassigned: (
    <FormattedMessage id="Dashboard.unassigned" defaultMessage="Unassigned" />
  )
};
