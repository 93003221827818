/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import CustomDialog from '@packages/components/custom-dialog';
import { recordTranslations } from '@packages/utils/commontranslations';
import OrganisationEntityDialog from '../../../organisation-entities/organisationEntityDialog';

const DpoSelector = (props) => {
  const [open, setOpen] = useState(false);
  const [contactDetails, setContactDetails] = useState([]);

  const noNameMessage = recordTranslations.noDpoName;
  const { formatMessage } = props.intl;

  useEffect(() => {
    const selectedItem = props.fields.getAll() || [];
    const itemList = filterEntityDetails(selectedItem, props.entityDetails);
    setContactDetails(itemList);
  }, [props.fields, props.entityDetails]);

  const filterEntityDetails = (selectedItems, searchedItems) => {
    const modifiedItem = getModifiedList(searchedItems);
    const filteredData = modifiedItem.filter((item) => {
      const index = selectedItems.findIndex(selectedItem =>
        (selectedItem.name || selectedItem.value.name) === item.name);
      return (index === -1);
    });
    return filteredData;
  };

  const getModifiedList = (item) => {
    const list = [];

    const dpoContact = getContactEntity(item, 'dataProtectionOfficer');
    const repContact = getContactEntity(item, 'representative');

    if (dpoContact) {
      list.push(dpoContact);
    }
    if (repContact) {
      list.push(repContact);
    }
    return list;
  };

  const getContactEntity = (item, type) => {
    const contact = item[type] && (item[type].name || item[type].email || item[type].workPhone);
    return contact ?
      { id: item.id,
        name: contact,
        type: formatMessage(recordTranslations[type].props),
        contactMemberType: type === 'dataProtectionOfficer' ? 'DataProtectionOfficer' : 'Representative',
        addlkey: !item[type].name ? formatMessage(noNameMessage.props) : '' }
      : null;
  };

  const handleSelectedItem = (selectedItem) => {
    const selectedEntities = props.fields.length ? [...props.fields.getAll()] : [];
    const modifiedItem = { ...selectedItem.value };

    selectedEntities.push(modifiedItem);
    props.updateContactDetails(selectedEntities);
  };

  const handleNewContacts = (orgDetails, closeDialog) => {
    const selectedEntities = props.fields.length ? [...props.fields.getAll()] : [];
    const dpoContact = getContactEntity(orgDetails.value, 'dataProtectionOfficer');
    const repContact = getContactEntity(orgDetails.value, 'representative');

    if (dpoContact) {
      selectedEntities.push(dpoContact);
    }
    if (repContact) {
      selectedEntities.push(repContact);
    }
    props.updateContactDetails(selectedEntities);
    if (closeDialog) {
      requestClose();
    }
  };

  const handleRemoveItem = (selectedIndexValue) => {
    const selectedItems = props.fields.length ? [...props.fields.getAll()] : [];
    if (selectedIndexValue !== -1) {
      selectedItems.splice(selectedIndexValue, 1);
    }
    props.updateContactDetails(selectedItems);
  };

  const handleAddClick = () => {
    setOpen(true);
  };

  const requestClose = () => {
    setOpen(false);
  };

  const resetDPO = () => {
    props.resetDPO();
    setOpen(false);
  };

  const selectedEntities = props.fields.getAll() || [];
  return (
    <div>
      {selectedEntities && selectedEntities.length > 0 &&
      <ItemList
        id="item_list"
        {...props}
        type={props.label}
        isEditable={false}
        handleRemoveItem={handleRemoveItem}
        selectedItems={selectedEntities}
        isNoteEditable={false}
      />}
      <ItemSelector
        id="item_selector"
        multiValue={props.multiValue}
        dataSource={contactDetails}
        dataSourceConfig={props.dataSourceConfig || { text: 'name', value: 'name' }}
        additionalInfoConfig={{ text: 'type' }}
        handleSelectedItem={handleSelectedItem}
        hintTextLabel={props.hintTextLabel}
        handleAddClick={handleAddClick}
        createNewMenuItem={contactDetails.length === 0 && selectedEntities.length === 0}
        onFocus={props.onFocus}
      />
      <span>
        {open && (props.isSuperAdmin || props.isAdmin) &&
        <OrganisationEntityDialog
          open={open}
          label={<FormattedMessage
            id="Records.contact"
            description="contact"
            defaultMessage="contact details for {type}"
            values={{ type: props.entityDetails.name }}
          />}
          onRequestClose={requestClose}
          isEdit={true}
          showOnlyDPOContacts={true}
          entityId={props.entityDetails.id}
          handleSelectedItem={handleNewContacts}
        />
        }
        {open && !(props.isSuperAdmin || props.isAdmin) &&
        <CustomDialog
          show={open}
          proceed={resetDPO}
          isDelete={false}
          content={recordTranslations.dpoPermission}
        />}
      </span>
    </div>
  );
};

DpoSelector.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number,
    getAll: PropTypes.func
  }).isRequired,
  entityDetails: PropTypes.shape({
    dataProtectionOfficer: PropTypes.shape({}),
    representative: PropTypes.shape({}),
    id: PropTypes.string,
    name: PropTypes.string
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  isAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  updateContactDetails: PropTypes.func,
  hintTextLabel: PropTypes.string,
  label: PropTypes.string,
  multiValue: PropTypes.bool,
  resetDPO: PropTypes.func,
  dataSourceConfig: PropTypes.shape({})
};

DpoSelector.defaultProps = {
  entityDetails: {},
  isAdmin: false,
  isSuperAdmin: false,
  updateContactDetails: e => e,
  hintTextLabel: '',
  label: '',
  multiValue: true,
  resetDPO: e => e,
  dataSourceConfig: undefined
};

export default injectIntl(DpoSelector);
